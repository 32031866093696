<mat-accordion *ngIf="showSubArguments" class="inner-accordion">
    <ng-container *ngFor="let argument of this._argument.subArguments">
        <mat-expansion-panel
            [expanded]="argument.parameterId === this.openedSubArgument"
            (opened)="this.openedSubArgument = argument.parameterId"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ argument.id }}
                    <button
                        class="deleteButton"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.dataformat.attribute.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
                <label [attr.for]="'select-attribute-' + argument.parameterId" class="small-input-label">{{
                    'v2.action.argument.dataformat.attribute.select' | translate
                }}</label>
                <select
                    [attr.id]="'select-attribute-' + argument.parameterId"
                    class="small-input"
                    [ngModel]="this.removeQuotesIfPresent(argument.value)"
                    (ngModelChange)="onDataFormatAttributeSelected($event, argument)">
                    <option
                        *ngFor="let dataFormatAttribute of dataFormatAttributes; trackBy: identifyAttribute"
                        [value]="dataFormatAttribute.name">
                        {{ dataFormatAttribute.name }}
                    </option>
                </select>
            </ng-container>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="add-button nocodexbutton" type="button" mat-raised-button color="primary" (click)="onAddDataFormatAttribute()">
    <mat-icon>add</mat-icon>
    <span>{{ 'v2.action.argument.dataformat.attribute.add.attribute' | translate }}</span>
</button>
