import { Component, Input, OnInit } from '@angular/core';
import { AppFacade } from '@core/facades/app.facade';
import { GenerativeTaskDto } from '../../../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';

@Component({
    selector: 'codex-generative-sender-message-text',
    templateUrl: './generative-sender-message-text.component.html',
    styleUrls: ['./generative-sender-message-text.component.scss'],
    standalone: false,
})
export class GenerativeSenderMessageTextComponent {
    user$ = this.appFacade.user;

    @Input()
    message: GenerativeTaskDto;

    constructor(private appFacade: AppFacade) {}
}
