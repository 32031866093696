<mat-dialog-content class="!p-4">
    <img width="300" src="/src/images/theme/notify.svg" />
    <h2 class="mb-2">Co-Dex.eu BVBA</h2>
    <h4 class="mb-2">BE 0693.665.707</h4>
    <div class="info text-white">
        <div class="address">
            <h5 class="font-bold mb-1">{{ 'codex.info.address.title' | translate }}</h5>
            <p>Albert I-Laan 23</p>
            <p>8920 Langemark-Poelkappelle</p>
            <p>{{ 'codex.info.address.country' | translate }}</p>
        </div>
        <div class="contact-info mb-1">
            <h5 class="font-bold">{{ 'codex.info.contact.information.title' | translate }}</h5>
            <p>Phone: +32 473 93 34 30</p>
            <p>Email: info&#64;co-dex.eu</p>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="privacy text-white mb-2">
        <a data-name="privacy-policy" target="_blank" href="{{ 'codex.info.privacy.policy' | translate }}">{{
            'codex.info.privacy.policy.title' | translate
        }}</a>
        -
        <a data-name="cookie-policy" target="_blank" href="{{ 'codex.info.cookie.policy' | translate }}">Cookie</a>
    </div>
</mat-dialog-content>
