import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BillingEntry } from '../../../features/billing/dto/billingentry';
import { backofficeEnvironment } from '@shared/environment';
import { BillingPackage } from '../../../features/billing/dto/billingpackage';
import { CompanyDto } from '../../shared/interfaces/company.dto';
import { InitiateUpdateBillingPackageSessionDto } from '../../../features/add-company/dto/initiate-update-billing-package-session.dto';
import { LoggerService } from '@backoffice/utils';

@Injectable()
export class BillingService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    initiateUpdateBillingDetails(company: CompanyDto, returnUrl: string): Observable<InitiateUpdateBillingPackageSessionDto> {
        return this.httpClient.get<InitiateUpdateBillingPackageSessionDto>(
            `${backofficeEnvironment.rest.v2.billingpackage}initiate-update-billing-details?companyId=${company.id}&returnUrl=${returnUrl}`
        );
    }

    initiateTopUpAiCredits(
        company: CompanyDto,
        returnUrl: string,
        amount: number,
        couponCode: string
    ): Observable<InitiateUpdateBillingPackageSessionDto> {
        const params = new HttpParams().set('returnUrl', returnUrl).set('amount', amount);

        if (couponCode) {
            params.set('couponCode', couponCode);
        }

        return this.httpClient.get<InitiateUpdateBillingPackageSessionDto>(
            `${backofficeEnvironment.rest.v2.billingpackage}initiate-top-up-ai-credits?companyId=${company.id}`,
            { params: params }
        );
    }

    public getBillingPackage(companyId: string): Observable<BillingPackage> {
        this.log.debug('Getting billingpackage for company', [companyId]);

        const params = new HttpParams().set('companyId', companyId);
        return this.httpClient.get<BillingPackage>(`${backofficeEnvironment.rest.v2.billingpackage}`, { params: params });
    }

    public getApplicationBilling(applicationId: string, billingView: string): Observable<BillingEntry[]> {
        this.log.debug('Getting billing for application && billingview', [applicationId, billingView]);

        const params = new HttpParams().set('billingView', billingView).set('applicationId', applicationId);
        return this.httpClient.get<BillingEntry[]>(`${backofficeEnvironment.rest.v2.billingentries}`, { params: params });
    }
}
