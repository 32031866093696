<div class="generative-message w-full">
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'SUCCESS'">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text">
                <markdown [start]="5" [data]="message.answer" [disableSanitizer]="false"></markdown>
            </div>
        </div>
    </div>
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'FAILED'">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text max-w-[85%]">An error occured</div>
        </div>
    </div>
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'GENERATING' && !message.answer">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text generating">
                <div class="h-5 w-12 rounded">
                    <img height="20" width="50" class="object-cover" src="/src/images/theme/chat_loading.gif" style="height: 20px" />
                </div>
            </div>
        </div>
    </div>

    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'GENERATING' && message.answer">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text generating">
                {{ message.answer }}
            </div>
        </div>
    </div>
</div>
