import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Argument, Scope, ScopeItem } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-action-edit-scope-dialog',
    templateUrl: './action-edit-scope-dialog.component.html',
    styleUrls: ['./action-edit-scope-dialog.component.scss'],
    standalone: false,
})
export class ActionEditScopeDialogComponent {
    contextId: string;

    argument: Argument;

    scopeItems: ScopeItem[];

    scope: Scope;

    constructor(
        private readonly fb: FormBuilder,
        private dialogRef: MatDialogRef<ActionEditScopeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.argument = data.argument;
        this.scope = data.scope;
        this.contextId = data.contextId;

        if (this.scope.scopeItemByInvocation.get(this.contextId)) {
            this.scopeItems = Array.from(this.scope.scopeItemByInvocation.get(this.contextId).values());
        } else {
            this.scopeItems = [];
        }
    }

    isEligableToUse(scopeItem: ScopeItem) {
        return Scope.getMatchingTypes(scopeItem.type).indexOf(this.argument.parameter.type) > -1;
    }

    useScopeItem(scopeItem: ScopeItem) {
        this.dialogRef.close(scopeItem);
    }
}
