import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { GUIDFunctions } from '@shared/utils';
import { Output as ProgramOutput } from '@backoffice/data-access/editor';
import { TabDefinition } from '../../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-action-edit-outputs',
    templateUrl: './action-edit-outputs.component.html',
    styleUrls: ['./action-edit-outputs.component.scss'],
    standalone: false,
})
export class ActionEditOutputsComponent implements AfterViewInit {
    @Input()
    action: Action;

    @Output()
    actionUpdated: EventEmitter<{ action: Action }> = new EventEmitter<{ action: Action }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    @Input()
    language: string;

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    onAddOutput() {
        if (!this.action.program.outputs) {
            this.action.program.outputs = [];
        }
        const output = new ProgramOutput();
        output.id = new GUIDFunctions().newGuid();
        output.selectorId = new GUIDFunctions().newGuid();
        this.action.program.outputs.push(output);
        this.actionUpdated.emit({ action: this.action });
        this.action.program.outputs = [...this.action.program.outputs];
        setTimeout(() => initFlowbite());
    }

    onRemoveOutput(output: ProgramOutput) {
        this.action.program.outputs.splice(this.action.program.outputs.indexOf(output), 1);
        this.action.program.outputs = [...this.action.program.outputs];
        this.actionUpdated.emit({ action: this.action });
        setTimeout(() => initFlowbite());
    }

    onChangeOutput($event) {
        this.actionUpdated.emit($event);
    }

    identifyOutput(index, item: ProgramOutput) {
        return item.id;
    }
}
