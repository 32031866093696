import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Part, ScreenType, Template } from '@backoffice/data-access/editor';
import { TemplateEditorState } from '../state/template-editor.state';
import {
    addPartToSelection,
    changeCurrentScreenType,
    clearTemplateDialogData,
    clearTemplateDialogFilter,
    closeToolpane,
    deleteTemplateSuccess,
    deselectPart,
    disablePanningToolSwitch,
    disableSelectionToolSwitch,
    enablePanningTool,
    enablePanningToolSwitch,
    enableSelectionTool,
    enableSelectionToolSwitch,
    endDrag,
    fetchTemplateError,
    fetchTemplateSuccess,
    loadTemplateDialogSuccess,
    openToolpane,
    refreshTemplateSuccess,
    selectPart,
    startDrag,
    templateDialogFacetsChanged,
    templateDialogFilterPluginsChanged,
    templateDialogPaginationChanged,
    templateDialogSearchTermChanged,
    updateTemplate,
} from '../actions/templates-editor.actions';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { TemplateContextEditorState, TemplatesContextEditorState } from '../state/template-context-editor.state';
import { TemplateDialogState } from '../state/template-dialog.state';
import { Page } from '@shared/data-access';
import { OverviewTemplateDto } from '../../dto/overview/overview-template.dto';
import { SelectedFacets } from '../../dto/overview/facets.dto';

const adapterAction = createEntityAdapter<Template>();

const contextAdapterAction = createEntityAdapter<TemplateContextEditorState>();
export const templateInitialState: TemplateEditorState = adapterAction.getInitialState({});
export const templateDragInitialState: TemplatesContextEditorState = contextAdapterAction.getInitialState({});

export const templateDialogState: TemplateDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const templateReducer = (initialData: TemplateEditorState) =>
    createReducer(
        initialData,
        on(fetchTemplateSuccess, fetchTemplateError, (state, { template }) => adapterAction.addOne(template, state)),
        on(updateTemplate, refreshTemplateSuccess, (state, { template }) => {
            let templateIdsToBeRemovedFromState: string[] = [];
            Object.keys(state.entities).forEach(templateId => {
                if (state.entities[templateId]?.inheritFromTemplateId === template.id) {
                    templateIdsToBeRemovedFromState.push(templateId);
                }
            });
            let stateWithoutInheritingTemplates = adapterAction.removeMany(templateIdsToBeRemovedFromState, state);
            return adapterAction.updateOne(
                {
                    id: template.id,
                    changes: template,
                },
                stateWithoutInheritingTemplates
            );
        }),
        on(deleteTemplateSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

export const templateContextReducer = (initialData: TemplatesContextEditorState) =>
    createReducer(
        initialData,
        on(fetchTemplateSuccess, fetchTemplateError, (state, { template }) =>
            contextAdapterAction.addOne(
                {
                    id: template.id,
                    currentScreenTypes: template.chosenScreenType
                        ? template.chosenScreenType.split(';').map(item => {
                              const trimmedItem = item.trim();
                              if (trimmedItem in ScreenType) {
                                  return ScreenType[trimmedItem as keyof typeof ScreenType];
                              } else {
                                  throw new Error(`Invalid ScreenType: ${trimmedItem}`);
                              }
                          })
                        : [ScreenType.ALL_SCREENS],
                    selectedParts: [],
                    currentTool: 'selection',
                },
                state
            )
        ),
        on(startDrag, (state, { part, templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        selectedParts: state.entities[templateId]?.selectedParts,
                        draggingPart: part,
                    },
                },
                state
            );
        }),
        on(endDrag, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        selectedParts: state.entities[templateId]?.selectedParts,
                        draggingPart: undefined,
                    },
                },
                state
            );
        }),
        on(enablePanningTool, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentTool: 'panning',
                    },
                },
                state
            );
        }),
        on(enableSelectionTool, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentTool: 'selection',
                    },
                },
                state
            );
        }),
        on(enablePanningToolSwitch, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentTool: 'panning',
                        previousTool: state.entities[templateId]?.currentTool,
                    },
                },
                state
            );
        }),
        on(enableSelectionToolSwitch, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentTool: 'selection',
                        previousTool: state.entities[templateId]?.currentTool,
                    },
                },
                state
            );
        }),
        on(disablePanningToolSwitch, disableSelectionToolSwitch, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentTool: state.entities[templateId]?.previousTool,
                    },
                },
                state
            );
        }),
        on(openToolpane, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        toolpaneOpen: true,
                    },
                },
                state
            );
        }),
        on(closeToolpane, (state, { templateId }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        toolpaneOpen: false,
                    },
                },
                state
            );
        }),
        on(changeCurrentScreenType, (state, { templateId, currentScreenTypes }) => {
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        currentScreenTypes: currentScreenTypes,
                    },
                },
                state
            );
        }),
        on(addPartToSelection, (state, { part, templateId }) => {
            if (!!part) {
                let existingSelectedPartIndex = state.entities[templateId]?.selectedParts?.findIndex(
                    existingSelectedPart => existingSelectedPart.id === part.id
                );
                if (existingSelectedPartIndex === -1) {
                    state.entities[templateId]?.selectedParts?.push(part);
                }
            }
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        selectedPart: part,
                        selectedParts: state.entities[templateId]?.selectedParts,
                    },
                },
                state
            );
        }),
        on(selectPart, (state, { part, templateId }) => {
            if (!!part && !!state.entities[templateId]) {
                state.entities[templateId].selectedParts = [part];
            } else if (!part && !!state.entities[templateId]) {
                state.entities[templateId].selectedParts = [];
            }
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        selectedPart: part,
                        selectedParts: state.entities[templateId]?.selectedParts,
                    },
                },
                state
            );
        }),
        on(deselectPart, (state, { templateId, deselectedPartId }) => {
            let newSelectedParts: Part[] | undefined = [];
            if (!!deselectedPartId) {
                let existingSelectedPartIndex = state.entities[templateId]?.selectedParts?.findIndex(part => part.id === deselectedPartId);
                if (existingSelectedPartIndex || existingSelectedPartIndex === 0) {
                    state.entities[templateId]?.selectedParts?.splice(existingSelectedPartIndex, 1);
                    newSelectedParts = state.entities[templateId]?.selectedParts;
                }
            }
            return contextAdapterAction.updateOne(
                {
                    id: templateId,
                    changes: {
                        selectedPart: undefined,
                        selectedParts: newSelectedParts,
                    },
                },
                state
            );
        })
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(templateDialogFacetsChanged, (state, { facets }) => facets),
        on(clearTemplateDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(templateDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearTemplateDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(templateDialogPaginationChanged, (_, { page }) => page),
        on(templateDialogSearchTermChanged, () => 0),
        on(clearTemplateDialogFilter, () => 0),
        on(templateDialogFacetsChanged, () => 0),
        on(templateDialogFilterPluginsChanged, () => 0),
        on(templateDialogSearchTermChanged, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(templateDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearTemplateDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(templateDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearTemplateDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewTemplateDto> | undefined) =>
    createReducer(
        initialData,
        on(loadTemplateDialogSuccess, (_, { data }) => data),
        on(clearTemplateDialogData, () => undefined)
    );

export const templateDialogReducers = combineReducers({
    page: pageReducer(templateDialogState.page),
    maxResults: maxResultsReducer(templateDialogState.maxResults),
    searchTerm: searchTermReducer(templateDialogState.searchTerm),
    result: resultReducer(templateDialogState.result),
    facets: facetsReducer(templateDialogState.facets),
    filterPlugins: filterPluginsReducer(templateDialogState.filterPlugins),
});
