import { Method } from '../../../../../../../../libs/backoffice/data-access/editor/src/lib/action/model/method';
import { Action, Program } from '@backoffice/editor/data-access/action';
import { Invocation } from '../../../../../../../../libs/backoffice/data-access/editor/src/lib/action/model/invocation';
import { ChangeDetectorRef } from '@angular/core';
import { NextInvocation } from '../../../../../../../../libs/backoffice/data-access/editor/src/lib/action/model/next-invocation';

export class ActionGrid extends mxGraph {
    invocationVertices: Map<string, any> = new Map<string, any>();
    nextInvocationCells: Map<string, any> = new Map<string, any>();
    defaultEdgeStyle: string;
    program: Program;
    action: Action;
    changeDetectorRef: ChangeDetectorRef;
    selectedCellId: string;
    pickableMethods: Map<string, mxDragSource> = new Map<string, mxDragSource>();
    methodMap: Map<string, Method>;

    compactTreeLayout: mxCompactTreeLayout;

    constructor(element: any, action: Action, changeDetectorRef: ChangeDetectorRef, methodMap: Map<string, Method>) {
        super(element);
        this.program = action.program;
        this.action = action;
        this.methodMap = methodMap;
        this.changeDetectorRef = changeDetectorRef;
        this.createHierarchicalLayout();
        this.initStyle();
        this.initPanning();
        this.initSelection();
        this.setEnabled(true);
        this.initGrid();
        this.getModel().beginUpdate();
        this.initNodes();
        this.initEdges();
        this.initConnectorHighLights();
        this.initConnectorPreview();
        this.initFixedTerminalPoint();
        this.initHotspots();
        this.getModel().endUpdate();
        this.initializeSelectedCell(this.program.invocations[0].id);
        this.addMouseListener({
            mouseDown: this.onClick,
            mouseMove: this.onMove,
            mouseUp: this.onMouseUp,
        });
        setTimeout(() => this.scrollCellToVisible(this.getModel().getCell(this.program.invocations[0].id), true), 500);
        this.getView().refresh();
    }

    executeHierachicalLayout() {
        this.compactTreeLayout.execute(this.getDefaultParent());
    }

    createHierarchicalLayout() {
        this.compactTreeLayout = new mxCompactTreeLayout(this);
        this.compactTreeLayout.horizontal = false;
        this.compactTreeLayout.levelDistance = 100;
        this.compactTreeLayout.nodeDistance = 40;
        this.compactTreeLayout.resetEdges = false;
        this.compactTreeLayout.prefHozEdgeSep = 10;
        this.compactTreeLayout.prefVertEdgeOff = 40;
        //this.hierarchicalLayout.disableEdgeStyle = false
        //this.hierarchicalLayout.edgeStyle = 'orthogonalEdgeStyle';
        //this.hierarchicalLayout.interRankCellSpacing = 40;
        //this.hierarchicalLayout.intraCellSpacing = 40;
        //this.hierarchicalLayout.interHierarchySpacing = 0;
        //this.hierarchicalLayout.parallelEdgeSpacing = 0;
        //this.hierarchicalLayout.tightenToSource = false;
        //this.hierarchicalLayout.traverseAncestors = false;
    }

    update(action: Action) {
        this.pickableMethods.forEach((ds: mxDragSource, key: string) => {
            ds.reset();
            ds.element['mxListenerList'].forEach(eventListener => {
                removeEventListener(eventListener.name, eventListener.f);
            });
            ds.element['mxListenerList'] = null;
        });

        this.pickableMethods.clear();
        this.program = action.program;
        this.action = action;

        this.getModel().beginUpdate();
        this.removeCellsFromParent(this.getChildVertices(this.getDefaultParent()));
        this.getModel().clear();
        this.action.program.invocations.forEach(invocation => {
            this.insertInvocationInGraph(this.getDefaultParent(), invocation);
        });
        this.action.program.invocations.forEach(invocation => {
            const source = this.getModel().getCell(invocation.id);
            for (let i = 0; i < invocation.nextInvocations.length; i++) {
                const nextInvocation = invocation.nextInvocations[i];
                this.insertEdge(
                    this.getDefaultParent(),
                    nextInvocation.id,
                    this.getEdgeLabel(i + 1, invocation.id, nextInvocation),
                    source,
                    this.getModel().getCell(nextInvocation.invocationId),
                    this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                );
                this.nextInvocationCells.set(nextInvocation.id, this.getModel().getCell(nextInvocation.id));
            }
        });
        this.getModel().endUpdate();
        this.getView().refresh();
        this.scrollCellToVisible(this.getModel().getCell(this.program.invocations[0].id), true);
        this.initializeSelectedCell(this.program.invocations[0].id);
    }

    initializeSelectedCell(id: string) {
        let cell = this.getModel().getCell(this.selectedCellId);
        if (!!cell && !cell.edge) {
            this.onChangeSelectedCell(id);
            this.selectedCellId = id;
            window['codex'].actions.onClickInvocation(id);
        } else if (cell && cell.edge) {
            this.onChangeSelectedCell(id);
            this.selectedCellId = id;
            window['codex'].actions.onClickEdge(id);
        }
    }

    public updateAction(action: Action) {
        this.program = action.program;
        this.action = action;
    }

    onMove(sender, me) {}

    onMouseUp = (sender, me) => {
        const event = new Event('change');
        document.activeElement.dispatchEvent(event);
        if (me.state && me.state.cell && !me.state.cell.edge) {
            const element = document.elementFromPoint(me.evt.clientX, me.evt.clientY);
            if (
                this.selectedCellId !== me.state.cell.id &&
                element &&
                typeof element.className === 'string' &&
                element.className.indexOf('close-button') === -1
            ) {
                this.onChangeSelectedCell(me.state.cell.id);
                window['codex'].actions.onClickInvocation(me.state.cell.id);
            } else if (
                this.selectedCellId === me.state.cell.id &&
                element &&
                typeof element.className === 'string' &&
                element.className.indexOf('close-button') === -1
            ) {
                window['codex'].actions.onClickInvocation(me.state.cell.id);
            }
        } else if (me.state && me.state.cell && me.state.cell.edge) {
            window['codex'].actions.onClickEdge(me.state.cell.id, this.getModel().getTerminal(me.state.cell, true).id);
            this.onChangeSelectedCell(me.state.cell.id);
        }
    };

    updateInvocation(invocation: Invocation) {
        const selectedCell = this.getModel().getCell(this.selectedCellId);
        if (!!selectedCell && !selectedCell.edge) {
            this.getModel().beginUpdate();
            const vertex = this.getModel().getCell(invocation.id);
            this.getModel().setValue(vertex, this.getInvocationLabel(invocation, true));
            this.getModel().endUpdate();
            window['codex'].actions.onClickInvocation(vertex.id);
        } else if (!!selectedCell && selectedCell.edge) {
            this.getModel().beginUpdate();
            const vertex = this.getModel().getCell(invocation.id);
            if (!!invocation.nextInvocations && invocation.nextInvocations.length > 0) {
                for (let k = 0; k < invocation.nextInvocations.length; k++) {
                    const nextInvocation = invocation.nextInvocations[k];
                    if (!!vertex.edges && vertex.edges.length > 0) {
                        for (let i = 0; i < vertex.edges.length; i++) {
                            const edge = vertex.edges[i];
                            if (edge.id === nextInvocation.id) {
                                this.getModel().setValue(edge, this.getEdgeLabel(k + 1, invocation.id, nextInvocation));
                            }
                        }
                    }
                }
            }
            this.getModel().endUpdate();

            window['codex'].actions.onClickEdge(selectedCell.id, this.getModel().getTerminal(selectedCell, true).id);
        }
    }

    onClick = (sender, me) => {
        /*const event = new Event('change');
        document.activeElement.dispatchEvent(event);
        if (me.state && me.state.cell && !me.state.cell.edge) {
            if (
                this.selectedCellId !== me.state.cell.id &&
                document.elementFromPoint(me.evt.clientX, me.evt.clientY).className !== 'material-icons close-button'
            ) {
                this.onChangeSelectedCell(me.state.cell.id);
                window['codex'].actions.onClickInvocation(me.state.cell.id);
            }
        } else if (me.state && me.state.cell && me.state.cell.edge) {
            window['codex'].actions.onClickEdge(me.state.cell.id, this.getModel().getTerminal(me.state.cell, true).id);
            this.onChangeSelectedCell(null);
        }*/
    };

    onChangeSelectedCell(newSelectedCellId: string) {
        if (newSelectedCellId !== this.selectedCellId) {
            let cell = this.getModel().getCell(newSelectedCellId);
            if (!!cell && !cell.edge) {
                if (!!this.selectedCellId && this.program.invocationMap.has(this.selectedCellId)) {
                    this.getModel().setValue(
                        this.getModel().getCell(this.selectedCellId),
                        this.getInvocationLabel(this.program.invocationMap.get(this.selectedCellId), false)
                    );
                }
                if (!!newSelectedCellId) {
                    this.getModel().setValue(
                        this.getModel().getCell(newSelectedCellId),
                        this.getInvocationLabel(this.program.invocationMap.get(newSelectedCellId), true)
                    );
                }
                this.selectedCellId = newSelectedCellId;
            } else if (!!cell) {
                this.selectedCellId = newSelectedCellId;
            }
        }
    }

    getAllConnectionConstraints(terminal) {
        if (terminal != null && this.model.isVertex(terminal.cell)) {
            return [
                new mxConnectionConstraint(new mxPoint(0.25, 0), true, 'bottom-left'),
                new mxConnectionConstraint(new mxPoint(0.5, 0), true, 'bottom-center'),
                new mxConnectionConstraint(new mxPoint(0.75, 0), true, 'bottom-right'),
                new mxConnectionConstraint(new mxPoint(0, 0.25), true, 'left-bottom'),
                //new mxConnectionConstraint(new mxPoint(0, 0.5), true, 'left-center'),
                new mxConnectionConstraint(new mxPoint(0, 0.75), true, 'left-top'),
                new mxConnectionConstraint(new mxPoint(1, 0.25), true, 'right-left'),
                //new mxConnectionConstraint(new mxPoint(1, 0.5), true, 'right-center'),
                new mxConnectionConstraint(new mxPoint(1, 0.75), true, 'right-right'),
                new mxConnectionConstraint(new mxPoint(0.25, 1), true, 'top-top'),
                new mxConnectionConstraint(new mxPoint(0.5, 1), true, 'top-center'),
                new mxConnectionConstraint(new mxPoint(0.75, 1), true, 'top-bottom'),
            ];
        }
        return null;
    }

    initGrid() {
        this.setGridEnabled(true);
        this.setGridSize(25);
        this.snap(25);
        try {
            const canvas = document.createElement('canvas');
            canvas.style.position = 'absolute';
            canvas.style.top = '0px';
            canvas.style.left = '0px';
            canvas.style.zIndex = '-1';
            this.container.appendChild(canvas);

            // Modify event filtering to accept canvas as container
            const mxGraphViewIsContainerEvent = mxGraphView.prototype.isContainerEvent;
            mxGraphView.prototype.isContainerEvent = function (evt) {
                return mxGraphViewIsContainerEvent.apply(this, arguments) || mxEvent.getSource(evt) === canvas;
            };

            mxGraphView.prototype.validateBackground = () => {
                this.repaintGrid(canvas);
            };
            this.repaintGrid(canvas);
        } catch (e) {
            console.log(e);
        }
    }

    public repaintGrid(canvas: HTMLCanvasElement) {
        const ctx = canvas.getContext('2d');
        let s = 0;
        let gs = 0;
        let tr = new mxPoint(0, 0);
        let w = 0;
        let h = 0;
        if (ctx != null) {
            const bounds = this.getGraphBounds();
            const width = Math.max(bounds.x + bounds.width, this.container.clientWidth) + 500;
            const height = Math.max(bounds.y + bounds.height - 48, this.container.clientHeight - 48) + 500;
            const sizeChanged = width !== w || height !== h;
            if (
                this.view.scale !== s ||
                this.view.translate.x !== tr.x ||
                this.view.translate.y !== tr.y ||
                gs !== this.gridSize ||
                sizeChanged
            ) {
                tr = this.view.translate.clone();
                s = this.view.scale;
                gs = this.gridSize;
                w = width;
                h = height;

                // Clears the background if required
                if (!sizeChanged) {
                    ctx.clearRect(0, 0, w, h);
                } else {
                    canvas.setAttribute('width', String(w));
                    canvas.setAttribute('height', String(h));
                }

                let tx = tr.x * s;
                let ty = tr.y * s;

                // Sets the distance of the grid lines in pixels
                var minStepping = this.gridSize;
                var stepping = minStepping * s;

                let xs = Math.floor((0 - tx) / stepping) * stepping + tx;
                let xe = Math.ceil(w / stepping) * stepping;
                let ys = Math.floor((0 - ty) / stepping) * stepping + ty;
                let ye = Math.ceil(h / stepping) * stepping;

                xe += Math.ceil(stepping);
                ye += Math.ceil(stepping);

                let ixs = Math.round(xs);
                let ixe = Math.round(xe);
                let iys = Math.round(ys);
                let iye = Math.round(ye);

                // Draws the actual grid
                ctx.strokeStyle = 'rgba(156, 163, 175, 0.05)';
                ctx.beginPath();

                for (var x = xs; x <= xe; x += stepping) {
                    x = Math.round((x - tx) / stepping) * stepping + tx;
                    var ix = Math.round(x);

                    ctx.moveTo(ix + 0.5, iys + 0.5);
                    ctx.lineTo(ix + 0.5, iye + 0.5);
                }

                for (var y = ys; y <= ye; y += stepping) {
                    y = Math.round((y - ty) / stepping) * stepping + ty;
                    var iy = Math.round(y);

                    ctx.moveTo(ixs + 0.5, iy + 0.5);
                    ctx.lineTo(ixe + 0.5, iy + 0.5);
                }

                ctx.closePath();
                ctx.stroke();
            }
        }
    }

    initConnectorPreview() {
        // Connect preview
        this.connectionHandler.createEdgeState = function (me) {
            const edge = this.createEdge(null, null, null, null, null, 'edgeStyle=orthogonalEdgeStyle');
            return new mxCellState(this.view, edge, []);
        };

        // Use this code to snap the source point for new connections without a connect preview,
        // ie. without an overridden graph.connectionHandler.createEdgeState
        const mxConnectionHandlerMouseMove = mxConnectionHandler.prototype.mouseMove;
        mxConnectionHandler.prototype.mouseMove = function (sender, me) {
            this.sourceConstraint = null;

            mxConnectionHandlerMouseMove.apply(this, arguments);
        };

        const mxConnectionHandlerGetSourcePerimeterPoint = mxConnectionHandler.prototype.getSourcePerimeterPoint;
        mxConnectionHandler.prototype.getSourcePerimeterPoint = function (state, pt, me) {
            let result = null;

            if (this.previous != null && pt != null) {
                const constraints = state.view.graph.getAllConnectionConstraints(this.previous);
                let nearestConstraint = null;
                let nearest = null;
                let dist = null;

                for (let i = 0; i < constraints.length; i++) {
                    const cp = state.view.graph.getConnectionPoint(this.previous, constraints[i]);

                    if (cp != null) {
                        const tmp = (cp.x - pt.x) * (cp.x - pt.x) + (cp.y - pt.y) * (cp.y - pt.y);

                        if (dist == null || tmp < dist) {
                            nearestConstraint = constraints[i];
                            nearest = cp;
                            dist = tmp;
                        }
                    }
                }

                if (nearestConstraint != null) {
                    this.sourceConstraint = nearestConstraint;
                    result = nearest;
                }
            }

            if (result == null) {
                result = mxConnectionHandlerGetSourcePerimeterPoint.apply(this, arguments);
            }

            return result;
        };

        mxConstraintHandler.prototype.intersects = function (icon, point, source, existingEdge) {
            return !source || existingEdge || mxUtils.intersects(icon.bounds, point);
        };
    }

    initConnectorHighLights() {
        mxConstraintHandler.prototype.pointImage = new mxImage('../../../../../../../images/theme/connector.png', 16, 16);
        mxConstraintHandler.prototype.highlightColor = '#8000ff';
        mxConstraintHandler.prototype.createHighlightShape = function () {
            const highLightShape = new mxEllipse(null, this.highlightColor, this.highlightColor, 0);
            highLightShape.opacity = 50;
            return highLightShape;
        };
    }

    initFixedTerminalPoint() {
        this.view.updateFixedTerminalPoint = function (edge, terminal, source, constraint) {
            // Implements perimeter-less connection points as fixed points (computed before the edge style).
            mxGraphView.prototype.updateFixedTerminalPoint.apply(this, arguments);

            const pts = edge.absolutePoints;
            const pt = pts[source ? 0 : pts.length - 1];
            if (terminal && !pt && !this.getPerimeterFunction(terminal)) {
                edge.setAbsoluteTerminalPoint(new mxPoint(this.view.getRoutingCenterX(terminal), this.getRoutingCenterY(terminal)), source);
            }
        };
    }

    initHotspots() {
        // Disables floating connections (only use with no connect image)
        if (this.connectionHandler.connectImage == null) {
            this.connectionHandler.isConnectableCell = function (cell) {
                return false;
            };
            mxEdgeHandler.prototype.isConnectableCell = function (cell) {
                return this.graph.connectionHandler.isConnectableCell(cell);
            };
        }

        this.splitEnabled = true;
        this.addListener(mxEvent.SPLIT_EDGE, (sender, evt) => {
            // Only need to add new edge here, the edge that has a
            // source change will be handled by the mxTerminalChange event.
            if (evt.properties.newEdge.source && evt.properties.newEdge.target) {
                const nextInvocation = this.program.changeNextInvocation(
                    evt.properties.newEdge.source.id,
                    evt.properties.edge.target.id,
                    evt.properties.newEdge.target.id
                );
                this.getModel().remove(evt.properties.newEdge);
                this.removeStateForCell(evt.properties.newEdge);
                const sourceInvocation = this.program.invocationMap.get(evt.properties.newEdge.source.id);
                this.insertEdge(
                    this.getDefaultParent(),
                    nextInvocation.id,
                    this.getEdgeLabel(sourceInvocation.nextInvocations.length, evt.properties.newEdge.source.id, nextInvocation),
                    evt.properties.newEdge.source,
                    evt.properties.newEdge.target,
                    this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                );
                this.nextInvocationCells.set(nextInvocation.id, this.getModel().getCell(nextInvocation.id));
            }
        });

        this.getModel().addListener(mxEvent.CHANGE, (sender, evt) => {
            const changes = evt.getProperty('edit').changes;
            const cellsToRemove = new Set();
            this.getModel().beginUpdate();
            try {
                for (let i = 0; i < changes.length; i++) {
                    if (changes[i].constructor.name === 'mxGeometryChange') {
                        if (changes[i].cell.edge) {
                            const nextInvocation = this.program.nextInvocationMap.get(changes[i].cell.id);
                            if (nextInvocation && changes[i].cell.geometry && changes[i].cell.geometry.points) {
                                nextInvocation.points = changes[i].cell.geometry.points.map(point => {
                                    return { x: point.x, y: point.y };
                                });
                                window['codex'].actions.onActionUpdated({ action: this.action });
                            }
                        }
                    }
                    if (changes[i].constructor.name === 'mxTerminalChange' && !!changes[i].previous) {
                        if (changes[i].source && changes[i].cell.source && changes[i].cell.target) {
                            cellsToRemove.add(changes[i].cell.id);
                            const nextInvocation = this.program.changeSourceInvocation(
                                changes[i].previous.id,
                                changes[i].cell.source.id,
                                changes[i].cell.target.id
                            );
                            const sourceInvocation = this.program.invocationMap.get(changes[i].cell.source.id);
                            this.insertEdge(
                                this.getDefaultParent(),
                                nextInvocation.id,
                                this.getEdgeLabel(sourceInvocation.nextInvocations.length, changes[i].cell.source.id, nextInvocation),
                                changes[i].cell.source,
                                changes[i].cell.target,
                                this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                            );
                            this.nextInvocationCells.set(nextInvocation.id, this.getModel().getCell(nextInvocation.id));
                            window['codex'].actions.onConnectEdge();
                        } else if (changes[i].cell.source && changes[i].previous && changes[i].terminal) {
                            cellsToRemove.add(changes[i].cell.id);
                            const nextInvocation = this.program.changeNextInvocation(
                                changes[i].cell.source.id,
                                changes[i].previous.id,
                                changes[i].terminal.id
                            );
                        }
                    }
                }

                cellsToRemove.forEach(cellId => {
                    const cell = this.getModel().getCell(cellId);
                    this.getModel().remove(cell);
                    this.removeStateForCell(cell);
                });
                this.getView().refresh();
            } finally {
                this.getModel().endUpdate();
            }
        });

        this.connectionHandler.addListener(mxEvent.CELL_CONNECTED, function (sender, evt) {
            console.log('CELL CONNECTED' + evt);
        });

        this.connectionHandler.addListener(mxEvent.CELLS_MOVED, function (sender, evt) {
            console.log('CELL MOVED' + evt);
        });

        this.connectionHandler.addListener(mxEvent.CONNECT_CELL, function (sender, evt) {
            console.log('CONNECT CELL' + evt);
        });

        this.connectionHandler.addListener(mxEvent.CONNECT, (sender, evt) => {
            const edge: mxCell = evt.getProperty('cell');
            const source = this.getModel().getTerminal(edge, true);
            const target = this.getModel().getTerminal(edge, false);
            const newNextInvocation = this.program.setNextInvocation(source.id, target.id);
            delete this.getModel().cells[edge.id];
            edge.setId(newNextInvocation.id);
            this.getModel().cells[edge.id] = edge;
            if (!!newNextInvocation) {
                edge.setStyle(this.defaultEdgeStyle + ';elbow=' + this.getElbowStyle(source, target));
                edge.id = newNextInvocation.id;
                this.nextInvocationCells.set(edge.id, edge);
                this.cellLabelChanged(
                    edge,
                    this.getEdgeLabel(this.program.invocationMap.get(source.id).nextInvocations.length, source.id, newNextInvocation),
                    true
                );

                window['codex'].actions.onActionUpdated({ action: this.action });
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    initEdges() {
        this.action.program.invocations.forEach(invocation => {
            if (!!invocation.nextInvocations) {
                const source = this.getModel().getCell(invocation.id);
                for (let i = 0; i < invocation.nextInvocations.length; i++) {
                    const nextInvocation = invocation.nextInvocations[i];
                    const graphEdge = this.insertEdge(
                        this.getDefaultParent(),
                        nextInvocation.id,
                        this.getEdgeLabel(i + 1, invocation.id, nextInvocation),
                        source,
                        this.getModel().getCell(nextInvocation.invocationId),
                        this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                    );
                    if (nextInvocation.points) {
                        graphEdge.geometry.points = nextInvocation.points.map(point => new mxPoint(point.x, point.y));
                    }
                    this.nextInvocationCells.set(nextInvocation.id, this.getModel().getCell(nextInvocation.id));
                }
            }
        });
    }

    initNodes() {
        this.keepEdgesInBackground = true;
        mxGraphHandler.prototype.guidesEnabled = true;
        this.setCellsEditable(false);
        this.setAutoSizeCells(true);
        this.setConnectable(true);
        this.setAllowDanglingEdges(false);
        this.setDisconnectOnMove(false);
        this.setHtmlLabels(true);
        this.setDropEnabled(true);

        this.action.program.invocations.forEach(invocation => {
            this.insertInvocationInGraph(this.getDefaultParent(), invocation);
        });

        mxGraphHandler.prototype.isDelayedSelection = function (cell, me) {
            return true;
        };

        mxGraphHandler.prototype.getCells = function (initialCell) {
            if (this.graph.getSelectionCells().includes(initialCell)) {
                return this.graph.getMovableCells(this.graph.getSelectionCells());
            }
            return this.graph.getMovableCells([initialCell]);
        };

        this.addListener(mxEvent.CELLS_MOVED, (sender, evt) => {
            if (evt && evt.properties && evt.properties.cells && evt.properties.cells.length > 0 && evt.properties.cells[0].vertex) {
                const invocation: Invocation = this.program.invocationMap.get(evt.properties.cells[0].id);
                invocation.x = evt.properties.cells[0].geometry.x;
                invocation.y = evt.properties.cells[0].geometry.y;
                window['codex'].actions.onActionUpdated({ action: this.action });
            }
        });

        this.addListener(mxEvent.SELECT, (sender, evt) => {});
    }

    initStyle() {
        this.resizeContainer = false;
        mxConstants.HANDLE_FILLCOLOR = '#8000ff';
        mxConstants.HANDLE_STROKECOLOR = '#8000ff';
        mxConstants.VERTEX_SELECTION_COLOR = '#8000ff00';
        mxConstants.VERTEX_SELECTION_STROKEWIDTH = 0;
        mxConstants.OUTLINE_HIGHLIGHT_COLOR = '#8000ff';
        mxConstants.OUTLINE_COLOR = '#8000ff';
        mxConstants.OUTLINE_HANDLE_FILLCOLOR = '#8000ff';
        mxConstants.OUTLINE_HANDLE_STROKECOLOR = '#8000ff';
        mxConstants.VALID_COLOR = '#8000ff';
        mxConstants.INVALID_COLOR = '#D68081';
        mxConstants.EDGE_SELECTION_STROKEWIDTH = 4;
        mxConstants.EDGE_SELECTION_COLOR = '#8000ff';
        mxConstants.EDGE_SELECTION_DASHED = false;
        mxConstants.GUIDE_COLOR = '#FFBF00';
        mxConstants.GUIDE_STROKEWIDTH = 1;
        mxConstants.STYLE_INDICATOR_COLOR;
        mxGraphHandler.prototype.previewColor = '#8000ff';
        this.initEdgeStyle();
        this.initNodeStyle();
    }

    initPreview() {
        let previewShape = new mxText();
        previewShape.text = 'Hey ho!!!';
        previewShape.style =
            'shape=rectangle;align=center;labelPosition=center;verticalAlign=middle;verticalLabelPosition=middle;fillColor=none;strokeColor=none;strokeWidth=-1;align=left;overflow=fill;resizable=0;';
        return previewShape;
    }

    initEdgeStyle() {
        const defaultEdgeStyleArray = this.getStylesheet().getDefaultEdgeStyle();
        defaultEdgeStyleArray[mxConstants.STYLE_SHADOW] = 0;
        defaultEdgeStyleArray[mxConstants.STYLE_ROUNDED] = 1;
        defaultEdgeStyleArray[mxConstants.STYLE_EDGE] = mxConstants.EDGESTYLE_ORTHOGONAL;
        defaultEdgeStyleArray[mxConstants.STYLE_ORTHOGONAL_LOOP] = 1;
        defaultEdgeStyleArray[mxConstants.STYLE_JETTY_SIZE] = 'auto';
        defaultEdgeStyleArray[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_OPEN;
        defaultEdgeStyleArray[mxConstants.STYLE_TARGET_PERIMETER_SPACING] = -7;
        defaultEdgeStyleArray[mxConstants.STYLE_SOURCE_PERIMETER_SPACING] = -7;
        defaultEdgeStyleArray[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
        defaultEdgeStyleArray[mxConstants.STYLE_CURVED] = 0;
        defaultEdgeStyleArray[mxConstants.STYLE_STROKECOLOR] = '#8000ff';
        defaultEdgeStyleArray[mxConstants.STYLE_STROKEWIDTH] = 2;
        defaultEdgeStyleArray[mxConstants.STYLE_BENDABLE] = true;

        this.defaultEdgeStyle = Object.keys(defaultEdgeStyleArray)
            .map(edgeStyleItem => edgeStyleItem + '=' + defaultEdgeStyleArray[edgeStyleItem])
            .join(';');
    }

    initNodeStyle() {
        const defaultVertexStyle = this.getStylesheet().getDefaultVertexStyle();
        defaultVertexStyle[mxConstants.STYLE_ROUNDED] = 1;
        defaultVertexStyle[mxConstants.STYLE_ARCSIZE] = 2;
        defaultVertexStyle[mxConstants.STYLE_SHADOW] = 0;
        mxVertexHandler.prototype.singleSizer = true;
    }

    initPanning() {
        mxPanningHandler.prototype.useLeftButtonForPanning = true;
        this.setPanning(true);
    }

    initSelection() {}

    insertInvocationInGraph(parent: any, invocation: Invocation, newInvocation: boolean = false) {
        this.getModel().beginUpdate();
        try {
            const vertex = this.insertVertex(
                parent,
                invocation.id,
                this.getInvocationLabel(invocation, false, newInvocation),
                invocation.x,
                invocation.y,
                450,
                80,
                `shape=${invocation.getShape()};align=center;labelPosition=center;verticalAlign=middle;verticalLabelPosition=middle;fillColor=none;strokeColor=none;strokeWidth=-1;align=left;overflow=fill;resizable=0;`,
                false
            );
            vertex.setConnectable(true);
            this.invocationVertices.set(invocation.id, vertex);
            return vertex;
        } finally {
            this.getModel().endUpdate();
            if (newInvocation) {
                setTimeout(() => document.getElementById('invocation-' + invocation.id).classList.remove('new-invocation'), 400);
            }
        }
    }

    getInvocationLabel(invocation: Invocation, selected: boolean, newInvocation: boolean = false) {
        if (invocation) {
            let statusClass = '';
            if (!this.methodMap.has(invocation.methodKey)) {
                statusClass = 'error';
            } else if (this.methodMap.get(invocation.methodKey).deprecated) {
                statusClass = 'deprecated';
            }
            let label;
            if (invocation.method && invocation.method.key !== 'start') {
                label = `<section id='invocation-${invocation.id}' class='invocation ${
                    newInvocation ? 'new-invocation' : ''
                } flex justify-between ${
                    selected ? 'selected' : ''
                } ${statusClass}'><div class='invocationLabel flex flex-grow-1'><div class="invocationIcon"><i class="material-icons">${
                    invocation.iconName
                }</i></div><div class="invocationName"><div>${invocation.name}</div><div class='functionName'>(${
                    this.methodMap.get(invocation.methodKey) ? this.methodMap.get(invocation.methodKey).getName() : 'Not available'
                })</div></div></div>`;
            } else {
                label = `<section id='invocation-${invocation.id}' class='invocation ${
                    newInvocation ? 'new-invocation' : ''
                } flex justify-between ${
                    selected ? 'selected' : ''
                } ${statusClass}'><div class='invocationLabel flex flex-grow-1'><div class="invocationIcon"><i class="material-icons">${
                    this.action.iconName
                }</i></div><div class="invocationName"><div>${this.action.name}</div><div class='functionName'>(${
                    this.methodMap.get(invocation.methodKey) ? this.methodMap.get(invocation.methodKey).getName() : 'Not available'
                })</div></div></div>`;
            }
            label += `<ul class="invocation-actions">`;
            if (invocation.description) {
                label += `<li class="invocationInfoIcon accordion-heading-button"><svg class="icon" aria-hidden="true" focusable="false"><use href="#help" class="ui-element"></use></svg><span class="invocationInfo">`;
                label += `<strong>Custom description:</strong> ${invocation.description} <br></br>`;
                label += `<strong>Function description:</strong> ${
                    this.methodMap.get(invocation.methodKey) ? this.methodMap.get(invocation.methodKey).getDescription() : 'Not available'
                }`;
                label += `</span></li>`;
            }
            if (invocation.method && invocation.method.key !== 'start') {
                if (invocation.method.key === 'subflow') {
                    label += `<li class="accordion-heading-button"><svg class="icon" onclick="window['codex'].actions.onCollapse('${invocation.id}')" aria-hidden="true" focusable="false"><use href="#copy" class="ui-element"></use></svg></li>`;
                }
                label += `<li class="accordion-heading-button"><svg class="icon" onclick="window['codex'].actions.onCopyInvocation('${invocation.id}')" aria-hidden="true" focusable="false"><use href="#copy" class="ui-element"></use></svg></li>`;
                label += `<li class="close" onclick="window['codex'].actions.onRemoveInvocation('${invocation.id}')"><i class="material-icons close-button accordion-heading-button destructive-accordion-heading-button">close</i></li>`;
            } else if (!invocation.method) {
                label += `<li class="close" onclick="window['codex'].actions.onRemoveInvocation('${invocation.id}')"><i class="material-icons close-button accordion-heading-button destructive-accordion-heading-button">close</i></li>`;
            }
            label += `</ul><div class="invocation-log-lines-button" onclick="window['codex'].actions.onHoverInvocationLogLines('${invocation.id}')"><svg class="icon"><use href="#logs" class="fill-ui-element"></use></svg></div></section>`;
            return label;
        }
        return null;
    }

    getEdgeLabel(edgeNumber: number, sourceInvocationId: string, nextInvocation: NextInvocation) {
        if (nextInvocation.conditional && nextInvocation.conditional !== '' && nextInvocation.conditional !== 'else') {
            const expressions = nextInvocation.determineConditionalExpressions();
            let expressionHtml = '';
            for (let i = 0; i < expressions.expressions.length; i++) {
                const expression = expressions.expressions[i];
                expressionHtml += expression;
                if (i < expressions.expressions.length - 1) {
                    expressionHtml += '</br>';
                    expressionHtml += expressions.expressionOperators[i];
                    expressionHtml += '</br>';
                }
            }
            return `<div id="edge-${nextInvocation.id}" class="edgeLabel conditional mat-elevation-z3">${expressionHtml}<span class="close"><i class="material-icons" onclick="window['codex'].actions.onRemoveNextInvocation('${sourceInvocationId}', '${nextInvocation.invocationId}')">close</i></span></div>`;
        } else if (nextInvocation.conditional && nextInvocation.conditional === 'else') {
            return `<div id="edge-${nextInvocation.id}" class="edgeLabel conditional mat-elevation-z3">else<span class="close"><i class="material-icons" onclick="window['codex'].actions.onRemoveNextInvocation('${sourceInvocationId}', '${nextInvocation.invocationId}')">close</i></span></div>`;
        } else {
            return `<div id="edge-${nextInvocation.id}" class="edgeLabel mat-elevation-z3">${edgeNumber}<span class="close"><i class="material-icons" onclick="window['codex'].actions.onRemoveNextInvocation('${sourceInvocationId}', '${nextInvocation.invocationId}')">close</i></span></div>`;
        }
    }

    isPickable(method: Method) {
        return this.pickableMethods.has(method.key);
    }

    makeMethodPickable(method: Method, language: string) {
        if (!this.isPickable(method)) {
            // Function that is executed when the image is dropped on
            // the graph. The cell argument points to the cell under
            // the mousepointer if there is one.

            const funct = (graph, evt, cell, x, y) => {
                const graphParent = graph.getDefaultParent();
                const graphModel = graph.getModel();
                const v1 = null;
                const pt = graph.getPointForEvent(evt);

                graphModel.beginUpdate();
                try {
                    const newInvocation = this.program.addInvocation(method, pt.x - 175, pt.y, language);
                    const addedCell = this.insertInvocationInGraph(graphParent, newInvocation, true);
                    window['codex'].actions.onInvocationAdded(newInvocation);
                    window['codex'].actions.onActionUpdated({ action: this.action });

                    if (cell && cell.edge) {
                        const sourceNextInvocation = this.program.changeNextInvocation(cell.source.id, cell.target.id, addedCell.id);
                        this.getModel().remove(cell);
                        this.removeStateForCell(cell);
                        const sourceInvocation = this.program.invocationMap.get(cell.source.id);
                        this.insertEdge(
                            this.getDefaultParent(),
                            sourceNextInvocation.id,
                            this.getEdgeLabel(sourceInvocation.nextInvocations.length, cell.source.id, sourceNextInvocation),
                            cell.source,
                            addedCell,
                            this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                        );
                        this.nextInvocationCells.set(sourceNextInvocation.id, this.getModel().getCell(sourceNextInvocation.id));

                        const newInvocationNextInvocation = this.program.setNextInvocation(newInvocation.id, cell.target.id);
                        this.insertEdge(
                            this.getDefaultParent(),
                            newInvocationNextInvocation.id,
                            this.getEdgeLabel(newInvocation.nextInvocations.length, newInvocation.id, newInvocationNextInvocation),
                            addedCell,
                            cell.target,
                            this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                        );
                        this.nextInvocationCells.set(
                            newInvocationNextInvocation.id,
                            this.getModel().getCell(newInvocationNextInvocation.id)
                        );
                        this.getView().refresh();
                    }
                } finally {
                    graphModel.endUpdate();
                }
                graph.setSelectionCell(v1);
            };

            // Creates the image which is used as the sidebar icon (drag source)
            const methodPickerItem = document.getElementById(method.key + '-picker-' + this.action.id);
            const dragElt = document.createElement('div');
            dragElt.style.border = `dashed #8000ff 1px`;
            dragElt.style.borderRadius = `8px`;
            dragElt.style.width = '437px';
            dragElt.style.height = '66px';
            dragElt.style.backgroundColor = '#0f172a';
            dragElt.style.boxShadow = '0 0 20px rgba(128, 0, 255, 0.6),\n' + 'inset 0 0 10px rgba(128, 0, 255, 0.4),\n' + '0 2px 0 #000';
            // Creates the image which is used as the drag icon (preview)
            const ds = mxUtils.makeDraggable(methodPickerItem, this, funct, dragElt, -175.0, -20, false, false);
            ds.setGuidesEnabled(true);
            this.pickableMethods.set(method.key, ds);
        }
    }

    private getElbowStyle(sourceVertex: any, targetVertex: any): string {
        const xDif = sourceVertex.geometry.x - targetVertex.geometry.x;
        const yDif = sourceVertex.geometry.y - targetVertex.geometry.y;
        if (Math.abs(xDif) > Math.abs(yDif)) {
            return 'horizontal';
        } else {
            return 'vertical';
        }
    }

    copyInvocation(invocationId: string) {
        this.getModel().beginUpdate();
        try {
            this.insertInvocationInGraph(
                this.getDefaultParent(),
                this.program.copyInvocation(this.program.invocationMap.get(invocationId))
            );
            window['codex'].actions.onActionUpdated({ action: this.action });
        } finally {
            this.getModel().endUpdate();
        }
    }

    removeInvocation(invocationId: string) {
        this.getModel().beginUpdate();
        try {
            this.removeCells([this.getModel().getCell(invocationId)], true);
            this.getView().refresh();
            const invocationToRemove = this.program.invocationMap.get(invocationId);
            invocationToRemove.nextInvocations.forEach(nextInvocation => {
                if (this.program.previousInvocationMap.get(invocationId)) {
                    this.program.previousInvocationMap.get(invocationId).forEach(previousInvocation => {
                        const newNextInvocation = this.program.setNextInvocation(previousInvocation.id, nextInvocation.invocationId);
                        const source = this.getModel().getCell(previousInvocation.id);
                        this.insertEdge(
                            this.getDefaultParent(),
                            newNextInvocation.id,
                            this.getEdgeLabel(previousInvocation.nextInvocations.length - 1, previousInvocation.id, newNextInvocation),
                            source,
                            this.getModel().getCell(newNextInvocation.invocationId),
                            this.defaultEdgeStyle + ';edgeStyle=orthogonalEdgeStyle'
                        );
                        this.nextInvocationCells.set(newNextInvocation.id, this.getModel().getCell(newNextInvocation.id));
                    });
                }
            });
            this.program.removeInvocation(this.program.invocationMap.get(invocationId));
            window['codex'].actions.onActionUpdated({ action: this.action });
            if (this.selectedCellId === invocationId) {
                this.onChangeSelectedCell(null);
            }
        } finally {
            this.getModel().endUpdate();
        }
    }

    removeNextInvocation(sourceInvocationId: string, targetInvocationId: string) {
        this.getModel().beginUpdate();
        try {
            let nextInvocationToRemove;
            this.program.invocationMap.get(sourceInvocationId).nextInvocations.forEach(nextInvocation => {
                if (nextInvocation.invocationId === targetInvocationId) {
                    nextInvocationToRemove = nextInvocation;
                }
            });
            if (!!nextInvocationToRemove) {
                this.removeCells([this.nextInvocationCells.get(nextInvocationToRemove.id)], true);
                this.getView().refresh();
                this.program.invocationMap
                    .get(sourceInvocationId)
                    .nextInvocations.splice(
                        this.program.invocationMap.get(sourceInvocationId).nextInvocations.indexOf(nextInvocationToRemove),
                        1
                    );
            }
            window['codex'].actions.onActionUpdated({ action: this.action });
        } finally {
            this.getModel().endUpdate();
        }
    }
}
