<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <label for="select-output" class="small-input-label">Select output</label>
    <select id="select-output" class="small-input" [(ngModel)]="chosenOutputId" (change)="onSelectedOutput()">
        <ng-container *ngFor="let output of this.outputs">
            <option [value]="output.id">{{ output.getName() }} ({{ output.type }})</option>
        </ng-container>
    </select>

    <argument-value-picker
        *ngIf="argument"
        class="mb-1 block"
        [argument]="argument"
        [contextId]="contextId"
        [onlyLiteralValues]="true"
        [root]="[argument]"
        [scope]="null"></argument-value-picker>

    <div class="flex justify-end gap-1">
        <button class="primary-button button-medium" (click)="onSaveAssertion()" type="button">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" (click)="onCancelAssertion()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</mat-dialog-content>
