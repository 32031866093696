import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationLinkDto, AuthorizationLinkService } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../../../common/lib/confirmdialog/confirm.dialog.lib';
import { switchMap, take } from 'rxjs/operators';
import { AuthorizationLinkComponent } from '../authorization-link/authorization-link.component';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-authorization-link-picker',
    templateUrl: './authorization-link-picker.component.html',
    styleUrls: ['./authorization-link-picker.component.scss'],
    standalone: false,
})
export class AuthorizationLinkPickerComponent implements OnInit {
    @Input()
    referenceId: string;

    @Input()
    companyId: string;

    @Input()
    applicationId: string;

    @Input()
    type: string;

    @Input()
    authenticatedAccess: boolean;

    @Output()
    authorizationUpdated: EventEmitter<{ authenticatedAccess: boolean }> = new EventEmitter<{ authenticatedAccess: boolean }>();

    authorizationLinks$: BehaviorSubject<AuthorizationLinkDto[]> = new BehaviorSubject<AuthorizationLinkDto[]>([]);

    formGroup: FormGroup;

    displayedColumns: string[] = ['name', 'actions'];

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private authorizationLinkService: AuthorizationLinkService,
        public dialog: MatDialog,
        private confirmDialog: ConfirmDialog
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.authorizationLinkService
            .getAuthorizationLinksByReferenceId(this.referenceId, this.companyId, this.applicationId, this.type)
            .pipe(take(1))
            .subscribe(authorizationLinks => this.authorizationLinks$.next(authorizationLinks));
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            authenticatedAccess: [this.authenticatedAccess],
        });
    }

    onAuthenticatedAccess() {
        if (this.authenticatedAccess !== this.formGroup.get('authenticatedAccess').value) {
            this.authenticatedAccess = this.formGroup.get('authenticatedAccess').value;
            this.authorizationUpdated.emit({ authenticatedAccess: this.authenticatedAccess });
        }
    }

    createNewAuthorization() {
        this.authorizationLinkService
            .createAuthorizationLink(
                {
                    referenceId: this.referenceId,
                    applicationId: this.applicationId,
                    type: this.type,
                    companyId: this.companyId,
                },
                this.companyId,
                this.applicationId
            )
            .pipe(
                switchMap(result => {
                    return this.authorizationLinkService
                        .getAuthorizationLinksByReferenceId(this.referenceId, this.companyId, this.applicationId, this.type)
                        .pipe(take(1));
                })
            )
            .subscribe(authorizationLinks => this.authorizationLinks$.next(authorizationLinks));
    }

    identifyAuthorizationLink(index, item: AuthorizationLinkDto) {
        return item.id;
    }
    onEditAuthorization(authorizationLink: AuthorizationLinkDto) {
        const dialogRef = this.dialog.open(
            AuthorizationLinkComponent,
            Object.assign(
                {
                    data: {
                        authorizationLink: authorizationLink,
                    },
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.authorizationLinkService
                    .updateAuthorizationLink(
                        {
                            ...result,
                            type: this.type,
                            companyId: this.companyId,
                        },
                        this.companyId,
                        this.applicationId
                    )
                    .pipe(
                        switchMap(() => {
                            return this.authorizationLinkService
                                .getAuthorizationLinksByReferenceId(this.referenceId, this.companyId, this.applicationId, this.type)
                                .pipe(take(1));
                        })
                    )
                    .subscribe(authorizationLinks => this.authorizationLinks$.next(authorizationLinks));
            }
        });
    }

    onDeleteAuthorization(authorizationLink: AuthorizationLinkDto) {
        this.confirmDialog.showConfirmDialog(
            'v2.authorization.link.delete.title',
            'v2.authorization.link.delete.description',
            'v2.authorization.link.delete.ok',
            'v2.authorization.link.delete.cancel',
            'v2.authorization.link.delete.success.title',
            'v2.authorization.link.delete.success.description',
            () => {
                this.authorizationLinkService
                    .deleteAuthorizationLinks(authorizationLink.id, this.companyId, this.applicationId, this.type)
                    .pipe(
                        switchMap(result => {
                            return this.authorizationLinkService
                                .getAuthorizationLinksByReferenceId(this.referenceId, this.companyId, this.applicationId, this.type)
                                .pipe(take(1));
                        })
                    )
                    .subscribe(authorizationLinks => this.authorizationLinks$.next(authorizationLinks));
            }
        );
    }
}
