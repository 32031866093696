import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Scope } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-database-connection',
    templateUrl: './argument-database-connection.component.html',
    standalone: false,
})
export class ArgumentDatabaseConnectionComponent implements OnInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input()
    scope!: Scope;

    @Input()
    language!: string;

    @Input()
    contextId!: string;

    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {}

    onActionExecutionArgumentUpdated($event) {
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
