import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Scope } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'argument-number-input',
    templateUrl: './argument-number-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentNumberInputComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    _argument!: Argument;

    @Input() contextId: string;

    @Input() scope: Scope;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    ngOnInit(): void {
        this.initForm();
    }

    updateForm(): void {
        if (this._argument.inputSelectionType === 'number') {
            this.formGroup?.get('value')?.setValue(this._argument.value);
        }
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            value: [this._argument.value, this._argument.parameter?.required ? Validators.required : null],
        });

        this.subscriptions.add(
            this.formGroup
                .get('value')
                ?.valueChanges.pipe(debounceTime(1000))
                .subscribe(value => this.onChange(value))
        );
    }

    onChange(value: string) {
        this._argument.value = `${value}`;
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
