import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument, InvocationOutput } from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Scope } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';
import { debounceTime } from 'rxjs/operators';
import { mergeOutputs } from '@backoffice/data-access/editor';
import { ActionEditorFacade, OverviewActionDto, TabDefinition } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-edit-invocation-argument-action-output',
    templateUrl: './action-edit-invocation-argument-action-output.component.html',
    standalone: false,
})
export class ActionEditInvocationArgumentActionOutputComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadAction();
    }

    _arguments!: Argument[];

    @Input()
    set output(output: InvocationOutput) {
        this._output = output;
        this.loadAction();
    }

    _output!: InvocationOutput;

    @Input()
    language!: string;

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    outputUpdated: EventEmitter<{ output: InvocationOutput }> = new EventEmitter<{ output: InvocationOutput }>();

    @Output()
    openAction: EventEmitter<{ actionId: string }> = new EventEmitter<{ actionId: string }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    actions!: Observable<OverviewActionDto[]>;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    actionArgument!: Argument;

    showSubOutputs: boolean = false;

    subOutputs: InvocationOutput[] = [];

    actionId!: string;

    currentContextId!: string;

    filterParameters: string[] = [];

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private actionEditorFacade: ActionEditorFacade
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    loadAction() {
        if (this._output && this._arguments) {
            this.actionArgument = this._arguments.find(argument => argument.parameterId === this._output?.output?.linkedActionParameterId);
            if (this.actionArgument && (this.actionArgument.value !== this.actionId || this.currentContextId !== this.contextId)) {
                this.calculateArguments();
            }
        }
    }

    calculateArguments() {
        this.actionId = this.actionArgument.value;
        this.currentContextId = this.contextId;
        if (!!this.actionId) {
            this.actionEditorFacade.findById(this.removeQuotesIfPresent(this.actionArgument?.value)).subscribe(action => {
                if (this._output.subOutputsForAction !== this.actionArgument.value) {
                    this._output.subOutputs = action.program.createProgramOutputs();
                } else {
                    mergeOutputs(action.program.createProgramOutputs(), this._output);
                }
                this.subOutputs = this._output.subOutputs.filter(
                    subOutput => this.filterParameters.indexOf(subOutput.output?.names['en']) === -1
                );
                if (this.subOutputs.length > 0) {
                    this.showSubOutputs = true;
                } else {
                    this.showSubOutputs = false;
                }
                this.initForm();
                this.changeDetectorRef.detectChanges();
                this._output.subOutputsForAction = this.actionArgument.value;
            });
        } else {
            this.showSubOutputs = false;
            this._output.subOutputs = [];
            this.changeDetectorRef.detectChanges();
        }
    }

    initForm() {
        this.formGroup = this.fb.group({});
        this.subOutputs.forEach(subOutput => {
            if (subOutput.outputId) {
                this.formGroup.addControl(subOutput.outputId, new FormControl(subOutput.value));
                this.formGroup
                    .get(subOutput.outputId)
                    ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                    .subscribe(value => {
                        let _subOutput = this._output.subOutputs.find(_subOutput => _subOutput.outputId == subOutput.outputId);
                        if (_subOutput) {
                            _subOutput.value = value;
                            this.outputUpdated.emit({ output: this._output });
                        }
                    });
            }
        });
    }

    onDataFormatChange($event: { value: string }, output: InvocationOutput) {
        output.value = $event.value;
        this.outputUpdated.emit({ output: this._output });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
