<div class="title-button-header">
    <h1></h1>
    <div class="title-button-header-buttons">
        <button class="primary-button button-medium" type="button" (click)="onAddOutput()">
            <mat-icon>add</mat-icon>
            <span>{{ 'v2.action.output.add.output' | translate }}</span>
        </button>
    </div>
</div>
<ng-container *ngIf="action.program.outputs && action.program.outputs.length > 0">
    <div
        *ngIf="language && action.program.outputs && action.program.outputs.length > 0"
        [attr.id]="'accordion-outputs-' + action.id"
        data-accordion="open"
        data-active-classes="none"
        data-inactive-classes="none">
        <ng-container *ngFor="let output of action.program.outputs; let i = index; trackBy: identifyOutput">
            <h3 [attr.id]="'accordion-outputs-heading-' + action.id" [attr.data-name]="'header-' + action.id">
                <button
                    type="button"
                    class="accordion-heading"
                    [class.accordion-heading-first]="i === 0"
                    [class.accordion-heading-last]="i === action.program.outputs.length - 1"
                    [attr.data-accordion-target]="'#accordion-outputs-body-' + output.id"
                    [attr.aria-controls]="'accordion-outputs-body-' + output.id"
                    aria-expanded="true">
                    <span class="accordion-heading-icon">{{ output.getName() ? output.getName() : 'New output' }}</span>
                    <button
                        class="accordion-heading-button destructive-accordion-heading-button"
                        (click)="onRemoveOutput(output); $event.stopPropagation()"
                        [matTooltip]="'v2.action.output.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <svg
                        data-accordion-icon
                        class="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h3>
            <div
                [attr.id]="'accordion-outputs-body-' + output.id"
                [attr.data-name]="'content-' + output.id"
                [attr.aria-labelledby]="'accordion-outputs-heading-' + output.id"
                [class.accordion-panel-first]="i === 0"
                [class.accordion-panel-last]="i === action.program.outputs.length - 1"
                [class.accordion-panel]="true"
                class="hidden">
                <codex-action-edit-output
                    [language]="language"
                    [output]="output"
                    [root]="this.action.program.outputs"
                    [action]="action"
                    (actionUpdated)="onChangeOutput($event)">
                </codex-action-edit-output>
            </div>
        </ng-container>
    </div>
</ng-container>
<div class="flex flex-col justify-center items-center" *ngIf="!action.program.outputs || action.program.outputs.length === 0">
    <img class="w-[350px] h-[200px] object-contain" src="/images/theme/output.gif" />
    <h1 class="text-center !mb-2">We couldn't find any output for this action.</h1>
    <p class="!mb-8 text-white text-center">
        This action cannot return data to its callers. Start returning data by creating your first output!
    </p>
    <button type="button" class="primary-button button-extra-large" (click)="onAddOutput()">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
        </svg>
        {{ 'v2.action.output.add.output' | translate }}
    </button>
</div>
