import { Injectable } from '@angular/core';
import { apiDialogPaginationChanged } from '../actions/api-editor.actions';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { ApplicationEventDto } from '../../dto/application-event.dto';
import { createApplicationEvent } from '../actions/application-events.actions';

@Injectable()
export class ApplicationEventFacade {
    constructor(private readonly store: Store<EditorState>) {}

    create(applicationEvent: ApplicationEventDto) {
        this.store.dispatch(createApplicationEvent({ applicationEvent }));
    }
}
