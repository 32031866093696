import {
    Compiler,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dynamic-component',
    template: ` <div #target></div>`,
    standalone: false,
})
export class DynamicComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('target', { static: true, read: ViewContainerRef }) target: ViewContainerRef;

    @Input() type: any;

    @Input() data: any;

    @Input() submitted: boolean;

    @Input() selected: boolean;

    @Output() update = new EventEmitter();

    @Output() select = new EventEmitter();

    cmpRef: any;

    subscriptions: Subscription[] = new Array();

    constructor(
        @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
        @Inject(Compiler) private compiler: Compiler
    ) {}

    ngOnDestroy(): void {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnChanges(): void {
        if (Boolean(this.cmpRef) && Boolean(this.cmpRef.instance)) {
            this.setData();
            this.setSelected();
        }
    }

    ngOnInit(): void {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        const factory: any = this.componentFactoryResolver.resolveComponentFactory(this.type);
        this.cmpRef = this.target.createComponent(factory);
        if (Boolean(this.cmpRef) && Boolean(this.cmpRef.instance)) {
            this.setData();
            this.setSelected();
            this.setSelect();
        }
    }

    setSelect() {
        if (Boolean(this.cmpRef.instance.select)) {
            this.subscriptions.push(
                this.cmpRef.instance.select.subscribe(object => {
                    this.onSelect(object);
                })
            );
        }
    }

    setData() {
        if (typeof this.cmpRef.instance.setData === 'function') {
            this.cmpRef.instance.setData(this.data);
            this.cmpRef.instance.changeDetectorRef.markForCheck();
        } else {
            this.cmpRef.instance.data = this.data;
            this.cmpRef.instance.changeDetectorRef.markForCheck();
        }
    }

    setSelected() {
        this.cmpRef.instance.selected = this.selected;
        this.cmpRef.instance.changeDetectorRef.markForCheck();
    }

    onSelect(object) {
        this.select.emit(object);
    }

    onUpdate(object) {
        this.update.emit(object);
    }
}
