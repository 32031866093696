import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuditLogsFacade } from '../../facade/audit-logs.facade';
import { AuditLogDto, AuditLogTypeDto } from '@backoffice/data-access/audit-logs';
import { RouterFacade } from '../../../../../../utils-routing/src';
import { Argument } from '../../../../../../data-access/editor/src/lib/arguments/argument';

declare let vanillaTextMask: any;

@Component({
    selector: 'app-audit-logs',
    templateUrl: './audit-logs.page.html',
    styleUrls: ['./audit-logs.page.scss'],
    standalone: false,
})
export class AuditLogsPage implements OnInit, AfterViewInit, OnDestroy {
    logs: BehaviorSubject<AuditLogDto[]> = new BehaviorSubject([]);

    templateId: string | undefined;

    actionId: string | undefined;

    from: Date = new Date();
    to: Date = new Date();

    fromTime: string;
    toTime: string;

    dateTimeMask = [/[0-2]/, /[0-9]/, ':', /[0-6]/, /[0-9]/, ':', /[0-6]/, /[0-9]/];

    streamSubscription: Subscription = new Subscription();

    streaming = false;

    type: string;

    expandedLogLines: string[] = [];

    protected readonly AuditLogTypeDto = AuditLogTypeDto;

    advancedLogSearch = false;

    constructor(
        private auditLogsFacade: AuditLogsFacade,
        private routerFacade: RouterFacade,
        private route: ActivatedRoute
    ) {}

    async isElementLoaded(selector: any) {
        while (document.querySelector(selector) === null) {
            await new Promise(resolve => requestAnimationFrame(resolve));
        }
        return document.querySelector(selector);
    }

    ngOnDestroy() {
        this.streamSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.from.setHours(this.from.getHours() - 1);
        this.toTime = this.to.toTimeString();
        this.fromTime = this.from.toTimeString();
        this.fetchLogs();
    }

    ngAfterViewInit() {
        this.setTimeMasks();
    }

    expandToggle(logLine: AuditLogDto) {
        if (this.expandedLogLines.indexOf(logLine.id) > -1) {
            this.expandedLogLines.splice(this.expandedLogLines.indexOf(logLine.id), 1);
        } else {
            this.expandedLogLines.push(logLine.id);
        }
    }

    setTimeMasks() {
        this.isElementLoaded('#dateTimeFrom').then(selector => {
            vanillaTextMask.maskInput({
                inputElement: document.querySelector('#dateTimeFrom'),
                mask: this.dateTimeMask,
            });
        });
        this.isElementLoaded('#dateTimeTo').then(selector => {
            vanillaTextMask.maskInput({
                inputElement: document.querySelector('#dateTimeTo'),
                mask: this.dateTimeMask,
            });
        });
    }

    fetchLogs(): void {
        this.auditLogsFacade.getAuditLogs(this.templateId, this.actionId, this.from, this.to, this.type).subscribe(actionLogs => {
            this.logs.next(actionLogs);
        });
    }

    onChangeToTime($event?: any) {
        if ($event) {
            this.toTime = $event;
        }
        const [hours, minutes, seconds] = this.toTime.replace(/_/gi, '').split(':');
        if (!!hours) {
            this.to.setHours(Number(hours.slice(0, 2)));
        }
        if (!!minutes) {
            this.to.setMinutes(Number(minutes.slice(0, 2)));
        }
        if (!!seconds) {
            this.to.setSeconds(Number(seconds.slice(0, 2)));
        }
    }

    onChangeType() {}
    onChangeFromDate() {}
    onChangeToDate() {}
    onChangeFromTime($event?: any) {
        if ($event) {
            this.fromTime = $event;
        }
        const [hours, minutes, seconds] = this.fromTime.replace(/_/gi, '').split(':');
        if (!!hours) {
            this.from.setHours(Number(hours.slice(0, 2)));
        }
        if (!!minutes) {
            this.from.setMinutes(Number(minutes.slice(0, 2)));
        }
        if (!!seconds) {
            this.from.setSeconds(Number(seconds.slice(0, 2)));
        }
    }

    onTemplateChanged($event: { value: string | undefined; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.templateId = $event.value;
        } else {
            this.templateId = undefined;
        }
    }

    getIcon(type: string) {
        if (type === 'READ') {
            return 'visibility';
        } else if (type === 'UPDATE') {
            return 'edit';
        } else if (type === 'CREATE') {
            return 'add_circle';
        } else if (type === 'DELETE') {
            return 'clear';
        }
        return 'visibility';
    }

    getTooltip(type: string) {
        if (type === 'READ') {
            return 'Read action';
        } else if (type === 'UPDATE') {
            return 'Update action';
        } else if (type === 'CREATE') {
            return 'Create action';
        } else if (type === 'DELETE') {
            return 'Delete action';
        }
        return 'visibility';
    }

    refreshLogs() {
        this.from = new Date();
        this.to = new Date();
        this.from.setHours(this.from.getHours() - 1);
        this.toTime = this.to.toTimeString();
        this.fromTime = this.from.toTimeString();
        this.fetchLogs();
    }

    gotoAction(actionId: string) {
        this.routerFacade.navigate(['../../editor'], {
            relativeTo: this.route,
            queryParams: {
                type: 'action',
                id: actionId,
            },
        });
    }

    gotoTemplate(templateId: string) {
        this.routerFacade.navigate(['../../editor'], {
            relativeTo: this.route,
            queryParams: {
                type: 'template',
                id: templateId,
            },
        });
    }

    streamLogs() {
        if (this.streaming) {
            this.streaming = false;
            this.streamSubscription.unsubscribe();
            this.streamSubscription = new Subscription();
        } else {
            this.streaming = true;
            this.streamSubscription.add(
                timer(1, 3000).subscribe(() => {
                    this.fetchLogs();
                })
            );
        }
    }

    handleAdvancedSearchClicked() {
        this.advancedLogSearch = !this.advancedLogSearch;
    }
}
