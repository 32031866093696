import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';
import { DataFormat } from '../../../../../../data-access/editor/src/lib/dataformat/models/data-format';
import { EditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-data-edit-form',
    templateUrl: './data-edit-form.component.html',
    standalone: false,
})
export class DataEditFormComponent implements OnInit, OnChanges {
    editorOptions = {
        theme: 'vs-dark',
        language: 'json',
        formatOnType: true,
        automaticLayout: true,
    };

    @Input()
    public data: Data;

    @Input()
    public dataFormat: DataFormat;

    public formGroup: FormGroup;

    @Output()
    public update: EventEmitter<{ data: Data }> = new EventEmitter<{ data: Data }>();

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter();

    @Output()
    public refresh: EventEmitter<void> = new EventEmitter<void>();

    private editor: any;

    public body: string;

    errors: string[] = [];

    setModelMarkers: Function;
    constructor(
        private fb: FormBuilder,
        private editorFacade: EditorFacade,
        public changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: [this.data.name, Validators.required],
            description: [this.data.description],
            iconName: [this.data.iconName],
            installFromHub: [this.data.installFromHub],
        });
        this.body = JSON.stringify(this.data.body);
    }

    ngOnChanges(): void {
        this.body = JSON.stringify(this.data.body);
        setTimeout(() => {
            if (this.editor) {
                this.editor.getAction('editor.action.formatDocument').run();
            }
        }, 500);
    }

    showErrors(model, owner: string, markers: { message: string }[]) {
        this.setModelMarkers(model, owner, markers);
        if (markers.length == 0) {
            this.errors = [];
        } else {
            this.errors = markers.map(marker => marker.message);
        }
    }

    onChangeIconName(iconName: string) {
        this.data.iconName = iconName;
    }

    onUpdate() {
        if (this.formGroup.valid && this.errors.length === 0) {
            this.data.name = this.formGroup.get('name')?.value;
            this.data.description = this.formGroup.get('description')?.value;
            this.data.body = JSON.parse(this.body);
            this.data.installFromHub = this.formGroup.get('installFromHub')?.value;
            this.update.emit({
                data: this.data,
            });
        }
    }

    onInitBody(editor) {
        this.editor = editor;
        setTimeout(() => {
            if (editor) {
                editor.getAction('editor.action.formatDocument').run();
            }
        }, 500);
    }

    openDataFormat(dataFormatId: string, dataFormatName: string) {
        this.editorFacade.registerTab({ type: 'data-format', name: dataFormatName, typeId: dataFormatId });
    }

    onInitEditor(editor) {
        // @ts-ignore
        window.monaco.editor.getModels().forEach(model => {
            if (!model.isDisposed()) {
                model.dispose();
            }
        });
        // @ts-ignore
        const modelUri = window.monaco.Uri.parse('a://b/dataformat.json');
        // @ts-ignore
        const model = window.monaco.editor.createModel(this.body, 'json', modelUri);
        editor.setModel(model);

        // @ts-ignore
        this.setModelMarkers = window.monaco.editor.setModelMarkers;
        // @ts-ignore
        window.monaco.editor.setModelMarkers = this.showErrors.bind(this);
        this.setJsonSchema();
        this.onInitBody(editor);
    }

    setJsonSchema() {
        // load from source
        const schema = {
            uri: 'a://b/dataformat.json',
            fileMatch: ['*'],
            schema: Object.assign(this.dataFormat.jsonSchema, { $schema: null }),
        };
        // @ts-ignore
        window.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            enableSchemaRequest: false,
            validate: true,
            schemas: [schema],
        });
    }
}
