import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BorderStyleDto, ColorV2 } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-border-picker',
    templateUrl: './border-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class BorderPickerComponent implements OnInit {
    borderType: string = 'all';

    borderTypes: string[] = [
        'none',
        'hidden',
        'dotted',
        'dashed',
        'solid',
        'double',
        'groove',
        'ridge',
        'inset',
        'outset',
        'initial',
        'inherit',
    ];

    @Input()
    allowUnlock: boolean = true;

    @Input()
    set borderStyle(borderStyle: BorderStyleDto) {
        if (borderStyle) {
            this._borderStyle = borderStyle;
        } else {
            this._borderStyle = this.defaultBorderStyle();
        }
    }

    _borderStyle: BorderStyleDto;

    borderTop: boolean;

    @Input()
    set borderTopStyle(borderStyle: BorderStyleDto) {
        if (borderStyle) {
            this._borderTopStyle = borderStyle;
        } else {
            this._borderTopStyle = this.defaultBorderStyle();
        }
    }

    _borderTopStyle: BorderStyleDto;

    borderLeft: boolean;

    @Input()
    set borderLeftStyle(borderStyle: BorderStyleDto) {
        if (borderStyle) {
            this._borderLeftStyle = borderStyle;
        } else {
            this._borderLeftStyle = this.defaultBorderStyle();
        }
    }

    _borderLeftStyle: BorderStyleDto;

    borderRight: boolean;

    @Input()
    set borderRightStyle(borderStyle: BorderStyleDto) {
        if (borderStyle) {
            this._borderRightStyle = borderStyle;
        } else {
            this._borderRightStyle = this.defaultBorderStyle();
        }
    }

    _borderRightStyle: BorderStyleDto;

    borderBottom: boolean;

    @Input()
    set borderBottomStyle(borderStyle: BorderStyleDto) {
        if (borderStyle) {
            this._borderBottomStyle = borderStyle;
        } else {
            this._borderBottomStyle = this.defaultBorderStyle();
        }
    }

    _borderBottomStyle: BorderStyleDto;

    @Output()
    changeBorder: EventEmitter<{
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onChangeAllBorders() {
        this.changeBorder.emit({
            border: {
                borderStyle: this._borderStyle,
                borderTopStyle: this._borderTopStyle,
                borderLeftStyle: this._borderLeftStyle,
                borderBottomStyle: this._borderBottomStyle,
                borderRightStyle: this._borderRightStyle,
            },
        });
    }

    onChangeColor($event: ColorV2) {
        this._borderStyle.colorV2 = $event;
        if (!!$event.hex) {
            this._borderStyle.color = { value: $event.hex.value, type: 'RGB' };
        }

        this.onChangeAllBorders();
    }

    onChangeTopColor($event: ColorV2) {
        this._borderTopStyle.colorV2 = $event;
        if (!!$event.hex) {
            this._borderTopStyle.color = { value: $event.hex.value, type: 'RGB' };
        }

        this.onChangeAllBorders();
    }

    onChangeLeftColor($event: ColorV2) {
        this._borderLeftStyle.colorV2 = $event;
        if (!!$event.hex) {
            this._borderLeftStyle.color = { value: $event.hex.value, type: 'RGB' };
        }

        this.onChangeAllBorders();
    }
    onChangeRightColor($event: ColorV2) {
        this._borderRightStyle.colorV2 = $event;
        if (!!$event.hex) {
            this._borderRightStyle.color = { value: $event.hex.value, type: 'RGB' };
        }

        this.onChangeAllBorders();
    }

    onChangeBottomColor($event: ColorV2) {
        this._borderBottomStyle.colorV2 = $event;
        if (!!$event.hex) {
            this._borderBottomStyle.color = { value: $event.hex.value, type: 'RGB' };
        }

        this.onChangeAllBorders();
    }

    defaultBorderStyle() {
        return {
            borderType: null,
            borderColor: null,
            borderSize: null,
            borderRadius: null,
        };
    }
}
