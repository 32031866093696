import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, Subscription, switchMap } from 'rxjs';
import { DataFormat } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { ActionContextFacade, DataFormatEditorFacade, EditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-dataformat-attribute-picker',
    templateUrl: './argument-dataformat-attribute-picker.component.html',
    standalone: false,
})
export class ArgumentDataformatAttributePickerComponent implements OnInit, OnChanges {
    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language: string;
    @Input() contextId: string;
    @Input() scope: Scope;

    @Input() set root(value: Argument[]) {
        this._root = value;
        const argumentList: Argument[] = [];
        this.parseArguments(value, argumentList);
        this._arguments = argumentList;
        this.loadDataFormat();
    }

    _root: Argument[];

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    dataFormatArgument!: Argument | undefined;

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    dataFormatAttributes!: Array<{ name: string; type: string[]; description: string | undefined }>;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        private editorFacade: EditorFacade,
        private actionContextFacade: ActionContextFacade
    ) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {}

    loadDataFormat() {
        if (this._argument) {
            if (this._argument.parameter?.linkedDataFormatParameterId) {
                this.dataFormatArgument = this._arguments.find(
                    argument =>
                        argument.parameterId === this._argument.parameter?.linkedDataFormatParameterId ||
                        argument.selectorId === this._argument.parameter?.linkedDataFormatParameterId
                );
                if (this.dataFormatArgument) {
                    this.initializeDataAttributesByDataFormatArgument();
                }
            } else if (this._argument.parameter?.linkedArrayParameterId) {
                const arrayArgument = this._arguments.find(
                    argument =>
                        argument.parameterId === this._argument.parameter?.linkedArrayParameterId ||
                        argument.selectorId === this._argument.parameter?.linkedArrayParameterId
                );
                if (arrayArgument) {
                    this.initializeDataAttributesByArrayArgument(arrayArgument);
                }
            }
            this._argument.subArguments = [];
        }
        this.showSubArguments = false;
        this.changeDetectorRef.detectChanges();
    }

    initializeDataAttributesByArrayArgument(arrayArgument: Argument) {
        if (!!arrayArgument.value && arrayArgument.inputSelectionType === 'scope') {
            this.editorFacade.activeTab
                .pipe(
                    switchMap(tab => {
                        return this.actionContextFacade.getScope(tab.typeId);
                    }),
                    filter(scope => !!scope)
                )
                .subscribe(scope => {
                    let schema = scope.scopeItemsByInvocationAndByType
                        .get(this.contextId)
                        ?.get('ARRAY')
                        ?.get(arrayArgument.value?.replace('{', '')?.replace('}', ''))?.subTypeProperties;
                    if (schema) {
                        this.dataFormatAttributes = [];
                        if (!!schema) {
                            Object.keys(schema).forEach(dataPropertyName => {
                                const dataProperty = schema[dataPropertyName];
                                this.dataFormatAttributes.push({
                                    name: dataPropertyName,
                                    type: dataProperty.type,
                                    description: dataProperty.description,
                                });
                            });
                        }
                        this.showSubArguments = true;
                        this.changeDetectorRef.detectChanges();
                    }
                });
        }
    }

    initializeDataAttributesByDataFormatArgument() {
        if (
            this.dataFormatArgument &&
            this.dataFormatArgument.value !== this.dataFormatId &&
            this.dataFormatArgument.inputSelectionType !== 'scope'
        ) {
            this.initializeDataAttributes(this.dataFormatArgument.value);
        }
    }

    initializeDataAttributes(dataFormatId: string | undefined) {
        if (dataFormatId) {
            this.dataFormatId = dataFormatId;
            if (this.dataFormatId && !this.dataFormatId.startsWith('{')) {
                this.dataFormatEditorFacade.findById(this.removeQuotesIfPresent(this.dataFormatId)).subscribe(dataFormat => {
                    this.dataFormat = dataFormat;
                    const dataSchema = dataFormat.jsonSchema;
                    this.dataFormatAttributes = [];
                    this.dataFormatAttributes.push({
                        name: 'id',
                        type: ['STRING'],
                        description: 'The unique identifier of this data object',
                    });
                    if (!!dataSchema.properties) {
                        Object.keys(dataSchema.properties).forEach(dataPropertyName => {
                            if (dataSchema.properties) {
                                const dataProperty = dataSchema.properties[dataPropertyName];
                                this.dataFormatAttributes.push({
                                    name: dataPropertyName,
                                    type: dataProperty.type,
                                    description: dataProperty.description,
                                });
                            }
                        });
                    }
                    this.showSubArguments = true;
                    this.changeDetectorRef.detectChanges();
                });
                return;
            }
        }
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    onDataFormatAttributeSelected($event) {
        //this._argument.value = `'${$event}'`;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    identifyAttribute(index, item: { name: string; type: string[]; description: string | undefined }) {
        return item.name;
    }

    private parseArguments(parents: Argument[], parsed: Argument[]): void {
        if (!parents) {
            return;
        }

        for (const parent of parents) {
            parsed.push(parent);
            if (parent.subArguments) {
                this.parseArguments(parent.subArguments, parsed);
            }
        }
    }
}
