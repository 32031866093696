<ng-container [formGroup]="formGroup">
    <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'v2.action.argument.scope.select' | translate }}</mat-label>
        <input type="text" matInput formControlName="tag" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTag">
            <mat-option *ngFor="let tag of tags | async" [value]="tag">
                <mat-icon>label</mat-icon>
                <span>{{ tag.tag }}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-container>
