import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { MediaService } from '../../services/media.service';
import { mediaEditorSelectors } from '../selectors/media-editor.selectors';
import {
    clearMediaDialogFilter,
    createMediaSuccess,
    deleteMedia,
    deleteMediaSuccess,
    deleteMultipleMedia,
    deleteMultipleMediaSuccess,
    fetchMediaSuccess,
    findMedia,
    loadMediaDialogData,
    loadMediaDialogDataSuccess,
    mediaDialogFacetsChanged,
    mediaDialogFilterPluginsChanged,
    mediaDialogPaginationChanged,
    mediaDialogSearchTermChanged,
    updateMedia,
    updateMediaSuccess,
} from '../actions/media-editor.actions';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Page } from '@shared/data-access';
import { Media, toFilter } from '@backoffice/data-access/editor';
import { MediaEditorFacade } from '../facades/media-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';

@Injectable()
export class MediaEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly mediaService: MediaService,
        private readonly snackBar: MatSnackBar,
        private readonly editorFacade: MediaEditorFacade,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearMediaDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMultipleMedia),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.mediaService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteMultipleMediaSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMultipleMediaSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'media' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMedia),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.mediaService.delete(id, companyId, applicationId).pipe(map(() => deleteMediaSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findMedia),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(mediaEditorSelectors.byId(id)),
            ]),
            filter(([_media, company, application, detail]) => !detail && !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.mediaService.findById(id, companyId, applicationId).pipe(map(result => fetchMediaSuccess({ media: result })))
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteMultipleMediaSuccess,
                loadMediaDialogData,
                mediaDialogPaginationChanged,
                mediaDialogSearchTermChanged,
                mediaDialogFacetsChanged,
                mediaDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.mediaService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<Media>) => loadMediaDialogDataSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMedia),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ media }, { id: companyId }, { id: applicationId }]) =>
                this.mediaService.update(media, companyId, applicationId).pipe(map(response => updateMediaSuccess({ media })))
            )
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMediaSuccess),
            map(({ media }) => {
                const { id: typeId, title: name } = media;
                return updateTab({ definition: { type: 'media', typeId, name, icon: 'content_paste' } });
            })
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMediaSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'media' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateMediaSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.media.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createMediaSuccess),
            map(({ id, name }) => registerTab({ definition: { type: 'media', typeId: id, name } }))
        )
    );
}
