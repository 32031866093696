import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument, createArguments, Scope, TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-value-picker',
    templateUrl: './argument-value-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentValuePickerComponent implements OnInit {
    @Input() contextId!: string;

    @Input() root: Argument[];

    @Input() set argument(argument: Argument) {
        this._argument = argument;
        this.initAllowedSelectionTypes();
    }

    _argument!: Argument;

    @Input() set arguments(argumentList: Argument[] | undefined) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[] | undefined;

    @Input() language: string;
    @Input() scope: Scope;

    @Input() set onlyLiteralValues(onlyLiteralValues: boolean) {
        this._onlyLiteralValues = onlyLiteralValues;
        this.initAllowedSelectionTypes();
    }

    _onlyLiteralValues: boolean = false;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    allowedSelectionTypes: Set<string> = new Set();

    selectedSelectionType!: string;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    initAllowedSelectionTypes() {
        this.allowedSelectionTypes.clear();
        if (this._argument.parameter) {
            if (this._argument.parameter.type === 'PART') {
                this.allowedSelectionTypes.add('part');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.setSelectedSelectionType('part');
            } else if (this._argument.parameter.type === 'EMAIL') {
                this.allowedSelectionTypes.add('string');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.setSelectedSelectionType('string');
            } else if (this._argument.parameter.type === 'ACTION') {
                this.allowedSelectionTypes.add('action');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('prototype');
                this.setSelectedSelectionType('action');
            } else if (this._argument.parameter.type === 'ACTION_PARAMS') {
                this.allowedSelectionTypes.add('action-params');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.setSelectedSelectionType('action-params');
            } else if (this._argument.parameter.type === 'TEMPLATE') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('template');
                this.allowedSelectionTypes.add('template-contract');
                this.setSelectedSelectionType('template');
            } else if (this._argument.parameter.type === 'TEMPLATE_PARAMS') {
                this.allowedSelectionTypes.add('template-params');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.setSelectedSelectionType('template-params');
            } else if (this._argument.parameter.type === 'TAG') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('tag');
                this.allowedSelectionTypes.add('string');
                this.setSelectedSelectionType('tag');
            } else if (this._argument.parameter.type === 'STRING') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('string');
                this.setSelectedSelectionType('string');
            } else if (this._argument.parameter.type === 'URL') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('string');
                this.setSelectedSelectionType('string');
            } else if (this._argument.parameter.type === 'DOUBLE') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('number');
                this.setSelectedSelectionType('number');
            } else if (this._argument.parameter.type === 'NUMBER') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('number');
                this.setSelectedSelectionType('number');
            } else if (this._argument.parameter.type === 'PASSWORD') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('password');
                this.setSelectedSelectionType('password');
            } else if (this._argument.parameter.type === 'SELECT_ONE') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('select-one');
                this.setSelectedSelectionType('select-one');
            } else if (this._argument.parameter.type === 'SELECT_MULTI') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('select-multi');
                this.setSelectedSelectionType('select-multi');
            } else if (this._argument.parameter.type === 'CONDITIONAL') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('string');
                this.allowedSelectionTypes.add('number');
                this.allowedSelectionTypes.add('boolean');
                this.setSelectedSelectionType('string');
            } else if (this._argument.parameter.type === 'JSON') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('json');
                this.setSelectedSelectionType('json');
            } else if (this._argument.parameter.type === 'XML') {
                this.allowedSelectionTypes.add('xml');
                this.setSelectedSelectionType('xml');
            } else if (this._argument.parameter.type === 'JSON_FIELD') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('string');
                this.allowedSelectionTypes.add('number');
                this.allowedSelectionTypes.add('boolean');
                this.allowedSelectionTypes.add('object');
                this.allowedSelectionTypes.add('array');
                this.setSelectedSelectionType('string');
            } else if (this._argument.parameter.type === 'DATA_FORMAT') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('data-format');
                this.setSelectedSelectionType('data-format');
            } else if (this._argument.parameter.type === 'PARTIAL_DATA') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'DATA_FORMAT_FILTERS') {
                this.allowedSelectionTypes.add('data-format-filters');
                this.setSelectedSelectionType('data-format-filters');
            } else if (this._argument.parameter.type === 'DATA_FORMAT_ATTRIBUTE') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('data-format-attribute');
                this.allowedSelectionTypes.add('string');
                this.setSelectedSelectionType('data-format-attribute');
            } else if (this._argument.parameter.type === 'DATA_FORMAT_ATTRIBUTES') {
                this.allowedSelectionTypes.add('data-format-attributes');
                this.setSelectedSelectionType('data-format-attributes');
            } else if (this._argument.parameter.type === 'DATA_FORMAT_SORT_ORDERS') {
                this.allowedSelectionTypes.add('data-format-sort-orders');
                this.setSelectedSelectionType('data-format-sort-orders');
            } else if (this._argument.parameter.type === 'DATA') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'DATA_BODY') {
                this.allowedSelectionTypes.add('object');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('object');
                }
            } else if (this._argument.parameter.type === 'ARRAY') {
                this.allowedSelectionTypes.add('array');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('array');
                }
            } else if (this._argument.parameter.type === 'MAP') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'PAGE') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'DATE') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'BOOLEAN') {
                this.allowedSelectionTypes.add('boolean');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('boolean');
                }
            } else if (this._argument.parameter.type === 'JSON_NODE') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'FOLDER') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'FILE') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'STATUS') {
                this.allowedSelectionTypes.add('scope');
                this.setSelectedSelectionType('scope');
            } else if (this._argument.parameter.type === 'DATABASE_CONNECTION') {
                this.allowedSelectionTypes.add('databaseconnection');
                this.setSelectedSelectionType('databaseconnection');
            } else if (this._argument.parameter.type === 'COMPOSED_LIST') {
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                }
                this.allowedSelectionTypes.add('composed_list');
                this.setSelectedSelectionType('composed_list');
            } else if (this._argument.parameter.type === 'COMPOSED') {
                this.allowedSelectionTypes.add('composed');
                this.setSelectedSelectionType('composed');
            } else if (this._argument.parameter.type === 'VARIABLE') {
                if (this._argument.allowedTypes && this._argument.allowedTypes.length > 0) {
                    this._argument.allowedTypes.forEach(allowedType => {
                        this.allowedSelectionTypes.add(allowedType);
                    });
                    this.setSelectedSelectionType(this._argument.allowedTypes[0]);
                } else {
                    this.allowedSelectionTypes.add('string');
                    this.allowedSelectionTypes.add('number');
                    this.allowedSelectionTypes.add('boolean');
                    this.allowedSelectionTypes.add('object');
                    this.allowedSelectionTypes.add('array');
                    this.allowedSelectionTypes.add('part');
                    if (!this._onlyLiteralValues) {
                        this.allowedSelectionTypes.add('scope');
                        this.setSelectedSelectionType('scope');
                    } else {
                        this.setSelectedSelectionType('string');
                    }
                }
            } else if (this._argument.parameter.type === 'OBJECT') {
                this.allowedSelectionTypes.add('object');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('object');
                }
            } else if (this._argument.parameter.type === 'AGENT') {
                this.allowedSelectionTypes.add('agent');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('agent');
                }
            } else if (this._argument.parameter.type === 'TASK') {
                this.allowedSelectionTypes.add('task');
                if (!this._onlyLiteralValues) {
                    this.allowedSelectionTypes.add('scope');
                    this.setSelectedSelectionType('scope');
                } else {
                    this.setSelectedSelectionType('task');
                }
            }
        }
    }

    setSelectedSelectionType(selectionType: string) {
        if (this._argument.inputSelectionType && !this._onlyLiteralValues) {
            this.selectedSelectionType = this._argument.inputSelectionType;
        } else {
            this.selectedSelectionType = selectionType;
            this._argument.inputSelectionType = selectionType;
        }
    }

    onChangeSelectionType(selectionType: string) {
        this.selectedSelectionType = selectionType;
        this._argument.inputSelectionType = selectionType;
        this._argument.value = undefined;
        this._argument.subArguments = [];
        if (
            selectionType === 'object' &&
            this._argument.parameter &&
            this._argument.parameter.parameters &&
            this._argument.parameter.parameters.length > 0
        ) {
            this._argument.subArguments = createArguments(this._argument.parameter.parameters);
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
