import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Output as MijnenOutput } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-output-picker',
    templateUrl: './output-picker.component.html',
    standalone: false,
})
export class OutputPickerComponent {
    _outputs: MijnenOutput[] = [];

    @Input() selectedOutputId!: string;

    @Input() set root(value: MijnenOutput[]) {
        const outputs: MijnenOutput[] = [];
        this.flattenOutputs(value, outputs);
        this._outputs = outputs;
        this.changeDetectorRef.detectChanges();
    }

    @Output() changeSelectedOutput: EventEmitter<{ selectedOutputId: string }> = new EventEmitter();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}
    onChange($event) {
        this.selectedOutputId = $event;
        this.changeSelectedOutput.emit({ selectedOutputId: this.selectedOutputId });
    }

    private flattenOutputs(outputs: MijnenOutput[], flattenedList: MijnenOutput[]): void {
        if (!outputs) {
            return;
        }

        for (const output of outputs) {
            flattenedList.push(output);
            //when outputs become multi depth just like parameters.
            /**if (output.parameters) {
                this.parseParameters(parent.parameters, parsed);
            }**/
        }
    }

    identifyOutput(index, item: MijnenOutput) {
        return item.id;
    }
}
