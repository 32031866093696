<ng-container *ngIf="prototypeArgument && actionArgument">
    <argument-action-picker
        class="mb-4"
        [arguments]="[prototypeArgument]"
        [argument]="prototypeArgument"
        [scope]="scope"
        [contextId]="contextId"
        (openTab)="openTab.emit($event)"
        (argumentUpdated)="handleUpdatePrototypeArgument($event)">
    </argument-action-picker>
    <argument-scope-picker
        [arguments]="[actionArgument]"
        [argument]="actionArgument"
        [scope]="scope"
        [contextId]="contextId"
        (argumentUpdated)="handleUpdateActionArgument($event)">
    </argument-scope-picker>
</ng-container>
