import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataEditFacade } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/facade/data-edit.facade';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { DataFormatEditorFacade, isOverviewDataFormatDto, OverviewDataFormatDto } from '@backoffice/data-access/editor';
import { initFlowbite } from 'flowbite';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { ProgressService } from '../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/components/progress/progress.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

let monaco: any;

@Component({
    selector: 'codex-data-create-form',
    templateUrl: './data-create-form.component.html',
    standalone: false,
})
export class DataCreateFormComponent implements OnInit {
    formGroup: FormGroup;

    dataBody: string = '{}';

    editorOptions = {
        theme: 'vs-dark',
        language: 'json',
        formatOnType: true,
        automaticLayout: true,
    };

    selectedDataFormatId: string;

    errors: string[] = [];

    dataFormats: {
        name: string;
        id: string;
    }[] = [];

    selectedType = 'BLANK';

    options: UploaderOptions = {
        concurrency: 1,
        maxUploads: 1,
        maxFileSize: 1024000000,
        allowedContentTypes: ['text/csv'],
    };
    uploadInput = new EventEmitter<UploadInput>();
    files: UploadFile[] = [];
    dragOver: boolean;

    setModelMarkers: Function;

    model: any;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataEditFacade: DataEditFacade,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        public dialogRef: MatDialogRef<DataCreateFormComponent>,
        public progressService: ProgressService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly snackBar: MatSnackBar,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }

    clearDataFormats() {
        this.dataFormats = [];
    }

    onDataFormatPick($event: { value: string | undefined }) {
        if ($event.value) {
            this.dataFormatEditorFacade
                .findById($event.value)
                .pipe(take(1))
                .subscribe(dataFormat => {
                    this.dataFormats.push({ id: dataFormat.id, name: dataFormat.name });
                });
        }
    }

    onRemoveDataFormat(dataFormatToRemove: { name: string; id: string }): void {
        let index = this.dataFormats.findIndex(dataFormat => dataFormat.id === dataFormatToRemove.id);
        this.dataFormats.splice(index, 1);
        setTimeout(() => initFlowbite());
    }

    showErrors(model, owner: string, markers: { message: string }[]) {
        this.setModelMarkers(model, owner, markers);
        if (markers.length == 0) {
            this.errors = [];
        } else {
            this.errors = markers.map(marker => marker.message);
        }
    }

    onInitEditor(editor) {
        // @ts-ignore
        this.model.dispose();
        // @ts-ignore
        const modelUri = window.monaco.Uri.parse('a://b/dataformat.json');
        // @ts-ignore
        const model = window.monaco.editor.createModel('{}', 'json', modelUri);
        this.model = model;
        // @ts-ignore
        editor.setModel(model);
        // @ts-ignore
        this.setModelMarkers = window.monaco.editor.setModelMarkers;
        // @ts-ignore
        window.monaco.editor.setModelMarkers = this.showErrors.bind(this);
        this.setJsonSchema(modelUri);
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            dataName: [null],
            dataDescription: [null],
            environment: ['DEV', Validators.required],
            createDataFormat: [false],
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    removeFile(id: string): void {
        this.files = this.files.filter((file: UploadFile) => file.id !== id);
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    onCreateData(): void {
        if (this.formGroup.valid && this.errors.length === 0) {
            let dataFormatIds = this.dataFormats.map(dataFormat => dataFormat.id);
            const { dataName, environment, dataDescription, createDataFormat } = this.formGroup.value;
            this.dialogRef.close({
                dataName,
                dataFormatId: this.selectedDataFormatId,
                dataBody: this.dataBody,
                environment,
                type: this.selectedType,
                file: this.files[0],
                dataFormatIds,
                dataDescription,
                createDataFormat,
            });
        }
    }

    onUploadOutput(output: UploadOutput): void {
        if (output) {
            switch (output.type) {
                case 'allAddedToQueue':
                    break;
                case 'addedToQueue':
                    if (typeof output.file !== 'undefined') {
                        this.files.push(output.file);
                    }
                    break;
                case 'uploading':
                    if (typeof output.file !== 'undefined') {
                        // update current data in files array for uploading file
                        const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                        this.files[index] = output.file;
                    }
                    this.progressService.show();
                    this.changeDetectorRef.detectChanges();
                    break;
                case 'removed':
                    // remove file from array when removed
                    this.files = this.files.filter((file: UploadFile) => file !== output.file);
                    break;
                case 'rejected':
                    this.snackBar.open(this.translateService.instant('error.media.upload.rejected'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    break;
                case 'dragOver':
                    this.dragOver = true;
                    break;
                case 'dragOut':
                case 'drop':
                    this.dragOver = false;
                    break;
                case 'done':
                    this.progressService.hide();
                    break;
            }
            if (output.file) {
                const { responseStatus } = output.file;
                if (responseStatus == 400) {
                    this.snackBar.open(this.translateService.instant('error.media.upload.failed'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    return;
                }
            }
        }
    }

    setJsonSchema(modelUri) {
        this.dataEditFacade.getDataFormat(this.selectedDataFormatId).subscribe(dataFormat => {
            // load from source
            const schema = {
                uri: 'a://b/dataformat.json',
                fileMatch: ['*'],
                schema: Object.assign(dataFormat.jsonSchema, { $schema: null }),
            };
            // @ts-ignore
            window.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                enableSchemaRequest: false,
                validate: true,
                schemas: [schema],
            });
        });
    }

    onDataFormatChange($event): void {
        this.selectedDataFormatId = $event.value;
    }
}
