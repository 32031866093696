<h3 *ngIf="!!selectedInvocation" class="invocation-settings-title">
    <mat-icon class="invocation-icon">{{ selectedInvocation.iconName }}</mat-icon>
    <span class="grow shrink text-left">{{ selectedInvocation.name }}</span>
    <button
        class="accordion-heading-button destructive-accordion-heading-button"
        (click)="this.close.emit(); $event.stopPropagation()"
        [matTooltip]="'Close'">
        <mat-icon>clear</mat-icon>
    </button>
</h3>
<div *ngIf="!!selectedInvocation">
    <div class="invocation-config">
        <div class="alt-tabs-header-wrapper">
            <ul
                class="alt-tabs-header"
                id="default-styled-tab"
                data-tabs-toggle="#invocation-configuration-tabs"
                data-tabs-inactive-classes="alt-tabs-tab-inactive"
                data-tabs-active-classes="alt-tabs-tab-active"
                role="tablist">
                <li class="me-2" role="presentation" *ngIf="selectedInvocation.methodKey !== 'start'">
                    <button
                        class="alt-tabs-tab-button"
                        id="invocation-configuration-arguments-tab"
                        data-tabs-target="#invocation-configuration-arguments"
                        type="button"
                        role="tab"
                        aria-controls="invocation-configuration-arguments"
                        aria-selected="true"
                        (click)="reloadFlowBite()">
                        {{ 'v2.action.toolpane.invocation.arguments' | translate }}
                    </button>
                </li>
                <li class="me-2" role="presentation">
                    <button
                        class="alt-tabs-tab-button"
                        id="invocation-configuration-properties-tab"
                        data-tabs-target="#invocation-configuration-properties"
                        type="button"
                        role="tab"
                        aria-controls="invocation-configuration-properties"
                        aria-selected="false"
                        (click)="reloadFlowBite()">
                        {{ 'v2.action.toolpane.invocation.properties' | translate }}
                    </button>
                </li>
            </ul>
        </div>
        <div id="invocation-configuration-tabs">
            <div
                *ngIf="selectedInvocation.methodKey !== 'start'"
                id="invocation-configuration-arguments"
                class="hidden alt-tabs-tab-content"
                role="tabpanel"
                aria-labelledby="invocation-configuration-arguments-tab">
                <action-invocation-arguments
                    [invocation]="selectedInvocation"
                    [language]="language"
                    [action]="action"
                    [scope]="scope$ | async"
                    (invocationUpdated)="invocationUpdated.emit($event)"
                    (openTab)="this.openTab.emit($event)"
                    (calculateScope)="handleCalculateScope()">
                </action-invocation-arguments>
                <codex-action-edit-invocation-outputs
                    [invocation]="selectedInvocation"
                    [action]="action"
                    [language]="language"
                    (invocationUpdated)="invocationUpdated.emit($event)"
                    (openTab)="this.openTab.emit($event)"></codex-action-edit-invocation-outputs>
            </div>
            <div
                id="invocation-configuration-properties"
                class="hidden alt-tabs-tab-content"
                role="tabpanel"
                aria-labelledby="invocation-configuration-properties-tab">
                <codex-action-edit-invocation-properties
                    [invocation]="selectedInvocation"
                    [action]="action"
                    (invocationUpdated)="invocationUpdated.emit($event)"></codex-action-edit-invocation-properties>
            </div>
        </div>
    </div>
</div>
<div
    *ngIf="!!selectedInvocation && !!selectedInvocation.nextInvocations && selectedInvocation.nextInvocations.length > 0"
    id="accordion-action-selection"
    data-accordion="collapse"
    data-active-classes="none"
    data-inactive-classes="none">
    <h3 [attr.id]="'accordion-action-selection-edge'" [attr.data-name]="'edge-header'" class="pt-0">
        <button
            type="button"
            class="alt-accordion-heading"
            [attr.data-accordion-target]="'#accordion-action-selection-edge-body'"
            [attr.aria-controls]="'accordion-action-selection-edge-body'"
            aria-expanded="false">
            {{ 'v2.action.toolpane.invocation.next' | translate }}
            <svg
                data-accordion-icon
                class="w-3 h-3 rotate-180 shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
            </svg>
        </button>
    </h3>
    <div
        [attr.id]="'accordion-action-selection-edge-body'"
        [attr.data-name]="'edge-content'"
        [attr.aria-labelledby]="'accordion-action-selection-edge-body'"
        class="hidden p-4">
        <codex-action-edit-invocation-nextinvocations
            [invocation]="selectedInvocation"
            [action]="action"
            (invocationUpdated)="invocationUpdated.emit($event)"></codex-action-edit-invocation-nextinvocations>
    </div>
</div>
