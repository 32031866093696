import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-add-property-dialog',
    templateUrl: './add-property-dialog.component.html',
    standalone: false,
})
export class AddPropertyDialogComponent implements OnInit {
    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get typeControl(): FormControl {
        return this.form.get('type') as FormControl;
    }

    form: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        public dialogRef: MatDialogRef<AddPropertyDialogComponent>
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            name: [undefined, [Validators.required]],
            type: [undefined, [Validators.required]],
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onAddClicked(): void {
        const { valid, value } = this.form;
        if (valid) {
            this.dialogRef.close(value);
        } else {
            this.form.markAllAsTouched();
        }
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
