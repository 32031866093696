import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { iconsMetaData } from './icons';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-icon-picker',
    templateUrl: './icon-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class IconPickerComponent implements OnInit {
    @Input()
    icon: string;

    @ViewChild('toggleButton') toggleButton: ElementRef;

    @Output()
    changeIcon: EventEmitter<string> = new EventEmitter();

    iconsMetaData = iconsMetaData;

    filteredIcons: BehaviorSubject<any> = new BehaviorSubject<any>(this.iconsMetaData.icons.slice(0, 40));

    iconFilter: string;

    public selectIcon: boolean = false;

    windowClick: any;

    @ViewChild('iconPopover') popperContent: NgxFloatUiContentComponent;

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.initFilterCols();
    }

    initFilterCols(): void {
        this.filteredIcons.pipe(debounceTime(100)).subscribe(() => {
            if (this.popperContent) {
                //this.popperContent.update();
                initFlowbite();
            }
        });
    }

    filterIcons($event): void {
        this.filteredIcons.next(
            this.iconsMetaData.icons
                .filter(icon => {
                    return icon.name.indexOf($event) > -1 || icon.tags.find(tag => tag.indexOf($event) > -1);
                })
                .splice(0, 40)
        );
    }

    onChangeIcon(iconName: string) {
        this.changeIcon.emit(iconName);
    }

    onShowIconContainer($event): void {
        this.filteredIcons.next(this.iconsMetaData.icons.slice(0, 40));
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
