import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { Parameter } from '../../../../../../data-access/editor/src/lib/parameters/parameter';
import { TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-argument-template-contract',
    templateUrl: './argument-template-contract.component.html',
    styleUrls: ['./argument-template-contract.component.scss'],
    standalone: false,
})
export class ArgumentTemplateContractComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument: Argument;

    @Input()
    contextId!: string;

    @Input()
    scope: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    contractTemplateArgument: Argument;
    contractScopeArgument: Argument;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

    initForm(): void {
        this.contractTemplateArgument = new Argument();
        this.contractTemplateArgument.parameter = new Parameter();
        this.contractTemplateArgument.parameter.name = 'Parent template';
        this.contractTemplateArgument.parameter.description = 'Select the parent of the template in order to determine a contract';
        this.contractTemplateArgument.value = this._argument.contractValue;
        this.contractScopeArgument = new Argument();
        this.contractScopeArgument.parameter = new Parameter();
        this.contractScopeArgument.parameter.name = 'Template';
        this.contractScopeArgument.parameter.description = 'Select the variable from the scope representing the actual template to be used';
        this.contractScopeArgument.parameter.type = 'TEMPLATE';
        this.contractScopeArgument.value = this._argument.value;
    }

    onUpdateContractTemplateArgument($event: { argument: Argument }) {
        this._argument.contractValue = $event.argument.value;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onUpdateContractScopeArgument($event: { argument: Argument }) {
        this._argument.value = $event.argument.value;
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
