import { UserContext } from '@shared/interfaces/user-context.interface';
import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    createApplicationSuccess,
    createCompanySuccess,
    deleteApplicationSuccess,
    editApplicationSuccess,
    editCompanySuccess,
    requestApplicationsError,
    requestApplicationsSuccess,
    requestBillingPackageSuccess,
    requestCompaniesError,
    requestCompaniesSuccess,
    requestUserAchievementsSuccess,
    requestUserInformationError,
    requestUserInformationSuccess,
    requestUserRightsError,
    requestUserRightsSuccess,
    resetSelectedApplication,
    selectApplicationSuccess,
    selectCompanySuccess,
} from './context.actions';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { CalculatedRightSet } from '../../authenticated/dto/calculatedrightset';
import { BillingPackage } from '@billing/dto/billingpackage';
import { updateDesignSystem } from '../../../../../../libs/backoffice/data-access/editor/src/lib/designsystem/store/actions/design-system-editor.actions';

const userInfoReducer = (initialData: UserContext) =>
    createReducer(
        initialData,
        on(requestUserInformationSuccess, (_, { info }) => info),
        on(requestUserAchievementsSuccess, (currentUserContext, { achievements }) => {
            if (currentUserContext) {
                currentUserContext.achievements = achievements;
                return { ...currentUserContext };
            } else {
                return currentUserContext;
            }
        }),
        on(requestUserInformationError, () => undefined)
    );

const rightsReducer = (initialData: CalculatedRightSet) =>
    createReducer(
        initialData,
        on(requestUserRightsSuccess, (_, { calculatedRightSet }) => calculatedRightSet),
        on(requestUserRightsError, () => undefined)
    );

const companiesReducer = (initialData: CompanyDto[]) =>
    createReducer(
        initialData,
        on(requestCompaniesSuccess, (_, { companies }) => companies),
        on(requestCompaniesError, () => undefined),
        on(createCompanySuccess, (companies, { company }) => [...companies, company])
    );

const selectedCompanyReducer = (initialData: CompanyDto) =>
    createReducer(
        initialData,
        on(selectCompanySuccess, (_, { company }) => company),
        on(editCompanySuccess, (currentCompany, { company }) => {
            if (currentCompany?.id === company.id) {
                return new CompanyDto({
                    ...currentCompany,
                    companyName: company.companyName,
                });
            } else {
                return company;
            }
        })
    );

const recentCompaniesReducer = (initalData: CompanyDto[]) =>
    createReducer(
        initalData,
        on(requestCompaniesSuccess, (_, { recentCompanies }) => recentCompanies),
        on(selectCompanySuccess, (recentCompanies, { company }) => {
            let newRecentCompanies: CompanyDto[] = [];
            if (recentCompanies) {
                if (!!recentCompanies.find(r => r.id === company.id)) {
                    newRecentCompanies = recentCompanies.filter(recent => recent && recent.id !== company.id);
                }
                newRecentCompanies.unshift(company);
            } else {
                newRecentCompanies = [company];
            }
            return newRecentCompanies.slice(0, 5);
        }),
        on(createCompanySuccess, (recentCompanies, { company }) => [company, ...recentCompanies].slice(0, 5)),
        on(editCompanySuccess, (recentCompanies, { company }) => {
            const found: CompanyDto = recentCompanies.find(c => c.id === company.id);
            if (found) {
                found.companyName = company.companyName;
            }
            return recentCompanies;
        })
    );

const applicationsReducer = (initialData: ApplicationDto[]) =>
    createReducer(
        initialData,
        on(requestApplicationsSuccess, (_, { applications }) => applications),
        on(requestApplicationsError, () => undefined),
        on(createApplicationSuccess, (applications, { application }) => [...applications, application]),
        on(editApplicationSuccess, (applications, { application }) => {
            const index = applications.findIndex(a => a.id === application.id);
            if (index === -1) {
                return applications;
            } else {
                const updated = [...applications];
                updated[index] = application;
                return updated;
            }
        }),
        on(deleteApplicationSuccess, (applications, { applicationId }) =>
            applications.filter(application => application.id !== applicationId)
        )
    );

const selectedApplicationReducer = (initialData: ApplicationDto) =>
    createReducer(
        initialData,
        on(selectApplicationSuccess, (_, { application }) => application),
        on(updateDesignSystem, (selectedApplication, { designSystem }) => {
            return {
                ...selectedApplication,
                designsystemId: designSystem.active ? designSystem.id : selectedApplication.designsystemId,
            };
        }),
        on(resetSelectedApplication, () => undefined),
        on(editApplicationSuccess, (selectedApplication, { application }) =>
            selectedApplication?.id == application.id ? application : selectedApplication
        )
    );

const recentApplicationsReducer = (initialData: ApplicationDto[]) =>
    createReducer(
        initialData,
        on(requestApplicationsSuccess, (_, { recentApplications }) => recentApplications),
        on(selectApplicationSuccess, (recentApplications, { application }) => {
            let newRecentApplications: ApplicationDto[] = [];
            if (recentApplications) {
                newRecentApplications = recentApplications.filter(recent => !!recent && recent.id !== application.id);
                newRecentApplications.unshift(application);
            } else {
                newRecentApplications = [application];
            }
            return newRecentApplications.slice(0, 5);
        }),
        on(createApplicationSuccess, (recentApplications, { application }) => {
            const copy = [...recentApplications];
            copy.unshift(application);
            return copy.slice(0, 5);
        }),
        on(deleteApplicationSuccess, (_, { recentApplications }) => recentApplications)
    );

const billingReducer = (initialData: BillingPackage) =>
    createReducer(
        initialData,
        on(requestBillingPackageSuccess, (_, { billingPackage }) => billingPackage)
    );

export const contextReducer = combineReducers({
    user: userInfoReducer(undefined),
    rights: rightsReducer(undefined),
    companies: companiesReducer(undefined),
    selectedCompany: selectedCompanyReducer(undefined),
    applications: applicationsReducer(undefined),
    selectedApplication: selectedApplicationReducer(undefined),
    recentCompanies: recentCompaniesReducer(undefined),
    recentApplications: recentApplicationsReducer(undefined),
    billing: billingReducer(undefined),
});
