import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Argument, createArguments, Scope, TabDefinition, JsonArrayItem } from '@backoffice/data-access/editor';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'argument-array-parameters',
    templateUrl: './argument-array-parameters.component.html',
    standalone: false,
})
export class ArgumentArrayParametersComponent implements AfterViewInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.calculateComposedTitles();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
        this.loadSubArguments();
    }

    _argument!: Argument;

    @Input() contextId: string;
    @Input() language: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    arrayItem!: JsonArrayItem | undefined;
    openedSubArgument!: number;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    onArgumentUpdated($event: { argument: Argument }) {
        const changedSubArgument = this._argument.subArguments?.find(subArgument => subArgument.id === $event.argument.id);
        if (changedSubArgument) {
            changedSubArgument.value = $event.argument.value;
            changedSubArgument.subArguments = $event.argument.subArguments;
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(subArgument => {
            let parameter = this._argument.parameter?.parameters?.find(
                parameter => subArgument.parameterId === parameter.id || subArgument.selectorId === parameter.selectorId
            );
            if (parameter) {
                subArgument.parameter = parameter;
            }
        });
    }

    calculateComposedTitles() {
        if (this._argument && this._argument.subArguments && this._argument.subArguments.length > 0) {
            this._argument.subArguments.forEach(subArgument => {
                this.calculateComposedTitle(subArgument);
            });
        }
    }

    calculateComposedTitle(argument: Argument | undefined): void {
        if (argument && argument?.parameter?.composedTitleSubArgumentId) {
            const titleArgument = argument.subArguments?.find(
                subArgument =>
                    subArgument?.selectorId === argument.parameter?.composedTitleSubArgumentId ||
                    subArgument.parameterId === argument.parameter?.composedTitleSubArgumentId
            );
            if (titleArgument) {
                argument.composedTitle = this.removeQuotesIfPresent(titleArgument.value);
            }
        }
    }

    identifyArgument(index: number, item: Argument): string {
        return item.id;
    }

    onRemoveArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
        setTimeout(() => initFlowbite());
    }

    onAddArrayItem() {
        const subArgument = new Argument();
        if (this._argument?.parameter?.parameters && this._argument?.parameter?.parameters[0]) {
            subArgument.id = new GUIDFunctions().newGuid();
            subArgument.parameterId = this._argument?.parameter?.parameters[0].id;
            subArgument.parameter = this._argument?.parameter?.parameters[0];
            subArgument.selectorId = this._argument?.parameter?.parameters[0].selectorId;
            subArgument.type = this._argument?.parameter?.parameters[0].type;
            subArgument.name = this._argument?.parameter?.parameters[0].name;
            if (this._argument.parameter.parameters[0].parameters) {
                subArgument.subArguments = createArguments(this._argument.parameter.parameters[0].parameters);
            }
            if (!this._argument.subArguments) {
                this._argument.subArguments = [];
            }
            this._argument.subArguments.push(subArgument);
            this.argumentUpdated.emit({ argument: this._argument });
            setTimeout(() => initFlowbite());
        }
    }

    reorderSubArguments(event: CdkDragDrop<Argument[]>): void {
        if (this._argument.subArguments) {
            moveItemInArray(this._argument.subArguments, event.previousIndex, event.currentIndex);
            this.argumentUpdated.emit({ argument: this._argument });
        }
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }
}
