import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BackgroundChanged } from '../../../../../../../libs/backoffice/feature/editor/pickers/src/lib/background-picker/background-changed.event';
import { BorderStyleDto, ContainerStyleDto, FontStyleDto, PaddingStyleDto, Part, ShadowStyleDto } from '@backoffice/data-access/editor';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-container-part-style',
    templateUrl: './container-part-style.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ContainerPartStyleComponent implements AfterViewInit {
    @Input()
    part: Part;

    @Input()
    set containerStyle(containerStyle: ContainerStyleDto) {
        if (containerStyle) {
            this._containerStyle = containerStyle;
        } else {
            this._containerStyle = this.defaultContainerStyle();
        }
    }

    _containerStyle: ContainerStyleDto;

    @Output()
    containerStyleUpdated: EventEmitter<{ containerStyle: ContainerStyleDto }> = new EventEmitter<{
        containerStyle: ContainerStyleDto;
    }>();

    @Input()
    set hoverContainerStyle(containerStyle: ContainerStyleDto) {
        if (containerStyle) {
            this._hoverContainerStyle = containerStyle;
        } else {
            this._hoverContainerStyle = this.defaultContainerStyle();
        }
    }

    _hoverContainerStyle: ContainerStyleDto;

    @Output()
    hoverContainerStyleUpdated: EventEmitter<{ containerStyle: ContainerStyleDto }> = new EventEmitter<{
        containerStyle: ContainerStyleDto;
    }>();

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    defaultContainerStyle() {
        return {
            backgroundStyle: null,

            borderStyle: null,
            borderTopStyle: null,
            borderLeftStyle: null,
            borderRightStyle: null,
            borderBottomStyle: null,

            paddingStyle: null,
            paddingTopStyle: null,
            paddingLeftStyle: null,
            paddingRightStyle: null,
            paddingBottomStyle: null,

            shadowStyle: null,
            innerSize: {},

            cursorStyle: null,

            fontStyle: null,
        };
    }
}
