<div class="title-button-header">
    <h2>Options</h2>
    <div class="title-button-header-buttons">
        <button class="primary-button button-medium" type="button" (click)="onAddOption()">
            <mat-icon>add</mat-icon>
            <span>{{ 'v2.parameter.option.add' | translate }}</span>
        </button>
    </div>
</div>
<div
    [attr.id]="'accordion-parameter-' + this.parameter.id + '-options'"
    [attr.data-accordion]="'parameter-' + this.parameter.id + '-options'"
    cdkDropList>
    <div *ngFor="let option of this.options; let i = index; trackBy: identifyOption" cdkDrag>
        <h3
            [attr.id]="'accordion-parameter-' + option.id + '-options-heading'"
            [attr.data-name]="'parameter-options-header-' + option.id"
            class="p-0">
            <button
                type="button"
                class="accordion-heading"
                [class.accordion-heading-first]="i === 0"
                [class.accordion-heading-last]="i === this.options.length - 1"
                [attr.data-accordion-target]="'#accordion-template-parameter-' + option.id + '-options-body'"
                [attr.aria-controls]="'accordion-template-parameter-' + option.id + '-options-body'"
                aria-expanded="true">
                <span class="accordion-heading-icon">{{ option.name ? option.name : 'New Option' }}</span>
                <button
                    cdkDragHandle
                    class="accordion-heading-button"
                    (click)="$event.stopPropagation()"
                    [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                    <mat-icon>import_export</mat-icon>
                </button>
                <button
                    color="warn"
                    class="accordion-heading-button destructive-accordion-heading-button"
                    (click)="onRemoveOption(option); $event.stopPropagation()"
                    [matTooltip]="'v2.parameter.remove' | translate">
                    <mat-icon>clear</mat-icon>
                </button>
                <svg
                    data-accordion-icon
                    class="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h3>
        <div
            [attr.id]="'accordion-template-parameter-' + option.id + '-options-body'"
            [attr.data-name]="'content-' + option.id"
            [attr.aria-labelledby]="'accordion-template-parameter-' + option.id + '-options-body'"
            [class.accordion-panel-last]="i === this.options.length - 1"
            [class.accordion-panel]="true"
            class="hidden">
            <label [attr.for]="'option-' + option.id + '-id'" class="small-input-label">{{ 'v2.parameter.options.id' | translate }}</label>
            <input
                class="small-input"
                type="text"
                [attr.id]="'option-' + option.id + '-id'"
                [attr.name]="'option-' + option.id + '-id'"
                class="small-input"
                autocomplete="off"
                cd-code
                [(ngModel)]="option.id"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="onChangeOption()" />

            <label [attr.for]="'option-' + option.id + '-name'" class="small-input-label">{{
                'v2.parameter.options.name' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                [attr.id]="'option-' + option.id + '-name'"
                [attr.name]="'option-' + option.id + '-name'"
                class="small-input"
                autocomplete="off"
                [(ngModel)]="option.name"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="onChangeOption()" />

            <label [attr.for]="'option-' + option.id + '-description'" class="small-input-label">{{
                'v2.parameter.options.description' | translate
            }}</label>
            <textarea
                class="small-input"
                type="text"
                [attr.id]="'option-' + option.id + '-description'"
                [attr.name]="'option-' + option.id + '-description'"
                class="small-input"
                autocomplete="off"
                placeholder="Describe your option here..."
                [(ngModel)]="option.description"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="onChangeOption()"></textarea>
        </div>
    </div>
</div>
