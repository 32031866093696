import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { Group } from '@usermanagement/models/group.model';
import { Role } from '@usermanagement/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { MatCheckboxChange as MatCheckboxChange } from '@angular/material/checkbox';
import { SharedFacade } from '../../facade/shared.facade';
import { AuthorizationLinkDto } from '@backoffice/data-access/editor';
import { RightDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/right/dto/right.dto';
import { GroupDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';

@Component({
    selector: 'codex-authorization-link',
    templateUrl: './authorization-link.component.html',
    styleUrls: ['./authorization-link.component.scss'],
    standalone: false,
})
export class AuthorizationLinkComponent implements OnInit {
    form: FormGroup;

    private groups$: Observable<GroupDto[]>;
    private roles$: Observable<RightDto[]>;

    groupsAndRoles$: Observable<{
        groups: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[];
        roles: { id: string; name: string; checked: boolean; companyId: string }[];
    }>;

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly sharedFacade: SharedFacade,
        public dialogRef: MatDialogRef<AuthorizationLinkComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { applicationId: string; authorizationLink: AuthorizationLinkDto }
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            authenticationLinkName: [this.data.authorizationLink.name, Validators.required],
        });

        this.form.get('authenticationLinkName').valueChanges.subscribe(value => this.onAuthenticationLinkNameChanges(value));

        this.groups$ = this.sharedFacade.getAllGroups();
        this.roles$ = this.sharedFacade.getAllRoles();

        this.groupsAndRoles$ = combineLatest([this.groups$, this.roles$]).pipe(
            map(([groups, roles]) => {
                return {
                    groups: groups.map(group => ({
                        id: group.id,
                        name: group.name,
                        companyId: group.companyId,
                        applicationId: group.applicationId,
                        checked: this.data.authorizationLink.groups.some(selectedGroupId => selectedGroupId === group.id),
                    })),
                    roles: roles.map(role => ({
                        id: role.id,
                        name: role.name,
                        companyId: role.companyId,
                        checked: this.data.authorizationLink.roles.some(selectedRoleId => selectedRoleId === role.id),
                    })),
                };
            })
        );
    }

    onAuthenticationLinkNameChanges(name: string) {
        this.data.authorizationLink.name = name;
    }

    onGroupSelected(group: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }) {
        if (!group.checked) {
            group.checked = true;
            this.data.authorizationLink.groups.push(group.id);
        } else {
            group.checked = false;
            if (this.data.authorizationLink.groups.indexOf(group.id) > -1) {
                this.data.authorizationLink.groups.splice(this.data.authorizationLink.groups.indexOf(group.id));
            }
        }
    }

    onRoleSelected(right: { id: string; name: string; checked: boolean; companyId: string }) {
        if (!right.checked) {
            right.checked = true;
            this.data.authorizationLink.roles.push(right.id);
        } else {
            right.checked = false;
            if (this.data.authorizationLink.roles.indexOf(right.id) > -1) {
                this.data.authorizationLink.roles.splice(this.data.authorizationLink.roles.indexOf(right.id));
            }
        }
    }
}
