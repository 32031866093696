<mat-dialog-content>
    <div class="tabs-header-wrapper">
        <ul
            class="tabs-header"
            id="default-styled-tab"
            data-tabs-toggle="#action-information-tabs"
            data-tabs-inactive-classes="tabs-tab-inactive"
            data-tabs-active-classes="tabs-tab-active"
            role="tablist">
            <li class="me-2" role="presentation">
                <button
                    class="tabs-tab-button"
                    id="profile-styled-tab"
                    data-tabs-target="#action-properties"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false">
                    <mat-icon>tune</mat-icon>{{ 'v2.template.toolpane.template.properties' | translate }}
                </button>
            </li>
            <li role="presentation">
                <button
                    class="tabs-tab-button"
                    id="contacts-styled-tab"
                    data-tabs-target="#action-parameters"
                    type="button"
                    role="tab"
                    aria-controls="contacts"
                    aria-selected="false">
                    <mat-icon>settings_input_component</mat-icon>{{ 'v2.action.toolpane.action.parameters' | translate }}
                </button>
            </li>
            <li class="me-2" role="presentation">
                <button
                    class="tabs-tab-button"
                    id="settings-styled-tab"
                    data-tabs-target="#action-outputs"
                    type="button"
                    role="tab"
                    aria-controls="settings"
                    aria-selected="false">
                    <mat-icon>bolt</mat-icon>{{ 'v2.action.toolpane.action.outputs' | translate }}
                </button>
            </li>
        </ul>
    </div>
    <div id="action-information-tabs">
        <div class="hidden p-4" id="action-properties" role="tabpanel" aria-labelledby="profile-tab">
            <codex-action-edit-properties [action]="action" (actionUpdated)="actionUpdated.emit($event)"></codex-action-edit-properties>
        </div>
        <div class="hidden p-4" id="action-parameters" role="tabpanel" aria-labelledby="dashboard-tab">
            <app-parameters
                class="ml-2 mr-2 block"
                *ngIf="actionContext && action.program.parameters"
                [root]="action.program.parameters"
                [parameters]="action.program.parameters"
                [context]="actionContext"
                [language]="'en'"
                [showMissingParameters]="true"
                [parentId]="action.id"
                (parametersUpdated)="onUpdatedParameters($event)"
                (openTab)="onOpenTab($event)"></app-parameters>
        </div>
        <div class="hidden p-4" id="action-outputs" role="tabpanel" aria-labelledby="contacts-tab">
            <codex-action-edit-outputs
                [action]="action"
                [language]="'nl'"
                (actionUpdated)="actionUpdated.emit($event)"
                (openTab)="onOpenTab($event)"></codex-action-edit-outputs>
        </div>
    </div>
</mat-dialog-content>
