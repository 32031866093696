import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument, Parameter, Scope, TabDefinition } from '@backoffice/data-access/editor';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-object-mapping',
    templateUrl: './argument-object-mapping.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentObjectMappingComponent implements OnInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
        this.loadSubArguments();
    }

    _argument!: Argument;

    @Input()
    language!: string;

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output()
    openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    showSubArguments: boolean = false;

    openedSubArgument!: string;

    constructor() {}

    ngOnInit(): void {}

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(subArgument => {
            subArgument.parameter = new Parameter();
            subArgument.parameter.type = 'VARIABLE';
            subArgument.parameter.inputType = 'mapping';
        });
        this.showSubArguments = true;
    }

    onActionExecutionArgumentUpdated($event) {
        const argument: Argument = this._argument?.subArguments?.find(
            subArgument => subArgument.parameterId === $event.argument.parameterId
        );
        if (argument) {
            argument.value = $event.argument.value;
            argument.id = $event.argument.id;
            argument.selectorId = $event.argument.selectorId;
            argument.name = $event.argument.id;
            argument.inputSelectionType = $event.argument.inputSelectionType;
            argument.type = 'VARIABLE';
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument?.subArguments?.splice(this._argument?.subArguments?.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onAddField() {
        const subArgument = new Argument();
        subArgument.id = new GUIDFunctions().newGuid();
        subArgument.selectorId = new GUIDFunctions().newGuid();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        subArgument.parameter.type = 'VARIABLE';
        subArgument.parameter.inputType = 'mapping';
        subArgument.inputSelectionType = 'part';
        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    identifyArgument(index: number, item: Argument): string {
        //changed this to parameterId from just the index because deleting of records became extremely slow when we have 10+ records in our object.
        return item.parameterId;
    }

    reorderSubArguments(event: CdkDragDrop<Argument[]>): void {
        if (this._argument.subArguments) {
            moveItemInArray(this._argument.subArguments, event.previousIndex, event.currentIndex);
            this.argumentUpdated.emit({ argument: this._argument });
        }
    }
}
