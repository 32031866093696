import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ActionEditFacade, isOverviewTagDto, OverviewTagDto, Scope } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';
import { Argument } from '@backoffice/data-access/editor';
import { LoggerService } from '@backoffice/utils';

@Component({
    selector: 'argument-tag-picker',
    templateUrl: './argument-tag-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentTagPickerComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    tags!: Observable<OverviewTagDto[]>;

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private actionEditFacade: ActionEditFacade,
        private readonly logger: LoggerService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initActionAutoComplete();
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    initActionAutoComplete() {
        this.tags = this.formGroup.get('tag')?.valueChanges.pipe(
            startWith(''),
            switchMap(tag => {
                if (!isOverviewTagDto(tag)) {
                    return this.actionEditFacade
                        .getTags(tag, [], 'score desc', 0, 10)
                        .pipe(map(overviewTagPage => overviewTagPage.content));
                } else {
                    return of([]);
                }
            })
        );
    }

    updateForm(): void {
        this.formGroup?.get('tag')?.setValue(this._argument.value);
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            tag: [null, this._argument.parameter?.required ? Validators.required : null],
        });

        if (this._argument.value) {
            this.logger.info(this._argument);
            this.formGroup.get('tag')?.setValue(this._argument.value);
            this.subscriptions.add(
                this.formGroup
                    .get('tag')
                    ?.valueChanges.pipe(debounceTime(1000))
                    .subscribe(value => this.onChange(value))
            );
        } else {
            this.subscriptions.add(
                this.formGroup
                    .get('tag')
                    ?.valueChanges.pipe(debounceTime(1000))
                    .subscribe(value => this.onChange(value))
            );
        }
    }

    displayTag = (tagArgumentValue: string | OverviewTagDto) => {
        if (isOverviewTagDto(tagArgumentValue)) {
            return tagArgumentValue.tag;
        } else {
            return tagArgumentValue;
        }
    };

    onChange(value: any) {
        if (value.tag !== this._argument.value) {
            this._argument.value = value.tag;
            this.argumentUpdated.emit({ argument: this._argument });
        }
    }
}
