import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActionEditFacade, Argument, Part } from '@backoffice/data-access/editor';
import { Scope, ScopeItem } from '@backoffice/data-access/editor';
import { matchSorter } from 'match-sorter';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'argument-part-picker',
    templateUrl: './argument-part-picker.component.html',
    standalone: false,
})
export class ArgumentPartPickerComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() contextId: string;
    @Input() root: Argument[];

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Input()
    set scope(scope: Scope) {
        this._scope = scope;
        this.initTemplates();
    }

    _scope!: Scope;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    parts$: BehaviorSubject<Part[]> = new BehaviorSubject<Part[]>([]);

    filteredParts$: BehaviorSubject<Part[]> = new BehaviorSubject<Part[]>([]);

    partsBySelectorId$: BehaviorSubject<Map<string, Part>> = new BehaviorSubject<Map<string, Part>>(new Map());

    templates$: BehaviorSubject<ScopeItem[]> = new BehaviorSubject<ScopeItem[]>([]);

    chosenTemplate!: string;

    constructor(
        private actionEditFacade: ActionEditFacade,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initTemplates();
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            template: [this._argument.templateKey, this._argument?.parameter?.required ? Validators.required : null],
            part: [this._argument.value, this._argument.parameter?.required ? Validators.required : null],
            partFilter: [''],
            templateFilter: [''],
        });

        this.subscriptions.add(
            this.formGroup.get('partFilter')?.valueChanges.subscribe(value => {
                this.filterParts(value);
            })
        );

        this.subscriptions.add(
            this.formGroup
                .get('template')
                ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onChangeTemplate(value))
        );

        this.subscriptions.add(
            this.formGroup
                .get('part')
                ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onChangePart(value))
        );

        if (this._argument.templateKey && this._scope) {
            const templateScopeItem: ScopeItem = this._scope.scopeItemsByInvocationAndByType
                ?.get(this.contextId)
                ?.get('TEMPLATE')
                ?.get(this._argument.templateKey);
            this.initParts(templateScopeItem.key, templateScopeItem.id);
        }
    }

    initTemplates() {
        if (this.formGroup && this.formGroup.get('template') && this._scope) {
            const scopeMap = this._scope.scopeItemsByInvocationAndByType.get(this.contextId)?.get('TEMPLATE');
            if (scopeMap) {
                if (scopeMap?.size === 1) {
                    const templateScopeItem: ScopeItem = scopeMap?.entries().next().value[1];
                    if (this.formGroup?.get('template')) {
                        // Do not emit an event, because we want to initialize parts immediatly in this case.
                        // So we call onChangeTemplate manually in order to avoid the input debounce time.
                        this.formGroup.get('template')?.setValue(templateScopeItem.key, { emitEvent: false });
                        this.onChangeTemplate(templateScopeItem.key);
                    }
                }
                this.templates$.next(Array.from(scopeMap.values()));
            }
        }
    }

    filterParts(filterValue: string) {
        this.filteredParts$.next(matchSorter(this.parts$.value, filterValue.toLowerCase(), { keys: ['detail.code'] }));
    }

    initParts(templateKey: string, templateId: string) {
        this.subscriptions.add(
            this.actionEditFacade.getParts(templateId).subscribe(parts => {
                if (!!this._argument?.parameter?.subType) {
                    this.parts$.next(parts.filter(part => part.detail.partType === this._argument?.parameter?.subType));
                } else {
                    this.parts$.next(parts);
                }
                this.filteredParts$.next(this.parts$.value);
                this.partsBySelectorId$.next(new Map(parts.map(part => [part.getSelector(templateKey), part])));
                this.chosenTemplate = templateId;
            })
        );
    }

    displayPart = (value: string) => {
        if (value) {
            return this.partsBySelectorId$.value.get(value)?.detail['code'];
        }
    };

    clearPart() {
        this.formGroup.get('part')?.reset();
    }

    displayTemplateFromScope = (scopeItemArgumentValue: string) => {
        if (scopeItemArgumentValue) {
            const scopeItem = this._scope.scopeItemsByInvocationAndByType
                ?.get(this.contextId)
                ?.get('TEMPLATE')
                ?.get(scopeItemArgumentValue);
            if (!!scopeItem) {
                return scopeItem.key + ': ' + scopeItem.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    onChangePart(value: string) {
        this._argument.value = value;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onChangeTemplate(value: string) {
        if (this._argument.templateKey !== value) {
            this._argument.templateKey = value;
            this._argument.value = null;
            this.formGroup.get('part')?.setValue(null);
            this.argumentUpdated.emit({ argument: this._argument });
        }

        const templateScopeItem: ScopeItem = this._scope.scopeItemsByInvocationAndByType
            ?.get(this.contextId)
            ?.get('TEMPLATE')
            ?.get(this._argument.templateKey);
        if (this.chosenTemplate !== templateScopeItem.id) {
            this.initParts(templateScopeItem.key, templateScopeItem.id);
        }
    }
}
