import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { OverviewActionDto } from '@backoffice/data-access/editor';
import { FormGroup } from '@angular/forms';
import { Scope } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'argument-select-multi-input',
    templateUrl: './argument-select-multi-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentSelectMultiInputComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    actions!: Observable<OverviewActionDto[]>;

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    updateForm() {}
}
