import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Parameter } from '@backoffice/data-access/editor';
import { initFlowbite } from 'flowbite';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'app-select-parameters',
    templateUrl: './select-parameters.component.html',
    standalone: false,
})
export class SelectParametersComponent implements OnInit, AfterViewInit {
    @Input()
    set parameters(parameters: Parameter[]) {
        this._parameters = parameters;
        this.calculateSelectedParameters();
    }

    @Input()
    title: string;

    _parameters!: Parameter[];

    @Input() root: Parameter[];

    @Input()
    selectedParameterIds!: string[];

    selectedParameters: { id: string; parameterId?: string; name?: string }[] = [];

    @Output()
    change: EventEmitter<{ selectedParameters: { id: string; parameterId?: string; name?: string }[] }> = new EventEmitter();

    openedSelectedParameterIndex!: number;

    ngOnInit() {
        this.calculateSelectedParameters();
    }

    ngAfterViewInit() {
        initFlowbite();
    }

    addParameterToSelect() {
        this.selectedParameters.push({
            id: new GUIDFunctions().newGuid(),
        });

        setTimeout(() => initFlowbite());
    }

    getParameterIdentifier(index: number, item: { id: string; parameterId?: string; name?: string }) {
        return item.id;
    }

    onRemoveSelectedParameter(index: number): void {
        this.selectedParameters.splice(index, 1);
        this.change.emit({ selectedParameters: this.selectedParameters });
        setTimeout(() => initFlowbite());
    }

    calculateSelectedParameters() {
        this.selectedParameters = [];
        if (this.selectedParameterIds) {
            this.selectedParameterIds.forEach(selectedParameterId => {
                const parameter = this._parameters.find(parameter => parameter.selectorId === selectedParameterId);
                if (parameter) {
                    this.selectedParameters.push({
                        id: new GUIDFunctions().newGuid(),
                        parameterId: selectedParameterId,
                        name: parameter.name,
                    });
                }
            });
        }
    }

    changeSelectedParameter(index: number, $event: { selectedParameterId: string }) {
        const selectedParameter = this._parameters.find(parameter => parameter.selectorId === $event.selectedParameterId);
        if (selectedParameter) {
            this.selectedParameters[index].parameterId = $event.selectedParameterId;
            this.selectedParameters[index].name = selectedParameter.name;
            this.change.emit({ selectedParameters: this.selectedParameters });
        }
    }
}
