import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '@backoffice/data-access/editor';
import { FormBuilder, Validators } from '@angular/forms';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'codex-media-edit-form',
    templateUrl: './media-edit-form.component.html',
    styleUrls: ['./media-edit-form.component.scss'],
    standalone: false,
})
export class MediaEditFormComponent implements OnInit {
    @Input() media: Media;

    @Output() update: EventEmitter<{ media: Media }> = new EventEmitter<{ media: Media }>();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    formGroup = this.fb.group({
        title: this.fb.nonNullable.control('', [Validators.required]),
        alt: [''],
        installFromHub: this.fb.nonNullable.control(false),
    });

    constructor(
        private readonly fb: FormBuilder,
        private readonly http: HttpClient
    ) {}

    ngOnInit(): void {
        this.media.defineExtension();
        this.formGroup.patchValue({
            title: this.media.title,
            alt: this.media.alt,
            installFromHub: this.media.installFromHub,
        });
    }

    onUpdate(): void {
        const value = this.formGroup.getRawValue();
        const { title, alt, installFromHub } = value;

        this.media.title = title;
        this.media.alt = alt;
        this.media.installFromHub = installFromHub;

        this.update.emit({ media: this.media });
    }

    onDownload(): void {
        this.http
            .get(
                `${this.environment.rest.v2.media}file/company/${this.media.companyId}/application/${this.media.applicationId}/${this.media.id}`,
                { responseType: 'blob' }
            )
            .subscribe(response => {
                FileSaver.saveAs(response, this.media.fileName);
            });
    }
}
