import { Parameter } from '../../parameters/parameter';

export class ActionCtx {
    public readonly id: string;
    public readonly type: string;
    public readonly name: string;
    public readonly description: string;
    public readonly scopeName: string;
    public readonly parameters: Parameter[];
    public readonly outbound: boolean;
}
