import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
    Argument,
    DataFormat,
    DataFormatEditorFacade,
    JsonProperty,
    Parameter,
    Scope,
    TabDefinition,
} from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'argument-object-dataformat',
    templateUrl: './argument-object-dataformat.component.html',
    standalone: false,
})
export class ArgumentObjectDataformatComponent implements OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadDataFormat();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language: string;
    @Input() contextId: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    dataFormatAttributes!: Array<{ id: string; name: string; type: string[]; description: string }>;

    openedSubArgument!: number;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onAddDataFormatAttribute() {
        const subArgument = new Argument();
        subArgument.selectorId = new GUIDFunctions().newGuid();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
        this.openedSubArgument = -1;
        this.changeDetectorRef.detectChanges();
        this.openedSubArgument = this._argument.subArguments.length - 1;
        setTimeout(() => initFlowbite());
    }

    loadDataFormat() {
        if (this._argument) {
            if (this._argument.parameter?.subTypeParameterId || this._argument.parameter?.linkedDataFormatParameterId) {
                let dataFormatId = this._argument.parameter.subTypeParameterId;
                let dataFormatArgument = this._arguments?.find(
                    argument =>
                        argument.parameterId === this._argument.parameter?.linkedDataFormatParameterId ||
                        argument.selectorId === this._argument.parameter?.linkedDataFormatParameterId
                );
                if (dataFormatArgument && dataFormatArgument.value) {
                    dataFormatId = this.removeQuotesIfPresent(dataFormatArgument.value);
                }
                // it is wrong to use subTypeParameterId to store the dataformat, we will have to change this in the future....
                if (dataFormatId && this.dataFormatId !== dataFormatId) {
                    this.dataFormatId = dataFormatId;
                    this.dataFormatEditorFacade.findById(this.dataFormatId).subscribe(dataFormat => {
                        this.dataFormat = dataFormat;
                        const dataSchema = dataFormat.jsonSchema;
                        this.dataFormatAttributes = [];
                        if (!!dataSchema.properties) {
                            let properties = this.getPropertyList(dataSchema.properties);
                            properties.forEach(dataProperty => {
                                this.dataFormatAttributes.push({
                                    id: dataProperty.id,
                                    name: dataProperty.name,
                                    type: dataProperty.json.type,
                                    description: dataProperty.json.description,
                                });
                            });
                        }
                        this.mergeArguments();
                        this.showSubArguments = true;
                        this.changeDetectorRef.detectChanges();
                    });
                }
            }
        }
    }

    getPropertyList(properties: { [key: string]: JsonProperty }): { id: string; name: string; json: JsonProperty }[] {
        let propertyList: { id: string; name: string; json: JsonProperty }[] = [];
        if (this._argument.parameter?.subTypePath) {
            const pathParts = this._argument.parameter?.subTypePath.split('.');
            let currentPropertyLevel: { [key: string]: JsonProperty } | undefined = properties;
            for (const pathPart of pathParts) {
                if (currentPropertyLevel && currentPropertyLevel[pathPart]) {
                    if (currentPropertyLevel[pathPart]?.properties && currentPropertyLevel[pathPart].type.indexOf('object') > -1) {
                        currentPropertyLevel = currentPropertyLevel[pathPart].properties;
                    } else if (currentPropertyLevel[pathPart]?.items && currentPropertyLevel[pathPart].type.indexOf('array') > -1) {
                        currentPropertyLevel = currentPropertyLevel[pathPart].items?.properties;
                    }
                }
            }
            if (currentPropertyLevel) {
                Object.keys(currentPropertyLevel).forEach(propertyName => {
                    if (currentPropertyLevel) {
                        let id = this.sanitizePropertyName(this._argument.parameter?.subTypePath + propertyName);
                        propertyList.push({
                            id,
                            name: propertyName,
                            json: currentPropertyLevel[propertyName],
                        });
                    }
                });
            }
        } else {
            Object.keys(properties).forEach(propertyName => {
                let id = this.sanitizePropertyName(propertyName);
                propertyList.push({
                    id,
                    name: propertyName,
                    json: properties[propertyName],
                });
            });
        }
        return propertyList;
    }

    sanitizePropertyName(propertyName: string) {
        return propertyName.replace(/[^a-zA-Z0-9]/g, (match): string => match.charCodeAt(0).toString());
    }

    mergeArguments() {
        this._argument.subArguments?.forEach(subArgument => {
            let dataFormatAttributeForArgument = this.dataFormatAttributes.find(
                dataFormatAttribute => dataFormatAttribute.name === subArgument.parameterId
            );
            if (dataFormatAttributeForArgument) {
                subArgument.parameter = new Parameter();
                subArgument.parameter.type = this.mapToParamTypes(dataFormatAttributeForArgument.type);
                subArgument.parameter.name = dataFormatAttributeForArgument.name;
                subArgument.parameter.description = dataFormatAttributeForArgument.description;
                if (subArgument.type === 'OBJECT' || subArgument.type === 'ARRAY') {
                    subArgument.parameter.inputType = 'dataformat';
                    subArgument.parameter.subTypeParameterId = this.dataFormatId;
                    subArgument.parameter.subTypePath = [this._argument?.parameter?.subTypePath, dataFormatAttributeForArgument.name].join(
                        '.'
                    );
                }
            } else {
                // Remove this argument.
            }
        });
    }

    onDataFormatAttributeSelected($event, argument: Argument) {
        const foundDataFormatArgument = this.dataFormatAttributes.find(dataFormatAttribute => dataFormatAttribute.id === $event);
        argument.value = undefined;
        if (foundDataFormatArgument) {
            argument.id = foundDataFormatArgument.id;
            argument.type = this.mapToParamTypes(foundDataFormatArgument.type);
            argument.parameterId = foundDataFormatArgument.name;
            argument.selectorId = foundDataFormatArgument.name;

            if (argument.parameter) {
                argument.parameter.type = this.mapToParamTypes(foundDataFormatArgument.type);
                argument.parameter.name = foundDataFormatArgument.name;
                argument.parameter.description = foundDataFormatArgument.description;
                if (argument.type === 'OBJECT') {
                    argument.parameter.inputType = 'dataformat';
                    argument.parameter.subTypeParameterId = this._argument?.parameter?.subTypeParameterId;
                    argument.parameter.subTypePath = [this._argument?.parameter?.subTypePath, foundDataFormatArgument.name].join('.');
                } else if (argument.type === 'ARRAY') {
                    argument.parameter.inputType = 'dataformat';
                    argument.parameter.subTypeParameterId = this._argument?.parameter?.subTypeParameterId;
                    argument.parameter.subTypePath = [
                        this._argument?.parameter?.subTypePath,
                        foundDataFormatArgument.name,
                        'arrayitem',
                    ].join('.');
                }
            }
        }
        this._argument.subArguments?.splice(this._argument.subArguments?.indexOf(argument), 1);
        this._argument.subArguments?.push(Object.assign({}, argument));
        this.argumentUpdated.emit({ argument: this._argument });
    }

    mapToParamTypes(type: string[] | undefined) {
        if (type) {
            if (type.indexOf('string') > -1) {
                return 'STRING';
            } else if (type.indexOf('array') > -1) {
                return 'ARRAY';
            } else if (type.indexOf('date') > -1) {
                return 'DATE';
            } else if (type.indexOf('number') > -1) {
                return 'NUMBER';
            } else if (type.indexOf('integer') > -1) {
                return 'NUMBER';
            } else if (type.indexOf('object') > -1) {
                return 'OBJECT';
            } else if (type.indexOf('boolean') > -1) {
                return 'BOOLEAN';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    identifyArgument(index: number, item: Argument): number | undefined {
        return index;
    }

    identifyAttribute(index, item: { name: string; type: string[]; description: string | undefined }) {
        return item.name;
    }
}
