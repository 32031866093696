import { GenerativeTaskContextDto } from './generative-task-context.dto';

export interface CreateGenerativeTaskDto {
    prompt: string;
    applicationId: string;
    companyId: string;
    type: string;
    name?: string;
    context: GenerativeTaskContextDto;
}
