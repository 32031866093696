import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Page } from '../../../../../../libs/shared/data-access/src/lib/model/page.model';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';
import { BillingService } from '@core/services/billing.service';
import { BillingEntry } from '@billing/dto/billingentry';
import { filter, switchMap, take } from 'rxjs/operators';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { selectCurrentContext, selectPackage } from '../../../app/store/data/authenticated.selector';
import { ApplicationService } from '@core/services/application.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/store/application.state';
import { BillingPackage } from '@billing/dto/billingpackage';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationOrderAiCreditsComponent } from '@billing/components/application-order-ai-credits/application-order-ai-credits.component';

@Injectable({
    providedIn: 'root',
})
export class BillingFacade {
    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    get package(): Observable<BillingPackage> {
        return this.store.select(selectPackage);
    }

    constructor(
        private billingService: BillingService,
        private applicationService: ApplicationService,
        private store: Store<ApplicationState>,
        private dialog: MatDialog
    ) {}

    public updateAiCredits(returnUrl: string) {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.dialog
                    .open(ApplicationOrderAiCreditsComponent)
                    .afterClosed()
                    .pipe(
                        switchMap(result => {
                            if (result.success) {
                                return this.billingService.initiateTopUpAiCredits(
                                    currentContext.selectedCompany,
                                    returnUrl,
                                    result.amount,
                                    result.couponCode
                                );
                            } else {
                                return of(null);
                            }
                        })
                    );
            })
        );
    }

    public updateBillingDetails(returnUrl: string) {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.billingService.initiateUpdateBillingDetails(currentContext.selectedCompany, returnUrl);
            })
        );
    }

    public getBillingEntriesByApplication(applicationId: string, billingView: string): Observable<BillingEntry[]> {
        return this.billingService.getApplicationBilling(applicationId, billingView);
    }

    public getApplications(): Observable<Page<ApplicationDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.applicationService.getApplications(currentContext.selectedCompany.id, '*', 'name desc', [], 0, 1000);
            })
        );
    }

    public getBillingPackage(): Observable<BillingPackage> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.billingService.getBillingPackage(currentContext.selectedCompany.id);
            })
        );
    }
}
