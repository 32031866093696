import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { initFlowbite } from 'flowbite';
import { Action, ActionExecutionCreatedDto } from '@backoffice/editor/data-access/action';
import { ActionCtx } from '../../../../../../../data-access/editor/src/lib/action/model/action-ctx';
import { ActionEditorFacade, ActionTestDto, Argument, EditorFacade, Parameter, Scope } from '@backoffice/data-access/editor';
import { ApplicationLogsFacade } from '../../../../../../../feature/application-logs/src/lib/facade/application-logs.facade';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationLogDto } from '@backoffice/data-access/application-logs';
import { map, switchMap, take } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { plainToInstance } from 'class-transformer';
import { ActionTestOutputAssertion } from '../../../../../../../data-access/editor/src/lib/action/dto/action-test-output-assertion.dto';
import { ActionExecutionOutput } from '../../../../../../../editor/data-access/action/src/lib/interface/action-execution-output.dto';
import { GUIDFunctions } from '@shared/utils';
import { ApplicationSelectorAllComponent } from '../../../../../../../../../apps/no-code-x-backoffice/src/features/workspace-selection/components/application-selector-all/application-selector-all.component';
import { ActionCreateAssertionComponent } from '../action-create-assertion/action-create-assertion.component';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-test',
    templateUrl: './action-test.component.html',
    standalone: false,
})
export class ActionTestComponent implements AfterViewInit {
    action: Action;

    mockArgument: Argument;

    mockArguments: Argument[] = [];

    mockRootArguments: Argument[] = [];

    outputAssertions: ActionTestOutputAssertion[] | undefined;

    testName: string;
    mockScope: Scope = new Scope();

    contextId: string;

    language: string;

    testNameShowRequiredMessage: boolean;

    logs: BehaviorSubject<ApplicationLogDto[]> = new BehaviorSubject<[]>([]);

    latestResult: ActionExecutionCreatedDto | null;

    latestResults: Map<string, ActionExecutionCreatedDto> = new Map();

    actionTests$: Observable<ActionTestDto[]>;

    forceRefreshActionTests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    selectedActionTest: string | null;

    currentlyFetchedActionTests: ActionTestDto[] = [];

    constructor(
        private dialogRef: MatDialogRef<ActionTestComponent>,
        private readonly guidFunctions: GUIDFunctions,
        public changeDetectorRef: ChangeDetectorRef,
        public editorFacade: EditorFacade,
        public actionFacade: ActionEditorFacade,
        public applicationLogsFacade: ApplicationLogsFacade,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly dialog: MatDialog,
        private confirmDialog: ConfirmDialog
    ) {
        this.action = this.data.action;
        this.mockArgument = new Argument();
        this.mockArgument.subArgumentsForAction = this.action.id;
        this.mockArgument.parameter = new Parameter();
        this.mockArgument.parameter.linkedActionParameterId = 'TEST_LINK';
        let actionArgument = new Argument();
        actionArgument.selectorId = 'TEST_LINK';
        actionArgument.value = "'" + this.action.id + "'";
        actionArgument.inputSelectionType = 'action';
        this.mockArguments.push(actionArgument);

        dialogRef.backdropClick().subscribe(() => {
            // Close the dialog
            dialogRef.close({ action: this.action });
        });

        this.actionTests$ = this.forceRefreshActionTests.pipe(
            switchMap(() => this.actionFacade.getActionTests(this.action.id)),
            map(actionTests => {
                this.currentlyFetchedActionTests = actionTests;
                setTimeout(() => initFlowbite());
                return actionTests;
            })
        );
    }

    fetchLogs(from: Date, to: Date): void {
        this.applicationLogsFacade.getActionLogs(null, this.action.id, from, to, null).subscribe(actionLogs => {
            this.logs.next(actionLogs);
        });
    }

    ngAfterViewInit() {
        initFlowbite();
    }

    onArgumentUpdated(actionTest: ActionTestDto) {
        actionTest.arguments = this.mockArgument.subArguments;
    }

    executeAllTests() {
        this.actionTests$.pipe(take(1)).subscribe(actionTests => {
            actionTests.forEach(actionTest => {
                this.onExecuteTest(actionTest);
            });
        });
    }
    createNewTest() {
        this.mockArgument = new Argument();
        this.mockArgument.subArgumentsForAction = this.action.id;
        this.mockArgument.parameter = new Parameter();
        this.mockArgument.parameter.linkedActionParameterId = 'TEST_LINK';
        this.mockArgument.subArguments = [];
        let actionArgument = new Argument();
        actionArgument.selectorId = 'TEST_LINK';
        actionArgument.value = "'" + this.action.id + "'";
        actionArgument.inputSelectionType = 'action';
        this.mockArguments.push(actionArgument);
        this.testName = 'Unnamed test';
        this.selectedActionTest = null;
        this.onSaveTest(null);
    }

    onSaveTest(actionTest: ActionTestDto) {
        this.testNameShowRequiredMessage = false;
        if (this.testName && this.testName !== '') {
            this.actionFacade
                .saveActionTest(this.action.id, {
                    id: this.selectedActionTest,
                    name: this.testName,
                    arguments: this.mockArgument.subArguments,
                    outputAssertions: this.outputAssertions,
                    actionId: this.action.id,
                    assertOnPublish: actionTest?.assertOnPublish,
                })
                .subscribe(() => this.forceRefreshActionTests.next(this.forceRefreshActionTests.value + 1));
            this.forceRefreshActionTests.next(this.forceRefreshActionTests.value + 1);
        } else {
            this.testNameShowRequiredMessage = true;
        }
    }

    onDeleteActionTest(actionTestId: string) {
        this.confirmDialog.showConfirmDialog(
            'v2.action.test.delete.title',
            'v2.action.test.delete.description',
            'v2.action.test.delete.ok',
            'v2.action.test.delete.cancel',
            'v2.action.test.delete.success.title',
            'v2.action.test.delete.success.description',
            () => {
                this.actionFacade
                    .deleteActionTest(this.action.id, actionTestId)
                    .subscribe(() => this.forceRefreshActionTests.next(this.forceRefreshActionTests.value + 1));
            }
        );
    }

    onSelectActionTest() {
        let actionTest = this.currentlyFetchedActionTests.find(actionTest => this.selectedActionTest === actionTest.id);
        if (actionTest) {
            this.testName = actionTest.name;
            this.mockArgument.subArguments = plainToInstance(Argument, actionTest.arguments);
            let mockargs = this.mockArguments;
            this.changeDetectorRef.detectChanges();
            this.mockArguments = [...mockargs];
            this.outputAssertions = actionTest.outputAssertions;
        }
    }

    prepareForExecution(argument: Argument) {
        argument.name = argument.parameter?.name;
        argument.calculatedValue = this.removeQuotesIfPresent(argument.value);
        if (!!argument.subArguments && argument.subArguments.length > 0) {
            argument.subArguments.forEach(subArg => this.prepareForExecution(subArg));
        }
    }

    onExecuteTest(actionTest: ActionTestDto) {
        this.testNameShowRequiredMessage = false;
        let from: Date = new Date();
        from.setSeconds(from.getSeconds() - 10);
        // Lets make sure all inputs are processed before we actually launch the test!
        setTimeout(() => {
            actionTest.arguments.forEach(subArg => this.prepareForExecution(subArg));
            this.actionFacade.executeAction(this.action, actionTest.arguments, actionTest.outputAssertions).subscribe(result => {
                let to: Date = new Date();
                to.setSeconds(to.getSeconds() + 10);
                this.fetchLogs(from, to);
                this.latestResults.set(actionTest.id, result);
                this.latestResult = result;
            });
        }, backofficeEnvironment.inputdebounce + 100);
    }

    onInitCreateNewAssertion() {
        let newAssertionDialog = this.dialog
            .open(
                ActionCreateAssertionComponent,
                Object.assign(
                    {
                        data: {
                            outputs: this.action.program.outputs,
                        },
                    },
                    backofficeEnvironment.dialogConfig.extrasmall
                )
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.onAddOutputAsAssertion({
                        id: result.output.id,
                        name: result.output.getName(),
                        type: result.output.type,
                        value: result.value,
                    });
                }
            });
    }

    removeQuotesIfPresent(value: string | undefined): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    latestResultSucceeded(actionTest: ActionTestDto) {
        if (this.latestResults.has(actionTest.id)) {
            let result: boolean = true;
            if (this.latestResults.get(actionTest.id).assertionResults) {
                this.latestResults.get(actionTest.id).assertionResults.forEach(assertionResults => {
                    result = result && assertionResults?.assertionResult === 'SUCCESS';
                });
            }
            return result;
        }
        return false;
    }

    latestResultDidNotSucceed(actionTest: ActionTestDto) {
        if (this.latestResults.has(actionTest.id)) {
            let result: boolean = false;
            if (this.latestResults.get(actionTest.id).assertionResults) {
                for (let i = 0; i < this.latestResults.get(actionTest.id).assertionResults.length; i++) {
                    const assertionResults = this.latestResults.get(actionTest.id).assertionResults[i];
                    result = assertionResults?.assertionResult === 'FAILED';
                    if (result) {
                        return result;
                    }
                }
            }
            return result;
        }
        return false;
    }

    onAddAssertion() {}

    onEditAssertion(outputAssertion: ActionTestOutputAssertion) {
        let outputAssertionIndex = this.outputAssertions?.indexOf(outputAssertion);
        if ((outputAssertionIndex || outputAssertionIndex === 0) && outputAssertionIndex > -1 && this.outputAssertions) {
            let assertionToEdit = this.outputAssertions[outputAssertionIndex];
            let editAssertionDialog = this.dialog
                .open(
                    ActionCreateAssertionComponent,
                    Object.assign(
                        {
                            data: {
                                outputs: this.action.program.outputs,
                                assertion: assertionToEdit,
                            },
                        },
                        backofficeEnvironment.dialogConfig.extrasmall
                    )
                )
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        let outputAssertionToChange = this.outputAssertions?.find(outputAssertion => outputAssertion.id === result.id);
                        if (outputAssertionToChange) {
                            outputAssertionToChange.output = {
                                id: result.output.id,
                                name: result.output.getName(),
                                type: result.output.type,
                                value: result.value,
                            };
                        }
                    }
                });
        }
    }
    onRemoveAssertion(outputAssertion: ActionTestOutputAssertion) {
        let outputAssertionIndex = this.outputAssertions?.indexOf(outputAssertion);
        if ((outputAssertionIndex || outputAssertionIndex === 0) && outputAssertionIndex > -1 && this.outputAssertions) {
            this.outputAssertions?.splice(outputAssertionIndex, 1);
        }
    }

    onAddOutputAsAssertion(output: ActionExecutionOutput) {
        if (!this.outputAssertions) {
            this.outputAssertions = [];
        }
        this.outputAssertions?.push({
            id: this.guidFunctions.newGuid(),
            output,
        });
    }

    identifyTest(index, item): string {
        return item.id;
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
