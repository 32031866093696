<ng-container *ngIf="_invocation && formGroup" [formGroup]="formGroup">
    <label for="invocation-name" class="small-input-label" data-name="invocation-name">{{ 'v2.action.properties.name' | translate }}</label>
    <input
        class="alt-small-input"
        type="text"
        id="invocation-name"
        name="invocation-name"
        autocomplete="off"
        cdkFocusInitial
        formControlName="name" />

    <label for="invocation-description" class="small-input-label" data-name="invocation-description">{{
        'v2.action.properties.description' | translate
    }}</label>
    <textarea
        class="alt-small-input"
        type="text"
        id="invocation-description"
        name="invocation-description"
        autocomplete="off"
        formControlName="description"></textarea>

    <codex-icon-picker [icon]="_invocation.iconName" (changeIcon)="onChangeIconName(_invocation, $event)"></codex-icon-picker>
</ng-container>
