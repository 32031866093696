import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Job } from '../../../../../../data-access/editor/src/lib/dto/job.dto';
import { EditorFacade, JobEditorFacade } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-job-edit',
    templateUrl: './job-edit.component.html',
    standalone: false,
})
export class JobEditComponent implements OnInit, OnDestroy {
    @Input()
    jobId: string;

    public formGroup: FormGroup;

    public job$: Observable<Job>;

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly jobEditorFacade: JobEditorFacade,
        private readonly editorFacade: EditorFacade,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.job$ = this.jobEditorFacade.findById(this.jobId);
        this.initForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            id: [null, Validators.required],
            companyId: [null, Validators.required],
            applicationId: [null, Validators.required],
            name: [null, Validators.required],
            description: [null],
            iconName: [null],
            cronStatement: [null],
            frequency: [null],
        });

        this.subscriptions.add(
            this.job$
                .pipe(
                    filter(job => !!job),
                    take(1)
                )
                .subscribe(job => {
                    this.formGroup.patchValue(job);
                })
        );
    }

    onUpdateJob() {
        this.jobEditorFacade.update(this.formGroup.value);
    }

    onChangeIconName(iconName: string) {
        this.formGroup.get('iconName').setValue(iconName);
    }

    onCancel() {
        this.editorFacade.closeTab(this.jobId, 'job');
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
