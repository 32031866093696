import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { initFlowbite } from 'flowbite';
import { Action, ActionExecutionCreatedDto } from '@backoffice/editor/data-access/action';
import { ActionCtx } from '../../../../../../../data-access/editor/src/lib/action/model/action-ctx';
import { ActionEditorFacade, ActionTestDto, Argument, EditorFacade, Parameter, Scope } from '@backoffice/data-access/editor';
import { ApplicationLogsFacade } from '../../../../../../../feature/application-logs/src/lib/facade/application-logs.facade';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationLogDto } from '@backoffice/data-access/application-logs';
import { map, switchMap } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { plainToInstance } from 'class-transformer';
import { ActionTestOutputAssertion } from '../../../../../../../data-access/editor/src/lib/action/dto/action-test-output-assertion.dto';
import { ActionExecutionOutput } from '../../../../../../../editor/data-access/action/src/lib/interface/action-execution-output.dto';
import { GUIDFunctions } from '@shared/utils';
import { ApplicationSelectorAllComponent } from '../../../../../../../../../apps/no-code-x-backoffice/src/features/workspace-selection/components/application-selector-all/application-selector-all.component';
import { ActionCreateAssertionComponent } from '../action-create-assertion/action-create-assertion.component';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';

@Component({
    selector: 'codex-action-links',
    templateUrl: './action-links.component.html',
    standalone: false,
})
export class ActionLinksComponent {
    action: Action;

    actionContext: ActionCtx[];

    outboundActionContext: ActionCtx[];

    inboundActionContext: ActionCtx[];

    contextId: string;

    constructor(
        private dialogRef: MatDialogRef<ActionLinksComponent>,
        private readonly guidFunctions: GUIDFunctions,
        public changeDetectorRef: ChangeDetectorRef,
        public editorFacade: EditorFacade,
        public actionFacade: ActionEditorFacade,
        public applicationLogsFacade: ApplicationLogsFacade,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly dialog: MatDialog,
        private confirmDialog: ConfirmDialog
    ) {
        this.action = this.data.action;
        this.actionContext = this.data.actionContext;
        this.outboundActionContext = this.actionContext.filter(actionContext => actionContext.outbound);
        this.inboundActionContext = this.actionContext.filter(actionContext => !actionContext.outbound);
        dialogRef.backdropClick().subscribe(() => {
            // Close the dialog
            dialogRef.close({ action: this.action });
        });
    }

    openContextElement(actionContext: ActionCtx) {
        if (actionContext.type === 'API') {
            this.editorFacade.registerTab({
                name: actionContext.name,
                typeId: actionContext.id,
                type: 'api',
            });
        } else if (actionContext.type === 'TEMPLATE') {
            this.editorFacade.registerTab({
                name: actionContext.name,
                typeId: actionContext.id,
                type: 'template',
            });
        } else if (actionContext.type === 'JOB') {
            this.editorFacade.registerTab({
                name: actionContext.name,
                typeId: actionContext.id,
                type: 'job',
            });
        } else if (actionContext.type === 'DATA-FORMAT') {
            this.editorFacade.registerTab({
                name: actionContext.name,
                typeId: actionContext.id,
                type: 'data-format',
            });
        } else if (actionContext.type === 'ACTION') {
            this.editorFacade.registerTab({
                name: actionContext.name,
                typeId: actionContext.id,
                type: 'action',
            });
        }
    }
}
