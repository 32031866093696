<div
    *ngIf="this._argument.subArguments && this._argument.subArguments.length > 0"
    (cdkDropListDropped)="reorderSubArguments($event)"
    [cdkDropListData]="this._argument.subArguments"
    [attr.id]="'subarguments-accordion-' + this._argument.id"
    [attr.data-accordion]="'subarguments-accordion-' + this._argument.id"
    class="mb-2 accordion-border"
    cdkDropList>
    <div class="mb-2 accordion-border">
        <ng-container *ngFor="let argument of this._argument.subArguments; let i = index; trackBy: identifyArgument">
            <h3
                [attr.id]="'accordion-array-subargument-' + argument.id + '-arrayitem-heading'"
                [attr.data-name]="'header-' + argument.id"
                class="pt-0"
                cdkDrag>
                <button
                    type="button"
                    class="accordion-heading"
                    [class.accordion-heading-first]="i === 0"
                    [class.accordion-heading-last]="i === this._argument.subArguments?.length - 1"
                    [attr.data-accordion-target]="'#accordion-array-subargument-' + this._argument.id + argument.id + '-arrayitem'"
                    [attr.aria-controls]="'accordion-array-subargument-' + this._argument.id + argument.id + '-arrayitem'"
                    aria-expanded="true">
                    <span class="leading-6 grow shrink text-left">{{
                        argument.composedTitle ? (argument.composedTitle | truncate: 25) : ('item ' + i | truncate: 25)
                    }}</span>
                    <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                        <mat-icon>import_export</mat-icon>
                    </button>
                    <div *cdkDragPreview class="border bg-primarylight border-primary w-[100%] !h-9 rounded"></div>
                    <button
                        class="accordion-heading-button destructive-accordion-heading-button"
                        (click)="onRemoveArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.object.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <svg
                        data-accordion-icon
                        class="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h3>
            <div
                [attr.id]="'accordion-array-subargument-' + this._argument.id + argument.id + '-arrayitem'"
                [attr.data-name]="'content-' + this._argument.id + argument.id"
                [attr.aria-labelledby]="'accordion-array-subargument-' + this._argument.id + argument.id + '-arrayitem'"
                [class.accordion-panel-first]="i === 0"
                [class.accordion-panel-last]="i === this._argument.subArguments?.length - 1"
                [class.accordion-panel]="true"
                class="hidden">
                <argument-value-picker
                    class="mb-1 block"
                    [argument]="argument"
                    [arguments]="_argument.subArguments"
                    [contextId]="contextId"
                    [scope]="scope"
                    [root]="root"
                    [language]="language"
                    [onlyLiteralValues]="onlyLiteralValues"
                    (openTab)="this.openTab.emit($event)"
                    (argumentUpdated)="onArgumentUpdated($event)">
                </argument-value-picker>
            </div>
        </ng-container>
    </div>
</div>
<button
    class="w-full primary-button button-medium flex justify-center items-center"
    type="button"
    mat-raised-button
    color="primary"
    (click)="onAddArrayItem()">
    <mat-icon>add</mat-icon>
    <span>{{
        _argument.parameter.addComposedButtonText
            ? _argument.parameter.addComposedButtonText
            : this._argument.parameter?.name
              ? ('v2.action.argument.array.add.item' | translate) + (this._argument.parameter?.name | truncate: 25)
              : ('v2.action.argument.array.add.item' | translate) + ' array'
    }}</span>
</button>
