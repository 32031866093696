import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { Output as MijnenOutput } from '@backoffice/data-access/editor';
import { TabDefinition } from '../../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';

@Component({
    selector: 'codex-action-edit-output',
    templateUrl: './action-edit-output.component.html',
    styleUrls: ['./action-edit-output.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditOutputComponent implements OnInit, OnDestroy {
    @Input() output: MijnenOutput;
    @Input() root: MijnenOutput[];
    @Input() action: Action;
    @Input() language: string;

    @Output() actionUpdated: EventEmitter<{ action: Action }> = new EventEmitter<{ action: Action }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    subFormats: string[] = [];

    form = this.fb.group({
        name: ['', [Validators.required]],
        description: [''],
        type: ['', Validators.required],
        subformat: [''],
        inputType: [''],
    });

    private readonly _subscriptions = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.form.patchValue({
            name: this.output.getName('en'),
            description: this.output.getDescription('en'),
            type: this.output.type,
            subformat: this.output.subTypePath,
            inputType: this.output.inputType,
        });

        if (!!this.output.type) {
            this.form.get('type')?.disable();
        }

        this.initForm();
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    initForm(): void {
        this._subscriptions.add(
            this.form.controls.name.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(name => name ?? '')
                )
                .subscribe(value => this.onNameChange(value))
        );

        this._subscriptions.add(
            this.form.controls.inputType.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(name => name ?? '')
                )
                .subscribe(value => this.onInputTypeChange(value))
        );
        this._subscriptions.add(
            this.form.controls.description.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(description => description ?? '')
                )
                .subscribe(value => this.onDescriptionChange(value))
        );
        this._subscriptions.add(
            this.form.controls.type.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(type => type ?? '')
                )
                .subscribe(value => this.onTypeChange(value))
        );
    }

    onLinkedDataFormatParameterIdChange($event: { selectedOutputId: string }) {
        this.output.linkedDataFormatParameterId = $event.selectedOutputId;
        this.actionUpdated.emit({ action: this.action });
    }

    onDataFormatChange($event: { value: string }, output: MijnenOutput) {
        this.output.subTypeParameterId = $event.value;
        this.actionUpdated.emit({ action: this.action });
    }

    onInputTypeChange($event) {
        this.output.inputType = $event;
        this.actionUpdated.emit({ action: this.action });
    }

    onNameChange($event) {
        if (!this.output.names) {
            this.output.names = {};
        }
        this.output.names['en'] = $event;
        this.actionUpdated.emit({ action: this.action });
    }

    onDescriptionChange($event) {
        if (!this.output.descriptions) {
            this.output.descriptions = {};
        }
        this.output.descriptions['en'] = $event;
        this.actionUpdated.emit({ action: this.action });
    }

    onTypeChange($event) {
        this.output.type = $event;
        this.actionUpdated.emit({ action: this.action });
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
