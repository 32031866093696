import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument, DataFormat, DataFormatEditorFacade, Parameter, Scope } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-dataformat-attributes',
    templateUrl: './argument-dataformat-attributes.component.html',
    styleUrls: ['./argument-dataformat-attributes.component.scss'],
    standalone: false,
})
export class ArgumentDataformatAttributesComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadDataFormat();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language: string;
    @Input() contextId: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    dataFormatArgument!: Argument;

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    dataFormatAttributes!: Array<{ name: string; type: string[]; description: string | undefined }>;

    openedSubArgument!: string;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onAddDataFormatAttribute() {
        const subArgument = new Argument();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        subArgument.parameter.type = 'STRING';
        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    loadDataFormat() {
        if (this._argument) {
            this.dataFormatArgument = this._arguments.find(
                argument =>
                    argument.parameterId === this._argument.parameter?.linkedDataFormatParameterId ||
                    argument.selectorId === this._argument.parameter?.linkedDataFormatParameterId
            );
            if (this.dataFormatArgument && this.dataFormatArgument.value !== this.dataFormatId) {
                this.dataFormatId = this.dataFormatArgument.value;
                if (!!this.dataFormatId && !this.dataFormatId.startsWith('{')) {
                    this.dataFormatEditorFacade
                        .findById(this.removeQuotesIfPresent(this.dataFormatArgument.value))
                        .subscribe(dataFormat => {
                            this.dataFormat = dataFormat;
                            const dataSchema = dataFormat.jsonSchema;
                            this.dataFormatAttributes = [];
                            if (!!dataSchema.properties) {
                                Object.keys(dataSchema.properties).forEach(dataPropertyName => {
                                    if (dataSchema.properties) {
                                        const dataProperty = dataSchema.properties[dataPropertyName];
                                        this.dataFormatAttributes.push({
                                            name: dataPropertyName,
                                            type: dataProperty.type,
                                            description: dataProperty.description,
                                        });
                                    }
                                });
                            }
                            this.showSubArguments = true;
                            this.changeDetectorRef.detectChanges();
                        });
                } else {
                    this.showSubArguments = false;
                    this._argument.subArguments = [];
                    this.changeDetectorRef.detectChanges();
                }
            }
        }
    }

    onDataFormatAttributeSelected($event, argument: Argument) {
        argument.id = $event;
        argument.value = `'${$event}'`;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    identifyAttribute(index, item: { name: string; type: string[]; description: string | undefined }) {
        return item.name;
    }
}
