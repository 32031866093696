<ng-container *ngIf="_nextInvocation">
    <h3 class="edge-settings-title">
        <span class="grow shrink text-left">Conditionals</span>
        <button
            class="accordion-heading-button destructive-accordion-heading-button"
            (click)="this.close.emit(); $event.stopPropagation()"
            [matTooltip]="'Close'">
            <mat-icon>clear</mat-icon>
        </button>
    </h3>
    <div class="edge-settings-body">
        <label class="toggle-line">
            {{ 'v2.action.conditionals.make.edge.else' | translate }}
            <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="elseConditional"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onChangeElseConditional()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>
        <ng-container *ngIf="!_nextInvocation.conditional && _nextInvocation.conditional !== '' && !elseConditional">
            <button
                class="primary-button button-medium mb-2 self-end"
                data-name="save"
                type="button"
                (click)="_nextInvocation.conditional = ''; determineConditionalExpressions(_nextInvocation)">
                <mat-icon>add</mat-icon>
                {{ 'v2.action.conditionals.make.edge.conditional' | translate }}
            </button>
        </ng-container>
        <ng-container *ngIf="!!_nextInvocation.conditional && !elseConditional">
            <button
                class="destructive-button button-medium mb-2 self-end"
                data-name="save"
                type="button"
                (click)="onRemoveAllExpressions()">
                <mat-icon>remove</mat-icon>
                {{ 'v2.action.conditionals.undo.edge.conditional' | translate }}
            </button>
        </ng-container>
        <div
            *ngIf="!elseConditional"
            id="accordion-conditionals"
            data-accordion="open"
            data-active-classes="none"
            data-inactive-classes="none">
            <ng-container *ngFor="let expression of _expressions; let i = index; trackBy: getExpressionUniqueIdentifier">
                <div class="mb-2">
                    <h3
                        [attr.id]="'accordion-conditionals-heading-' + expression.id"
                        [attr.data-name]="'header-' + expression.id"
                        class="pt-0">
                        <button
                            type="button"
                            class="accordion-heading"
                            [class.accordion-heading-first]="false"
                            [class.accordion-heading-last]="true"
                            [attr.data-accordion-target]="'#accordion-conditionals-body-' + expression.id"
                            [attr.aria-controls]="'accordion-conditionals-body-' + expression.id"
                            aria-expanded="true">
                            <span class="accordion-heading-icon">{{
                                expression && expression.expression
                                    ? (expression.expression | truncate: 35)
                                    : ('v2.action.conditionals.conditional.add.conditional' | translate)
                            }}</span>
                            <button
                                class="accordion-heading-button destructive-accordion-heading-button"
                                (click)="onRemoveExpression(i); $event.stopPropagation()"
                                [matTooltip]="'v2.action.conditionals.conditional.remove' | translate">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <svg
                                data-accordion-icon
                                class="w-3 h-3 rotate-180 shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6">
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M9 5 5 1 1 5" />
                            </svg>
                        </button>
                    </h3>
                    <div
                        [attr.id]="'accordion-conditionals-body-' + expression.id"
                        [attr.data-name]="'content-' + expression.id"
                        [attr.aria-labelledby]="'accordion-conditionals-heading-' + expression.id"
                        [class.accordion-panel-last]="true"
                        [class.accordion-panel]="true"
                        class="hidden">
                        <codex-action-edit-conditional
                            [expression]="expression"
                            [invocation]="nextInvocationTarget"
                            [scope]="scope"
                            [action]="action"
                            [language]="language"
                            [onlyLiteralValues]="onlyLiteralValues"
                            (changedValue)="onExpressionChange(i, $event)">
                        </codex-action-edit-conditional>
                    </div>

                    <select
                        id="select-equality-operator"
                        [attr.placeholder]="'v2.action.conditionals.conditional.operator.title' | translate"
                        class="small-input mt-2"
                        [ngModel]="expressionOperators[i]"
                        (ngModelChange)="onExpressionOperatorChange(i, $event)">
                        <option style="text-align: center" value="&&">
                            {{ 'v2.action.conditionals.conditional.operator.and' | translate }}
                        </option>
                        <option style="text-align: center" value="||">
                            {{ 'v2.action.conditionals.conditional.operator.or' | translate }}
                        </option>
                    </select>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
