import 'reflect-metadata';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FacetField } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/statemanagement/state/datastate';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'search-facets',
    styleUrls: ['./search-facets.component.scss'],
    templateUrl: 'search-facets.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SearchFacets implements OnInit, OnDestroy {
    @Input() set facetFields(facetFields: Map<string, FacetField[]>) {
        this.calculateFilteredFacetFields(facetFields, this._filterFacets);
        this._facetFields = facetFields;
    }

    _facetFields: Map<string, FacetField[]>;

    @Input() set filterFacets(filterFacets: Map<string, string>) {
        this.calculateFilteredFacetFields(this._facetFields, filterFacets);
        this._filterFacets = filterFacets;
    }

    _filterFacets: Map<string, string> = new Map<string, string>();

    filteredFacetFields: Map<string, FacetField[]>;

    selectedFacets: string[] = [];

    searchFacetsSubscriptions: Subscription[] = new Array();

    @Input() openedFacetList: string;

    @Input() type: string;

    constructor(
        public translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.searchFacetsSubscriptions.push(
            this.route.queryParams.subscribe(params => {
                let convertedFilters = params.filter ? params.filter : [];
                if (typeof convertedFilters === 'string') {
                    convertedFilters = [convertedFilters];
                }
                this.updateSelectedFacets(convertedFilters);
            })
        );
    }

    calculateFilteredFacetFields(facetFields: Map<string, FacetField[]>, filterFacets: Map<string, string>) {
        this.filteredFacetFields = new Map<string, FacetField[]>();
        facetFields.forEach((value: FacetField[], key: string) => {
            if (filterFacets && filterFacets.get(key) && filterFacets.get(key) !== '') {
                this.filteredFacetFields.set(key, []);
                value.forEach(facetField => {
                    if (facetField.name.startsWith(filterFacets.get(key))) {
                        this.filteredFacetFields.get(key).push({
                            name: facetField.name,
                            count: facetField.count,
                            displayName: this.prepareDisplayName(facetField.name.replace(filterFacets.get(key), '')),
                            selected: this.selectedFacets.indexOf(key + ':' + facetField.name) > -1,
                        });
                    }
                });
            } else {
                this.filteredFacetFields.set(key, []);
                value.forEach(facetField => {
                    this.filteredFacetFields.get(key).push({
                        name: facetField.name,
                        count: facetField.count,
                        displayName: this.prepareDisplayName(facetField.name),
                        selected: this.selectedFacets.indexOf(key + ':' + facetField.name) > -1,
                    });
                });
            }
            this.filteredFacetFields.get(key).sort(function (a, b) {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        });
    }

    ngOnDestroy() {
        this.searchFacetsSubscriptions.forEach(searchFacetSubscription => searchFacetSubscription.unsubscribe());
    }

    updateSelectedFacets(filters: string[]) {
        this.selectedFacets = [];
        if (filters && filters instanceof Array) {
            filters.forEach(filter => {
                this.selectedFacets.push(filter);
            });
            this.filteredFacetFields.forEach((value: FacetField[], key: string) => {
                value.forEach(filteredFacet => {
                    filteredFacet.selected = this.selectedFacets.indexOf(key + ':' + filteredFacet.name) > -1;
                });
            });
        }
    }

    onSelect(checked, key, facetField) {
        facetField.selected = checked;
        if (checked) {
            this.selectedFacets.push(key + ':' + facetField.name);
        } else {
            this.selectedFacets.splice(this.selectedFacets.indexOf(key + ':' + facetField.name), 1);
        }
        this.changeFilterParam(this.selectedFacets);
    }

    prepareDisplayName(name: string) {
        let displayName = name.replace('_', ' ');
        displayName = displayName[0].toUpperCase() + displayName.substr(1).toLowerCase();
        return displayName;
    }

    changeFilterParam(filter: Array<string>) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { filter: filter, page: 0 },
            queryParamsHandling: 'merge',
        });
    }

    closeFacetList(facetListKey: string) {
        if (this.openedFacetList === facetListKey) {
            this.openedFacetList = null;
        }
    }
}
