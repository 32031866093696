import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActionEditorFacade, Argument, createArguments, showArgumentInput } from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { OverviewActionDto } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Scope } from '@backoffice/data-access/editor';
import { take } from 'rxjs/operators';
import { TabDefinition } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'argument-action-params',
    templateUrl: './argument-action-params.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentActionParamsComponent implements OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadAction();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language!: string;
    @Input() contextId!: string;
    @Input() root: Argument[];
    @Input() scope!: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Input()
    reloadAction: boolean = false;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    actions!: Observable<OverviewActionDto[]>;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    actionArgument!: Argument | undefined;

    showSubArguments: boolean = false;

    subArguments: Argument[] | undefined = [];

    actionId!: string | null;

    filterParameters: string[] = [];

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private actionEditorFacade: ActionEditorFacade
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    getActionIdFromArgument() {
        this.actionArgument = this._arguments.find(
            argument =>
                argument.parameterId === this._argument.parameter?.linkedActionParameterId ||
                argument.selectorId === this._argument.parameter?.linkedActionParameterId
        );
        let actionId = null;
        if (
            this.actionArgument &&
            this.actionArgument.inputSelectionType === 'action' &&
            !!this.actionArgument.value &&
            this.actionArgument.value !== "'undefined'"
        ) {
            actionId = this.actionArgument.value;
        } else if (
            this.actionArgument &&
            this.actionArgument.inputSelectionType === 'prototype' &&
            !!this.actionArgument.contractValue &&
            this.actionArgument.contractValue !== "'undefined'"
        ) {
            actionId = this.actionArgument.contractValue;
        }
        return actionId;
    }

    loadAction() {
        if (this._argument) {
            let actionIdFromArgument = this.getActionIdFromArgument();
            if ((actionIdFromArgument !== this.actionId && !!actionIdFromArgument) || this.reloadAction) {
                this.actionId = actionIdFromArgument;
                this.actionEditorFacade
                    .findById(this.removeQuotesIfPresent(this.actionId))
                    .pipe(take(1))
                    .subscribe(action => {
                        if (action) {
                            if (this._argument.subArgumentsForAction !== this.actionId) {
                                this._argument.subArguments = createArguments(action.program.parameters);
                            } else {
                                this._argument.mergeArguments(createArguments(action.program.parameters));
                            }

                            this._arguments.forEach(argument => {
                                if (
                                    this._argument?.parameter?.linkedFilterParams?.indexOf(argument.parameterId) > -1 ||
                                    this._argument.parameter?.linkedFilterParams?.indexOf(argument.selectorId) > -1
                                ) {
                                    if (argument.value) {
                                        this.filterParameters.push(this.removeQuotesIfPresent(argument.value));
                                    }
                                }
                            });
                            this.subArguments = this._argument.subArguments?.filter(
                                subArgument => this.filterParameters.indexOf(subArgument.parameter?.name) === -1
                            );
                            if (!this.subArguments) {
                                this.subArguments = [];
                            }
                            this.showSubArguments = true;
                            this.changeDetectorRef.detectChanges();
                            this._argument.subArgumentsForAction = this.actionId;
                        } else {
                            this.actionId = null;
                            this.showSubArguments = true;
                            this._argument.subArguments = [];
                            this.subArguments = [];
                            this.changeDetectorRef.detectChanges();
                            this.argumentUpdated.emit({ argument: this._argument });
                        }
                    });
            } else if (actionIdFromArgument !== this.actionId && !actionIdFromArgument) {
                this.actionId = null;
                this.showSubArguments = true;
                this._argument.subArguments = [];
                this.subArguments = [];
                this.changeDetectorRef.detectChanges();
                this.argumentUpdated.emit({ argument: this._argument });
            }
        }
    }

    onActionExecutionArgumentUpdated($event: { argument: Argument }) {
        let subArgument = this._argument.subArguments?.find(subArgument => $event.argument.id === subArgument.id);
        if (subArgument) {
            subArgument.value = $event.argument.value;
            subArgument.subArguments = $event.argument.subArguments;
            subArgument.inputSelectionType = $event.argument.inputSelectionType;
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    removeQuotesIfPresent(value: string | undefined): string | undefined {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    identifyArgument(index: number, item: Argument): string {
        return item.id;
    }

    protected readonly showArgumentInput = showArgumentInput;
}
