import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetchTemplateSuccess, updateTemplateSuccess } from '../actions/templates-editor.actions';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { ApplicationEventFacade } from '../facades/application-event.facade';
import { createApplicationEvent } from '../actions/application-events.actions';

@Injectable()
export class ApplicationEventsEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly applicationEventFacade: ApplicationEventFacade
    ) {}

    updateTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchTemplateSuccess),
            map(({ template }) => {
                return createApplicationEvent({
                    applicationEvent: {
                        id: template.id,
                        action: 'CREATED',
                        applicationId: template.applicationId,
                        companyId: template.companyId,
                        modified: new Date(),
                        modifiedBy: 'currentUser',
                        payload: template,
                        type: 'TEMPLATE',
                    },
                });
            })
        )
    );
}
