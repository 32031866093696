<ng-container [formGroup]="formGroup">
    <ng-container *ngIf="{ templates: templates$ | async } as observables">
        <ng-container *ngIf="observables.templates.length > 1">
            <label class="small-input-label" [attr.for]="'select-template-' + _argument.id">{{
                'v2.action.argument.part.select.template' | translate
            }}</label>
            <mat-form-field appearance="outline">
                <mat-select
                    [attr.id]="'select-template-' + _argument.id"
                    panelClass="toolpaneSelect scrollbar scrollbar-primary"
                    formControlName="template">
                    <mat-option *ngFor="let template of observables.templates" [value]="template.key">
                        <span>{{ template.key }}: {{ template.name }}</span> <br />
                        <small>{{ template.description }}</small>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="observables.templates.length === 0">
                <mat-error>{{ 'v2.action.argument.part.select.template.none.found' | translate }}</mat-error>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="{ parts: this.filteredParts$ | async } as observables">
        <label class="small-input-label" [attr.for]="'select-part-' + _argument.id">{{
            'v2.action.argument.part.select.part' | translate
        }}</label>
        <div class="flex flex-row gap-1 flex-auto items-center">
            <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline" *ngIf="observables.parts?.length > 0">
                <mat-select
                    [attr.id]="'select-part-' + _argument.id"
                    formControlName="part"
                    [placeholder]="'v2.action.argument.part.select.part' | translate"
                    panelClass="toolpaneSelect scrollbar scrollbar-primary">
                    <mat-option>
                        <ngx-mat-select-search
                            formControlName="partFilter"
                            [clearSearchInput]="false"
                            [placeholderLabel]="'v2.action.argument.part.select.part.search' | translate">
                            <span ngxMatSelectNoEntriesFound>
                                {{ 'v2.action.argument.part.select.part.none.found' | translate }}
                            </span>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let part of observables.parts" [value]="part.getSelector(this._argument.templateKey)">
                        <span class="leading-3 block"
                            ><strong>{{ part.detail['code'] }}</strong
                            >: ({{ part.detail.partType }})</span
                        >
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button
                class="destructive-button button-extra-small"
                *ngIf="!!formGroup.get('part').value"
                (click)="clearPart(); $event.stopPropagation()"
                [matTooltip]="'v2.action.argument.part.select.part.clear' | translate">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <ng-container *ngIf="observables.parts?.length === 0">
            <mat-error class="text-xxs">{{ 'v2.action.argument.part.select.part.none.found' | translate }}</mat-error>
        </ng-container>
    </ng-container>
</ng-container>
