<form class="edit-form" [formGroup]="form">
    <div fxLayout="column" fxLayoutGap="10px">
        <label for="role-name" class="small-input-label" data-name="role-name">{{ 'v2.usermanagement.roles.edit.name' | translate }}</label>
        <input
            class="small-input"
            type="text"
            id="role-name"
            name="role-name"
            autocomplete="off"
            cdkFocusInitial
            required
            [formControl]="nameControl" />
        <p class="small-input-error" *ngIf="nameControl.touched && nameControl.invalid">
            {{ 'v2.usermanagement.roles.edit.name.required' | translate }}
        </p>

        <label for="role-description" class="small-input-label" data-name="role-description">{{
            'v2.usermanagement.roles.edit.description' | translate
        }}</label>
        <textarea
            class="small-input"
            type="text"
            id="role-description"
            name="role-description"
            autocomplete="off"
            [formControl]="descriptionControl"></textarea>
    </div>
    <div class="flex justify-end gap-1 mt-2">
        <button class="primary-button button-large" [disabled]="form.invalid" (click)="onSaveClicked()">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-large" (click)="onCancelClicked()">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
