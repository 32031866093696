import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabDefinition } from '../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, ReplaySubject, Subscription } from 'rxjs';
import { ActionEditorFacade, OverviewActionDto } from '@backoffice/data-access/editor';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { Argument } from '../../../../../../data-access/editor/src/lib/arguments/argument';
import { ActionCreateFormComponent } from '../../../../../../ui/editor/src/lib/action/components/action-create-form/action-create-form.component';

@Component({
    selector: 'app-action-single-picker',
    templateUrl: './action-single-picker.component.html',
    styleUrls: ['./action-single-picker.component.scss'],
    standalone: false,
})
export class ActionSinglePickerComponent {
    @Input() label: string | undefined;
    @Input() value: string | undefined | null;
    @Input() required = false;
    @Input() addQuotesToValue = true;
    @Input() enableArguments = false;
    @Input() applicationId: string | undefined;
    @Input() arguments: Argument[] = [];
    @Input() argumentsForParent: string;
    @Input() contextId: string;
    @Input() enableAddButton: boolean = true;

    @Output() valueUpdated: EventEmitter<{
        value: string | undefined;
        arguments?: Argument[] | undefined;
    }> = new EventEmitter<{
        value: string | undefined;
        arguments?: Argument[] | undefined;
    }>();

    @Output() argumentsUpdated: EventEmitter<{ arguments: Argument[] }> = new EventEmitter<{ arguments: Argument[] }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    valueControl(): FormControl {
        return this.form?.get('value') as FormControl;
    }

    filterControl(): FormControl {
        return this.form?.get('valueFilter') as FormControl;
    }

    filterChanged$ = new ReplaySubject<string>();

    filteredActions$: Observable<OverviewActionDto[]>;

    form: FormGroup;

    missingAction = false;

    private readonly _subscriptions = new Subscription();

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly fb: FormBuilder,
        private readonly actionEditorFacade: ActionEditorFacade,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const actionId = this.value ? this.removeQuotesIfPresent(this.value) : undefined;
        const required = this.required;

        this.form = this.fb.group({
            value: [actionId, required ? Validators.required : null],
            valueFilter: [''],
        });

        // De subscription kan na de emit van de value komen en dan missen we die.
        this._subscriptions.add(
            this.filterControl()
                .valueChanges.pipe(distinctUntilChanged())
                .subscribe(value => {
                    this.filterChanged$.next(value);
                })
        );

        this.filteredActions$ = this.filterChanged$.pipe(
            switchMap(value => this.actionEditorFacade.findAll(value ?? '', [], 'score desc', 0, 10).pipe(map(({ content }) => content)))
        );

        if (!!actionId) {
            this._subscriptions.add(
                this.actionEditorFacade
                    .findById(actionId)
                    .pipe(
                        take(1),
                        tap(action => {
                            if (!action.deleted) {
                                const { name, id } = action;
                                this.filterControl().setValue(name);
                                this.valueControl().setValue(id);
                                this.missingAction = false;
                                this.changeDetectorRef.detectChanges();
                            } else {
                                this.filterControl().setValue('');
                                this.filterControl().setValue('Missing action', { emitEvent: false });
                                this.missingAction = true;
                                this.changeDetectorRef.detectChanges();
                            }
                        })
                    )
                    .subscribe()
            );
        } else {
            this.filterControl().setValue('');
            this.changeDetectorRef.detectChanges();
        }

        this._subscriptions.add(this.valueControl().valueChanges.subscribe(value => this.onChange(value)));
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    onChange(value: any) {
        if (this.addQuotesToValue) {
            if (this.value !== `'${value}'`) {
                if (value) {
                    this.value = `'${value}'`;
                } else {
                    this.value = undefined;
                }
                this.missingAction = false;
                this.valueUpdated.emit({ value: this.value, arguments: this.arguments });
            }
        } else {
            if (this.value !== `${value}`) {
                if (value) {
                    this.value = `${value}`;
                } else {
                    this.value = undefined;
                }
                this.missingAction = false;
                this.valueUpdated.emit({ value: this.value, arguments: this.arguments });
            }
        }
    }

    onOpenAction(templateId: string | undefined) {
        if (templateId) {
            this.openTab.emit({ typeId: this.removeQuotesIfPresent(templateId), type: 'action' });
        }
    }

    onCreateTemplate() {
        this.actionEditorFacade.create(ActionCreateFormComponent).subscribe(actionCreatedId => {
            this.value = `'${actionCreatedId}'`;
            this.valueUpdated.emit({ value: this.value, arguments: [] });
            this.onOpenAction(actionCreatedId);
        });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    deleteActionLink(): void {
        this.value = undefined;

        this.valueControl().reset(null, { onlySelf: true, emitEvent: false });
        this.filterControl().reset(null, { onlySelf: true, emitEvent: false });
        this.missingAction = false;

        this.valueUpdated.emit({ value: this.value, arguments: [] });
    }
}
