import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverviewDataFormatDto } from '@backoffice/data-access/editor';
import { data } from 'autoprefixer';

@Component({
    selector: 'codex-dataformat-overview-row-v2',
    templateUrl: './dataformat-overview-row.component.html',
    styleUrls: ['./dataformat-overview-row.component.scss'],
    standalone: false,
})
export class DataformatOverviewRowComponent {
    @Input() dataformat: OverviewDataFormatDto;

    @Output() clicked = new EventEmitter<void>();
    @Output() boxSelectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected() {
        this.checkboxSelected = !this.checkboxSelected;
        this.boxSelectionChanged.emit(this.checkboxSelected);
    }

    protected readonly data = data;
}
