import { Component, Input, OnInit } from '@angular/core';
import { GenerativeTaskDto } from '../../../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';

@Component({
    selector: 'codex-generative-ai-message-text',
    templateUrl: './generative-ai-message-text.component.html',
    styleUrls: ['./generative-ai-message-text.component.scss'],
    standalone: false,
})
export class GenerativeAiMessageTextComponent implements OnInit {
    @Input()
    message: GenerativeTaskDto;
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
}
