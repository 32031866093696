import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorFacade, Template } from '@backoffice/data-access/editor';
import { Parameter } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-parameter-picker',
    templateUrl: './parameter-picker.component.html',
    styleUrls: ['./parameter-picker.component.scss'],
    standalone: false,
})
export class ParameterPickerComponent implements OnInit {
    missingParameterInfo: {
        template;
        parameter: Parameter;
    }[];

    parametersToAdd: Parameter[] = [];

    constructor(
        public dialogRef: MatDialogRef<ParameterPickerComponent>,
        private editorFacade: EditorFacade,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.missingParameterInfo = this.data.parameters;
    }

    addParameter(parameter: Parameter) {
        this.parametersToAdd.push(parameter);
    }

    removeParameter(parameter: Parameter) {
        this.parametersToAdd.splice(this.parametersToAdd.indexOf(parameter), 1);
    }

    onCancel() {
        this.dialogRef.close();
    }

    addParameters() {
        this.dialogRef.close({
            parameters: this.parametersToAdd,
        });
    }

    onOpenTemplate(template: Template) {
        this.editorFacade.registerTab({
            name: template.name,
            type: 'template',
            typeId: template.id,
            icon: template.iconName,
        });
        this.dialogRef.close();
    }
}
