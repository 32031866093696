import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Argument, DataFormat, DataFormatEditorFacade, Parameter, Scope } from '@backoffice/data-access/editor';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { backofficeEnvironment } from '@shared/environment';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-dataformat-sort-orders',
    templateUrl: './argument-dataformat-sort-orders.component.html',
    styleUrls: ['./argument-dataformat-sort-orders.component.scss'],
    standalone: false,
})
export class ArgumentDataformatSortOrdersComponent implements OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language!: string;
    @Input() contextId!: string;
    @Input() scope!: Scope;
    @Input() root: Argument[];
    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    dataFormatArgument!: Argument;

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    dataFormatAttributes!: Array<{ name: string; type: string[]; description: string | undefined }>;

    openedSubArgument!: string;

    selectedSelectionType!: string;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    onAddFilter() {
        const subArgument = new Argument();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        subArgument.parameter.type = 'DATAFORMAT_SORT_ORDER';

        const orderFieldArgument = new Argument();
        orderFieldArgument.parameterId = new GUIDFunctions().newGuid();
        orderFieldArgument.parameter = new Parameter();
        orderFieldArgument.parameter.type = 'DATAFORMAT_ATTRIBUTE';
        orderFieldArgument.parameter.linkedDataFormatParameterId = this._argument.parameter?.linkedDataFormatParameterId;

        const orderTypeArgument = new Argument();
        orderTypeArgument.parameterId = new GUIDFunctions().newGuid();
        orderTypeArgument.parameter = new Parameter();
        orderTypeArgument.parameter.type = 'DATAFORMAT_ATTRIBUTE';

        if (!subArgument.subArguments) {
            subArgument.subArguments = [];
        }
        subArgument.subArguments.push(orderFieldArgument);
        subArgument.subArguments.push(orderTypeArgument);

        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onDataFormatSortOrderOrderSelected($event, argument: Argument) {
        const oldArgument = this._argument.subArguments?.find(subArgument => subArgument.parameterId === argument.parameterId);
        const newArgument = Object.assign({}, oldArgument);
        newArgument.order = $event;
        this._argument.subArguments.splice(this._argument.subArguments?.indexOf(oldArgument), 1, newArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
