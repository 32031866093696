import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Invocation } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-action-edit-invocation-nextinvocations',
    templateUrl: './action-edit-invocation-nextinvocations.component.html',
    styleUrls: ['./action-edit-invocation-nextinvocations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditInvocationNextinvocationsComponent implements OnInit {
    @Input()
    invocation: Invocation;

    @Input()
    action: Action;

    @Output()
    invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();

    constructor() {}

    ngOnInit(): void {}

    dropNextInvocation($event: CdkDragDrop<string>) {
        moveItemInArray(this.invocation.nextInvocations, $event.previousIndex, $event.currentIndex);
        this.invocationUpdated.emit({ invocation: this.invocation, action: this.action });
    }
}
