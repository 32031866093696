import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { TabDefinition } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { CrewAiTaskDto } from '../../../../../../data-access/editor/src/lib/interfaces/ai/crew-ai-task.dto';

@Component({
    selector: 'argument-task',
    templateUrl: './argument-task.component.html',
    standalone: false,
})
export class ArgumentTaskComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    ngOnInit(): void {
        this.initForm();
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    updateForm(): void {
        this.formGroup?.get('value')?.setValue(this._argument.value);
    }

    initForm(): void {
        let task: CrewAiTaskDto | null = null;
        if (this._argument.value) {
            task = JSON.parse(this._argument.value);
        }
        this.formGroup = this.fb.group({
            name: [task?.name, Validators.required],
            description: [task?.description, Validators.required],
            expectedOutput: [task?.expectedOutput, Validators.required],
            agent: [task?.agent, Validators.required],
        });

        this.subscriptions.add(this.formGroup.valueChanges.pipe(debounceTime(1000)).subscribe(formValues => this.onChange(formValues)));
    }

    onChange(value: string) {
        this._argument.value = JSON.stringify(value);
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
