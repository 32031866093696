import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@backoffice/utils';
import { AddPropertyDialogComponent } from '../add-property-dialog/add-property-dialog.component';
import { backofficeEnvironment } from '@shared/environment';
import {
    DataFormatAttribute,
    setTypeBasedPropertiesOfAttribute,
} from '../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';
import { initFlowbite } from 'flowbite';
import { GUIDFunctions } from '@shared/utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { attributesToJsonSchema } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-json-schema-array-item',
    templateUrl: './json-schema-array-item.component.html',
    standalone: false,
})
export class JsonSchemaArrayItemComponent implements AfterViewInit {
    @Input() item: DataFormatAttribute;
    @Input() attribute: DataFormatAttribute;

    @Output() delete = new EventEmitter<{ attribute: DataFormatAttribute }>();
    @Output() nameChanged = new EventEmitter<{ oldName: string; newName: string }>();
    @Output() requiredChanged = new EventEmitter<{ name: string; isRequired: boolean }>();
    @Output() change: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly dialog: MatDialog,
        private readonly logger: LoggerService
    ) {}

    ngAfterViewInit() {
        setTimeout(() => initFlowbite(), 200);
    }

    onAddPropertyClicked(): void {
        const dialogRef = this.dialog.open(
            AddPropertyDialogComponent,
            Object.assign({ restoreFocus: true }, backofficeEnvironment.dialogConfig.extrasmall)
        );

        dialogRef.afterClosed().subscribe((result: { name: string; type: any }) => {
            if (result) {
                this.logger.info(`Received: ${JSON.stringify(result)}`);

                if (!this.item.attributes) {
                    this.item.attributes = [];
                }

                const { name, type } = result;

                let attribute = {
                    id: new GUIDFunctions().newGuid(),
                    name,
                    noCodeXType: type,
                    types: [type],
                    nullable: true,
                    required: false,
                };
                setTypeBasedPropertiesOfAttribute(attribute);
                this.item.attributes.push(attribute);
                setTimeout(() => initFlowbite());
                this.change.emit();
            }
        });
    }

    identifyAttribute(index, item: DataFormatAttribute) {
        return index;
    }

    onDeleteAttribute(attribute: DataFormatAttribute) {
        this.item.attributes = this.item.attributes?.filter(attributeToCheck => attribute.name !== attributeToCheck.name);
        setTimeout(() => initFlowbite());
        this.change.emit();
    }

    reorderAttributes(event: CdkDragDrop<DataFormatAttribute[]>): void {
        if (this.item.attributes) {
            moveItemInArray(this.item.attributes, event.previousIndex, event.currentIndex);
            setTimeout(() => initFlowbite());
            this.change.emit();
        }
    }
}
