<ng-container
    *ngIf="{
        user: user$ | async,
        editorNavigationRights: editorNavigationRights$ | async,
        userRights: userRights$ | async,
        applications: applications$ | async,
        companies: companies$ | async,
        selectedCompany: selectedCompany$ | async,
        selectedApplication: selectedApplication$ | async,
    } as observables">
    <ng-container *ngIf="observables.user">
        <div class="sidenav">
            <div class="logo-wrapper">
                <img class="logo" src="images/theme/nocode-x-logo-symbol-negative.svg" />
            </div>
            <div class="sidenav-item-wrapper">
                <div class="sidenav-items">
                    <div
                        *ngIf="observables.editorNavigationRights?.showTemplateNav"
                        data-name="templates"
                        class="link"
                        (click)="onOpenDialog('template')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="templateDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#templates" class="ui-element"></use>
                        </svg>
                        <float-ui-content #templateDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Templates (alt + t)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="observables.editorNavigationRights?.showApiNav"
                        data-name="api"
                        class="link"
                        (click)="onOpenDialog('api')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="apiDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#apis" class="ui-element"></use>
                        </svg>
                        <float-ui-content #apiDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">APIs (alt + a)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="observables.editorNavigationRights?.showActionsNav"
                        data-name="action"
                        class="link"
                        (click)="onOpenDialog('action')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="actionsDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#actions" class="ui-element"></use>
                        </svg>
                        <float-ui-content #actionsDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Actions (alt + l)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <hr
                        class="seperator"
                        *ngIf="
                            observables.editorNavigationRights?.showActionsNav ||
                            observables.editorNavigationRights?.showApiNav ||
                            observables.editorNavigationRights?.showTemplateNav
                        " />
                    <div
                        *ngIf="observables.editorNavigationRights?.showDataFormatNav"
                        data-name="dataformat"
                        class="link"
                        (click)="onOpenDialog('data-format')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="dataformatDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#dataformat" class="fill-ui-element"></use>
                        </svg>
                        <float-ui-content #dataformatDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Data-format (alt + f)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="observables.editorNavigationRights?.showDataNav"
                        data-name="data"
                        class="link"
                        (click)="onOpenDialog('data')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="dataDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#data" class="ui-element"></use>
                        </svg>
                        <float-ui-content #dataDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Data (alt + d)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <hr
                        class="seperator"
                        *ngIf="observables.editorNavigationRights?.showDataNav || observables.editorNavigationRights?.showDataFormatNav" />
                    <div
                        *ngIf="observables.editorNavigationRights?.showMediaNav"
                        data-name="media"
                        class="link"
                        (click)="onOpenDialog('media')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="mediaDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#media" class="ui-element"></use>
                        </svg>
                        <float-ui-content #mediaDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Media (alt + m)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="environment.enableJobs && observables.editorNavigationRights?.showJobNav"
                        data-name="jobs"
                        class="link"
                        (click)="onOpenDialog('job')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="jobDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#jobs" class="ui-element"></use>
                        </svg>
                        <float-ui-content #jobDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Jobs (alt + j)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="environment.enableDesignSystem && observables.editorNavigationRights?.showDesignSystemNav"
                        data-name="designsystem"
                        class="link"
                        (click)="onOpenDialog('designsystem')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="designSystemDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#designsystem" class="ui-element"></use>
                        </svg>
                        <float-ui-content #designSystemDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Design system (alt + s)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <hr
                        class="seperator"
                        *ngIf="
                            observables.editorNavigationRights?.showMediaNav ||
                            (environment.enableJobs && observables.editorNavigationRights?.showJobNav) ||
                            (environment.enableDesignSystem && observables.editorNavigationRights?.showDesignSystemNav)
                        " />
                    <div
                        *ngIf="observables.editorNavigationRights?.showGroupsNav"
                        data-name="groups"
                        class="link"
                        (click)="onOpenDialog('groups')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="groupsDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#groups" class="ui-element"></use>
                        </svg>
                        <float-ui-content #groupsDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Groups (alt + g)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        *ngIf="observables.editorNavigationRights?.showRightsNav"
                        data-name="rights"
                        class="link"
                        (click)="onOpenDialog('rights')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="rightsDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#rights" class="ui-element"></use>
                        </svg>

                        <float-ui-content #rightsDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Rights (alt + g)</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <hr
                        class="seperator"
                        *ngIf="observables.editorNavigationRights?.showRightsNav || observables.editorNavigationRights?.showGroupsNav" />
                    <div
                        data-name="plugins"
                        class="link"
                        (click)="onOpenDialog('plugins')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="pluginsDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#plugins" class="ui-element"></use>
                        </svg>
                        <float-ui-content #pluginsDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Plugins</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <hr class="seperator" />
                    <div
                        data-name="users"
                        class="link"
                        (click)="onOpenDialog('users')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="usersDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#users" class="ui-element"></use>
                        </svg>
                        <float-ui-content #usersDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Users</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>

                    <hr class="seperator" />
                    <div
                        data-name="company"
                        class="link"
                        (click)="onOpenDialog('company')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="companyDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#company" class="ui-element"></use>
                        </svg>
                        <float-ui-content #companyDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Workspace</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                    <div
                        data-name="hub"
                        class="link"
                        (click)="onOpenDialog('hub')"
                        [showTrigger]="NgxFloatUiTriggers.hover"
                        [floatUi]="hubDescriptionPopover"
                        appendTo="body"
                        [preventOverflow]="false"
                        [boundariesElement]="'rootBoundary'"
                        [positionFixed]="true"
                        [placement]="NgxFloatUiPlacements.RIGHT"
                        [appendTo]="'body'">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#hub" class="ui-element"></use>
                        </svg>
                        <float-ui-content #hubDescriptionPopover>
                            <div class="popover navigation-info">
                                <div class="draggable-card-popover-title-without-text">
                                    <h3 class="draggable-card-popover-title-element">Hub</h3>
                                </div>
                            </div>
                        </float-ui-content>
                    </div>
                </div>

                <div
                    data-name="rights"
                    class="link mb-2"
                    (click)="onCodexInfo()"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="companyInfoDescriptionPopover"
                    appendTo="body"
                    [preventOverflow]="false"
                    [boundariesElement]="'rootBoundary'"
                    [positionFixed]="true"
                    [placement]="NgxFloatUiPlacements.RIGHT"
                    [appendTo]="'body'">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#information" class="ui-element"></use>
                    </svg>
                    <float-ui-content #companyInfoDescriptionPopover>
                        <div class="popover navigation-info">
                            <div class="draggable-card-popover-title-without-text">
                                <h3 class="draggable-card-popover-title-element">{{ 'v2.navigation.codex.info' | translate }}</h3>
                            </div>
                        </div>
                    </float-ui-content>
                </div>

                <div id="userDropDown" class="dropdown-items hidden">
                    <ul class="dropdown-items-wrapper" aria-labelledby="dropdownDelayButton">
                        <li>
                            <a (click)="goToUrl(environment.accountManagementLink)" class="dropdown-item" target="_blank">
                                <mat-icon>person</mat-icon>
                                <span>Edit account</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="onLogout()" class="dropdown-item" target="_blank">
                                <mat-icon>logout</mat-icon>
                                <span>Logout</span></a
                            >
                        </li>
                    </ul>
                </div>
                <ngx-avatars
                    class="mb-[5px] cursor-pointer"
                    data-name="user"
                    data-dropdown-toggle="userDropDown"
                    data-dropdown-trigger="hover"
                    size="36"
                    [round]="false"
                    cornerRadius="9999"
                    name="{{ observables.user?.firstname }} {{ observables.user?.name }}"></ngx-avatars>
            </div>
        </div>
        <div class="content-container">
            <div class="content-header">
                <div class="content-workspace-navigation">
                    <codex-company-selector
                        [companies]="observables.companies"
                        [selectedCompany]="observables.selectedCompany"
                        [recentCompanies]="recentCompanies$ | async"
                        (companySelect)="onSelectCompany($event)"
                        (companyAdd)="onCreateCompany()"></codex-company-selector>
                    <codex-application-selector
                        *ngIf="observables.selectedCompany"
                        [applications]="observables.applications"
                        [selectedApplication]="observables.selectedApplication"
                        [recentApplications]="recentApplications$ | async"
                        (applicationSelect)="onApplicationChange($event)"
                        (createClicked)="onCreateApplicationClicked()"
                        (editClicked)="onEditApplicationClicked($event)"></codex-application-selector>
                </div>
                <div class="content-navigation"></div>
                <div class="content-right-navigation">
                    <codex-top-right-navigation
                        *ngIf="observables.selectedApplication"
                        [application]="observables.selectedApplication"
                        (openDialog)="onOpenDialog($event)"
                        [showVersionNav]="observables?.editorNavigationRights?.showVersionNav"
                        (openApplicationVersions)="onClickApplicationVersion(observables.selectedApplication)">
                    </codex-top-right-navigation>
                </div>
            </div>
            <router-outlet></router-outlet>
            <div id="generative-chat">
                <codex-generative></codex-generative>
            </div>
        </div>
        <app-assistant
            *ngIf="
                false &&
                observables.selectedCompany?.id &&
                backofficeEnvironment.testableCompanies.includes(observables.selectedCompany?.id)
            "
            [userName]="observables.user?.firstname + ' ' + observables.user?.name">
        </app-assistant>
    </ng-container>
</ng-container>
