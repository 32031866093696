import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GUIDFunctions } from '@shared/utils';
import { ActionCtx } from '@backoffice/data-access/editor';
import { MatDialog } from '@angular/material/dialog';
import { backofficeEnvironment } from '@shared/environment';
import { Parameter, TabDefinition, Template, TemplateEditorFacade } from '@backoffice/data-access/editor';
import { take } from 'rxjs/operators';
import { ParameterPickerComponent } from '../parameter-picker/parameter-picker.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Accordion, initFlowbite } from 'flowbite';
import { ConfirmDialog } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';

@Component({
    selector: 'app-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ParametersComponent implements OnInit {
    @Input() language: string;
    @Input() root: Parameter[];
    @Input() parameters!: Parameter[];
    @Input() context!: ActionCtx[];
    @Input() showMissingParameters: boolean = false;
    @Input() parentId: string;

    @Output() parametersUpdated: EventEmitter<{ parameters: Parameter[] }> = new EventEmitter<{
        parameters: Parameter[];
    }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    missingParameters: {
        template: Template;
        parameter: Parameter;
    }[] = [];

    openedParameterIndex!: number;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private confirmDialog: ConfirmDialog,
        private dialog: MatDialog,
        private templateEditorFacade: TemplateEditorFacade
    ) {}

    ngOnInit(): void {
        this.checkIfParametersAreMissingAccordingToContext();
        setTimeout(() => initFlowbite());
    }

    addMissingParameters(
        missingParameters: { template: Template; parameter: Parameter }[],
        actionContextItem: ActionCtx,
        template: Template
    ) {
        if (actionContextItem && !actionContextItem.outbound && actionContextItem.parameters) {
            if (!!actionContextItem.parameters && actionContextItem.parameters.length > 0) {
                actionContextItem.parameters.forEach(contextParameter => {
                    let existingParameter = null;
                    if (!!this.parameters && this.parameters.length > 0) {
                        existingParameter = this.parameters.find(
                            parameter =>
                                !!contextParameter.name &&
                                parameter.name === contextParameter.name &&
                                parameter.type === contextParameter.type
                        );
                    }
                    if (!existingParameter) {
                        this.missingParameters.push({
                            template,
                            parameter: contextParameter,
                        });
                    }
                });
            }
        }
    }

    checkIfParametersAreMissingAccordingToContext() {
        this.missingParameters = [];
        this.changeDetectorRef.detectChanges();
        if (!!this.context && this.context.length > 0) {
            this.context.forEach(actionContextItem => {
                if (actionContextItem && actionContextItem.type === 'TEMPLATE') {
                    this.templateEditorFacade
                        .findById(actionContextItem.id)
                        .pipe(take(1))
                        .subscribe(template => {
                            this.addMissingParameters(this.missingParameters, actionContextItem, template);
                        });
                }
            });
            this.changeDetectorRef.detectChanges();
        }
    }

    onAddParameter() {
        if (!this.parameters) {
            this.parameters = [];
        }
        const parameter = new Parameter();
        parameter.id = new GUIDFunctions().newGuid();
        parameter.selectorId = new GUIDFunctions().newGuid();
        this.parameters.push(parameter);
        let counter: number = 1;
        this.parameters.forEach(parameter => {
            parameter.sequence = counter;
            counter++;
        });
        this.parametersUpdated.emit({ parameters: this.parameters });
        this.checkIfParametersAreMissingAccordingToContext();
        setTimeout(() => initFlowbite(), 100);

        /*
        const accordionElement = document.getElementById('accordion-template-parameters');
        let accordion = new Accordion(accordionElement);
        accordion.toggle('accordion-template-parameters-heading-' + parameter.id)*/
    }

    onRemoveParameter(parameter: Parameter) {
        this.confirmDialog.showConfirmDialog(
            'v2.parameter.delete.title',
            'v2.parameter.delete.description',
            'v2.parameter.delete.ok',
            'v2.parameter.delete.cancel',
            'v2.parameter.delete.success.title',
            'v2.parameter.delete.success.description',
            () => {
                this.parameters.splice(this.parameters.indexOf(parameter), 1);
                let counter: number = 1;
                this.parameters.forEach(parameter => {
                    parameter.sequence = counter;
                    counter++;
                });
                this.parametersUpdated.emit({ parameters: this.parameters });
                this.checkIfParametersAreMissingAccordingToContext();
            }
        );
    }

    onParametersUpdated($event: { parameter: Parameter }) {
        this.parametersUpdated.emit({ parameters: this.parameters });
        this.checkIfParametersAreMissingAccordingToContext();
    }

    identifyParameter(index: number, item: Parameter) {
        return index + item.selectorId;
    }

    onClickDependantParameters() {
        const dialogRef = this.dialog.open(
            ParameterPickerComponent,
            Object.assign(
                {
                    data: {
                        parameters: this.missingParameters,
                    },
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );
        dialogRef.afterClosed().subscribe((result: { parameters: Parameter[] }) => {
            if (!!result.parameters && result.parameters.length > 0) {
                if (!this.parameters) {
                    this.parameters = [];
                }
                this.parameters = this.parameters.concat(this.copyParameters(result.parameters));
                this.parametersUpdated.emit({ parameters: this.parameters });
                this.checkIfParametersAreMissingAccordingToContext();
            }
        });
    }

    public copyParameters(parameters: Parameter[]): Parameter[] {
        let copiedParameters: Parameter[] = [];
        if (parameters) {
            parameters.forEach(parameter => {
                const copiedParameter = new Parameter();
                copiedParameter.id = new GUIDFunctions().newGuid();
                copiedParameter.type = parameter.type;
                copiedParameter.name = parameter['name'];
                copiedParameter.description = parameter['description'];
                copiedParameter.required = parameter.required;
                copiedParameter.linkedTemplateParameterId = parameter.linkedTemplateParameterId;
                copiedParameter.linkedDataFormatParameterId = parameter.linkedDataFormatParameterId;
                copiedParameter.linkedActionParameterId = parameter.linkedActionParameterId;
                copiedParameter.linkedFilterParams = parameter.linkedFilterParams;
                copiedParameter.addComposedButtonText = parameter.addComposedButtonText;
                copiedParameter.composedTitleSubArgumentId = parameter.composedTitleSubArgumentId;
                copiedParameter.subType = parameter.subType;
                copiedParameter.subTypeParameterId = parameter.subTypeParameterId;
                copiedParameter.subTypePath = parameter.subTypePath;
                if (parameter.defaultValue) {
                    copiedParameter.defaultValue = parameter.defaultValue;
                }
                copiedParameter.hideIfEmptyParameterIds = parameter.hideIfEmptyParameterIds;
                copiedParameter.options = parameter.options;
                copiedParameter.inputType = parameter.inputType;
                copiedParameter.required = parameter.required;
                if (parameter.parameters) {
                    copiedParameter.parameters = this.copyParameters(parameter.parameters);
                }
                copiedParameters.push(parameter);
            });
        }
        return copiedParameters;
    }

    reorderParameters(event: CdkDragDrop<Parameter[]>) {
        moveItemInArray(this.parameters, event.previousIndex, event.currentIndex);
        let counter: number = 1;
        this.parameters.forEach(parameter => {
            parameter.sequence = counter;
            counter++;
        });
        this.parametersUpdated.emit({ parameters: this.parameters });
        setTimeout(() => initFlowbite());
    }
}
