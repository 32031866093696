import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'tagsToString',
    standalone: false,
})
export class TagsToStringPipe implements PipeTransform {
    constructor(public translateService: TranslateService) {}

    transform(tags: string[]) {
        return tags.map(tag => this.translateService.instant('tags.' + tag)).join(', ');
    }
}
