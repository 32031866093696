<mat-accordion *ngIf="showSubArguments" class="inner-accordion">
    <ng-container *ngFor="let argument of this._argument.subArguments">
        <mat-expansion-panel
            [expanded]="argument.parameterId === this.openedSubArgument"
            (opened)="this.openedSubArgument = argument.parameterId"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ argument.id }}
                    <button
                        class="deleteButton"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.dataformatfilter.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label for="select-filter-attribute" class="small-input-label">{{
                'v2.action.argument.dataformatfilter.name' | translate
            }}</label>
            <select
                id="select-filter-attribute"
                class="small-input"
                [(ngModel)]="argument.id"
                (ngModelChange)="onDataFormatAttributeSelected($event, argument)">
                <option *ngFor="let dataFormatAttribute of dataFormatAttributes" [value]="dataFormatAttribute.name">
                    {{ dataFormatAttribute.name }}
                </option>
            </select>

            <label for="select-filter-operator" class="small-input-label">{{
                'v2.action.argument.dataformatfilter.operator.title' | translate
            }}</label>
            <select
                id="select-filter-operator"
                class="small-input"
                [(ngModel)]="argument.operator"
                (ngModelChange)="onDataFormatOperatorSelected($event, argument)">
                <option value="EQUAL">{{ 'v2.action.argument.dataformatfilter.operator.equal' | translate }}</option>
                <option value="GREATER_THAN">{{ 'v2.action.argument.dataformatfilter.operator.greaterthan' | translate }}</option>
                <option value="GREATER_THAN_OR_EQUAL">
                    {{ 'v2.action.argument.dataformatfilter.operator.greaterthanorequal' | translate }}
                </option>
                <option value="IN">{{ 'v2.action.argument.dataformatfilter.operator.in' | translate }}</option>
                <option value="LESS_THAN">{{ 'v2.action.argument.dataformatfilter.operator.lessthan' | translate }}</option>
                <option value="LESS_THAN_OR_EQUAL">{{ 'v2.action.argument.dataformatfilter.operator.lessthanorequal' | translate }}</option>
                <option value="NOT_EQUAL">{{ 'v2.action.argument.dataformatfilter.operator.notequal' | translate }}</option>
                <option value="NOT_IN">{{ 'v2.action.argument.dataformatfilter.operator.notin' | translate }}</option>
                <option value="CONTAINS">{{ 'v2.action.argument.dataformatfilter.operator.contains' | translate }}</option>
                <option value="STARTS_WITH">{{ 'v2.action.argument.dataformatfilter.operator.startswith' | translate }}</option>
                <option value="ENDS_WITH">{{ 'v2.action.argument.dataformatfilter.operator.endswith' | translate }}</option>
            </select>
            <argument-value-picker
                *ngIf="argument.id && argument.operator"
                class="mb-1 block"
                [argument]="argument"
                [arguments]="this.argument.subArguments"
                [contextId]="contextId"
                [scope]="scope"
                [language]="language"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="onActionExecutionArgumentUpdated($event)"></argument-value-picker>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="add-button nocodexbutton" type="button" mat-raised-button color="primary" (click)="onAddFilter()">
    <mat-icon>add</mat-icon>
    <span>{{ 'v2.action.argument.dataformatfilter.add.filter' | translate }}</span>
</button>
