import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProductService } from '@core/services/product.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { concatMap, filter, map } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';
import { AppFacade } from '@core/facades/app.facade';
import { InstallationService } from '@core/services/installation.service';
import { PageEvent } from '@angular/material/paginator';
import { Page } from '@shared/data-access';
import { backofficeEnvironment } from '@shared/environment';
import { Product } from '../../models/product.model';
import { MarketplaceFacade } from '../../facade/marketplace.facade';
import { RouterFacade } from '../../../../../../../utils-routing/src';
import { selectSelectedApplicationId } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/statemanagement/selectors/application.selector';

export interface HubFilter {
    type: string;
}

@Component({
    selector: 'codex-marketplace-overview-page',
    templateUrl: './marketplace-overview-page.component.html',
    styleUrls: ['marketplace-overview-page.component.scss'],
    standalone: false,
})
export class MarketplaceOverviewPageComponent implements OnInit {
    page$: Observable<Page<any>>;

    refresh$ = new BehaviorSubject<void>(undefined);
    searchTermChanged$ = new BehaviorSubject('');
    filterChanged$ = new BehaviorSubject<HubFilter>({
        type: 'PUBLIC',
    });

    pagingChanged$ = new BehaviorSubject({ page: 0, maxResults: 10 });

    hubType: string = 'PUBLIC';

    currentFilter: HubFilter;

    availableButtons: Observable<{ text: string; value: string; icon: string }[]>;

    @Output()
    loadProductDetailPage: EventEmitter<{ productId: string }> = new EventEmitter<{ productId: string }>();

    @Output()
    loadAddProductPage: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly productService: ProductService,
        private readonly installationService: InstallationService,
        private readonly appFacade: AppFacade,
        private readonly route: ActivatedRoute,
        private readonly logger: LoggerService,
        private readonly routerFacade: RouterFacade,
        private readonly marketplaceFacade: MarketplaceFacade
    ) {}

    ngOnInit(): void {
        this.availableButtons = this.appFacade.currentContext$.pipe(
            filter(context => !!context && !!context.selectedCompany),
            map(context => context.selectedCompany),
            map(({ id }) => {
                const buttons = [
                    {
                        text: 'v2.hub.type.public',
                        icon: 'public',
                        value: 'PUBLIC',
                    },
                    {
                        text: 'v2.hub.type.private',
                        icon: 'vpn_lock',
                        value: 'PRIVATE',
                    },
                ];

                if (backofficeEnvironment.testableCompanies.includes(id)) {
                    buttons.push({
                        text: 'v2.hub.type.test',
                        icon: 'science',
                        value: 'TEST',
                    });
                    buttons.push({
                        text: 'v2.hub.type.ai',
                        icon: 'smart_toy',
                        value: 'AI',
                    });
                }
                return buttons;
            })
        );

        this.page$ = combineLatest([
            this.appFacade.selectedApplication.pipe(filter(app => !!app)),
            this.searchTermChanged$,
            this.filterChanged$,
            this.pagingChanged$,
            this.refresh$,
        ]).pipe(
            concatMap(([{ id: applicationId, companyId }, searchTerm, hubFilter, { page, maxResults }]) =>
                forkJoin({
                    installedApplications: this.installationService.findAll({ page: 0, maxResults: 0, applicationId }),
                    availableProducts: this.productService.findAll(companyId, {
                        searchTerm,
                        ...hubFilter,
                        page,
                        maxResults,
                    }),
                    selectedApplicationId: of(selectSelectedApplicationId),
                })
            ),
            map(({ installedApplications, availableProducts, selectedApplicationId }) => {
                const content = availableProducts.content.map(product => {
                    const { id: productId } = product;
                    return {
                        ...product,
                        installation: installedApplications.content.find(i => i.productId === productId),
                    };
                });
                return {
                    content,
                    count: availableProducts.count,
                    facetFields: {},
                };
            })
        );
    }

    handleProductClicked(id: string): void {
        this.loadProductDetailPage.emit({ productId: id });
    }

    handleSearchTermChanged(searchTerm: string): void {
        this.searchTermChanged$.next(searchTerm);
    }

    handlePaginationChanged(event: PageEvent): void {
        this.pagingChanged$.next({
            page: event.pageIndex,
            maxResults: event.pageSize,
        });
    }

    handleHubTypeChanged(hubType: string): void {
        this.hubType = hubType;
        this.filterChanged$.next({ ...this.currentFilter, type: this.hubType });
    }

    handleInstall(data: Product): void {
        this.marketplaceFacade.installLatestVersion(data).subscribe(() => {
            this.loadProductDetailPage.emit({ productId: data.id });
        });
    }

    handleUninstall(id: string): void {
        this.marketplaceFacade.uninstallProduct(id).subscribe(() => this.refresh$.next());
    }

    onPublish() {
        this.loadAddProductPage.emit();
    }
}
