import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Argument, Scope, ScopeItem } from '@backoffice/data-access/editor';
import { BehaviorSubject, combineLatest, Observable, shareReplay, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { backofficeEnvironment } from '@shared/environment';
import { ActionEditScopeDialogComponent } from '../../action/components/action-edit-scope-dialog/action-edit-scope-dialog.component';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'argument-scope-picker',
    templateUrl: './argument-scope-picker.component.html',
    styleUrls: ['./argument-scope-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentScopePickerComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input()
    set scope(scope: Scope) {
        this._scope = scope;
        this.updateScopeItems();
    }

    _scope!: Scope;

    @Input()
    contextId!: string;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    selectedScopeItem: ScopeItem;

    @ViewChild('partDescriptionPopover') popperContent: NgxFloatUiContentComponent;

    searchTerm$ = new BehaviorSubject('');
    scopeItems$ = new BehaviorSubject<ScopeItem[]>([]);
    filteredScopeItems$: Observable<ScopeItem[]> = combineLatest([this.searchTerm$, this.scopeItems$]).pipe(
        map(([searchTerm, items]) => this.filterScopeItems(searchTerm, items)),
        shareReplay(1)
    );

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private readonly fb: FormBuilder,
        private readonly dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.updateScopeItems();
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    clearScopeParameter() {
        this.formGroup.get('valueFilter')?.reset();
        this.formGroup.get('value')?.reset();
    }

    updateForm() {
        if (this.formGroup && !!this.formGroup.get('value') && this._argument.inputSelectionType === 'scope') {
            this.formGroup.get('value').setValue(this._argument.value);
            this.formGroup.get('value').setValidators(this._argument?.parameter?.required ? Validators.required : null);
        }
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            value: [this._argument.value, this._argument?.parameter?.required ? Validators.required : null],
            valueFilter: [''],
        });

        this.subscriptions.add(
            this.formGroup.get('valueFilter')?.valueChanges.subscribe(searchTerm => {
                this.searchTerm$.next(searchTerm);
            })
        );

        this.subscriptions.add(
            this.formGroup
                .get('value')
                ?.valueChanges.pipe(debounceTime(200))
                .subscribe(value => this.onChange(value))
        );
    }

    onChange(value: string) {
        this._argument.value = value;

        if (this._argument.value) {
            if (this._scope && this._argument?.parameter?.type) {
                const scopeItemValues = this._scope.scopeItemsByInvocationAndByType.get(this.contextId)?.get(this._argument.parameter.type);
                if (scopeItemValues) {
                    this.selectedScopeItem = scopeItemValues.get(this._argument.value.replace('{', '').replace('}', ''));
                }
            }
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    updateScopeItems() {
        if (this._scope && this._argument?.parameter?.type) {
            const scopeItemValues = this._scope.scopeItemsByInvocationAndByType.get(this.contextId)?.get(this._argument.parameter.type);
            if (scopeItemValues) {
                if (this._argument.value) {
                    this.selectedScopeItem = scopeItemValues.get(this._argument.value.replace('{', '').replace('}', ''));
                }
                this.scopeItems$.next(Array.from(scopeItemValues.values()));
            }
        }
    }

    viewCompleteScope() {
        const scopeViewer = this.dialog.open(ActionEditScopeDialogComponent, {
            data: {
                argument: this._argument,
                scope: this._scope,
                contextId: this.contextId,
            },
        });

        this.subscriptions.add(
            scopeViewer.afterClosed().subscribe(result => {
                if (result) {
                    this.formGroup.get('value')?.setValue('{' + result.key + '}');
                }
            })
        );
    }

    private filterScopeItems(searchTerm: string, scopeItems: ScopeItem[]): ScopeItem[] {
        if (!scopeItems) {
            return [];
        }

        // get the search keyword
        if (!searchTerm) {
            return scopeItems.slice();
        } else {
            return scopeItems.filter(scopeItem => scopeItem.key && scopeItem?.key?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
        }
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
