<mat-dialog-content class="!p-4">
    <ng-container *ngIf="actionContext && actionContext.length > 0">
        <h1 class="mb-4">Called from</h1>
        <ul class="item-list mb-4">
            <ng-container *ngIf="actionContext && outboundActionContext.length === 0">
                <div class="text-center pt-6">
                    <h1>No Links found</h1>
                    <p class="text-white">it appears this action is not called.</p>
                    <img class="m-auto w-[350px] h-[200px] object-contain" src="/src/images/theme/no_success.gif" />
                </div>
            </ng-container>
            <ng-container *ngFor="let actionContextLink of outboundActionContext">
                <li class="item-list-link" (click)="openContextElement(actionContextLink)">
                    <svg *ngIf="actionContextLink.type === 'TEMPLATE'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#templates" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'ACTION'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#actions" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'API'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#apis" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'JOB'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#jobs" class="ui-element"></use>
                    </svg>
                    <div class="item-name">{{ actionContextLink.name }}</div>
                </li>
            </ng-container>
        </ul>
        <h1 class="mb-4">Calling</h1>
        <ul class="item-list mb-4">
            <ng-container *ngIf="actionContext && inboundActionContext.length === 0">
                <div class="text-center pt-6">
                    <h1>No Links found</h1>
                    <p class="text-white">it appears this action does not call other actions.</p>
                    <img class="m-auto w-[350px] h-[200px] object-contain" src="/src/images/theme/no_success.gif" />
                </div>
            </ng-container>
            <ng-container *ngFor="let actionContextLink of inboundActionContext">
                <li class="item-list-link" (click)="openContextElement(actionContextLink)">
                    <svg *ngIf="actionContextLink.type === 'TEMPLATE'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#templates" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'ACTION'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#actions" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'API'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#apis" class="ui-element"></use>
                    </svg>
                    <svg *ngIf="actionContextLink.type === 'JOB'" aria-hidden="true" focusable="false" class="icon">
                        <use href="#jobs" class="ui-element"></use>
                    </svg>
                    <div class="item-name">{{ actionContextLink.name }}</div>
                </li>
            </ng-container>
        </ul>
    </ng-container>
    <ng-container *ngIf="!actionContext || actionContext.length === 0">
        <div class="text-center">
            <h1>No Links found</h1>
            <p class="text-white">it appears this action is not called nor does it call other actions.</p>
            <img class="m-auto w-[350px] h-[200px] object-contain" src="/src/images/theme/no_success.gif" />
        </div>
    </ng-container>
</mat-dialog-content>
