<mat-accordion *ngIf="showSubArguments" class="inner-accordion">
    <ng-container *ngFor="let argument of this._argument.subArguments">
        <mat-expansion-panel
            [expanded]="argument.parameterId === this.openedSubArgument"
            (opened)="this.openedSubArgument = argument.parameterId"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ argument.id }}
                    <button
                        class="deleteButton"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.json.field.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <argument-value-picker
                class="mb-1 block"
                [argument]="argument"
                [arguments]="this.argument.subArguments"
                [contextId]="contextId"
                [root]="root"
                [scope]="scope"
                [language]="language"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="(argumentUpdated)"></argument-value-picker>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="w-full add-button nocodexbutton" type="button" mat-raised-button color="primary" (click)="onAddField()">
    <mat-icon>add</mat-icon>
    <span>{{ 'v2.action.argument.json.field.add.field' | translate }}</span>
</button>
