import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, take } from 'rxjs/operators';
import { Action } from '../../action/model/action';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ActionCtx } from '../../action/model/action-ctx';
import { calculateScope, findActionCalledActions, findActionContext, findActionMethod } from '../actions/action-context.actions';
import { actionContextSelectors } from '../selectors/action-context.selectors';
import { Scope, ScopeItem } from '../../action/model/scope';
import { Invocation } from '../../action/model/invocation';
import { InvocationOutput } from '../../action/model/invocation-output';
import { DataFormat } from '../../dataformat/models/data-format';
import { JsonProperty } from '../../dataformat/models/json-property';
import { DataFormatEditorFacade } from '../../dataformat/store/facades/data-format-editor.facade';
import { ActionEditorFacade } from './action-editor.facade';
import { ActionContext } from '../../interfaces/action-context.interface';
import { Method } from '../../action/model/method';
import { plainToClass } from 'class-transformer';
import { Parameter } from '../../parameters/parameter';
import { Output } from '@backoffice/data-access/editor';
import { Argument } from '../../arguments/argument';
import { mergeOutputs } from '../../action/model/program';

@Injectable()
export class ActionContextFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly dataFormatEditorFacade: DataFormatEditorFacade,
        private readonly actionEditorFacade: ActionEditorFacade,
        private readonly log: LoggerService
    ) {}

    getActionContext(actionId: string): Observable<ActionContext | undefined> {
        return this.store.select(actionContextSelectors.byId(actionId));
    }

    getActionCalledActions(actionId: string): Observable<Action[]> {
        return this.store.select(actionContextSelectors.byId(actionId)).pipe(
            filter(actionContext => !!actionContext && actionContext.id === actionId && !!actionContext.calledActions),
            switchMap(currentContext => {
                if (currentContext && currentContext.calledActions && currentContext.calledActions.length > 0) {
                    return forkJoin(
                        currentContext.calledActions.map(calledActionId => {
                            this.log.info('Fetching for called action');
                            return this.actionEditorFacade.findById(calledActionId.replace("'", '').replace("'", '')).pipe(take(1));
                        })
                    );
                } else {
                    return of([]);
                }
            })
        );
    }

    findContextByAction(action: Action): Observable<ActionCtx[]> {
        this.log.info(`Find action context by id ${action.id}`);
        this.store.dispatch(findActionContext({ action }));
        // @ts-ignore
        return this.store.select(actionContextSelectors.byId(action.id)).pipe(
            filter(actionContext => !!actionContext && actionContext.id === action.id),
            map(actionContext => actionContext?.actionCtxs)
        );
    }

    findCalledActionsById(action: Action): Observable<Action[]> {
        this.log.info('called actions initialization');
        this.log.info(`Find action methods by id ${action.id}`);
        this.store.dispatch(findActionCalledActions({ action }));
        // @ts-ignore
        return this.store.select(actionContextSelectors.byId(action.id)).pipe(
            debounceTime(500),
            filter(actionContext => !!actionContext && actionContext.id === action.id && !!actionContext.calledActions),
            switchMap(actionContext => {
                if (actionContext && actionContext.calledActions && actionContext.calledActions.length > 0) {
                    this.log.info('called actions <{}>', actionContext.calledActions);
                    return combineLatest(
                        actionContext.calledActions
                            .filter(calledActionId => !!calledActionId)
                            .map(calledActionId => {
                                this.log.info('Fetching for called actions do we need 2?');
                                return this.actionEditorFacade.findById(calledActionId.replace("'", '').replace("'", '')).pipe(take(1));
                            })
                    );
                } else {
                    return of([]);
                }
            })
        );
    }

    findMethodsByAction(action: Action): Observable<Method[]> {
        this.log.info(`Find action methods by id ${action.id}`);
        this.store.dispatch(findActionMethod({ action }));
        // @ts-ignore
        return this.store.select(actionContextSelectors.byId(action.id)).pipe(
            filter(actionContext => !!actionContext && actionContext.id === action.id),
            map(actionContext => plainToClass(Method, actionContext?.methods))
        );
    }

    public getScope(actionId: string): Observable<Scope> {
        return this.store.select(actionContextSelectors.scope(actionId));
    }

    public loadActionScope(
        action: Action,
        methods: Method[] | undefined,
        calledActions: Action[],
        actionContext: ActionCtx[],
        language: string
    ) {
        action.program.initInvocationMap();
        const methodOutputMap: Map<string, Map<string, Output>> = this.createMethodOutputMap(methods);
        const calledActionMap: Map<string, Action> = this.createCalledActionMap(calledActions);
        const scope = new Scope();
        return from(this.loadProgramScope(action, methodOutputMap, calledActionMap, actionContext, scope, language));
    }

    private createCalledActionMap(actions: Action[]) {
        const calledActionMap = new Map();
        if (actions) {
            actions.forEach(action => {
                calledActionMap.set(action.id, action);
            });
        }
        return calledActionMap;
    }

    private createMethodOutputMap(methods: Method[] | undefined) {
        const methodMap = new Map<string, Map<string, Output>>();
        if (methods) {
            methods.forEach(method => {
                if (method && method.key) {
                    if (!methodMap.has(method.key)) {
                        methodMap.set(method.key, new Map<string, Output>());
                    }
                    if (method.outputs) {
                        method.outputs.forEach(output => {
                            if (output && output.id) {
                                methodMap.get(method.key)?.set(output.id, output);
                            }
                        });
                    }
                }
            });
        }
        return methodMap;
    }

    public recalculateScope(action: Action): void {
        this.store.dispatch(calculateScope({ action }));
    }

    private async loadProgramScope(
        action: Action,
        methodOutputMap: Map<string, Map<string, Output>>,
        calledActionMap: Map<string, Action>,
        actionContext: ActionCtx[],
        scope: Scope,
        language: string
    ) {
        this.log.info('(load action scope: load program scope): Load program scope');
        const scopeDataFormatReferences: Map<string, DataFormat> = await this.createScopeDataFormatReferences(
            action.program.invocations,
            action.program.parameters,
            methodOutputMap,
            calledActionMap
        );
        const defaultScopeItems: Map<string, ScopeItem> = new Map();
        this.addUserScopeItems(defaultScopeItems);
        this.addUrlScopeItems(defaultScopeItems);
        this.addBrowserScopeItems(defaultScopeItems);
        this.addContextScopeItems(defaultScopeItems, actionContext);
        this.addParameterScopeItems(defaultScopeItems, action.program.parameters, scopeDataFormatReferences);
        scope.addScopeItem(action.program.invocations[0].id, defaultScopeItems);
        this.createInvocationScopeItems(
            action,
            action.program.invocations[0],
            scope,
            language,
            methodOutputMap,
            calledActionMap,
            scopeDataFormatReferences
        );
        return scope;
    }

    private async createScopeDataFormatReferences(
        invocations: Invocation[],
        parameters: Parameter[],
        methodOutputMap: Map<string, Map<string, Output>>,
        calledActionMap: Map<string, Action>
    ): Promise<Map<string, DataFormat>> {
        let scopeReferences: Map<string, DataFormat> = new Map<string, DataFormat>();
        await this.createScopeDataFormatReferencesFromParameters(parameters, scopeReferences);
        await this.createScopeDataFormatReferencesFromInvocations(invocations, scopeReferences, methodOutputMap, calledActionMap);
        return scopeReferences;
    }

    private async createScopeDataFormatReferencesFromParameters(parameters: Parameter[], scopeReferences: Map<string, DataFormat>) {
        if (parameters) {
            for (const parameter of parameters) {
                if (
                    (parameter.type === 'DATA' ||
                        parameter.type === 'PARTIAL_DATA' ||
                        parameter.type === 'DATA_BODY' ||
                        parameter.type === 'OBJECT') &&
                    !!parameter.subTypeParameterId &&
                    parameter.subTypeParameterId !== '' &&
                    !parameter.subTypeParameterId.startsWith('{')
                ) {
                    let dataFormatId = this.removeQuotesIfPresent(parameter.subTypeParameterId);
                    await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                }
                if (parameter.parameters) {
                    await this.createScopeDataFormatReferencesFromParameters(parameter.parameters, scopeReferences);
                }
            }
        }
    }

    private async createScopeDataFormatReferencesFromInvocations(
        invocations: Invocation[],
        scopeReferences: Map<string, DataFormat>,
        methodOutputMap: Map<string, Map<string, Output>>,
        calledActionMap: Map<string, Action>
    ) {
        for (const invocation of invocations) {
            for (const invocationOutput of invocation.invocationOutputs) {
                if (
                    invocationOutput.type === 'DATA' ||
                    invocationOutput.type === 'PARTIAL_DATA' ||
                    invocationOutput.type === 'DATA_BODY' ||
                    invocationOutput.type === 'OBJECT'
                ) {
                    if (invocationOutput.outputId) {
                        const outputDefinition = methodOutputMap.get(invocation.methodKey)?.get(invocationOutput.outputId);
                        if (outputDefinition) {
                            let subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedDataFormatParameterId);
                            if (!subTypeValue) {
                                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
                            }
                            if (subTypeValue && subTypeValue !== '' && !subTypeValue.startsWith('{')) {
                                let dataFormatId = this.removeQuotesIfPresent(subTypeValue);
                                await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                            } else {
                                let dataFormatId = this.removeQuotesIfPresent(outputDefinition.subTypeParameterId);
                                await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                            }
                        }
                    }
                } else if (invocationOutput.type === 'ACTION_OUTPUTS') {
                    const referencedAction: Action | undefined = calledActionMap.get(
                        this.removeQuotesIfPresent(invocationOutput.subOutputsForAction)
                    );
                    if (referencedAction && !!referencedAction.program.outputs) {
                        for (const programOutput of referencedAction.program.outputs) {
                            if (
                                programOutput.type === 'DATA' ||
                                programOutput.type === 'PARTIAL_DATA' ||
                                programOutput.type === 'DATA_BODY' ||
                                programOutput.type === 'OBJECT'
                            ) {
                                let dataFormatId = this.removeQuotesIfPresent(programOutput.subTypeParameterId);
                                await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                            }
                        }
                    }
                } else if (invocationOutput.type === 'ARRAY' || invocationOutput.type === 'PAGE') {
                    if (invocationOutput.outputId) {
                        const outputDefinition = methodOutputMap.get(invocation.methodKey)?.get(invocationOutput.outputId);
                        if (outputDefinition) {
                            let subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedDataFormatParameterId);
                            if (!subTypeValue) {
                                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
                            }
                            if (subTypeValue && subTypeValue !== '' && !subTypeValue.startsWith('{')) {
                                let dataFormatId = this.removeQuotesIfPresent(subTypeValue);
                                await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                            } else {
                                let dataFormatId = this.removeQuotesIfPresent(outputDefinition.subTypeParameterId);
                                await this.addDataFormatToScopeReferences(dataFormatId, scopeReferences);
                            }
                        }
                    }
                }
            }
        }
    }

    private async addDataFormatToScopeReferences(dataFormatId: string, scopeReferences: Map<string, DataFormat>) {
        if (!!dataFormatId && !scopeReferences.has(dataFormatId)) {
            const dataFormat: DataFormat | undefined = await this.dataFormatEditorFacade.findById(dataFormatId).pipe(first()).toPromise();
            if (dataFormat) {
                scopeReferences.set(dataFormat.id, dataFormat);
            }
        }
    }

    private createInvocationScopeItems(
        action: Action,
        invocation: Invocation | undefined,
        scope: Scope,
        language: string,
        methodOutputMap: Map<string, Map<string, Output>>,
        calledActionMap: Map<string, Action>,
        scopeReferences: Map<string, DataFormat>
    ) {
        if (invocation) {
            const scopeItems: Map<string, ScopeItem> = new Map();
            if (scope.scopeItemByInvocation.has(invocation.id)) {
                let scopeItemMap = scope.scopeItemByInvocation.get(invocation.id);
                if (scopeItemMap && scopeItemMap.size > 0) {
                    const scopeItemOfInvocationTillNow: ScopeItem[] = [...scopeItemMap.values()];
                    for (const scopeItem of scopeItemOfInvocationTillNow) {
                        scopeItems.set(scopeItem.key, scopeItem);
                    }
                }
            }
            this.log.debug('(load action scope: add output items): adding output items <{}>', invocation.invocationOutputs);
            if (!!invocation.invocationOutputs) {
                for (const output of invocation.invocationOutputs) {
                    this.addOutputToScope(output, language, invocation, scopeItems, methodOutputMap, calledActionMap, scopeReferences);
                }
            }

            if (!!invocation.nextInvocations) {
                for (const nextInvocation of invocation.nextInvocations) {
                    scope.addScopeItem(nextInvocation.invocationId, scopeItems);
                    if (action.program.invocationMap.has(nextInvocation.invocationId)) {
                        this.createInvocationScopeItems(
                            action,
                            action.program.invocationMap.get(nextInvocation.invocationId),
                            scope,
                            language,
                            methodOutputMap,
                            calledActionMap,
                            scopeReferences
                        );
                    }
                }
            } else {
                scope.addScopeItem(invocation.id, new Map());
            }
        }
    }

    private getArgumentWithSelectorId(_arguments: Argument[], selectorId: string): Argument | undefined {
        for (let argument of _arguments) {
            if (argument.selectorId === selectorId || argument.parameterId === selectorId) {
                return argument;
            }
            if (argument.subArguments) {
                let argumentFound = this.getArgumentWithSelectorId(argument.subArguments, selectorId);
                if (argumentFound) {
                    return argumentFound;
                }
            }
        }
        return undefined;
    }

    private getOutputWithSelectorId(outputs: InvocationOutput[], selectorId: string): InvocationOutput | undefined {
        if (outputs.length > 0) {
            for (let output of outputs) {
                if (output.output?.selectorId === selectorId || output.output?.id === selectorId) {
                    return output;
                }
                if (output.subOutputs && output.subOutputs.length > 0) {
                    return this.getOutputWithSelectorId(output.subOutputs, selectorId);
                }
            }
        }
        return undefined;
    }

    private getLinkedSubType(invocation: Invocation, parameterId: string | undefined) {
        if (parameterId) {
            let argument = this.getArgumentWithSelectorId(invocation.arguments, parameterId);
            if (argument) {
                return argument.value;
            }
            let output = this.getOutputWithSelectorId(invocation.invocationOutputs, parameterId);
            if (output) {
                return output.value;
            }
            return parameterId;
        }
        return undefined;
    }

    private addOutputToScope(
        output: InvocationOutput,
        language: string,
        invocation: Invocation,
        scopeItems: Map<string, ScopeItem>,
        methodOutputMap: Map<string, Map<string, Output>>,
        calledActionMap: Map<string, Action>,
        scopeReferences: Map<string, DataFormat>
    ) {
        if (!output || !output.outputId) {
            return;
        }
        const outputDefinition = methodOutputMap.get(invocation.methodKey)?.get(output.outputId);
        if (!outputDefinition) {
            return;
        }

        this.log.debug('(load action scope: load program scope): Checking output <{}>', [output]);
        if (outputDefinition.type === 'ARRAY' || outputDefinition.type === 'PAGE') {
            let subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedDataFormatParameterId);
            if (!subTypeValue) {
                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
            }
            if (output.value) {
                scopeItems.set(output.value, {
                    key: output.value,
                    name: output.value,
                    description: outputDefinition.descriptions['en'],
                    type: outputDefinition.type,
                    subType: subTypeValue ? this.removeQuotesIfPresent(subTypeValue) : undefined,
                    subTypePath: outputDefinition.subTypePath,
                });
            }
        } else if (outputDefinition.type !== 'DATA_FORMAT') {
            if (output.value) {
                scopeItems.set(output.value, {
                    key: output.value,
                    name: output.value,
                    description: outputDefinition.descriptions['en'],
                    type: outputDefinition.type,
                });
            }
        }

        if (outputDefinition.type === 'PAGE') {
            if (output.value) {
                scopeItems.set(output.value + '.totalAmount', {
                    id: output.value + '.totalAmount',
                    key: output.value + '.totalAmount',
                    name: output.value + '.totalAmount',
                    description: 'The total amount of items',
                    type: 'NUMBER',
                });
            }
        }

        if (outputDefinition.type === 'ARRAY') {
            if (output.value) {
                scopeItems.set(output.value + '.size', {
                    id: output.value + '.size',
                    key: output.value + '.size',
                    name: output.value + '.size',
                    description: 'The amount of items in this list',
                    type: 'NUMBER',
                });
            }
        }

        if (outputDefinition.type === 'DATA') {
            let subTypeValue = undefined;
            if (outputDefinition.linkedDataFormatParameterId) {
                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedDataFormatParameterId);
            } else if (outputDefinition.linkedArrayParameterId) {
                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedArrayParameterId);
                if (subTypeValue) {
                    let scopeItem = scopeItems.get(subTypeValue.replace('{', '').replace('}', ''));
                    if (scopeItem) {
                        subTypeValue = scopeItem.subType;
                    }
                }
            }
            if (!subTypeValue) {
                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
            }
            if (subTypeValue) {
                this.addDataScopeItems(scopeItems, this.removeQuotesIfPresent(subTypeValue), output.value, scopeReferences);
            }
        }

        if (outputDefinition.type === 'DATA_BODY') {
            const subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
            if (subTypeValue && output.value) {
                this.addDataBodyScopeItems(scopeItems, this.removeQuotesIfPresent(subTypeValue), output.value, scopeReferences);
            }
        }

        if (outputDefinition.type === 'OBJECT') {
            this.addObjectOutputToScope(outputDefinition, output, invocation, scopeItems, scopeReferences);
        }

        if (outputDefinition.type === 'FILE') {
            if (output.value) {
                this.addFileScopeItems(scopeItems, output.value);
            }
        }

        if (outputDefinition.type === 'PARTIAL_DATA') {
            const subTypePathArgument = invocation.arguments.find(
                argument => argument.parameterId === outputDefinition.subTypePathParameterId
            );
            let subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
            if (subTypeValue && subTypePathArgument && output.value) {
                this.addPartialDataScopeItems(
                    scopeItems,
                    this.removeQuotesIfPresent(subTypeValue),
                    this.removeQuotesIfPresent(subTypePathArgument.value),
                    output.value,
                    scopeReferences
                );
            }
        }

        if (outputDefinition.type === 'PART') {
            if (output.value) {
                this.addPartScopeItems(scopeItems, output.value);
            }
        }

        if (
            outputDefinition.type === 'ACTION_OUTPUTS' &&
            output.subOutputsForAction &&
            this.removeQuotesIfPresent(output.subOutputsForAction) !== ''
        ) {
            const referencedAction: Action | undefined = calledActionMap.get(this.removeQuotesIfPresent(output.subOutputsForAction));
            if (referencedAction) {
                // make sure all references to the action outputs are on the invocation outputs.
                mergeOutputs(referencedAction.program.createProgramOutputs(), output);
                for (const subOutput of output.subOutputs) {
                    let subOutputDefinition = referencedAction.program.outputs.find(output => output.id === subOutput.outputId);
                    //we should look into this if we can make this a recursive call to this function...
                    if (subOutputDefinition) {
                        this.addActionOutputToScope(subOutput, language, invocation, scopeItems, subOutputDefinition, scopeReferences);
                    }
                }
            }
        }
    }

    private addObjectOutputToScope(
        outputDefinition: Output,
        output: InvocationOutput,
        invocation: Invocation,
        scopeItems: Map<string, ScopeItem>,
        scopeReferences: Map<string, DataFormat>
    ) {
        let subTypeValue;
        let subTypeInputType;
        if (outputDefinition.linkedDataFormatParameterId) {
            subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedDataFormatParameterId);
        } else if (outputDefinition.linkedArrayParameterId) {
            let argument = this.getArgumentWithSelectorId(invocation.arguments, outputDefinition.linkedArrayParameterId);
            if (argument && argument.value && argument.inputSelectionType === 'scope') {
                let scopeItem = scopeItems.get(argument.value.replace('{', '').replace('}', ''));
                if (scopeItem && scopeItem.subTypeProperties) {
                    this.addScopeItemForEachProperty(
                        output.value,
                        Object.keys(scopeItem.subTypeProperties),
                        scopeItems,
                        scopeItem.subTypeProperties
                    );
                    return;
                }
            } else {
                subTypeValue = this.getLinkedSubType(invocation, outputDefinition.linkedArrayParameterId);
                if (subTypeValue && subTypeValue) {
                    // TODO check if this is a scope value...
                    let scopeItem = scopeItems.get(subTypeValue.replace('{', '').replace('}', ''));
                    if (scopeItem) {
                        subTypeValue = scopeItem.subType;
                    }
                }
            }
        }
        if (!subTypeValue) {
            subTypeValue = this.getLinkedSubType(invocation, outputDefinition.subTypeParameterId);
        }
        if (subTypeValue && output.value) {
            if (!outputDefinition.subTypePath) {
                this.addObjectDataFormatInputTypeScopeItems(
                    scopeItems,
                    this.removeQuotesIfPresent(subTypeValue),
                    output.value,
                    scopeReferences
                );
            } else {
                this.addObjectPartialDataScopeItems(
                    scopeItems,
                    this.removeQuotesIfPresent(subTypeValue),
                    outputDefinition.subTypePath,
                    output.value,
                    scopeReferences
                );
            }
        }
    }

    private addActionOutputToScope(
        output: InvocationOutput,
        language: string,
        invocation: Invocation,
        scopeItems: Map<string, ScopeItem>,
        outputDefinition: Output,
        scopeReferences: Map<string, DataFormat>
    ) {
        this.log.debug('(load action scope: load program scope): Checking output <{}>', [output]);
        if (outputDefinition.type === 'ARRAY' || outputDefinition.type === 'PAGE') {
            const subTypeArgument = invocation.arguments.find(argument => argument.parameterId === outputDefinition.subTypeParameterId);
            if (output.value) {
                scopeItems.set(output.value, {
                    key: output.value,
                    name: outputDefinition.getName(language),
                    description: outputDefinition.getDescription(language),
                    type: outputDefinition.type,
                    subType: subTypeArgument && subTypeArgument.value ? this.removeQuotesIfPresent(subTypeArgument.value) : null,
                });
            }
        } else if (outputDefinition.type !== 'DATA_FORMAT') {
            if (output.value) {
                scopeItems.set(output.value, {
                    key: output.value,
                    name: outputDefinition.getName(language),
                    description: outputDefinition.getDescription(language),
                    type: outputDefinition.type,
                });
            }
        }

        if (outputDefinition.type === 'PAGE' && output.value) {
            scopeItems.set(output.value + '.totalAmount', {
                id: output.value + '.totalAmount',
                key: output.value + '.totalAmount',
                name: output.value + '.totalAmount',
                description: 'The total amount of items',
                type: 'NUMBER',
            });
        }

        if ((outputDefinition.type === 'ARRAY' || outputDefinition.type === 'PAGE') && output.value) {
            scopeItems.set(output.value + '.size', {
                id: output.value + '.size',
                key: output.value + '.size',
                name: output.value + '.size',
                description: 'The amount of items in this list',
                type: 'NUMBER',
            });
        }

        if (outputDefinition.type === 'DATA' && output.value) {
            // ow boy: apparently subtypeparameterId was used as a field to store dataformatId
            this.addDataScopeItems(
                scopeItems,
                this.removeQuotesIfPresent(outputDefinition.subTypeParameterId),
                output.value,
                scopeReferences
            );
        }

        if (outputDefinition.type === 'DATA_BODY' && output.value) {
            // ow boy: apparently subtypeparameterId was used as a field to store dataformatId
            this.addDataBodyScopeItems(
                scopeItems,
                this.removeQuotesIfPresent(outputDefinition.subTypeParameterId),
                output.value,
                scopeReferences
            );
        }

        if (outputDefinition.type === 'OBJECT') {
            this.addObjectOutputToScope(outputDefinition, output, invocation, scopeItems, scopeReferences);
        }

        if (outputDefinition.type === 'PART' && output.value) {
            this.addPartScopeItems(scopeItems, output.value);
        }

        if (outputDefinition.type === 'FILE' && output.value) {
            this.addFileScopeItems(scopeItems, output.value);
        }
    }

    private addParameterScopeItems(scopeItems: Map<string, ScopeItem>, parameters: Parameter[], scopeReferences: Map<string, DataFormat>) {
        for (const parameter of parameters) {
            if (parameter.type === 'ARRAY' || parameter.type === 'PAGE') {
                if (parameter.linkedDataFormatParameterId) {
                    const linkedParameter = parameters.find(
                        linkedParameter => linkedParameter.id === parameter.linkedDataFormatParameterId
                    );
                    if (linkedParameter) {
                        scopeItems.set(parameter.name, {
                            id: parameter.id,
                            key: parameter.name,
                            name: parameter.name,
                            description: parameter.description,
                            type: parameter.type,
                            subType: linkedParameter.subType,
                            subTypePath: linkedParameter.subTypePath,
                        });
                    }
                } else if (parameter.subTypeParameterId || parameter.subType) {
                    scopeItems.set(parameter.name, {
                        id: parameter.id,
                        key: parameter.name,
                        name: parameter.name,
                        description: parameter.description,
                        type: parameter.type,
                        subType: parameter.subType ? parameter.subType : parameter.subTypeParameterId,
                        subTypePath: parameter.subTypePath,
                    });
                } else {
                    scopeItems.set(parameter.name, {
                        id: parameter.id,
                        key: parameter.name,
                        name: parameter.name,
                        description: parameter.description,
                        type: parameter.type,
                    });
                }

                scopeItems.set(parameter.name + '.size', {
                    id: parameter.name + '.size',
                    key: parameter.name + '.size',
                    name: parameter.name + '.size',
                    description: 'The amount of items in this list',
                    type: 'NUMBER',
                });
            } else {
                scopeItems.set(parameter.name, {
                    id: parameter.id,
                    key: parameter.name,
                    name: parameter.name,
                    description: parameter.description,
                    type: parameter.type,
                });
            }

            if (parameter.type === 'DATA') {
                this.addDataScopeItems(scopeItems, parameter.subTypeParameterId, parameter.name, scopeReferences);
            }
            if (parameter.type === 'OBJECT' && parameter.inputType === 'dataformat') {
                if (!parameter.subTypePath) {
                    this.addObjectDataFormatInputTypeScopeItems(scopeItems, parameter.subTypeParameterId, parameter.name, scopeReferences);
                } else {
                    this.addObjectPartialDataScopeItems(
                        scopeItems,
                        parameter.subTypeParameterId,
                        parameter.subTypePath,
                        parameter.name,
                        scopeReferences
                    );
                }
            }

            if (parameter.type === 'OBJECT' && parameter.inputType === 'parameter') {
                this.addObjectParameterScopeItem(scopeItems, null, parameter, scopeReferences);
            }
            if (parameter.type === 'PART') {
                this.addPartScopeItems(scopeItems, parameter.name);
            }

            if (parameter.type === 'FILE') {
                this.addFileScopeItems(scopeItems, parameter.name);
            }

            //DEPRECATED
            if (parameter.type === 'PARTIAL_DATA') {
                this.addPartialDataScopeItems(
                    scopeItems,
                    parameter.subTypeParameterId,
                    parameter.subTypePath,
                    parameter.name,
                    scopeReferences
                );
            }
            //DEPRECATED
            if (parameter.type === 'DATA_BODY') {
                this.addDataBodyScopeItems(scopeItems, parameter.subTypeParameterId, parameter.name, scopeReferences);
            }

            //DEPRECATED
            if (parameter.type === 'COMPOSED') {
                this.addComposedScopeItem(scopeItems, null, parameter, scopeReferences);
            }
            //DEPRECATED
            if (parameter.type === 'COMPOSED_LIST') {
                this.addComposedListScopeItem(scopeItems, null, parameter);
            }
        }
    }

    private addContextScopeItems(scopeItems: Map<string, ScopeItem>, actionContext: ActionCtx[]) {
        actionContext.forEach(actionContextItem => {
            if (!!actionContextItem && !!actionContextItem.scopeName) {
                if (actionContextItem.type === 'TEMPLATE') {
                    scopeItems.set(actionContextItem.scopeName, {
                        id: actionContextItem.id,
                        key: actionContextItem.scopeName,
                        name: actionContextItem.name,
                        description: actionContextItem.description,
                        type: 'TEMPLATE',
                    });

                    scopeItems.set(actionContextItem.scopeName + '.name', {
                        id: actionContextItem.id + '.name',
                        key: actionContextItem.scopeName + '.name',
                        name: actionContextItem.name + '.name',
                        description: actionContextItem.description,
                        type: 'STRING',
                    });

                    scopeItems.set(actionContextItem.scopeName + '.description', {
                        id: actionContextItem.id + '.description',
                        key: actionContextItem.scopeName + '.description',
                        name: actionContextItem.name + '.description',
                        description: actionContextItem.description,
                        type: 'STRING',
                    });
                }

                scopeItems.set(actionContextItem.scopeName, {
                    id: actionContextItem.id,
                    key: actionContextItem.scopeName,
                    name: actionContextItem.name,
                    description: actionContextItem.description,
                    type: actionContextItem.type,
                });
            }
        });
    }

    private addBrowserScopeItems(scopeItems: Map<string, ScopeItem>) {
        scopeItems.set('browser.language', {
            id: 'browser.language',
            key: 'browser.language',
            name: 'Browser language',
            description: 'The language as set in the browser.',
            type: 'STRING',
        });

        scopeItems.set('browser.clipboard', {
            id: 'browser.clipboard',
            key: 'browser.clipboard',
            name: 'Clipboard',
            description: 'The content of the clipboard',
            type: 'STRING',
        });

        scopeItems.set('browser.geolocation', {
            id: 'browser.geolocation',
            key: 'browser.geolocation',
            name: 'Geolocation',
            description: 'The geolocation of the user',
            type: 'STRING',
        });

        scopeItems.set('browser.online', {
            id: 'browser.online',
            key: 'browser.online',
            name: 'Online',
            description: 'Whether an internet connection is available or not.',
            type: 'BOOLEAN',
        });

        scopeItems.set('browser.userAgent', {
            id: 'browser.userAgent',
            key: 'browser.userAgent',
            name: 'User agent',
            description: 'The user agent string of the current browser.',
            type: 'STRING',
        });
    }

    private addUrlScopeItems(scopeItems: Map<string, ScopeItem>) {
        scopeItems.set('currentUrl.path', {
            id: 'currentUrl.path',
            key: 'currentUrl.path',
            name: 'Relative path',
            description: 'The relative path of the current page',
            type: 'STRING',
        });

        scopeItems.set('currentUrl.domain', {
            id: 'currentUrl.domain',
            key: 'currentUrl.domain',
            name: 'Domain name',
            description: 'The domain name of the current page',
            type: 'STRING',
        });
    }

    private addUserScopeItems(scopeItems: Map<string, ScopeItem>) {
        scopeItems.set('currentUser.userId', {
            id: 'currentUser.userId',
            key: 'currentUser.userId',
            name: 'Current user id',
            description: 'The id of the current user',
            type: 'STRING',
        });

        scopeItems.set('currentUser.userId', {
            id: 'currentUser.anonymous',
            key: 'currentUser.anonymous',
            name: 'Is current user anonymous',
            description: '"true" when the current user is not authenticated, "false" if the user is authenticated',
            type: 'BOOLEAN',
        });

        scopeItems.set('currentUser.email', {
            id: 'currentUser.email',
            key: 'currentUser.email',
            name: 'Current user email',
            description: 'The email of the current user',
            type: 'EMAIL',
        });

        scopeItems.set('currentUser.firstName', {
            id: 'currentUser.firstName',
            key: 'currentUser.firstName',
            name: 'Current user firstname',
            description: 'The firstname of the current user',
            type: 'STRING',
        });

        scopeItems.set('currentUser.lastName', {
            id: 'currentUser.lastName',
            key: 'currentUser.lastName',
            name: 'Current user lastName',
            description: 'The lastName of the current user',
            type: 'STRING',
        });

        scopeItems.set('currentUser.rights', {
            id: 'currentUser.rights',
            key: 'currentUser.rights',
            name: 'Current user rights',
            description: 'The rights of the current user',
            type: 'ARRAY',
            subType: 'STRING',
        });
    }

    private addPartScopeItems(partScopeItems: Map<string, ScopeItem>, name: string) {
        this.log.debug('(load action scope: part scope items): adding part scope items');
        partScopeItems.set(name + '.id', {
            id: name + '.id',
            key: name + '.id',
            name: name + '.id',
            description: 'The id of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.languageCode', {
            id: name + '.languageCode',
            key: name + '.languageCode',
            name: name + '.languageCode',
            description: 'The language of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.x', {
            id: name + '.x',
            key: name + '.x',
            name: name + '.x',
            description: 'The x coordinate of this component',
            type: 'NUMBER',
        });

        partScopeItems.set(name + '.positionXUnit', {
            id: name + '.positionXUnit',
            key: name + '.positionXUnit',
            name: name + '.positionXUnit',
            description: 'The unit used with the x coordinate of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.y', {
            id: name + '.y',
            key: name + '.y',
            name: name + '.y',
            description: 'The y coordinate of this component',
            type: 'NUMBER',
        });

        partScopeItems.set(name + '.positionYUnit', {
            id: name + '.positionYUnit',
            key: name + '.positionYUnit',
            name: name + '.positionYUnit',
            description: 'The unit used with the y coordinate of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.sizeX', {
            id: name + '.sizeX',
            key: name + '.sizeX',
            name: name + '.sizeX',
            description: 'The width of this component',
            type: 'NUMBER',
        });

        partScopeItems.set(name + '.sizeXUnit', {
            id: name + '.sizeXUnit',
            key: name + '.sizeXUnit',
            name: name + '.sizeXUnit',
            description: 'The unit used to express the width of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.sizeY', {
            id: name + '.sizeY',
            key: name + '.sizeY',
            name: name + '.sizeY',
            description: 'The height of this component',
            type: 'NUMBER',
        });

        partScopeItems.set(name + '.sizeYUnit', {
            id: name + '.sizeYUnit',
            key: name + '.sizeYUnit',
            name: name + '.sizeYUnit',
            description: 'The unit used to express the height of this component',
            type: 'STRING',
        });

        partScopeItems.set(name + '.partType', {
            id: name + '.partType',
            key: name + '.partType',
            name: name + '.partType',
            description: "This components' type",
            type: 'STRING',
        });
    }

    private addDataScopeItems(
        dataFormatScopeItems: Map<string, ScopeItem>,
        dataFormatId: string | undefined,
        name: string,
        scopeReference: Map<string, DataFormat>
    ) {
        this.log.debug('(load action scope: add data scope items): adding data scope items');
        dataFormatScopeItems.set(name, {
            id: name,
            key: name,
            name: name,
            description: 'The data object',
            type: 'DATA',
        });

        dataFormatScopeItems.set(name + '.id', {
            id: name + '.id',
            key: name + '.id',
            name: 'id',
            description: 'The id of this piece of data',
            type: 'STRING',
        });

        dataFormatScopeItems.set(name + '.name', {
            id: name + '.name',
            key: name + '.name',
            name: 'name',
            description: 'The name of this piece of data',
            type: 'STRING',
        });

        dataFormatScopeItems.set(name + '.description', {
            id: name + '.description',
            key: name + '.description',
            name: 'description',
            description: 'The description of this piece of data',
            type: 'STRING',
        });

        dataFormatScopeItems.set(name + '.dataFormatId', {
            id: name + '.dataFormatId',
            key: name + '.dataFormatId',
            name: 'dataFormatId',
            description: 'The dataformat id of this piece of data',
            type: 'STRING',
        });

        dataFormatId = this.removeQuotesIfPresent(dataFormatId);
        if (scopeReference.has(dataFormatId)) {
            const dataFormat: DataFormat | undefined = scopeReference.get(dataFormatId);

            if (dataFormat && dataFormat.jsonSchema) {
                const schema = dataFormat.jsonSchema;

                dataFormatScopeItems.set(name + '.payload', {
                    id: name + '.payload',
                    key: name + '.payload',
                    name: name + ' (Payload)',
                    description: 'The payload of this data',
                    type: 'OBJECT',
                });

                if (schema.properties) {
                    this.addScopeItemForEachProperty(
                        name + '.payload',
                        Object.keys(schema.properties),
                        dataFormatScopeItems,
                        schema.properties
                    );
                }
            }
        }
    }

    private addPartialDataScopeItems(
        dataFormatScopeItems: Map<string, ScopeItem>,
        dataFormatId: string | undefined,
        path: string,
        name: string,
        scopeReference: Map<string, DataFormat>
    ) {
        if (dataFormatId) {
            this.log.debug('(load action scope: add data scope items): adding partial data scope items', []);
            dataFormatId = this.removeQuotesIfPresent(dataFormatId);
            if (scopeReference.has(dataFormatId)) {
                const dataFormat: DataFormat | undefined = scopeReference.get(dataFormatId);
                if (dataFormat && dataFormat.jsonSchema) {
                    this.log.debug('(load action scope: add data scope items): Json schema <{}>', [dataFormat.jsonSchema]);
                    const pathParts = path.split('.');
                    let partialProperty = null;
                    let properties: { [key: string]: JsonProperty } | undefined = dataFormat.jsonSchema.properties;
                    let arrayItemType: { type: string[] } | null = null;
                    for (const pathPart of pathParts) {
                        if (pathPart === 'arrayitem') {
                            partialProperty = {
                                type: arrayItemType?.type,
                                description: '',
                            };
                            break;
                        } else if (properties && !properties[pathPart]) {
                            properties = {};
                            break;
                        }
                        // TODO take into account multiple types.
                        if (properties && properties[pathPart].type.indexOf('object') > -1) {
                            partialProperty = properties[pathPart];
                            properties = properties[pathPart]?.properties;
                        } else if (properties && properties[pathPart].type.indexOf('array') > -1) {
                            partialProperty = properties[pathPart];
                            arrayItemType = properties[pathPart].items;
                            properties = properties[pathPart].items.properties;
                        }
                    }

                    dataFormatScopeItems.set(name, {
                        id: name,
                        key: name,
                        name,
                        description: partialProperty.description,
                        type: this.mapDataType(partialProperty.type),
                    });

                    if (partialProperty.type === 'ARRAY') {
                        dataFormatScopeItems.set(name + '.size', {
                            id: name + '.size',
                            key: name + '.size',
                            name: name + '.size',
                            description: 'The amount of items in this list',
                            type: 'NUMBER',
                        });
                    }

                    if (properties) {
                        this.addScopeItemForEachProperty(name, Object.keys(properties), dataFormatScopeItems, properties);
                    }
                }
            }
        }
    }

    private addObjectPartialDataScopeItems(
        dataFormatScopeItems: Map<string, ScopeItem>,
        dataFormatId: string | undefined,
        path: string,
        name: string,
        scopeReference: Map<string, DataFormat>
    ) {
        if (dataFormatId) {
            this.log.debug('(load action scope: add data scope items): adding partial data scope items', []);
            dataFormatId = this.removeQuotesIfPresent(dataFormatId);
            if (scopeReference.has(dataFormatId)) {
                const dataFormat: DataFormat | undefined = scopeReference.get(dataFormatId);
                if (dataFormat && dataFormat.jsonSchema) {
                    this.log.debug('(load action scope: add data scope items): Json schema <{}>', [dataFormat.jsonSchema]);
                    const pathParts = path.split('.');
                    let partialProperty = null;
                    let properties: { [key: string]: JsonProperty } | undefined = dataFormat.jsonSchema.properties;
                    let arrayItemType: {
                        type: string[];
                        properties?: {
                            [key: string]: JsonProperty;
                        };
                    } | null = null;
                    let addProperties = true;
                    for (const pathPart of pathParts) {
                        if (pathPart === 'arrayitem') {
                            partialProperty = {
                                type: arrayItemType?.type,
                                description: '',
                            };
                            addProperties = true;
                            break;
                        } else if (properties && !properties[pathPart]) {
                            properties = {};
                            break;
                        }
                        if (properties && properties[pathPart].type.indexOf('object') > -1) {
                            partialProperty = properties[pathPart];
                            properties = properties[pathPart]?.properties;
                            addProperties = true;
                        } else if (properties && properties[pathPart].type.indexOf('array') > -1) {
                            partialProperty = properties[pathPart];
                            arrayItemType = properties[pathPart].items;
                            if (arrayItemType) {
                                properties = arrayItemType.properties;
                            }
                            addProperties = false;
                        }
                    }

                    if (partialProperty && partialProperty.type && partialProperty.type.indexOf('array') > -1) {
                        dataFormatScopeItems.set(name + '.size', {
                            id: name + '.size',
                            key: name + '.size',
                            name: name + '.size',
                            description: 'The amount of items in this list',
                            type: 'NUMBER',
                        });
                    }

                    dataFormatScopeItems.set(name, {
                        id: name,
                        key: name,
                        name,
                        description: partialProperty.description,
                        type: this.mapDataType(partialProperty.type),
                        subType: dataFormatId,
                        subTypePath: path,
                    });

                    if (properties && addProperties) {
                        this.addScopeItemForEachProperty(name, Object.keys(properties), dataFormatScopeItems, properties);
                    }
                }
            }
        }
    }

    private addFileScopeItems(fileScopeItems: Map<string, ScopeItem>, name: string) {
        this.log.debug('(load action scope: add file scope items): adding file scope items');

        fileScopeItems.set(name + '.type', {
            id: name + '.type',
            key: name + '.type',
            name: 'type',
            description: 'The type of this file',
            type: 'STRING',
        });

        fileScopeItems.set(name + '.description', {
            id: name + '.description',
            key: name + '.description',
            name: 'description',
            description: 'The description of this file',
            type: 'STRING',
        });

        fileScopeItems.set(name + '.path', {
            id: name + '.path',
            key: name + '.path',
            name: 'path',
            description: 'The path of this file',
            type: 'STRING',
        });

        fileScopeItems.set(name + '.size', {
            id: name + '.size',
            key: name + '.size',
            name: 'size',
            description: 'The size of this file',
            type: 'NUMBER',
        });

        fileScopeItems.set(name + '.name', {
            id: name + '.name',
            key: name + '.name',
            name: 'name',
            description: 'The name of this file',
            type: 'STRING',
        });

        fileScopeItems.set(name, {
            id: name,
            key: name,
            name: name,
            description: 'File object',
            type: 'FILE',
        });
    }

    private addObjectDataFormatInputTypeScopeItems(
        dataFormatScopeItems: Map<string, ScopeItem>,
        dataFormatId: string | undefined,
        name: string,
        scopeReferences: Map<string, DataFormat>
    ) {
        if (dataFormatId) {
            this.log.debug('(load action scope: add data scope items): adding partial data scope items', []);
            dataFormatId = this.removeQuotesIfPresent(dataFormatId);
            if (scopeReferences.has(dataFormatId)) {
                const dataFormat: DataFormat | undefined = scopeReferences.get(dataFormatId);
                if (dataFormat && dataFormat.jsonSchema) {
                    this.log.debug('(load action scope: add data scope items): Json schema <{}>', [dataFormat.jsonSchema]);
                    const schema = dataFormat.jsonSchema;
                    dataFormatScopeItems.set(name, {
                        id: name,
                        key: name,
                        name: name + ' (Body)',
                        description: 'The body of this piece of data',
                        type: 'OBJECT',
                        subType: dataFormatId,
                    });
                    if (schema.properties) {
                        this.addScopeItemForEachProperty(name, Object.keys(schema.properties), dataFormatScopeItems, schema.properties);
                    }
                }
            }
        }
    }

    private addDataBodyScopeItems(
        dataFormatScopeItems: Map<string, ScopeItem>,
        dataFormatId: string | undefined,
        name: string,
        scopeReferences: Map<string, DataFormat>
    ) {
        if (dataFormatId) {
            this.log.debug('(load action scope: add data scope items): adding partial data scope items', []);
            dataFormatId = this.removeQuotesIfPresent(dataFormatId);
            if (scopeReferences.has(dataFormatId)) {
                const dataFormat: DataFormat | undefined = scopeReferences.get(dataFormatId);
                if (dataFormat && dataFormat.jsonSchema) {
                    this.log.debug('(load action scope: add data scope items): Json schema <{}>', [dataFormat.jsonSchema]);
                    const schema = dataFormat.jsonSchema;
                    dataFormatScopeItems.set(name, {
                        id: name,
                        key: name,
                        name: name + ' (Body)',
                        description: 'The body of this piece of data',
                        type: 'JSON_NODE',
                    });
                    if (schema.properties) {
                        this.addScopeItemForEachProperty(name, Object.keys(schema.properties), dataFormatScopeItems, schema.properties);
                    }
                }
            }
        }
    }

    private addScopeItemForEachProperty(
        prefix: string,
        propertiesNames: string[],
        scopeItems: Map<string, ScopeItem>,
        schemaProperties: {
            [key: string]: JsonProperty;
        }
    ) {
        if (!!propertiesNames && !!schemaProperties) {
            propertiesNames.forEach(propertyName => {
                scopeItems.set(prefix + '.' + propertyName, {
                    id: prefix + '.' + propertyName,
                    key: prefix + '.' + propertyName,
                    name: propertyName,
                    description: schemaProperties[propertyName].description,
                    type: this.mapDataType(schemaProperties[propertyName].type),
                });

                if (schemaProperties[propertyName].type.indexOf('object') > -1 && !!schemaProperties[propertyName]?.properties) {
                    this.addScopeItemForEachProperty(
                        prefix + '.' + propertyName,
                        Object.keys(schemaProperties[propertyName].properties),
                        scopeItems,
                        schemaProperties[propertyName].properties
                    );
                }

                if (schemaProperties[propertyName].type.indexOf('array') > -1) {
                    const arrayScopeItem: ScopeItem = {
                        id: prefix + '.' + propertyName,
                        key: prefix + '.' + propertyName,
                        name: propertyName,
                        description: schemaProperties[propertyName].description,
                        type: this.mapDataType(schemaProperties[propertyName].type),
                        subType: this.mapDataType(schemaProperties[propertyName]?.items?.type),
                    };
                    scopeItems.set(prefix + '.' + propertyName, arrayScopeItem);
                    if (schemaProperties[propertyName]?.items?.type.indexOf('object') > -1) {
                        arrayScopeItem.subTypeProperties = schemaProperties[propertyName]?.items?.properties;
                    }
                    scopeItems.set(prefix + '.' + propertyName + '.size', {
                        id: prefix + '.' + propertyName + '.size',
                        key: prefix + '.' + propertyName + '.size',
                        name: propertyName + '.size',
                        description: 'The amount of items in this list',
                        type: 'NUMBER',
                    });
                }
            });
        }
    }

    private addComposedListScopeItem(scopeItems: Map<string, ScopeItem>, parentNameSuffix: string, composedListParameter: Parameter) {
        let nameSuffix = parentNameSuffix ? parentNameSuffix + '.' : '';
        scopeItems.set(`${nameSuffix}${composedListParameter.name}`, {
            id: `${nameSuffix}${composedListParameter.name}`,
            key: `${nameSuffix}${composedListParameter.name}`,
            name: composedListParameter.name,
            description: composedListParameter.description,
            type: 'COMPOSED_LIST',
        });

        scopeItems.set(`${nameSuffix}${composedListParameter.name}.size`, {
            id: `${nameSuffix}${composedListParameter.name}.size`,
            key: `${nameSuffix}${composedListParameter.name}.size`,
            name: composedListParameter.name + '.size',
            description: 'The amount of composed items within this list',
            type: 'NUMBER',
        });
    }

    private addComposedScopeItem(
        scopeItems: Map<string, ScopeItem>,
        parentNameSuffix: string | null,
        composedParameter: Parameter,
        scopeReferences: Map<string, DataFormat>
    ) {
        let nameSuffix = parentNameSuffix ? parentNameSuffix + '.' : '';
        scopeItems.set(`${nameSuffix}${composedParameter.name}`, {
            id: `${nameSuffix}${composedParameter.name}`,
            key: `${nameSuffix}${composedParameter.name}`,
            name: composedParameter.name,
            description: composedParameter.description,
            type: 'COMPOSED',
        });

        if (composedParameter.parameters) {
            this.addComposedScopeItems(scopeItems, `${nameSuffix}${composedParameter.name}`, composedParameter.parameters, scopeReferences);
        }
    }

    private addObjectParameterScopeItem(
        scopeItems: Map<string, ScopeItem>,
        parentNameSuffix: string | null,
        composedParameter: Parameter,
        scopeReferences: Map<string, DataFormat>
    ) {
        let nameSuffix = parentNameSuffix ? parentNameSuffix + '.' : '';
        scopeItems.set(`${nameSuffix}${composedParameter.name}`, {
            id: `${nameSuffix}${composedParameter.name}`,
            key: `${nameSuffix}${composedParameter.name}`,
            name: composedParameter.name,
            description: composedParameter.description,
            type: 'OBJECT',
        });

        if (composedParameter.parameters) {
            this.addComposedScopeItems(scopeItems, `${nameSuffix}${composedParameter.name}`, composedParameter.parameters, scopeReferences);
        }
    }

    private async addComposedScopeItems(
        scopeItems: Map<string, ScopeItem>,
        nameSuffix: string,
        children: Parameter[],
        scopeReferences: Map<string, DataFormat>
    ) {
        for (let child of children) {
            if (child.type) {
                if (child.type === 'OBJECT' && child.inputType === 'dataformat') {
                    if (!child.subTypePath) {
                        this.addObjectDataFormatInputTypeScopeItems(
                            scopeItems,
                            child.subTypeParameterId,
                            `${nameSuffix}.${child.name}`,
                            scopeReferences
                        );
                    } else {
                        this.addObjectPartialDataScopeItems(
                            scopeItems,
                            child.subTypeParameterId,
                            child.subTypePath,
                            `${nameSuffix}.${child.name}`,
                            scopeReferences
                        );
                    }
                } else if (child.type === 'COMPOSED') {
                    //DEPRECATED
                    this.addComposedScopeItem(scopeItems, `${nameSuffix}`, child, scopeReferences);
                } else if (child.type === 'COMPOSED_LIST') {
                    //DEPRECATED
                    this.addComposedListScopeItem(scopeItems, `${nameSuffix}`, child);
                } else if (child.type === 'DATA') {
                    this.addDataScopeItems(scopeItems, child.subTypeParameterId, `${nameSuffix}.${child.name}`, scopeReferences);
                } else if (child.type === 'PART') {
                    this.addPartScopeItems(scopeItems, `${nameSuffix}.${child.name}`);
                } else if (child.type === 'PARTIAL_DATA') {
                    //DEPRECATED
                    this.addPartialDataScopeItems(
                        scopeItems,
                        child.subTypeParameterId,
                        child.subTypePath,
                        `${nameSuffix}.${child.name}`,
                        scopeReferences
                    );
                } else if (child.type === 'DATA_BODY') {
                    //DEPRECATED
                    this.addDataBodyScopeItems(scopeItems, child.subTypeParameterId, `${nameSuffix}.${child.name}`, scopeReferences);
                } else if (child.type === 'FILE') {
                    this.addFileScopeItems(scopeItems, `${nameSuffix}.${child.name}`);
                } else {
                    scopeItems.set(`${nameSuffix}.${child.name}`, {
                        id: `${nameSuffix}.${child.name}`,
                        key: `${nameSuffix}.${child.name}`,
                        name: `${nameSuffix}.${child.name}`,
                        description: child.description,
                        type: child.type,
                    });
                }
            }
        }
    }

    private mapDataType(type: string[] | undefined): string | null {
        // TODO take into account multiple types
        if (type) {
            if (type.indexOf('string') > -1) {
                return 'STRING';
            } else if (type.indexOf('email') > -1) {
                return 'EMAIL';
            } else if (type.indexOf('url') > -1) {
                return 'URL';
            } else if (type.indexOf('integer') > -1 || type.indexOf('number') > -1) {
                return 'NUMBER';
            } else if (type.indexOf('array') > -1) {
                return 'ARRAY';
            } else if (type.indexOf('object') > -1) {
                return 'JSON_NODE';
            } else if (type.indexOf('boolean') > -1) {
                return 'BOOLEAN';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    removeQuotesIfPresent(value: string | undefined): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }
}
