import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-object-parameters',
    templateUrl: './argument-object-parameters.component.html',
    standalone: false,
})
export class ArgumentObjectParametersComponent {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
        this.loadSubArguments();
    }

    _argument!: Argument;

    @Input() contextId: string;
    @Input() language: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    onArgumentUpdated($event: { argument: Argument }) {
        const changedSubArgument = this._argument.subArguments?.find(subArgument => subArgument.id === $event.argument.id);
        if (changedSubArgument) {
            changedSubArgument.value = $event.argument.value;
            changedSubArgument.subArguments = $event.argument.subArguments;
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(subArgument => {
            let parameter = this._argument.parameter?.parameters?.find(
                parameter => subArgument.parameterId === parameter.id || subArgument.selectorId === parameter.selectorId
            );
            if (parameter) {
                subArgument.parameter = parameter;
            }
        });
    }

    identifyArgument(index: number, item: Argument): string {
        return item.id;
    }
}
