import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssistantFacade, AssistantMessageDto } from 'backoffice/data-access/assistant';
import { DataFormat, DataFormatEditorFacade } from '@backoffice/data-access/editor';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-assistant-generate-test-data-input',
    templateUrl: './assistant-generate-test-data-input.component.html',
    styleUrls: ['./assistant-generate-test-data-input.component.scss'],
    standalone: false,
})
export class AssistantGenerateTestDataInputComponent {
    formGroup: FormGroup;

    @Output()
    addMessage: EventEmitter<{ message: AssistantMessageDto }> = new EventEmitter<{ message: AssistantMessageDto }>();

    chosenDataFormats: DataFormat[] = [];

    constructor(
        private fb: FormBuilder,
        private assistantFacade: AssistantFacade,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.formGroup = this.fb.group({
            input: ['', Validators.required],
        });
        this.addMessage.emit({
            message: {
                user: false,
                text: 'Would you like me to create some test data for you? Please select the dataformats for which you want to create the data and provide me with as much information as possible in the supporting prompt field.',
            },
        });
    }

    onAddDataFormat($event) {
        this.dataFormatEditorFacade
            .findById($event.value)
            .pipe(take(1))
            .subscribe(dataFormat => {
                this.chosenDataFormats.push(dataFormat);
            });
    }

    onRemoveDataFormat(dataFormat: DataFormat) {
        this.chosenDataFormats.splice(this.chosenDataFormats.indexOf(dataFormat), 1);
    }

    sendInput($event) {
        const prompt = this.formGroup.get('input')?.value;
        this.addMessage.emit({ message: { user: true, text: this.formGroup.get('input')?.value } });
        this.formGroup.get('input')?.reset();
        this.assistantFacade
            .generateTestData(
                prompt,
                this.chosenDataFormats.map(dataFormat => dataFormat.id)
            )
            .subscribe(response => {
                this.addMessage.emit({ message: { user: false, text: response.response } });
                this.changeDetectorRef.detectChanges();
            });
    }
}
