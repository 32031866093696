import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverviewActionDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-action-overview-row-v2',
    templateUrl: './action-overview-row.component.html',
    styleUrls: ['./action-overview-row.component.scss'],
    standalone: false,
})
export class ActionOverviewRowComponent {
    @Input() action: OverviewActionDto;

    @Output() clicked = new EventEmitter<void>();
    @Output() boxSelectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected() {
        this.checkboxSelected = !this.checkboxSelected;
        this.boxSelectionChanged.emit(this.checkboxSelected);
    }
}
