import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '../../../../../../../shared/data-access/src/lib/model/facet.model';
import { SelectedFacets } from '@backoffice/data-access/editor';

@Component({
    selector: 'no-codex-facets',
    templateUrl: './facets.component.html',
    standalone: false,
})
export class FacetsComponent {
    @Input() facets: { [key: string]: [Facet] };
    @Input() prefix: string | undefined;
    @Input() selectedValues = new SelectedFacets();

    @Output() selectionChanged = new EventEmitter<SelectedFacets>();

    onCheckboxChanged(event: { checked: boolean }, facet: string, value: string): void {
        const { checked } = event;
        if (checked) {
            this.selectedValues.set(facet, value);
        } else {
            this.selectedValues.delete(facet);
        }
        this.selectionChanged.emit(this.selectedValues);
    }

    protected readonly Object = Object;

    shouldShowFacets(facets: { [key: string]: [Facet] }) {
        let showFacets = false;
        if (facets) {
            if (Object.keys(facets)) {
                const keys = Object.keys(facets);
                for (const key of keys) {
                    if (facets[key] && facets[key].length > 0) {
                        showFacets = true;
                    }
                }
            }
        }
        return showFacets;
    }
}
