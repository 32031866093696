import 'reflect-metadata';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacetField } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/statemanagement/state/datastate';
import { Subscription } from 'rxjs';
import { PageNumberDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/components/layout/search-results/model/page-number.dto';

declare var $: any;

@Component({
    selector: 'search-results',
    styleUrls: ['./search-results-container.component.scss'],
    templateUrl: `search-results-container.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SearchResults implements OnChanges, OnDestroy, OnInit {
    @Input() searchResults: any[];

    @Input() count: number = -1;

    @Input() firstPage: number = 1;

    @Input() maxResults: number = 10;

    @Input() rowComponent: any;

    @Input() noResultsMessage: string = 'general.search.no.results';

    @Input() currentPage: number;

    @Input() openedFacetList: string;

    @Input() registerDossier: string;

    @Input() type: string;

    @Input() set facetFields(facetFields: any) {
        this._facetFields = new Map(Object.entries(facetFields));
    }

    _facetFields: Map<string, FacetField[]>;

    @Input() set filterFacets(filterFacets: any) {
        this._filterFacets = new Map(Object.entries(filterFacets));
    }

    _filterFacets: Map<string, string>;

    @Output() select = new EventEmitter<any>();

    @Input() allSelected: boolean = false;

    @Output() changePage = new EventEmitter();

    @Output() action = new EventEmitter<{ id: string }>();
    pages: PageNumberDto[] = [];

    pageAmount: number;

    searchResultSubscriptions: Subscription[] = new Array();

    matSideNavContainer: any;

    constructor(
        private route: ActivatedRoute,
        private host: ElementRef
    ) {
        // TODO remove this when no one is using (changePage) anymore
        this.searchResultSubscriptions.push(
            this.route.queryParams.subscribe(params => {
                this.onPage(parseInt(params['page']), params['filter']);
            })
        );
    }

    ngOnInit() {
        this.matSideNavContainer = $(this.host.nativeElement).closest('mat-sidenav-content')[0];
    }

    ngOnDestroy(): void {
        this.searchResultSubscriptions.forEach(searchResultSubscription => searchResultSubscription.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes['count'] && changes['count'].previousValue !== changes['count'].currentValue) ||
            (changes['maxResults'] && changes['maxResults'].previousValue !== changes['maxResults'].currentValue)
        ) {
            this.pageAmount = Math.ceil(this.count / this.maxResults);
            this.pages = [];
            for (let i = 0; i < this.pageAmount; i++) {
                this.pages.push({
                    page: this.firstPage + i,
                    pageRepresentation: this.firstPage + 1 + i,
                });
            }
        }
    }

    hasEnoughFacets() {
        let hasEnoughFacets: boolean = false;
        this._facetFields.forEach((value: FacetField[], key: string) => {
            if (value.length > 0) {
                hasEnoughFacets = true;
            }
        });
        return hasEnoughFacets;
    }

    noSearchResults(): boolean {
        return Boolean(this.searchResults) && this.searchResults.length === 0;
    }

    onSelect($event) {
        this.select.emit($event);
    }

    includePaging() {
        return this.pageAmount > 1;
    }

    //TODO remove this when no one is using (changePage) anymore
    onPage(page: number = 0, filters: string[] = []) {
        this.currentPage = page;
        this.changePage.emit({ page: page, filters: filters });
        $('.mat-drawer-content').scrollTop(0);
    }
}
