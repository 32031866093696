<ng-container *ngIf="showSubOutputs" [formGroup]="formGroup">
    <ng-container *ngFor="let output of subOutputs">
        <ng-container *ngIf="output.output.type !== 'DATA_FORMAT'">
            <div
                class="flex mb-1 mt-1 gap-1"
                [disabled]="!output?.output?.getDescription('en')"
                [showTrigger]="NgxFloatUiTriggers.hover"
                [floatUi]="argumentInformationPopover"
                [appendTo]="'body'"
                [boundariesElement]="'body'"
                [preventOverflow]="false"
                [positionFixed]="true"
                [applyClass]="'!max-w-[250px]'"
                [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ output?.output?.getName(language) }}</h4>
                <ng-container *ngIf="output?.output?.getDescription('en')">
                    <mat-icon class="help-icon" color="primary">help </mat-icon>
                </ng-container>
            </div>
            <float-ui-content #argumentInformationPopover
                ><div class="popover">{{ output?.output?.getDescription('en') }}</div></float-ui-content
            >
            <label [attr.for]="'output-name-' + contextId + output.outputId" class="small-input-label">Variable name</label>
            <input
                class="small-input"
                type="text"
                [attr.id]="'output-name-' + contextId + output.outputId"
                [attr.name]="'output-name-' + contextId + output.outputId"
                [attr.data-name]="'output-name-' + contextId + output.outputId"
                [formControlName]="output.outputId"
                autocomplete="off"
                cd-code />
        </ng-container>
        <ng-container *ngIf="output.output.type === 'DATA_FORMAT'">
            <app-dataformat-picker
                [value]="output.value"
                [required]="false"
                (valueUpdated)="onDataFormatChange($event, output)"
                (openTab)="this.openTab.emit($event)">
            </app-dataformat-picker>
        </ng-container>
    </ng-container>
</ng-container>
