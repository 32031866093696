import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import {
    actionDialogFacetsChanged,
    actionDialogFilterPluginsChanged,
    actionDialogPaginationChanged,
    actionDialogSearchTermChanged,
    clearActionDialogFilter,
    createActionSuccess,
    deleteAction,
    deleteActions,
    deleteActionsSuccess,
    deleteActionSuccess,
    fetchActionError,
    fetchActionSuccess,
    findAction,
    loadActionDialogData,
    loadActionDialogSuccess,
    updateAction,
    updateActionSuccess,
} from '../actions/action-editor.actions';
import { ActionService } from '../../../../../../editor/data-access/action/src/lib/services/action.service';
import { Action } from '../../action/model/action';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { actionEditorSelectors } from '../selectors/action-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import {
    calculateScope,
    fetchActionContextSuccess,
    fetchActionMethodsSuccess,
    findActionCalledActions,
    findActionContext,
    findActionMethod,
    updateActionScope,
    updateCalledActions,
} from '../actions/action-context.actions';
import { OverviewActionDto, toFilter } from '@backoffice/data-access/editor';
import { ActionContextFacade } from '../facades/action-context.facade';
import { ActionEditorFacade } from '../facades/action-editor.facade';
import { forkJoin, of } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { Page } from '@shared/data-access';
import { ActionContext } from '../../interfaces/action-context.interface';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';

@Injectable()
export class ActionEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly actionService: ActionService,
        private readonly actionContextFacade: ActionContextFacade,
        private readonly editorFacade: ActionEditorFacade,
        private readonly log: LoggerService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearActionDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActions),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.actionService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteActionsSuccess({ ids }))
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'action' }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'action' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAction),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.delete(id, companyId, applicationId).pipe(map(() => deleteActionSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findAction),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(actionEditorSelectors.byId(id)),
            ]),
            filter(([_action, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchActionSuccess({ action: detail }));
                } else {
                    return this.actionService.findById(id, companyId, applicationId).pipe(
                        map(result => fetchActionSuccess({ action: result })),
                        catchError(() => of(fetchActionError({ action: Action.createDeleted(id) })))
                    );
                }
            })
        )
    );

    fetchContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionContext),
            debounceTime(500),
            concatLatestFrom(({ action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_action, company, application]) => !!company && !!application),
            mergeMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.findContextById(action.id, companyId, applicationId).pipe(
                    take(1),
                    map(result =>
                        fetchActionContextSuccess({
                            action,
                            actionContext: result,
                        })
                    )
                )
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteActionsSuccess,
                loadActionDialogData,
                actionDialogPaginationChanged,
                actionDialogSearchTermChanged,
                actionDialogFacetsChanged,
                actionDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.actionService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewActionDto>) => loadActionDialogSuccess({ data }))
        )
    );

    recalculateMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionMethod),
            debounceTime(500),
            concatLatestFrom(({ action: Action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            mergeMap(([{ action }, company, application]) =>
                this.actionService.findMethodsByReferenceId(action.id, company.id, application.id).pipe(
                    take(1),
                    map(result =>
                        fetchActionMethodsSuccess({
                            action: action,
                            methods: result,
                        })
                    )
                )
            )
        )
    );

    recalculateCalledActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionCalledActions),
            debounceTime(500),
            map(({ action }) => {
                const calledActionIds = new Set<string>();
                action.program.invocations.forEach(invocation => {
                    if (invocation && invocation.invocationOutputs) {
                        invocation.invocationOutputs.forEach(invocationOutput => {
                            if (!!invocationOutput.subOutputsForAction && !invocationOutput.subOutputsForAction.startsWith('{')) {
                                calledActionIds.add(invocationOutput.subOutputsForAction);
                            }
                        });
                    }
                });
                return updateCalledActions({
                    id: action.id,
                    calledActions: Array.from(calledActionIds.values()),
                });
            })
        )
    );

    recalculateScope$ = createEffect(() =>
        this.actions$.pipe(
            ofType(calculateScope),
            debounceTime(500),
            concatLatestFrom(({ action }) => [
                this.actionContextFacade.getActionContext(action.id).pipe(
                    take(1),
                    filter((actionContext: ActionContext | undefined): actionContext is ActionContext => {
                        return !!actionContext && !!actionContext.actionCtxs && !!actionContext.methods && !!actionContext.language;
                    })
                ),
            ]),
            mergeMap(([{ action }, actionContext]) => {
                return this.actionContextFacade.getActionCalledActions(action.id).pipe(
                    take(1),
                    switchMap(calledActions => {
                        return this.actionContextFacade
                            .loadActionScope(action, actionContext.methods, calledActions, actionContext.actionCtxs, actionContext.language)
                            .pipe(
                                map(scope =>
                                    updateActionScope({
                                        id: action.id,
                                        scope,
                                    })
                                )
                            );
                    })
                );
            })
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAction),
            debounceTime(backofficeEnvironment.autosavedebounce),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([{ action }, company, application]) => action.isValid() && !!company && !!application),
            switchMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.update(action, companyId, applicationId).pipe(map(() => updateActionSuccess({ action })))
            )
        )
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createActionSuccess),
            map(({ id }) => registerTab({ definition: { type: 'action', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionSuccess),
            map(({ action }) => {
                const { id: typeId, name, iconName: icon } = action;
                return updateTab({ definition: { type: 'action', typeId, name, icon } });
            })
        )
    );
}
