import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataFormatAttribute } from '../../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';

@Component({
    selector: 'codex-string-type-settings',
    templateUrl: './string-type-settings.component.html',
    standalone: false,
})
export class StringTypeSettingsComponent implements OnInit {
    @Input() attribute: DataFormatAttribute;

    @Output() change: EventEmitter<void> = new EventEmitter();

    enum: string;

    ngOnInit() {
        this.enum = this.attribute?.enum?.join(',') || '';
    }
    handleEnumChange($event: any): void {
        const value = $event.target.value;
        if (value) {
            const enums = value.split(',');
            this.attribute.enum = enums;
        } else {
            this.attribute.enum = undefined;
        }
        this.change.emit();
    }
}
