<ng-container *ngFor="let argument of this._argument.subArguments">
    <argument-value-picker
        class="mb-1 block"
        [argument]="argument"
        [arguments]="this._argument.subArguments"
        [contextId]="contextId"
        [root]="root"
        [scope]="scope"
        [language]="language"
        [onlyLiteralValues]="onlyLiteralValues"
        (argumentUpdated)="onActionExecutionArgumentUpdated($event)"></argument-value-picker>
</ng-container>
