<ng-container *ngFor="let argument of _argument.subArguments; trackBy: identifyArgument">
    <argument-value-picker
        class="mb-1 block"
        [argument]="argument"
        [arguments]="_argument.subArguments"
        [contextId]="contextId"
        [scope]="scope"
        [root]="root"
        [language]="language"
        [onlyLiteralValues]="onlyLiteralValues"
        (openTab)="this.openTab.emit($event)"
        (argumentUpdated)="onArgumentUpdated($event)">
    </argument-value-picker>
</ng-container>
