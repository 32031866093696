import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { TabDefinition } from '@backoffice/data-access/editor';
import { Parameter } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-argument-action-prototype',
    templateUrl: './argument-action-prototype.component.html',
    styleUrls: [],
    standalone: false,
})
export class ArgumentActionPrototypeComponent implements OnInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument: Argument;

    @Input() contextId!: string;

    @Input() scope: Scope;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    prototypeArgument: Argument;
    actionArgument: Argument;

    ngOnInit(): void {
        this.initForm();
    }

    initForm(): void {
        this.prototypeArgument = new Argument();
        this.prototypeArgument.parameter = new Parameter();
        this.prototypeArgument.parameter.name = 'Prototoype';
        this.prototypeArgument.parameter.description = 'Prototoype';
        this.prototypeArgument.value = this._argument.contractValue;

        this.actionArgument = new Argument();
        this.actionArgument.parameter = new Parameter();
        this.actionArgument.parameter.name = 'Action';
        this.actionArgument.parameter.description = 'Action';
        this.actionArgument.parameter.type = 'ACTION';
        this.actionArgument.value = this._argument.value;
    }

    handleUpdatePrototypeArgument($event: { argument: Argument }): void {
        const { argument } = $event;
        this._argument.contractValue = argument.value;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    handleUpdateActionArgument($event: { argument: Argument }): void {
        const { argument } = $event;
        this._argument.value = argument.value;
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
