import { backofficeEnvironment } from '@shared/environment';
import { getClosestPartPositioning, Part, PartPositioning, ScreenTypeMaxWidth, ScreenTypeMinWidth } from '@backoffice/data-access/editor';
import { GridStyleDto } from '../interfaces/grid/grid.style.dto';

declare var jQuery: any;

export function removeGhostDrags(viewType: number) {
    if (backofficeEnvironment.multiselect) {
        const ghostContainer = document.getElementById('ghost-container-' + viewType);
        if (ghostContainer) {
            const dynamicGhosts = ghostContainer.querySelectorAll(':not(.ghost-drag-new-part)');
            dynamicGhosts.forEach(dynamicGhost => {
                dynamicGhost.remove();
            });
        }
        jQuery('#ghost-container-' + viewType + ' .ghost-drag-new-part').hide();
    } else {
        jQuery('#ghost-container-' + viewType + ' .ghost-drag-new-part').hide();
    }
}

export function createGhostDrags(
    currentPart: Part,
    selectedParts: Part[] | undefined,
    viewType: number,
    grid: GridStyleDto,
    scale: number,
    offsetX: number,
    offsetY: number
) {
    if (backofficeEnvironment.multiselect) {
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(viewType),
            ScreenTypeMaxWidth.get(viewType),
            currentPart.positions
        );
        var ghostContainer = document.getElementById('ghost-container-' + viewType);
        if (selectedParts) {
            selectedParts?.forEach(part => {
                const gridDropList = jQuery('#grid-drop-list-' + viewType).get(0);
                const partElement = jQuery('#grid-drop-list-' + viewType + ' #part-' + part?.id).get(0);
                const top = Math.round(
                    (offsetY +
                        partElement.getBoundingClientRect().top -
                        gridDropList.getBoundingClientRect().top +
                        gridDropList.scrollTop * scale) /
                        scale
                );
                const left = Math.round(
                    (offsetX +
                        partElement.getBoundingClientRect().left -
                        gridDropList.getBoundingClientRect().left +
                        gridDropList.scrollLeft * scale) /
                        scale
                );

                const ghostDrag = document.createElement('div');
                ghostDrag.classList.add('ghost-drag');
                ghostDrag.id = 'ghost-drag-' + viewType + '-' + part.id;
                ghostDrag.style.top = `${top}px`;
                ghostDrag.style.left = `${left}px`;
                ghostDrag.style.width = partElement.getBoundingClientRect().width / scale + 'px';
                ghostDrag.style.height = partElement.getBoundingClientRect().height / scale + 'px';
                ghostDrag.style.minWidth = part.getMinWidthFromPositioning(positioning, false, grid);
                ghostDrag.style.maxWidth = part.getMaxWidthFromPositioning(positioning, false, grid);
                ghostDrag.style.minHeight = part.getMinHeightFromPositioning(positioning, false, grid);
                ghostDrag.style.maxHeight = part.getMaxHeightFromPositioning(positioning, false, grid);
                ghostContainer?.appendChild(ghostDrag);
            });
        }
    } else {
        jQuery('#ghost-container-' + viewType + ' .ghost-drag-new-part').show();
        const positioning: PartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(viewType),
            ScreenTypeMaxWidth.get(viewType),
            currentPart.positions
        );
        jQuery('#ghost-container-' + viewType + ' .ghost-drag-new-part').css({
            width: currentPart.getWidthFromPositioning(positioning, false, grid),
            height: currentPart.getHeightFromPositioning(positioning, false, grid),
            'min-width': currentPart.getMinWidthFromPositioning(positioning, false, grid),
            'max-width': currentPart.getMaxWidthFromPositioning(positioning, false, grid),
            'min-height': currentPart.getMinHeightFromPositioning(positioning, false, grid),
            'max-height': currentPart.getMaxHeightFromPositioning(positioning, false, grid),
        });
    }
}
