import { Component, Input } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'argument-title',
    templateUrl: './argument-title.component.html',
    standalone: false,
})
export class ArgumentTitleComponent {
    @Input()
    argument!: Argument;

    @Input()
    contextId!: string;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
