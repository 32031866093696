import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Role } from '@usermanagement/models/role.model';
import { Group } from '@usermanagement/models/group.model';
import { RightDto } from '../../../../../../../data-access/editor/src/lib/right/dto/right.dto';

@Component({
    selector: 'codex-edit-role-form',
    templateUrl: './edit-role-form.component.html',
    styleUrls: ['./edit-role-form.component.scss'],
    standalone: false,
})
export class EditRoleFormComponent implements OnInit {
    @Input() selectedRole: RightDto;

    @Output() save = new EventEmitter<RightDto>();

    @Output() cancel = new EventEmitter<void>();

    form: FormGroup;

    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    constructor(private readonly fb: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.selectedRole.name, [Validators.required]],
            description: [this.selectedRole.description],
        });
    }

    onCancelClicked(): void {
        this.cancel.emit();
    }

    onSaveClicked(): void {
        this.save.emit({
            ...this.selectedRole,
            ...this.form.value,
        });
    }
}
