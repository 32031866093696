<div class="p-4 max-h-[60vh] scrollbar scrollbar-primary bg-dark-background_level_0 rounded-lg">
    <h1 class="!mb-4">{{ 'v2.parameter.add.list' | translate }}</h1>
    <ul class="item-list">
        <ng-container *ngFor="let missingParameterInfoItem of missingParameterInfo">
            <li class="justify-start gap-1 !h-20">
                <div class="grow">
                    <div>
                        <div>
                            <strong>{{ missingParameterInfoItem.parameter.name }} ({{ missingParameterInfoItem.parameter.type }}):</strong>
                        </div>
                        <div>{{ missingParameterInfoItem.parameter.description }}</div>
                    </div>
                    <div class="text-xs">
                        <a
                            class="cursor-pointer underline hover:text-primary"
                            (click)="onOpenTemplate(missingParameterInfoItem.template)"
                            >{{ missingParameterInfoItem.template.name }}</a
                        >
                    </div>
                </div>
                <div
                    [matTooltip]="
                        (this.parametersToAdd.indexOf(missingParameterInfoItem.parameter) === -1
                            ? 'v2.parameter.add.param'
                            : 'v2.parameter.remove.param'
                        ) | translate
                    ">
                    <button
                        class="primary-button button-extra-small"
                        *ngIf="this.parametersToAdd.indexOf(missingParameterInfoItem.parameter) === -1"
                        (click)="addParameter(missingParameterInfoItem.parameter)">
                        <mat-icon>input</mat-icon>
                    </button>
                    <button
                        class="destructive-button button-extra-small"
                        *ngIf="this.parametersToAdd.indexOf(missingParameterInfoItem.parameter) > -1"
                        (click)="removeParameter(missingParameterInfoItem.parameter)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </li>
        </ng-container>
    </ul>

    <div class="flex gap-1 mt-3 mb-3 justify-end h-9">
        <button class="primary-button button-large" data-name="save" type="button" (click)="addParameters()">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-large" data-name="cancel" type="button" (click)="onCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>
