import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Parameter } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-select-parameter',
    templateUrl: './select-parameter.component.html',
    standalone: false,
})
export class SelectParameterComponent {
    _parameters: Parameter[] = [];

    @Input() title: string;

    @Input() selectedParameterId!: string | undefined;

    @Input() set root(value: Parameter[]) {
        const parameters: Parameter[] = [];
        this.parseParameters(value, parameters);
        this._parameters = parameters;
    }

    @Output() changeParameter: EventEmitter<{ selectedParameterId: string }> = new EventEmitter();

    onChange() {
        this.changeParameter.emit({ selectedParameterId: this.selectedParameterId });
    }

    private parseParameters(parents: Parameter[], parsed: Parameter[]): void {
        if (!parents) {
            return;
        }

        for (const parent of parents) {
            parsed.push(parent);
            if (parent.parameters) {
                this.parseParameters(parent.parameters, parsed);
            }
        }
    }
}
