<ng-container *ngIf="_argument && dataFormatAttributes">
    <select
        [attr.id]="'select-dataformat-attribute-' + _argument.id"
        [attr.name]="'select-dataformat-attribute-' + _argument.id"
        class="small-input"
        [attr.placeholder]="'v2.action.argument.dataformat.attribute.select' | translate"
        [(ngModel)]="this._argument.value"
        (ngModelChange)="onDataFormatAttributeSelected($event)">
        <option
            *ngFor="let dataFormatAttribute of dataFormatAttributes; trackBy: identifyAttribute"
            [value]="'\'' + dataFormatAttribute.name + '\''">
            {{ dataFormatAttribute.name }}
        </option>
    </select>
</ng-container>
