<mat-accordion *ngIf="showSubArguments" class="inner-accordion">
    <ng-container *ngFor="let argument of this._argument.subArguments">
        <mat-expansion-panel
            [expanded]="argument.parameterId === this.openedSubArgument"
            (opened)="this.openedSubArgument = argument.parameterId"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ argument.id }}
                    <button
                        class="deleteButton"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.dataformatsortorder.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-button-toggle-group [(ngModel)]="selectedSelectionType" #group="matButtonToggleGroup">
                <mat-button-toggle
                    value="scope"
                    [matTooltip]="'v2.action.argument.type.scope' | translate"
                    (change)="selectedSelectionType = $event.value">
                    <mat-icon>integration_instructions</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle
                    value="string"
                    [matTooltip]="'v2.action.argument.type.string' | translate"
                    (change)="selectedSelectionType = $event.value">
                    <mat-icon>abc</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle
                    value="data-format-attributes"
                    [matTooltip]="'v2.action.argument.type.data-format-attributes' | translate"
                    (change)="selectedSelectionType = $event.value">
                    <mat-icon>filter_alt</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <ng-container *ngIf="group.value === 'scope'">
                <argument-scope-picker [argument]="argument" (argumentUpdated)="this.argumentUpdated.emit($event)"> </argument-scope-picker>
            </ng-container>
            <ng-container *ngIf="group.value === 'string'">
                <argument-string-input [argument]="_argument" (argumentUpdated)="this.argumentUpdated.emit($event)">
                </argument-string-input>
            </ng-container>
            <ng-container *ngIf="group.value === 'data-format-attribute'">
                <argument-dataformat-attribute-picker
                    [argument]="_argument"
                    [root]="root"
                    [language]="language"
                    (argumentUpdated)="this.argumentUpdated.emit($event)">
                </argument-dataformat-attribute-picker>
            </ng-container>

            <label for="select-order" class="small-input-label">{{ 'v2.action.argument.dataformatsortorder.order' | translate }}</label>
            <select
                id="select-order"
                class="small-input"
                [(ngModel)]="argument.order"
                (ngModelChange)="onDataFormatSortOrderOrderSelected($event, argument)">
                <option value="ASC">{{ 'v2.action.argument.dataformatsortorder.order.asc' | translate }}</option>
                <option value="DESC">{{ 'v2.action.argument.dataformatsortorder.order.desc' | translate }}</option>
            </select>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="add-button nocodexbutton" type="button" mat-raised-button color="primary" (click)="onAddFilter()">
    <mat-icon>add</mat-icon>
    <span>{{ 'v2.action.argument.dataformatsortorder.add.sortorder' | translate }}</span>
</button>
