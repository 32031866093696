import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Argument, EditorFacade, Output, Parameter, showArgumentInput } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';
import { ActionTestOutputAssertion } from '../../../../../../../data-access/editor/src/lib/action/dto/action-test-output-assertion.dto';

@Component({
    selector: 'codex-action-create-assertion',
    templateUrl: './action-create-assertion.component.html',
    standalone: false,
})
export class ActionCreateAssertionComponent {
    outputs: Output[];

    chosenOutputId: string;

    argument: Argument;

    contextId: string;

    assertion: ActionTestOutputAssertion;
    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public editorFacade: EditorFacade,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ActionCreateAssertionComponent>
    ) {
        this.outputs = this.data.outputs;
        if (this.data.assertion) {
            this.setAssertion(this.data.assertion);
        }
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
    protected readonly showArgumentInput = showArgumentInput;

    setAssertion(assertion: ActionTestOutputAssertion) {
        this.assertion = assertion;
        this.chosenOutputId = this.assertion.output.id;
        this.onSelectedOutput();
        this.argument.value = this.assertion.output.value;
    }
    onSelectedOutput() {
        let chosenOutput = this.outputs.find(output => output.id === this.chosenOutputId);
        if (chosenOutput) {
            let arg = new Argument();
            arg.parameter = new Parameter();
            arg.parameter.type = chosenOutput.type;
            arg.inputSelectionType = chosenOutput.inputType;
            arg.subArguments = [];
            this.argument = arg;
        }
    }

    onSaveAssertion() {
        let chosenOutput = this.outputs.find(output => output.id === this.chosenOutputId);
        setTimeout(() => {
            this.dialogRef.close({
                id: this.assertion?.id,
                output: chosenOutput,
                value: this.argument.value,
            });
        }, backofficeEnvironment.inputdebounce);
    }

    onCancelAssertion() {
        this.dialogRef.close();
    }
}
