import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
    ColorV2,
    DesignSystem,
    FontFamily,
    FontSize,
    FontStyle,
    FontWeight,
    Hex,
    LetterSpacing,
    Typography,
} from '@backoffice/data-access/editor';

@Component({
    selector: 'app-design-system-typography',
    templateUrl: './design-system-typography.component.html',
    styleUrls: ['./design-system-typography.component.scss'],
    standalone: false,
})
export class DesignSystemTypographyComponent implements OnInit, OnDestroy {
    @Input() designSystem: DesignSystem;
    @Input() title: string;

    @Input() set typography(value: Typography) {
        this._typography = value;
        if (!!value) {
            this.patchForm(this._typography, false);
        }
    }

    @Output() typographyChanged = new EventEmitter<Typography>();

    form = this.fb.group({
        fontSize: this.fb.group({
            value: [16, Validators.required],
            unit: ['px', Validators.required],
        }),
        fontWeight: [400, [Validators.required]],
        fontFamily: ['', [Validators.required]],
        italic: [false, [Validators.required]],
        letterSpacing: [0, [Validators.required]],
        color: [ColorV2.ofHex(new Hex('#ffffff', [])), [Validators.required]],
    });
    _typography: Typography;

    private readonly subscriptions = new Subscription();

    constructor(private readonly fb: NonNullableFormBuilder) {}

    ngOnInit() {
        this.subscriptions.add(this.form.valueChanges.subscribe(() => this.handleTypographyChanged()));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    handleTypographyChanged(): void {
        const { fontSize, fontFamily, fontWeight, italic, color, letterSpacing } = this.form.getRawValue();

        const updatedTypography = new Typography(this._typography);

        updatedTypography.fontSize = new FontSize(fontSize.value, 'px');
        updatedTypography.fontFamily = new FontFamily([fontFamily], this._typography.fontFamily.genericFamilyName);
        updatedTypography.fontWeight = new FontWeight(fontWeight, this._typography.fontWeight.keyword);
        updatedTypography.fontStyle = new FontStyle(italic ? 'ITALIC' : 'NORMAL', this._typography.fontStyle.angle);
        updatedTypography.letterSpacing = new LetterSpacing(letterSpacing, 'px');
        updatedTypography.color = color;

        this.typographyChanged.emit(updatedTypography);
    }

    getFontColor(): string {
        const color = this.form.value.color;
        if (color) {
            if (color.reference) {
                const colorDefinition = this.designSystem.dark.findColorByVariable(color.reference.variable);
                return colorDefinition.value;
            } else {
                return color.hex ? color.hex.value : '#ffffff';
            }
        }
        return '#ffffff';
    }

    private patchForm(typography: Typography, emit = false): void {
        const { fontSize, fontFamily, fontWeight, fontStyle, letterSpacing, color } = typography;
        this.form.patchValue(
            {
                fontSize: { value: fontSize.value, unit: fontSize.unit === 'px' ? 'pixels' : fontSize.unit },
                fontFamily: !fontFamily.fontFamilyNames ? '' : fontFamily.fontFamilyNames[0],
                fontWeight: fontWeight.value,
                italic: fontStyle.keyword === 'ITALIC',
                letterSpacing: letterSpacing.value,
                color,
            },
            { emitEvent: emit }
        );
    }
}
