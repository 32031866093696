<mat-accordion
    class="inner-accordion"
    cdkDropList
    (cdkDropListDropped)="reorderSubArguments($event)"
    [cdkDropListData]="this._argument.subArguments">
    <ng-container *ngFor="let argument of this._argument.subArguments; let i = index; trackBy: identifyArgument">
        <mat-expansion-panel
            cdkDrag
            [expanded]="i === this.openedSubArgument"
            (opened)="this.openedSubArgument = i"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title class="flex">
                    <span class="leading-6 grow">{{ argument.composedTitle ? (argument.composedTitle | truncate: 25) : '' }}</span>
                    <button cdkDragHandle class="!p-0.5 flex-none" mat-icon-button [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                        <mat-icon>import_export</mat-icon>
                    </button>
                    <div *cdkDragPreview class="border bg-primarylight border-primary w-[100%] !h-9 rounded"></div>
                    <button
                        class="!p-0.5 flex-none"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.object.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <argument-composed
                [argument]="argument"
                [arguments]="_arguments"
                [scope]="scope"
                [root]="root"
                [contextId]="contextId"
                [language]="language"
                (openTab)="this.openTab.emit($event)"
                (argumentUpdated)="onArgumentUpdated($event)">
            </argument-composed>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="w-[100%] mt-1 mb-1 !h-9" type="button" mat-raised-button color="primary" (click)="onAddArgument()">
    <mat-icon>add</mat-icon>
    <span>{{ _argument.parameter?.addComposedButtonText }}</span>
</button>
