import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    ActionCtx,
    ActionEditFacade,
    Argument,
    DataFormat,
    DataFormatEditorFacade,
    Parameter,
    ParameterOption,
    showArgumentInput,
    TabDefinition,
} from '@backoffice/data-access/editor';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-action-edit-parameter',
    templateUrl: './parameter.component.html',
    styleUrls: ['./parameter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ParameterComponent implements OnInit, OnDestroy {
    @Input() root: Parameter[];
    @Input() parameter!: Parameter;
    @Input() parameters!: Parameter[];
    @Input() language!: string;
    @Input() context!: ActionCtx[];

    @Output() parameterUpdated: EventEmitter<{ parameter: Parameter }> = new EventEmitter<{ parameter: Parameter }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    chosenDataFormat!: DataFormat;

    subFormats: string[] = [];

    form = this.fb.group({
        name: ['', [Validators.required, createReservedWordsValidator()]],
        description: [''],
        type: ['', Validators.required],
        inputType: [''],
        subformat: [''],
        composedButtonText: [''],
    });

    private readonly _subscriptions = new Subscription();

    parameterDefaultArgument!: Argument;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private actionEditFacade: ActionEditFacade,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnInit(): void {
        this.form.patchValue({
            name: this.parameter.name,
            description: this.parameter.description,
            type: this.parameter.type,
            inputType: this.parameter.inputType,
            subformat: this.parameter.subTypePath,
            composedButtonText: this.parameter.addComposedButtonText,
        });

        if (!!this.parameter.type) {
            this.form.get('type')?.disable();
        }

        this.initForm();
        this.initializeSelectorId();
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.inputdebounce + 100);
    }

    initForm(): void {
        if (
            this.parameter.type === 'DATA' ||
            this.parameter.type === 'DATA_BODY' ||
            this.parameter.type === 'PARTIAL_DATA' ||
            this.parameter.type === 'OBJECT' ||
            this.parameter.type === 'ARRAY'
        ) {
            if (this.parameter.subTypeParameterId) {
                this._subscriptions.add(
                    this.dataFormatEditorFacade
                        .findById(this.parameter.subTypeParameterId)
                        .pipe(take(1))
                        .subscribe(dataformat => {
                            this.subFormats = dataformat.getSubFormats();
                            this._subscriptions.add(
                                this.form.controls.subformat.valueChanges
                                    .pipe(
                                        debounceTime(backofficeEnvironment.inputdebounce),
                                        map(d => d ?? '')
                                    )
                                    .subscribe(value => this.onSubFormatChange(value))
                            );
                        })
                );
            }
        }

        this._subscriptions.add(
            this.form.controls.name.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(name => name ?? '')
                )
                .subscribe(value => this.onNameChange(value))
        );
        this._subscriptions.add(
            this.form.controls.description.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(description => description ?? '')
                )
                .subscribe(value => this.onDescriptionChange(value))
        );
        this._subscriptions.add(
            this.form.controls.type.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(type => type ?? '')
                )
                .subscribe(value => this.onTypeChange(value))
        );

        this._subscriptions.add(
            this.form.controls.composedButtonText.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(composedButtonText => composedButtonText ?? '')
                )
                .subscribe(value => this.onComposedButtonTextChange(value))
        );

        this._subscriptions.add(
            this.form.controls.inputType.valueChanges
                .pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onInputTypeChange(value))
        );

        this._subscriptions.add(
            this.form.controls.subformat.valueChanges
                .pipe(
                    debounceTime(backofficeEnvironment.inputdebounce),
                    map(subFormat => subFormat ?? '')
                )
                .subscribe(value => this.onSubFormatChange(value))
        );

        this.parameterDefaultArgument = new Argument();
        this.updateDefaultArgument();
    }

    updateDefaultArgument() {
        this.parameterDefaultArgument.type = this.parameter.type;
        this.parameterDefaultArgument.value = this.parameter.defaultValue?.value;
        this.parameterDefaultArgument.inputSelectionType = this.parameter.defaultValue?.inputSelectionType;
        this.parameterDefaultArgument.parameterId = this.parameter.id;
        this.parameterDefaultArgument.selectorId = this.parameter.selectorId;
        this.parameterDefaultArgument.parameter = Object.assign({}, this.parameter, { name: 'Default value', defaultValue: null });
    }

    onDefaultArgumentUpdated($event: { argument: Argument }) {
        this.parameter.defaultValue = $event.argument;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onDataFormatChange($event: { value: string | undefined }) {
        this.parameter.subTypeParameterId = $event.value;
        if (!!this.parameter.subTypeParameterId) {
            this._subscriptions.add(
                this.dataFormatEditorFacade
                    .findById(this.parameter.subTypeParameterId)
                    .pipe(take(1))
                    .subscribe(dataformat => {
                        this.chosenDataFormat = dataformat;
                        if (!!dataformat) {
                            this.subFormats = dataformat.getSubFormats();
                        }
                        this.changeDetectorRef.detectChanges();
                    })
            );
            this.parameterUpdated.emit({ parameter: this.parameter });
        }
    }

    initializeSelectorId(): void {
        if (!this.parameter.selectorId) {
            this.parameter.selectorId = new GUIDFunctions().newGuid();
            this.parameterUpdated.emit({ parameter: this.parameter });
        }
    }

    onSubFormatChange($event: string) {
        this.parameter.subTypePath = $event;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onNameChange($event: string) {
        this.parameter.name = $event;
        this.updateDefaultArgument();
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onDescriptionChange($event: string) {
        this.parameter.description = $event;
        this.updateDefaultArgument();
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onTypeChange($event: string) {
        this.parameter.type = $event;

        this.updateDefaultArgument();
        if (!!this.parameter.type) {
            this.form.get('type')?.disable({ emitEvent: false, onlySelf: true });
        }
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onComposedButtonTextChange($event: string) {
        this.parameter.addComposedButtonText = $event;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onInputTypeChange($event: string | null) {
        this.parameter.inputType = $event;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onHideIfEmptyParameterIdsChange($event: { selectedParameters: { parameterId?: string; name?: string }[] }) {
        this.parameter.hideIfEmptyParameterIds = [];
        if ($event.selectedParameters) {
            $event.selectedParameters.forEach(selectedParameter => {
                if (selectedParameter.parameterId) {
                    this.parameter.hideIfEmptyParameterIds.push(selectedParameter.parameterId);
                }
            });
        }
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onChangeLinkedDataFormatParameterId($event: { selectedParameterId: string }) {
        this.parameter.linkedDataFormatParameterId = $event.selectedParameterId;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onChangeLinkedActionParameterId($event: { selectedParameterId: string }) {
        this.parameter.linkedActionParameterId = $event.selectedParameterId;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onChangeComposedTitleSubArgumentId($event: { selectedParameterId: string }) {
        this.parameter.composedTitleSubArgumentId = $event.selectedParameterId;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onChangeLinkedTemplateParameterId($event: { selectedParameterId: string }) {
        this.parameter.linkedTemplateParameterId = $event.selectedParameterId;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onSubParametersUpdated($event: { parameters: Parameter[] }) {
        this.parameter.parameters = $event.parameters;
        this.parameterUpdated.emit({ parameter: this.parameter });
    }

    onOptionsUpdated($event: { options: ParameterOption[] }) {
        this.parameter.options = $event.options;
        this.updateDefaultArgument();
        this.parameterUpdated.emit({ parameter: this.parameter });
        this.changeDetectorRef.detectChanges();
        setTimeout(() => initFlowbite());
    }

    protected readonly showArgumentInput = showArgumentInput;
    protected readonly backofficeEnvironment = backofficeEnvironment;
}

const reservedWords: string[] = ['template'];

export function createReservedWordsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        return reservedWords.indexOf(value) > 0 ? { reservedWord: true } : null;
    };
}
