import { ColorV2 } from '@shared/data-access';
import { generateColorStyleV2 } from './color.style';

export interface ShadowStyleDto {
    inset?: boolean;
    offsetX?: string;
    offsetY?: string;
    blurRadius?: string;
    spreadRadius?: string;
    color?: string;
    colorV2?: ColorV2;
}

export function generateShadowStyleWithVars(prefix: string, shadowStyleDto: ShadowStyleDto | null | undefined) {
    let style = '';
    if (shadowStyleDto) {
        style += '--' + prefix + '-box-shadow:';
        if (shadowStyleDto.offsetX) {
            style += shadowStyleDto.offsetX + ' ';
        } else {
            style += 'none' + ' ';
        }
        if (shadowStyleDto.offsetY) {
            style += shadowStyleDto.offsetY + ' ';
        } else {
            style += 'none' + ' ';
        }

        if (shadowStyleDto.blurRadius) {
            style += shadowStyleDto.blurRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.spreadRadius) {
            style += shadowStyleDto.spreadRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.colorV2) {
            style += generateColorStyleV2(shadowStyleDto.colorV2) + ' ';
        } else if (shadowStyleDto.color) {
            style += shadowStyleDto.color + ' ';
        }

        if (shadowStyleDto.inset) {
            style += 'inset ';
        }

        style += ';';
    }
    return style;
}

export function generateResetShadowStyleWithVars(prefix: string, shadowStyleDto: ShadowStyleDto | null | undefined) {
    let style = '';
    if (shadowStyleDto) {
        style += '--' + prefix + '-box-shadow: initial;';
    }
    return style;
}

export function generateShadowStyle(shadowStyleDto: ShadowStyleDto | null | undefined) {
    let style = '';
    if (shadowStyleDto) {
        style += 'box-shadow:';
        if (shadowStyleDto.offsetX) {
            style += shadowStyleDto.offsetX + ' ';
        } else {
            style += 'none' + ' ';
        }
        if (shadowStyleDto.offsetY) {
            style += shadowStyleDto.offsetY + ' ';
        } else {
            style += 'none' + ' ';
        }

        if (shadowStyleDto.blurRadius) {
            style += shadowStyleDto.blurRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.spreadRadius) {
            style += shadowStyleDto.spreadRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.color) {
            style += shadowStyleDto.color + ' ';
        }

        if (shadowStyleDto.inset) {
            style += 'inset ';
        }

        style += ';';
    }
    return style;
}
