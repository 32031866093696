import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-array',
    templateUrl: './argument-array.component.html',
    standalone: false,
})
export class ArgumentArrayComponent {
    @Input() arguments: Argument[];

    @Input() argument: Argument;

    @Input() contextId: string;
    @Input() language: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();
}
