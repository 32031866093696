import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'codex-application-order-ai-credits',
    templateUrl: './application-order-ai-credits.component.html',
    standalone: false,
})
export class ApplicationOrderAiCreditsComponent {
    amountOfCredits: number = 10000;
    couponCode: string;

    constructor(private readonly dialogRef: MatDialogRef<ApplicationOrderAiCreditsComponent>) {}

    initiateBuy() {
        this.dialogRef.close({
            success: true,
            amount: this.amountOfCredits,
            couponCode: this.couponCode,
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
