import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Facet } from '../../../../../../../shared/data-access/src/lib/model/facet.model';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { initFlowbite } from 'flowbite';
import { selectedCompanyLanguageReducer } from '../../../../../../feature/company/edit-company/src/lib/statemanagement/reducer/company.reducer';

@Component({
    selector: 'app-filter-plugins',
    templateUrl: './filter-plugins.component.html',
    standalone: false,
})
export class FilterPluginsComponent implements OnInit, AfterViewInit {
    @Input()
    label: string;

    @Input()
    filterPlugins: boolean;

    @Input()
    filterPrototypes: boolean;

    @Output() selectionChanged = new EventEmitter<boolean>();

    formGroup: FormGroup;

    @Input() facets: { [key: string]: [Facet] };
    @Input() prefix: string | undefined;
    @Input() selectedValues = new SelectedFacets();

    @Output() facetSelectionChanged = new EventEmitter<SelectedFacets>();

    protected readonly Object = Object;

    constructor(private readonly fb: FormBuilder) {}

    ngOnInit() {
        this.initForm();
    }

    ngAfterViewInit() {
        initFlowbite();
    }

    initForm() {
        this.formGroup = this.fb.group({
            filterPlugins: [!this.filterPlugins],
        });

        this.formGroup.get('filterPlugins')?.valueChanges.subscribe(value => {
            this.selectionChanged.emit(!value);
        });
    }

    onCheckboxChanged(facet: string, value: string): void {
        if (!this.selectedValues.has(facet)) {
            this.selectedValues.set(facet, value);
        } else {
            this.selectedValues.delete(facet);
        }
        this.facetSelectionChanged.emit(this.selectedValues);
    }

    shouldShowFacets(facets: { [key: string]: [Facet] }) {
        let showFacets = false;
        if (facets) {
            if (this.selectedValues && this.selectedValues.size > 0) {
                return true;
            }
            if (Object.keys(facets)) {
                const keys = Object.keys(facets);
                for (const key of keys) {
                    if (facets[key] && facets[key].length > 0) {
                        for (const facet of facets[key]) {
                            if (
                                facet.count > 0 ||
                                (key &&
                                    !!this.selectedValues &&
                                    this.selectedValues.has(key) &&
                                    !!this.selectedValues.get(key) &&
                                    this.selectedValues.get(key)?.indexOf(facet.name) > -1)
                            ) {
                                showFacets = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return showFacets;
    }

    protected readonly selectedCompanyLanguageReducer = selectedCompanyLanguageReducer;
}
