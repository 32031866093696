<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <h1>{{ 'v2.action.scope.all.items' | translate }}</h1>
    <ng-container *ngFor="let scopeItem of scopeItems">
        <div class="scopeItem text-white">
            <div class="scopeItemDescription">
                <div>
                    <strong>{{ scopeItem.name }}:</strong> ({{ scopeItem.type }})
                </div>
                <div>{{ scopeItem.description }}</div>
            </div>
            <div
                class="scopeItemActions"
                [matTooltipClass]="isEligableToUse(scopeItem) ? '' : 'warn-tooltip'"
                [matTooltip]="
                    isEligableToUse(scopeItem)
                        ? ('v2.action.scope.use.item' | translate)
                        : ('v2.action.scope.cannot.use.because.type' | translate)
                ">
                <button [disabled]="!isEligableToUse(scopeItem)" mat-icon-button (click)="useScopeItem(scopeItem)">
                    <mat-icon>input</mat-icon>
                </button>
            </div>
        </div>
        <hr class="seperator" />
    </ng-container>
</mat-dialog-content>
