import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {} from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
    DataFormat,
    Parameter,
    Scope,
    TabDefinition,
    Argument,
    JsonProperty,
    DataFormatEditorFacade,
    JsonArrayItem,
} from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { initFlowbite } from 'flowbite';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-array-dataformat',
    templateUrl: './argument-array-dataformat.component.html',
    standalone: false,
})
export class ArgumentArrayDataformatComponent implements AfterViewInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadDataFormat();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language: string;
    @Input() contextId: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    arrayItem!: JsonArrayItem | undefined;

    arrayProperty!: JsonProperty | undefined;

    openedSubArgument!: number;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
        setTimeout(() => initFlowbite());
    }

    onAddArrayItem() {
        const subArgument = new Argument();
        subArgument.id = new GUIDFunctions().newGuid();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.selectorId = new GUIDFunctions().newGuid();

        subArgument.type = this.mapToParamTypes(this.arrayItem?.type[0]);
        subArgument.parameter = new Parameter();

        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        subArgument.parameter.type = this.mapToParamTypes(this.arrayItem?.type[0]);
        subArgument.parameter.inputType = 'dataformat';
        subArgument.parameter.subTypeParameterId = this._argument?.parameter?.subTypeParameterId;
        subArgument.parameter.subTypePath = [this._argument?.parameter?.subTypePath, 'items'].join('.');

        this._argument.subArguments.push(subArgument);

        this.argumentUpdated.emit({ argument: this._argument });
        this.openedSubArgument = -1;
        this.changeDetectorRef.detectChanges();
        this.openedSubArgument = this._argument.subArguments.length - 1;
        setTimeout(() => initFlowbite());
    }

    loadDataFormat() {
        if (this._argument) {
            if (this._argument.parameter.subTypeParameterId && this._argument.parameter.subTypeParameterId !== this.dataFormatId) {
                this.dataFormatId = this._argument.parameter.subTypeParameterId;
                this.dataFormatEditorFacade.findById(this._argument.parameter.subTypeParameterId).subscribe(dataFormat => {
                    this.dataFormat = dataFormat;
                    const dataSchema = dataFormat.jsonSchema;
                    if (!!dataSchema.properties) {
                        this.identifyArrayItem(dataSchema.properties);
                    }
                    this.mergeArguments();
                    this.showSubArguments = true;
                    this.changeDetectorRef.detectChanges();
                });
            }
        }
    }

    identifyArrayItem(properties: { [key: string]: JsonProperty }): JsonArrayItem | undefined {
        if (this._argument.parameter?.subTypePath) {
            const pathParts = this._argument.parameter?.subTypePath.split('.');
            let currentArrayItem: JsonArrayItem | undefined = undefined;
            let currentArrayProperty: { [key: string]: JsonProperty } | undefined = properties;
            for (const pathPart of pathParts) {
                if (currentArrayProperty && currentArrayProperty[pathPart]) {
                    if (currentArrayProperty[pathPart]?.properties && currentArrayProperty[pathPart].type.indexOf('object') > -1) {
                        currentArrayProperty = currentArrayProperty[pathPart].properties;
                    } else if (currentArrayProperty[pathPart]?.items && currentArrayProperty[pathPart].type.indexOf('array') > -1) {
                        currentArrayItem = currentArrayProperty[pathPart].items;
                        currentArrayProperty = currentArrayProperty[pathPart].items?.properties;
                    }
                }
            }
            this.arrayItem = currentArrayItem;
        }
        return undefined;
    }

    mergeArguments() {
        this._argument.subArguments?.forEach(subArgument => {
            if (this.arrayItem) {
                subArgument.parameter = new Parameter();
                subArgument.parameter.type = this.mapToParamTypes(this.arrayItem?.type[0]);
                if (subArgument.type === 'OBJECT' || subArgument.type === 'ARRAY') {
                    subArgument.parameter.inputType = 'dataformat';
                    subArgument.parameter.subTypeParameterId = this._argument?.parameter?.subTypeParameterId;
                    subArgument.parameter.subTypePath = [this._argument?.parameter?.subTypePath, 'items'].join('.');
                }
            } else {
                // Remove this argument.
            }
        });
    }

    mapToParamTypes(type: string | undefined) {
        if (type === 'string') {
            return 'STRING';
        } else if (type === 'array') {
            return 'ARRAY';
        } else if (type === 'number') {
            return 'NUMBER';
        } else if (type === 'integer') {
            return 'NUMBER';
        } else if (type === 'object') {
            return 'OBJECT';
        } else if (type === 'boolean') {
            return 'BOOLEAN';
        }
        return 'OBJECT';
    }

    identifyArgument(index: number, item: Argument): number | undefined {
        return index;
    }

    reorderSubArguments(event: CdkDragDrop<Argument[]>): void {
        if (this._argument.subArguments) {
            moveItemInArray(this._argument.subArguments, event.previousIndex, event.currentIndex);
            this.argumentUpdated.emit({ argument: this._argument });
        }
    }
}
