import 'reflect-metadata';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'not-found',
    styleUrls: ['./not-found-page.component.scss'],
    templateUrl: 'not-found-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class NotFoundPageComponent {
    constructor(public router: Router) {}
}
