<form autocomplete="off">
    <ng-container *ngFor="let argument of invocation.arguments; trackBy: identifyArgument">
        <argument-value-picker
            class="mb-1 block"
            *ngIf="showArgumentInput(argument, invocation.arguments)"
            [argument]="argument"
            [arguments]="invocation.arguments"
            [contextId]="invocation.id"
            [language]="language"
            [root]="invocation.arguments"
            [scope]="_scope"
            [onlyLiteralValues]="onlyLiteralValues"
            (argumentUpdated)="onArgumentUpdated($event)"
            (openTab)="openTab.emit($event)"></argument-value-picker>
    </ng-container>
</form>
