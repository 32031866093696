import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument, DataFormat, DataFormatEditorFacade, Parameter, Scope } from '@backoffice/data-access/editor';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { backofficeEnvironment } from '@shared/environment';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-dataformat-filters',
    templateUrl: './argument-dataformat-filters.component.html',
    styleUrls: ['./argument-dataformat-filters.component.scss'],
    standalone: false,
})
export class ArgumentDataformatFiltersComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadDataFormat();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input()
    language!: string;

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    dataFormatArgument!: Argument;

    showSubArguments: boolean = false;

    dataFormatId!: string;

    dataFormat!: DataFormat;

    dataFormatAttributes!: Array<{ name: string; type: string[]; description: string | undefined }>;

    openedSubArgument!: string;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(subArgument => {
            subArgument.parameter = new Parameter();
            const dataFormatAttributeFilter = this.dataFormatAttributes.find(
                dataFormatAttribute => dataFormatAttribute.name === subArgument.id
            );
            if (dataFormatAttributeFilter) {
                if (subArgument.operator === 'IN' || subArgument.operator === 'NOT_IN') {
                    subArgument.parameter.type = 'ARRAY';
                    subArgument.parameter.subType = this.translateType(dataFormatAttributeFilter.type);
                } else {
                    subArgument.parameter.type = this.translateType(dataFormatAttributeFilter.type);
                }
            } else {
                subArgument.parameter.type = 'DATAFORMAT_FILTER';
            }
        });
    }

    loadDataFormat() {
        if (this._argument) {
            this.dataFormatArgument = this._arguments.find(
                argument =>
                    argument.parameterId === this._argument?.parameter?.linkedDataFormatParameterId ||
                    argument.selectorId === this._argument.parameter?.linkedDataFormatParameterId
            );
            if (this.dataFormatArgument && this.dataFormatArgument.value !== this.dataFormatId) {
                this.dataFormatId = this.dataFormatArgument.value;
                if (!!this.dataFormatId && !this.dataFormatId.startsWith('{')) {
                    this.dataFormatEditorFacade
                        .findById(this.removeQuotesIfPresent(this.dataFormatArgument.value))
                        .subscribe(dataFormat => {
                            this.dataFormat = dataFormat;
                            const dataSchema = dataFormat.jsonSchema;
                            this.dataFormatAttributes = [];
                            if (!!dataSchema.properties) {
                                Object.keys(dataSchema.properties).forEach(dataPropertyName => {
                                    if (dataSchema.properties) {
                                        const dataProperty = dataSchema.properties[dataPropertyName];
                                        this.dataFormatAttributes.push({
                                            name: dataPropertyName,
                                            type: dataProperty.type,
                                            description: dataProperty.description,
                                        });
                                    }
                                });
                            }
                            this.loadSubArguments();
                            this.showSubArguments = true;
                            this.changeDetectorRef.detectChanges();
                        });
                } else {
                    this.showSubArguments = false;
                    this._argument.subArguments = [];
                    this.changeDetectorRef.detectChanges();
                }
            }
        }
    }

    onAddFilter() {
        const subArgument = new Argument();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        subArgument.parameter.type = 'DATAFORMAT_FILTER';
        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments.splice(this._argument?.subArguments?.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onActionExecutionArgumentUpdated($event) {
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onDataFormatOperatorSelected($event, argument: Argument) {
        const dataFormatAttribute = this.dataFormatAttributes.find(dataFormatAttribute => dataFormatAttribute.name === argument.id);
        const oldArgument = this._argument.subArguments?.find(subArgument => subArgument.parameterId === argument.parameterId);
        const newArgument = Object.assign({}, oldArgument);
        newArgument.operator = $event;
        if (newArgument.operator === 'IN' || newArgument.operator === 'NOT_IN') {
            newArgument.parameter.type = 'ARRAY';
            newArgument.parameter.subType = this.translateType(dataFormatAttribute.type);
        } else {
            newArgument.parameter.type = this.translateType(dataFormatAttribute.type);
        }
        this._argument.subArguments?.splice(this._argument?.subArguments?.indexOf(oldArgument), 1, newArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onDataFormatAttributeSelected($event, argument: Argument) {
        const dataFormatAttribute = this.dataFormatAttributes.find(dataFormatAttribute => dataFormatAttribute.name === $event);
        const oldArgument = this._argument.subArguments?.find(subArgument => subArgument.parameterId == argument.parameterId);
        const newArgument = Object.assign({}, oldArgument);
        if (dataFormatAttribute) {
            newArgument.id = dataFormatAttribute.name;
            newArgument.name = dataFormatAttribute.name;
            if (newArgument.parameter) {
                newArgument.parameter.type = this.translateType(dataFormatAttribute.type);
            }
        }
        newArgument.operator = 'EQUAL';
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(oldArgument), 1, newArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    translateType(dataFormatType: string[]) {
        if (dataFormatType.indexOf('string') > -1) {
            return 'STRING';
        } else if (dataFormatType.indexOf('number') - 1) {
            return 'NUMBER';
        } else if (dataFormatType.indexOf('boolean') - 1) {
            return 'BOOLEAN';
        } else {
            return null;
        }
    }
}
