import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { FormBuilder } from '@angular/forms';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';
import { Invocation, NextInvocation, Scope } from '@backoffice/data-access/editor';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';

@Component({
    selector: 'codex-action-edit-conditionals',
    templateUrl: './action-edit-conditionals.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditConditionalsComponent {
    @Input() action!: Action;
    @Input() nextInvocationSource!: Invocation;
    @Input() nextInvocationTarget!: Invocation;
    @Input() language!: string;

    @Input() scope!: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output() invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @Input() set nextInvocation(nextInvocation: NextInvocation) {
        this._nextInvocation = nextInvocation;
        this.determineConditionalExpressions(nextInvocation);
    }

    _nextInvocation: NextInvocation;

    _expressions: Array<{
        id: string;
        expression: string;
        leftArgumentSelectionType: string;
        rightArgumentSelectionType: string;
    }> = new Array();

    expressions: string[] = new Array();

    expressionOperators: string[] = new Array();

    elseConditional: boolean = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private confirmDialog: ConfirmDialog
    ) {}

    determineConditionalExpressions(nextInvocation: NextInvocation) {
        this._expressions = [];
        this.expressionOperators = [];
        if (nextInvocation.conditional === 'else') {
            this.elseConditional = true;
        } else {
            const conditionalExpressions = nextInvocation.determineConditionalExpressions();
            if (conditionalExpressions) {
                this._expressions = conditionalExpressions.expressions.map((expression, index) => {
                    return {
                        id: new GUIDFunctions().newGuid(),
                        expression: expression,
                        leftArgumentSelectionType:
                            this._nextInvocation.conditionalLeftArgumentInputSelectionType &&
                            this._nextInvocation.conditionalLeftArgumentInputSelectionType.length >= 1
                                ? this._nextInvocation.conditionalLeftArgumentInputSelectionType[index]
                                : null,
                        rightArgumentSelectionType:
                            this._nextInvocation.conditionalRightArgumentInputSelectionType &&
                            this._nextInvocation.conditionalRightArgumentInputSelectionType.length >= 1
                                ? this._nextInvocation.conditionalRightArgumentInputSelectionType[index]
                                : null,
                    };
                });
                this.expressionOperators = conditionalExpressions.expressionOperators;
            }
        }
        setTimeout(() => initFlowbite());
    }

    onExpressionOperatorChange(expressionOperatorIndex, $event) {
        if (!this.expressionOperators[expressionOperatorIndex] && Boolean($event)) {
            this._expressions.push({
                id: new GUIDFunctions().newGuid(),
                expression: '',
                leftArgumentSelectionType: null,
                rightArgumentSelectionType: null,
            });
        }
        this.expressionOperators[expressionOperatorIndex] = $event;
        this.updateConditional();
        setTimeout(() => initFlowbite());
    }

    onExpressionChange(
        expressionIndex: number,
        $event: {
            id: string;
            expression: string;
            leftArgumentSelectionType: string;
            rightArgumentSelectionType: string;
        }
    ) {
        this._expressions[expressionIndex].expression = $event.expression;
        this._expressions[expressionIndex].leftArgumentSelectionType = $event.leftArgumentSelectionType;
        this._expressions[expressionIndex].rightArgumentSelectionType = $event.rightArgumentSelectionType;
        this.updateConditional();
    }

    getExpressionUniqueIdentifier(
        index,
        item: {
            id: string;
            expression: string;
            leftArgumentSelectionType: string;
            rightArgumentSelectionType: string;
        }
    ) {
        return item.id;
    }

    updateConditional(): void {
        let conditional: string = null;
        if (!this._nextInvocation.conditionalLeftArgumentInputSelectionType) {
            this._nextInvocation.conditionalLeftArgumentInputSelectionType = [];
        }
        if (!this._nextInvocation.conditionalRightArgumentInputSelectionType) {
            this._nextInvocation.conditionalRightArgumentInputSelectionType = [];
        }

        for (let i = 0; i < this._expressions.length; i++) {
            if (!conditional) {
                conditional = '';
            }
            conditional += this._expressions[i].expression;
            if (this.expressionOperators.length > i) {
                conditional += ' ' + this.expressionOperators[i] + ' ';
            }
            this._nextInvocation.conditionalLeftArgumentInputSelectionType[i] = this._expressions[i].leftArgumentSelectionType;
            this._nextInvocation.conditionalRightArgumentInputSelectionType[i] = this._expressions[i].rightArgumentSelectionType;
        }
        this._nextInvocation.conditional = conditional;

        const nextInvocation = this.nextInvocationSource.nextInvocations.find(
            nextInvocation => nextInvocation.id === this._nextInvocation.id
        );
        nextInvocation.conditional = conditional;
        nextInvocation.conditionalLeftArgumentInputSelectionType = this._nextInvocation.conditionalLeftArgumentInputSelectionType;
        nextInvocation.conditionalRightArgumentInputSelectionType = this._nextInvocation.conditionalRightArgumentInputSelectionType;
        this.action.program.nextInvocationMap.get(this._nextInvocation.id).conditional = conditional;
        this.action.program.nextInvocationMap.get(this._nextInvocation.id).conditionalLeftArgumentInputSelectionType =
            this._nextInvocation.conditionalLeftArgumentInputSelectionType;
        this.action.program.nextInvocationMap.get(this._nextInvocation.id).conditionalRightArgumentInputSelectionType =
            this._nextInvocation.conditionalRightArgumentInputSelectionType;
        this.invocationUpdated.emit({ invocation: this.nextInvocationSource, action: this.action });
    }

    onRemoveAllExpressions() {
        this.confirmDialog.showConfirmDialog(
            'v2.action.conditionals.delete.title',
            'v2.action.conditionals.delete.description',
            'v2.action.conditionals.delete.ok',
            'v2.action.conditionals.delete.cancel',
            'v2.action.conditionals.delete.success.title',
            'v2.action.conditionals.delete.success.description',
            () => {
                this._expressions = [];
                this.expressionOperators = [];
                this.updateConditional();
                setTimeout(() => initFlowbite());
            }
        );
    }

    onChangeElseConditional() {
        if (this.elseConditional) {
            this._nextInvocation.conditional = 'else';
            const nextInvocation = this.nextInvocationSource.nextInvocations.find(
                nextInvocation => nextInvocation.id === this._nextInvocation.id
            );
            if (nextInvocation) {
                nextInvocation.conditional = 'else';
                this.invocationUpdated.emit({ invocation: this.nextInvocationSource, action: this.action });
            }
        } else {
            this._nextInvocation.conditional = undefined;
            const nextInvocation = this.nextInvocationSource.nextInvocations.find(
                nextInvocation => nextInvocation.id === this._nextInvocation.id
            );
            if (nextInvocation) {
                nextInvocation.conditional = undefined;
                this.invocationUpdated.emit({ invocation: this.nextInvocationSource, action: this.action });
            }
        }
    }

    onRemoveExpression(index: number) {
        this.confirmDialog.showConfirmDialog(
            'v2.action.conditionals.delete.title',
            'v2.action.conditionals.delete.description',
            'v2.action.conditionals.delete.ok',
            'v2.action.conditionals.delete.cancel',
            'v2.action.conditionals.delete.success.title',
            'v2.action.conditionals.delete.success.description',
            () => {
                this._expressions.splice(index, 1);
                this.expressions.splice(index, 1);
                if (index === this.expressionOperators.length) {
                    this.expressionOperators.splice(index - 1, 1);
                }
                this.expressionOperators.splice(index, 1);
                this.updateConditional();
            }
        );
    }
}
