<div
    *ngIf="showSubArguments && this._argument.subArguments && this._argument.subArguments.length > 0"
    (cdkDropListDropped)="reorderSubArguments($event)"
    [cdkDropListData]="this._argument.subArguments"
    [attr.id]="'subarguments-accordion-' + this._argument.id"
    [attr.data-accordion]="'subarguments-accordion-' + this._argument.id"
    cdkDropList
    class="mb-2 accordion-border">
    <ng-container *ngFor="let argument of this._argument.subArguments; let i = index; trackBy: identifyArgument">
        <h3
            cdkDrag
            [attr.id]="'subarguments-accordion-' + argument.selectorId + '-objectitem-heading'"
            [attr.data-name]="'subarguments-accordion-objectitem-header-' + argument.selectorId"
            class="p-0">
            <button
                type="button"
                class="accordion-heading"
                [class.accordion-heading-first]="i === 0"
                [class.accordion-heading-last]="i === this._argument.subArguments.length - 1"
                [attr.data-accordion-target]="'#subarguments-accordion-' + argument.selectorId + '-objectitem'"
                [attr.aria-controls]="'subarguments-accordion-' + argument.selectorId + '-objectitem'"
                aria-expanded="true">
                <span class="leading-6 grow shrink text-left" [matTooltip]="argument.id">{{
                    argument.id ? (argument.id | truncate: 25) : ''
                }}</span>
                <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                    <mat-icon>import_export</mat-icon>
                </button>
                <button
                    class="accordion-heading-button destructive-accordion-heading-button"
                    (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                    [matTooltip]="'v2.action.argument.json.field.remove' | translate">
                    <mat-icon>clear</mat-icon>
                </button>
                <svg
                    data-accordion-icon
                    class="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h3>
        <div
            [attr.id]="'subarguments-accordion-' + argument.selectorId + '-objectitem'"
            [attr.data-name]="'subarguments-' + argument.selectorId"
            [attr.aria-labelledby]="'subarguments-accordion-' + argument.selectorId + '-objectitem'"
            [class.accordion-panel-first]="i === 0"
            [class.accordion-panel-last]="i === this._argument.subArguments.length - 1"
            [class.accordion-panel]="true"
            class="hidden">
            <label for="fieldname" class="small-input-label">{{ 'v2.action.argument.json.field.name' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="fieldname"
                name="fieldname"
                class="small-input"
                autocomplete="off"
                required
                [(ngModel)]="argument.id"
                (change)="onActionExecutionArgumentUpdated({ argument: argument })" />
            <argument-value-picker
                class="mb-1 block"
                [argument]="argument"
                [arguments]="this._argument.subArguments"
                [contextId]="contextId"
                [scope]="scope"
                [language]="language"
                [root]="this.root"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="onActionExecutionArgumentUpdated($event)"
                (openTab)="this.openTab.emit($event)"></argument-value-picker>
        </div>
    </ng-container>
</div>
<button class="mt-2 w-full justify-center primary-button button-medium" type="button" (click)="onAddField()">
    <mat-icon>add</mat-icon>
    <span *ngIf="!this._argument.parameter?.addComposedButtonText"
        >{{ 'v2.action.argument.object.add.field' | translate }} "{{
            this._argument.parameter?.name ? (this._argument.parameter.name | truncate: 25) : 'object'
        }}"</span
    >
    <span *ngIf="!!this._argument.parameter?.addComposedButtonText">{{ this._argument.parameter?.addComposedButtonText }}</span>
</button>
