import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BorderStyleDto } from '@backoffice/data-access/editor';

export interface WrapperStyle {
    borderStyle?: BorderStyleDto | null;
    borderTopStyle?: BorderStyleDto | null;
    borderLeftStyle?: BorderStyleDto | null;
    borderRightStyle?: BorderStyleDto | null;
    borderBottomStyle?: BorderStyleDto | null;
}

@Component({
    selector: 'codex-border-picker-wrapper',
    templateUrl: './border-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class BorderPickerWrapperComponent {
    @Input()
    title: string;

    @Input()
    firstPanel: boolean = false;

    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Output()
    activeWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    activeWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover', 'focus'];

    type: string = 'normal';

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        } else if (this.type === 'active') {
            this.resetActiveWrapperStyle();
        }
    }

    onChangeStyle($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        if (!this.wrapperStyle) {
            this.wrapperStyle = {};
        }
        this.wrapperStyle.borderStyle = $event.border.borderStyle;
        this.wrapperStyle.borderTopStyle = $event.border.borderTopStyle;
        this.wrapperStyle.borderLeftStyle = $event.border.borderLeftStyle;
        this.wrapperStyle.borderBottomStyle = $event.border.borderBottomStyle;
        this.wrapperStyle.borderRightStyle = $event.border.borderRightStyle;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        if (!this.hoverWrapperStyle) {
            this.hoverWrapperStyle = {};
        }
        this.hoverWrapperStyle.borderStyle = $event.border.borderStyle;
        this.hoverWrapperStyle.borderTopStyle = $event.border.borderTopStyle;
        this.hoverWrapperStyle.borderLeftStyle = $event.border.borderLeftStyle;
        this.hoverWrapperStyle.borderBottomStyle = $event.border.borderBottomStyle;
        this.hoverWrapperStyle.borderRightStyle = $event.border.borderRightStyle;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    onChangeFocusStyle($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        if (!this.focusWrapperStyle) {
            this.focusWrapperStyle = {};
        }
        this.focusWrapperStyle.borderStyle = $event.border.borderStyle;
        this.focusWrapperStyle.borderTopStyle = $event.border.borderTopStyle;
        this.focusWrapperStyle.borderLeftStyle = $event.border.borderLeftStyle;
        this.focusWrapperStyle.borderBottomStyle = $event.border.borderBottomStyle;
        this.focusWrapperStyle.borderRightStyle = $event.border.borderRightStyle;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    onChangeActiveStyle($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        if (!this.activeWrapperStyle) {
            this.activeWrapperStyle = {};
        }
        this.activeWrapperStyle.borderStyle = $event.border.borderStyle;
        this.activeWrapperStyle.borderTopStyle = $event.border.borderTopStyle;
        this.activeWrapperStyle.borderLeftStyle = $event.border.borderLeftStyle;
        this.activeWrapperStyle.borderBottomStyle = $event.border.borderBottomStyle;
        this.activeWrapperStyle.borderRightStyle = $event.border.borderRightStyle;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }

    resetWrapperStyle() {
        this.wrapperStyle.borderStyle = null;
        this.wrapperStyle.borderTopStyle = null;
        this.wrapperStyle.borderLeftStyle = null;
        this.wrapperStyle.borderRightStyle = null;
        this.wrapperStyle.borderBottomStyle = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.borderStyle = null;
        this.hoverWrapperStyle.borderTopStyle = null;
        this.hoverWrapperStyle.borderLeftStyle = null;
        this.hoverWrapperStyle.borderRightStyle = null;
        this.hoverWrapperStyle.borderBottomStyle = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }
    resetFocusWrapperStyle() {
        this.focusWrapperStyle.borderStyle = null;
        this.focusWrapperStyle.borderTopStyle = null;
        this.focusWrapperStyle.borderLeftStyle = null;
        this.focusWrapperStyle.borderRightStyle = null;
        this.focusWrapperStyle.borderBottomStyle = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetActiveWrapperStyle() {
        this.activeWrapperStyle.borderStyle = null;
        this.activeWrapperStyle.borderTopStyle = null;
        this.activeWrapperStyle.borderLeftStyle = null;
        this.activeWrapperStyle.borderRightStyle = null;
        this.activeWrapperStyle.borderBottomStyle = null;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }
}
