import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { Observable } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { map } from 'rxjs/operators';
import { CreateCompanyDto } from '@shared/interfaces/create-company.dto';
import { CreateCompanyUserDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/create-company-user.dto';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';

@Injectable()
export class CompanyService {
    constructor(private readonly httpClient: HttpClient) {}

    findByUserId(userId: string): Observable<CompanyDto[]> {
        return this.httpClient.get<CompanyDto[]>(`${backofficeEnvironment.rest.company}`);
    }

    inviteUser(email: string, companyId: string): Observable<void> {
        const dto: CreateCompanyUserDto = { email, companyId };
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.companyuser}`, dto);
    }

    createCompany(company: CreateCompanyDto): Observable<CompanyDto> {
        return this.httpClient.post<CompanyDto>(`${backofficeEnvironment.rest.company}`, company, {
            headers: new HttpHeaders().set('handle-errors', 'true'),
        });
    }

    getCompanySecurity(companyId: string): Observable<CompanySecurityDto> {
        return this.httpClient.get<CompanySecurityDto>(`${backofficeEnvironment.rest.company}${companyId}/security`);
    }

    updateCompanySecurity(companySecurity: CompanySecurityDto): Observable<void> {
        return this.httpClient.put<void>(`${backofficeEnvironment.rest.company}${companySecurity.companyId}/security`, companySecurity);
    }

    getCompanyApiSecret(companyId: string, environment: string): Observable<{ secret: string }> {
        return this.httpClient.get<{
            secret: string;
        }>(`${backofficeEnvironment.rest.company}${companyId}/api-access/${environment}`);
    }

    update(company: CompanyDto): Observable<CompanyDto> {
        return this.httpClient.put<CompanyDto>(`${backofficeEnvironment.rest.company}${company.id}`, company);
    }

    getUserCompanies(userId: string): Observable<CompanyDto[]> {
        return this.httpClient
            .get<CompanyDto[]>(`${backofficeEnvironment.rest.company}?userId=${userId}`, {
                headers: {
                    [NgHttpCachingHeaders.LIFETIME]: (1000 * 30).toString(), // one year
                },
            })
            .pipe(map(response => (!!response ? response : [])));
    }

    getUserCompaniesNoCache(userId: string): Observable<CompanyDto[]> {
        return this.httpClient.get<CompanyDto[]>(`${backofficeEnvironment.rest.company}?userId=${userId}`, {
            headers: {
                [NgHttpCachingHeaders.DISALLOW_CACHE]: '1',
            },
        });
    }

    linkUserCompanies(userId: string, code: string): Observable<void> {
        return this.httpClient.get<void>(`${backofficeEnvironment.rest.company}link-user?userId=${userId}&code=${code}`);
    }
}
