import { NgModule } from '@angular/core';
import { AppFacade } from './facades/app.facade';
import { ApplicationService } from './services/application.service';
import { UserService } from '@core/services/user.service';
import { UserFacade } from '@core/facades/user.facade';
import { CompanyService } from '@core/services/company.service';
import { CompanyFacade } from '@core/facades/company.facade';
import { WorkspaceCookieFacade } from '@core/facades/workspace-cookie.facade';
import { ApplicationVersionService } from '@core/services/application-version.service';
import { LoggerService } from '../../../../../libs/backoffice/utils/src/lib/services/logger.service';
import { BillingService } from '@core/services/billing.service';
import { InstallationService } from '@core/services/installation.service';
import { ProductService } from '@core/services/product.service';
import { AchievementsService } from '@core/services/achievements.service';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        AppFacade,
        ApplicationService,
        ApplicationVersionService,
        AchievementsService,
        BillingService,
        CompanyService,
        CompanyFacade,
        InstallationService,
        LoggerService,
        ProductService,
        UserService,
        UserFacade,
        WorkspaceCookieFacade,
    ],
})
export class CoreModule {}
