<argument-object-parameters
    *ngIf="this.argument.parameter?.inputType === 'parameter'"
    [argument]="this.argument"
    [arguments]="this.arguments"
    [contextId]="this.contextId"
    [language]="this.language"
    [scope]="this.scope"
    [root]="this.root"
    [onlyLiteralValues]="onlyLiteralValues"
    (argumentUpdated)="this.argumentUpdated.emit($event)"
    (openTab)="this.openTab.emit($event)"></argument-object-parameters>
<argument-object-free
    *ngIf="!this.argument.parameter?.inputType || this.argument.parameter?.inputType === 'free'"
    [argument]="this.argument"
    [arguments]="this.arguments"
    [contextId]="this.contextId"
    [language]="this.language"
    [scope]="this.scope"
    [root]="this.root"
    [onlyLiteralValues]="onlyLiteralValues"
    (argumentUpdated)="this.argumentUpdated.emit($event)"
    (openTab)="this.openTab.emit($event)"></argument-object-free>
<argument-object-dataformat
    *ngIf="this.argument.parameter?.inputType === 'dataformat'"
    [argument]="this.argument"
    [arguments]="this.arguments"
    [contextId]="this.contextId"
    [language]="this.language"
    [scope]="this.scope"
    [root]="this.root"
    [onlyLiteralValues]="onlyLiteralValues"
    (argumentUpdated)="this.argumentUpdated.emit($event)"
    (openTab)="this.openTab.emit($event)"></argument-object-dataformat>
<argument-object-mapping
    *ngIf="this.argument.parameter?.inputType === 'mapping'"
    [argument]="this.argument"
    [arguments]="this.arguments"
    [contextId]="this.contextId"
    [language]="this.language"
    [scope]="this.scope"
    [root]="this.root"
    [onlyLiteralValues]="onlyLiteralValues"
    (argumentUpdated)="this.argumentUpdated.emit($event)"
    (openTab)="this.openTab.emit($event)"></argument-object-mapping>
