import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { Argument, Invocation, NextInvocation, Scope } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-edit-conditional',
    templateUrl: './action-edit-conditional.component.html',
    styleUrls: ['./action-edit-conditional.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditConditionalComponent {
    @Input()
    set expression(expression: { expression: string; leftArgumentSelectionType: string; rightArgumentSelectionType: string }) {
        if (this._expression !== expression.expression) {
            this._expression = expression.expression;
            const expressionParts = NextInvocation.determineExpressionParts(expression.expression);
            if (expressionParts) {
                this.leftArgument = expressionParts.leftArgument;
                this.leftArgument.inputSelectionType = expression.leftArgumentSelectionType;
                this.rightArgument = expressionParts.rightArgument;
                this.rightArgument.inputSelectionType = expression.rightArgumentSelectionType;
                this.equalityOperator = expressionParts.equalityOperator;
                if (this.equalityOperator === '== NULL' || this.equalityOperator === '!= NULL') {
                    this.showRightArgument = false;
                } else {
                    this.showRightArgument = true;
                }
            }
        }
    }

    @Input() action: Action;
    @Input() invocation: Invocation;
    @Input() scope: Scope;
    @Input() language: string;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output() changedValue: EventEmitter<any> = new EventEmitter();

    leftArgument: Argument;
    equalityOperator: string;
    rightArgument: Argument;
    _expression: string;

    showRightArgument: boolean;

    onLeftArgumentUpdated() {
        if (this.equalityOperator === '== NULL' || this.equalityOperator === '!= NULL') {
            this.changedValue.emit({
                expression: this.leftArgument.value + this.equalityOperator,
                leftArgumentSelectionType: this.leftArgument.inputSelectionType,
                rightArgumentSelectionType: this.rightArgument.inputSelectionType,
            });
        } else {
            this.changedValue.emit({
                expression: this.leftArgument.value + this.equalityOperator + this.rightArgument.value,
                leftArgumentSelectionType: this.leftArgument.inputSelectionType,
                rightArgumentSelectionType: this.rightArgument.inputSelectionType,
            });
        }
    }

    onRightArgumentUpdated() {
        this.changedValue.emit({
            expression: this.leftArgument.value + this.equalityOperator + this.rightArgument.value,
            leftArgumentSelectionType: this.leftArgument.inputSelectionType,
            rightArgumentSelectionType: this.rightArgument.inputSelectionType,
        });
    }

    onExpressionEqualityOperatorChange($event) {
        this.equalityOperator = $event;
        if (this.equalityOperator === '== NULL' || this.equalityOperator === '!= NULL') {
            this.changedValue.emit(this.leftArgument.value + this.equalityOperator);
            this.changedValue.emit({
                expression: this.leftArgument.value + this.equalityOperator,
                leftArgumentSelectionType: this.leftArgument.inputSelectionType,
                rightArgumentSelectionType: null,
            });
            this.showRightArgument = false;
        } else {
            this.changedValue.emit({
                expression: this.leftArgument.value + this.equalityOperator + this.rightArgument.value,
                leftArgumentSelectionType: this.leftArgument.inputSelectionType,
                rightArgumentSelectionType: null,
            });
            this.showRightArgument = true;
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
