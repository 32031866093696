<div
    *ngIf="this.showMissingParameters && this.missingParameters && missingParameters.length > 0"
    (click)="onClickDependantParameters()"
    class="warning-message cursor-pointer">
    <span>
        <svg class="icon inline-block mr-1" aria-hidden="true" focusable="false">
            <use href="#warn" class="ui-element"></use></svg
        >{{ 'v2.parameter.dependent.parameters.found' | translate }}</span
    >
</div>

<div class="title-button-header">
    <h1></h1>
    <div class="title-button-header-buttons">
        <button class="primary-button button-medium" type="button" (click)="onAddParameter()">
            <mat-icon>add</mat-icon>
            <span>{{ 'v2.parameter.add.parameter' | translate }}</span>
        </button>
    </div>
</div>
<ng-container *ngIf="language && this.parameters && this.parameters.length > 0">
    <div
        [attr.id]="'accordion-template-parameters-' + this.parentId"
        class="!mb-2"
        data-accordion="template-parameters"
        data-active-classes="none"
        data-inactive-classes="none">
        <div cdkDropList (cdkDropListDropped)="reorderParameters($event)" [cdkDropListData]="this.parameters">
            <div *ngFor="let parameter of this.parameters; let i = index; trackBy: identifyParameter" cdkDrag>
                <h3
                    [attr.id]="'accordion-template-parameters-heading-' + this.parentId + '-' + parameter.id"
                    [attr.data-name]="'header-' + this.parentId + '-' + parameter.id"
                    class="p-0">
                    <button
                        type="button"
                        class="accordion-heading"
                        [class.accordion-heading-first]="i === 0"
                        [class.accordion-heading-last]="i === this.parameters.length - 1"
                        [attr.data-accordion-target]="'#accordion-template-parameters-body-' + this.parentId + '-' + parameter.id"
                        [attr.aria-controls]="'accordion-template-parameters-body-' + this.parentId + '-' + parameter.id"
                        aria-expanded="true">
                        <span class="accordion-heading-icon">
                            {{ parameter.name ? (parameter.name | truncate: 25) : 'New Parameter' }}
                            {{ parameter.type ? '(' + ('v2.types.' + parameter.type.toLowerCase() | translate) + ')' : '' }}</span
                        >
                        <button
                            cdkDragHandle
                            class="accordion-heading-button"
                            (click)="$event.stopPropagation()"
                            [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                            <mat-icon>import_export</mat-icon>
                        </button>
                        <button
                            color="warn"
                            class="accordion-heading-button destructive-accordion-heading-button"
                            (click)="onRemoveParameter(parameter); $event.stopPropagation()"
                            [matTooltip]="'v2.parameter.remove' | translate">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <svg
                            data-accordion-icon
                            class="w-3 h-3 rotate-180 shrink-0"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </h3>
                <div
                    [attr.id]="'accordion-template-parameters-body-' + this.parentId + '-' + parameter.id"
                    [attr.data-name]="'content-' + this.parentId + '-' + parameter.id"
                    [attr.aria-labelledby]="'accordion-template-parameters-heading-' + this.parentId + '-' + parameter.id"
                    [class.accordion-panel-first]="i === 0"
                    [class.accordion-panel-last]="i === this.parameters.length - 1"
                    [class.accordion-panel]="true"
                    class="hidden">
                    <codex-action-edit-parameter
                        [language]="language"
                        [root]="root"
                        [parameter]="parameter"
                        [parameters]="parameters"
                        [context]="context"
                        (parameterUpdated)="onParametersUpdated($event)"
                        (openTab)="this.openTab.emit($event)">
                    </codex-action-edit-parameter>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="flex flex-col justify-center items-center" *ngIf="!this.parameters || this.parameters.length === 0">
    <img class="w-[350px] h-[200px] object-contain" src="/images/theme/no_params.gif" />
    <h1 class="text-center !mb-2">We couldn't find any parameters.</h1>
    <p class="!mb-8 text-white text-center">
        This action/page or component cannot receive data from the browser or other parts of your application. Start accepting data within
        this resource by creating a parameter.
    </p>
    <button type="button" class="primary-button button-extra-large" (click)="onAddParameter()">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
        </svg>
        Create new parameter
    </button>
</div>
