import { combineReducers, createReducer, on } from '@ngrx/store';
import { ApplicationEventsState } from '../state/application-events.state';
import { ApplicationEventDto } from '../../dto/application-event.dto';
import { createApplicationEvent, revertToApplicationEvent, undoLastApplicationEvent } from '../actions/application-events.actions';

export const applicationEventsState: ApplicationEventsState = {
    applicationEvents: [],
};

export const applicationEventsReducer = (initialData: ApplicationEventDto[]) =>
    createReducer(
        initialData,
        on(createApplicationEvent, (state, { applicationEvent }) => {
            if (!state) {
                return [applicationEvent];
            } else {
                return [...state, applicationEvent];
            }
        }),
        on(revertToApplicationEvent, state => {
            // TODO implementation
            return state;
        }),
        on(undoLastApplicationEvent, state => {
            state = state.splice(state.length, 1);
            return [...state];
        })
    );

export const applicationEventsReducers = combineReducers({
    applicationEvents: applicationEventsReducer(applicationEventsState.applicationEvents),
});
