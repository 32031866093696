<div *ngIf="form" [formGroup]="form">
    <label for="select-output-type" class="small-input-label">{{ 'v2.parameter.type' | translate }}</label>
    <select id="select-output-type" class="small-input" formControlName="type" required>
        <ng-container *ngFor="let type of backofficeEnvironment.types">
            <option *ngIf="type.output" [value]="type.typeKey">{{ 'v2.types.' + type.name | translate }}</option>
        </ng-container>
    </select>
    <label for="output-name" class="small-input-label">{{ 'v2.parameter.name' | translate }}</label>
    <input class="small-input" type="text" id="output-name" name="output-name" autocomplete="off" required formControlName="name" cd-code />
    <label for="output-description" class="small-input-label">{{ 'v2.parameter.description' | translate }}</label>
    <textarea
        class="small-input"
        type="text"
        id="output-description"
        name="output-description"
        autocomplete="off"
        required
        formControlName="description"></textarea>

    <ng-container *ngIf="output.type === 'OBJECT' || output.type === 'ARRAY'">
        <label for="select-inputtype" class="small-input-label">{{ 'v2.parameter.inputtype.select' | translate }}</label>
        <select id="select-inputtype" class="small-input" formControlName="inputType" required>
            <option value="free">Free input</option>
            <option value="dataformat">Data Format input</option>
            <option value="parameter-reference">Reference output</option>
        </select>
    </ng-container>

    <app-dataformat-picker
        *ngIf="
            output.type === 'DATA' ||
            output.type === 'DATA_BODY' ||
            output.type === 'PARTIAL_DATA' ||
            (output.type === 'OBJECT' && output.inputType === 'dataformat')
        "
        [value]="output.subTypeParameterId"
        [required]="false"
        (valueUpdated)="onDataFormatChange($event, output)"
        (openTab)="this.openTab.emit($event)">
    </app-dataformat-picker>

    <app-output-picker
        *ngIf="output.type === 'OBJECT' && output.inputType === 'parameter-reference'"
        [selectedOutputId]="output.linkedDataFormatParameterId"
        [root]="root"
        (changeSelectedOutput)="onLinkedDataFormatParameterIdChange($event)">
    </app-output-picker>
</div>
