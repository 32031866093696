import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@backoffice/utils';
import { AddPropertyDialogComponent } from '../add-property-dialog/add-property-dialog.component';
import { backofficeEnvironment } from '@shared/environment';
import {
    Argument,
    attributesToJsonSchema,
    DataFormatAttribute,
    jsonSchemaToAttributes,
    setTypeBasedPropertiesOfAttribute,
} from '@backoffice/data-access/editor';
import { initFlowbite } from 'flowbite';
import { GUIDFunctions } from '@shared/utils';
import { DataFormat, JsonSchema } from '@backoffice/data-access/editor';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'codex-json-schema-editor',
    templateUrl: './json-schema-editor.component.html',
    styleUrls: ['./json-schema-editor.component.scss'],
    standalone: false,
})
export class JsonSchemaEditorComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() jsonSchema: JsonSchema;
    @Input() dataFormat: DataFormat;

    @Output() schemaChanged = new EventEmitter<JsonSchema>();

    editorModeEnabled: boolean = false;
    editorCode: string;

    editorOptions = {
        theme: 'vs-dark',
        autocomplete: true,
        autoIndent: true,
    };

    constructor(
        private readonly dialog: MatDialog,
        private readonly logger: LoggerService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.dataFormat.attributes = jsonSchemaToAttributes(this.dataFormat.jsonSchema);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.dataFormat.attributes = jsonSchemaToAttributes(this.dataFormat.jsonSchema);
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    private stringifyDataFormat(): any {
        return JSON.stringify(
            this.dataFormat.jsonSchema,
            (key, value) => {
                if (!value) {
                    return undefined;
                }
                return value;
            },
            4
        );
    }

    onInitEditor(editor): void {
        // @ts-ignore
        window.monaco.editor.getModels().forEach(editorModel => {
            if (!editorModel.isDisposed()) {
                editorModel.dispose();
            }
        });

        // @ts-ignore
        const modelUri = window.monaco.Uri.parse('a://b/foo.json');

        // @ts-ignore
        const model = window.monaco.editor.createModel(this.stringifyDataFormat(), 'json', modelUri);
        editor.setModel(model);

        this.setDiagnosticsOptions();
    }

    setDiagnosticsOptions() {
        const schema = {
            fileMatch: ['*'],
            uri: 'https://json-schema.org/draft/2020-12/schema',
            schema: undefined,
        };

        // @ts-ignore
        window.monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            enableSchemaRequest: true,
            validate: true,
            schemas: [schema],
        });
    }

    onDeleteAttribute(attribute: DataFormatAttribute) {
        this.dataFormat.attributes = this.dataFormat.attributes.filter(attributeToCheck => attribute.name !== attributeToCheck.name);
        this.dataFormat.jsonSchema = attributesToJsonSchema(this.dataFormat, this.dataFormat.attributes);
        setTimeout(() => initFlowbite());
        this.handleJsonSchemaChanged();
    }

    onAttributeChanged($event: { attribute: DataFormatAttribute }) {
        this.dataFormat.jsonSchema = attributesToJsonSchema(this.dataFormat, this.dataFormat.attributes);
        setTimeout(() => initFlowbite());
        this.handleJsonSchemaChanged();
    }

    onAIClicked(): void {}

    onModeClicked(): void {
        this.editorModeEnabled = !this.editorModeEnabled;
        if (this.editorModeEnabled) {
            this.editorCode = this.stringifyDataFormat();
        } else {
            setTimeout(() => initFlowbite());
        }
    }

    onEditorChanged(event: string): void {
        try {
            const json = JSON.parse(event);
            this.dataFormat.jsonSchema = new JsonSchema({ ...json });
            this.changeDetectorRef.markForCheck();
            this.handleJsonSchemaChanged();
        } catch {
            // we kunnen niet checken of de editor valid is of niet dusjaaa
        }
    }

    openAddPropertyDialog(): void {
        const dialogRef = this.dialog.open(
            AddPropertyDialogComponent,
            Object.assign({ restoreFocus: true }, backofficeEnvironment.dialogConfig.extrasmall)
        );

        dialogRef.afterClosed().subscribe((result: { name: string; type: any }) => {
            if (result) {
                const { name, type } = result;
                if (!this.dataFormat.attributes) {
                    this.dataFormat.attributes = [];
                }

                let attribute = {
                    id: new GUIDFunctions().newGuid(),
                    name,
                    noCodeXType: type,
                    types: [type],
                    nullable: true,
                    required: false,
                };
                setTypeBasedPropertiesOfAttribute(attribute);
                this.dataFormat.attributes.push(attribute);
                this.dataFormat.jsonSchema = attributesToJsonSchema(this.dataFormat, this.dataFormat.attributes);
                setTimeout(() => initFlowbite());
                this.handleJsonSchemaChanged();
            }
        });
    }

    private handleJsonSchemaChanged(): void {
        this.schemaChanged.emit(this.dataFormat.jsonSchema);
    }

    identifyAttribute(index, item: DataFormatAttribute) {
        return index;
    }

    reorderAttributes(event: CdkDragDrop<DataFormatAttribute[]>): void {
        if (this.dataFormat.attributes) {
            moveItemInArray(this.dataFormat.attributes, event.previousIndex, event.currentIndex);
            this.dataFormat.jsonSchema = attributesToJsonSchema(this.dataFormat, this.dataFormat.attributes);
            setTimeout(() => initFlowbite());
            this.handleJsonSchemaChanged();
        }
    }
}
