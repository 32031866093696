import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { ApplicationDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { Group } from '@usermanagement/models/group.model';
import { Role } from '@usermanagement/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { AppFacade } from '@core/facades/app.facade';
import { ApplicationService } from '@core/services/application.service';
import { GroupsService } from 'libs/backoffice/data-access/editor/src/lib/group/services/groups.service';
import { RightsService } from 'libs/backoffice/data-access/editor/src/lib/right/services/roles.service';
import { UserFacade } from '@core/facades/user.facade';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, switchMap } from 'rxjs/operators';
import { MatCheckboxChange as MatCheckboxChange } from '@angular/material/checkbox';
import { RightDto } from '../../../../../../../data-access/editor/src/lib/right/dto/right.dto';
import { GroupDto } from '../../../../../../../data-access/editor/src/lib/group/dto/group.dto';

@Component({
    selector: 'codex-edit-developer-permissions',
    templateUrl: './edit-developer-permissions.component.html',
    styleUrls: ['./edit-developer-permissions.component.scss'],
    standalone: false,
})
export class EditDeveloperPermissionsComponent implements OnInit {
    form: FormGroup;

    applications$: Observable<ApplicationDto[]>;
    private selectedGroups$: Observable<GroupDto[]>;
    private applicationBackofficeRoles$: Observable<RightDto[]>;
    private companyBackofficeRoles$: Observable<RightDto[]>;
    private selectedRoles$: Observable<RightDto[]>;

    groupsAndRoles$: Observable<{
        companyBackofficeRoles: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[];
        applicationBackofficeRoles: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[];
    }>;

    sortedApplicationRights: string[] = [
        'ROLE_READ_DATA',
        'ROLE_WRITE_DATA',
        'ROLE_READ_DATAFORMAT',
        'ROLE_WRITE_DATAFORMAT',
        'ROLE_READ_MEDIA',
        'ROLE_WRITE_MEDIA',
        'ROLE_READ_API',
        'ROLE_WRITE_API',
        'ROLE_READ_TEMPLATE',
        'ROLE_WRITE_TEMPLATE',
        'ROLE_READ_ACTIONS',
        'ROLE_WRITE_ACTIONS',
        'ROLE_READ_VERSION',
        'ROLE_WRITE_VERSION',
        'ROLE_READ_DASHBOARD',
        'ROLE_WRITE_DASHBOARD',
    ];

    sortedCompanyRights: string[] = ['APPLICATION_ADMIN', 'COMPANY_ADMIN', 'USER_ADMIN', 'BILLING_ADMIN'];

    applicationRightsDependencies: Map<string, string[]> = new Map([
        ['ROLE_READ_TEMPLATE', ['ROLE_READ_DATA', 'ROLE_READ_DATAFORMAT', 'ROLE_READ_MEDIA', 'ROLE_READ_API', 'ROLE_READ_ACTIONS']],
        ['ROLE_READ_API', ['ROLE_READ_DATA', 'ROLE_READ_DATAFORMAT', 'ROLE_READ_MEDIA', 'ROLE_READ_TEMPLATE', 'ROLE_READ_ACTIONS']],
        ['ROLE_READ_DATAFORMAT', ['ROLE_READ_DATA', 'ROLE_READ_TEMPLATE', 'ROLE_READ_MEDIA', 'ROLE_READ_API', 'ROLE_READ_ACTIONS']],
        ['ROLE_READ_ACTIONS', ['ROLE_READ_DATA', 'ROLE_READ_DATAFORMAT', 'ROLE_READ_MEDIA', 'ROLE_READ_API', 'ROLE_READ_TEMPLATE']],
        ['ROLE_READ_MEDIA', []],
        ['ROLE_READ_DATA', ['ROLE_READ_DATAFORMAT__']],
        ['ROLE_READ_VERSION', []],
        ['ROLE_READ_DASHBOARD', []],
        ['ROLE_WRITE_TEMPLATE', ['ROLE_READ_TEMPLATE']],
        ['ROLE_WRITE_DATAFORMAT', ['ROLE_READ_DATAFORMAT']],
        ['ROLE_WRITE_DATA', ['ROLE_READ_DATAFORMAT__']],
        ['ROLE_WRITE_MEDIA', ['ROLE_READ_MEDIA']],
        ['ROLE_WRITE_ACTIONS', ['ROLE_READ_ACTIONS']],
        ['ROLE_WRITE_DASHBOARD', ['ROLE_READ_DASHBOARD']],
    ]);

    get applicationControl(): FormControl {
        return this.form.get('applicationId') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly appFacade: AppFacade,
        private readonly applicationService: ApplicationService,
        private readonly groupService: GroupsService,
        private readonly rightsService: RightsService,
        private readonly userFacade: UserFacade,
        @Inject(MAT_DIALOG_DATA) public data: { userId: string }
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            applicationId: ['', Validators.required],
        });

        this.applications$ = this.appFacade.context.pipe(
            switchMap(({ selectedCompany }) =>
                this.applicationService
                    .getApplications(selectedCompany.id)
                    .pipe(map(page => page.content.sort((a, b) => a.name.localeCompare(b.name))))
            )
        );

        this.applicationBackofficeRoles$ = combineLatest([this.appFacade.context, this.applicationControl.valueChanges]).pipe(
            switchMap(([context, applicationId]) =>
                this.rightsService.getRightsByApplicationId(applicationId, context.selectedCompany.id, true).pipe(
                    map(page => {
                        page.content.sort(
                            (a, b) => this.sortedApplicationRights.indexOf(a.name) - this.sortedApplicationRights.indexOf(b.name)
                        );
                        return page.content;
                    })
                )
            )
        );

        this.companyBackofficeRoles$ = this.appFacade.context.pipe(
            switchMap(({ selectedCompany }) =>
                this.rightsService.getRightsByCompanyId(selectedCompany.id, true).pipe(
                    map(page => {
                        page.content.sort((a, b) => this.sortedCompanyRights.indexOf(a.name) - this.sortedCompanyRights.indexOf(b.name));
                        return page.content;
                    })
                )
            )
        );

        this.selectedRoles$ = combineLatest([this.applicationControl.valueChanges, this.appFacade.context]).pipe(
            switchMap(([applicationId, context]) =>
                this.rightsService
                    .fetchByUserId(applicationId, context.selectedCompany.id, this.data.userId)
                    .pipe(map(page => page.content))
            )
        );

        this.selectedGroups$ = combineLatest([this.appFacade.context, this.applicationControl.valueChanges]).pipe(
            switchMap(([context, applicationId]) =>
                this.groupService.fetchByUserId(applicationId, context.selectedCompany.id, this.data.userId).pipe(map(page => page.content))
            )
        );

        this.groupsAndRoles$ = combineLatest([
            this.companyBackofficeRoles$,
            this.applicationBackofficeRoles$,
            this.selectedGroups$,
            this.selectedRoles$,
        ]).pipe(
            map(([companyBackofficeRoles, applicationBackofficeRoles, selectedGroups, selectedRoles]) => {
                return {
                    companyBackofficeRoles: companyBackofficeRoles.map(role => ({
                        id: role.id,
                        name: role.name,
                        companyId: role.companyId,
                        checked: selectedRoles.some(r => r.id === role.id),
                        applicationId: role.applicationId,
                    })),
                    applicationBackofficeRoles: applicationBackofficeRoles.map(role => ({
                        id: role.id,
                        name: role.name,
                        companyId: role.companyId,
                        checked: selectedRoles.some(r => r.id === role.id),
                        applicationId: role.applicationId,
                    })),
                };
            })
        );
    }

    toggleAll($event, roles: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[]) {
        if ($event.target.checked) {
            roles.forEach(role => {
                role.checked = false;
                this.onRoleSelected(role, role.companyId, role.applicationId);
            });
        } else {
            roles.forEach(role => {
                role.checked = true;
                this.onRoleSelected(role, role.companyId, role.applicationId);
            });
        }
    }

    onRoleSelected(
        right: { id: string; name: string; checked: boolean; companyId: string; applicationId: string },
        companyId: string,
        applicationId: string
    ) {
        const { userId } = this.data;
        if (!right.checked) {
            right.checked = true;
            this.userFacade.addRole(right.id, userId, null, companyId, this.applicationControl.value);
        } else {
            right.checked = false;
            this.userFacade.removeRole(right.id, userId, null, companyId, this.applicationControl.value);
        }
    }
}
