import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-layer-picker',
    templateUrl: './argument-layer-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentLayerPickerComponent {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments: Argument[];

    @Input() argument: Argument;
    @Input() contextId: string;
    @Input() scope: Scope;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
}
