import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    DataFormatAttribute,
    setTypeBasedPropertiesOfAttribute,
} from '../../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';
import { AddPropertyDialogComponent } from '../../add-property-dialog/add-property-dialog.component';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { MatDialog } from '@angular/material/dialog';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'codex-object-type-settings',
    templateUrl: './object-type-settings.component.html',
    standalone: false,
})
export class ObjectTypeSettingsComponent {
    @Input() attribute: DataFormatAttribute;
    @Output() change: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly logger: LoggerService,
        private readonly dialog: MatDialog
    ) {}

    onAttributeChange($event: { attribute: DataFormatAttribute }) {
        this.change.emit();
    }

    onAddPropertyClicked(): void {
        const dialogRef = this.dialog.open(
            AddPropertyDialogComponent,
            Object.assign({ restoreFocus: true }, backofficeEnvironment.dialogConfig.extrasmall)
        );

        dialogRef.afterClosed().subscribe((result: { name: string; type: any }) => {
            if (result) {
                this.logger.info(`Received: ${JSON.stringify(result)}`);

                if (!this.attribute.attributes) {
                    this.attribute.attributes = [];
                }

                const { name, type } = result;

                let attribute = {
                    id: new GUIDFunctions().newGuid(),
                    name,
                    noCodeXType: type,
                    types: [type],
                    nullable: true,
                    required: false,
                };
                setTypeBasedPropertiesOfAttribute(attribute);
                this.attribute.attributes.push(attribute);
                setTimeout(() => initFlowbite());
                this.change.emit();
            }
        });
    }

    identifyAttribute(index, item: DataFormatAttribute) {
        return index;
    }

    onDeleteAttribute(attribute: DataFormatAttribute) {
        this.attribute.attributes = this.attribute.attributes?.filter(attributeToCheck => attribute.name !== attributeToCheck.name);
        setTimeout(() => initFlowbite());
        this.change.emit();
    }

    reorderAttributes(event: CdkDragDrop<DataFormatAttribute[]>): void {
        if (this.attribute.attributes) {
            moveItemInArray(this.attribute.attributes, event.previousIndex, event.currentIndex);
            setTimeout(() => initFlowbite());
            this.change.emit();
        }
    }
}
