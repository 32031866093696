import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    Color,
    ColorV2,
    DesignSystem,
    DesignSystemEditorFacade,
    FontSize,
    FontStyleDto,
    LetterSpacing,
} from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FormBuilder, FormControl } from '@angular/forms';
import { WordSpacing } from '../../../../../../data-access/editor/src/lib/designsystem/models/font/word-spacing.model';

declare let $: any;

class Font {
    constructor(param: { size: string; style: string; styles: string[]; family: string }) {}
}

@Component({
    selector: 'codex-font-picker',
    templateUrl: './font-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class FontPickerComponent implements OnInit, OnDestroy {
    fontWeightControl = new FormControl('400');
    fontWeightMap = new Map<string, string>();

    fontSizeControl = this.fb.group({
        value: [this.defaultFontStyle().fontSizeValue],
        unit: [this.defaultFontStyle().fontSizeUnit],
    });
    fontSizeMap = new Map<string, FontSize>();

    letterSpacingControl = this.fb.group({
        value: [this.defaultFontStyle().letterSpacing],
        unit: [this.defaultFontStyle().letterSpacingUnit],
    });

    wordSpacingControl = this.fb.group({
        value: [],
        unit: [],
    });
    letterSpacingMap = new Map<string, LetterSpacing>();
    wordSpacingMap = new Map<string, WordSpacing>();
    fontStyleControl = new FormControl('normal');
    fontStyleMap = new Map<string, string>();

    fontFamilyMap = new Map<string, string>();
    fontFamilyControl = new FormControl(this.defaultFontStyle().fontFamily);

    textDecorationThicknessControl = this.fb.group({
        value: [parseInt(this.defaultFontStyle().textDecorationThickness.constant.value)],
        unit: [this.defaultFontStyle().textDecorationThickness.constant.unit],
    });

    @Input()
    set fontStyle(fontStyle: FontStyleDto) {
        if (fontStyle) {
            this._fontStyle = fontStyle;
            this.font = new Font({
                family: this._fontStyle.fontFamily,
                size: this._fontStyle.fontSize,
                style: this._fontStyle.fontStyle,
                styles: [this._fontStyle.fontStyle],
            });
        } else {
            this._fontStyle = this.defaultFontStyle();
        }
    }

    _fontStyle: FontStyleDto;

    designSystem$: Observable<DesignSystem>;

    subscriptions = new Subscription();

    public font: Font = new Font({
        family: 'Poppins',
        size: '14px',
        style: 'regular',
        styles: ['regular'],
    });

    @Output()
    changeFont: EventEmitter<{ font: { fontStyle: FontStyleDto } }> = new EventEmitter();

    constructor(
        private hostElementRef: ElementRef,
        private readonly fb: FormBuilder,
        private readonly designSystemFacade: DesignSystemEditorFacade
    ) {}

    ngOnInit(): void {
        this.designSystem$ = this.designSystemFacade.activeDesignSystem.pipe(
            filter(ds => !!ds),
            tap(ds => {
                this.fontSizeMap.clear();
                this.fontWeightMap.clear();
                this.letterSpacingMap.clear();
                this.fontStyleMap.clear();
                this.fontFamilyMap.clear();
                this.wordSpacingMap.clear();

                ds.dark.typographies.forEach(typography => {
                    const variableNames = typography.variableNames;
                    this.fontWeightMap.set(variableNames.fontWeight.name, typography.fontWeight.value + '');
                    this.fontSizeMap.set(variableNames.fontSize.name, typography.fontSize);
                    this.letterSpacingMap.set(variableNames.letterSpacing.name, typography.letterSpacing);
                    this.fontStyleMap.set(variableNames.fontStyle.name, typography.fontStyle.keyword.toLowerCase());
                    this.fontFamilyMap.set(variableNames.fontFamily.name, typography.fontFamily.fontFamilyNames[0]);
                });

                this.initialiseWeightControl();
                this.initialiseFontSizeControl();
                this.initialiseLetterSpacing();
                this.initialiseStyleControl();
                this.initialiseFamilyControl();
                this.initialiseWordSpacing();
            })
        );

        this.subscriptions.add(this.designSystem$.subscribe());
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    defaultFontStyle() {
        return {
            fontFamily: 'Poppins',
            fontSizeValue: 14,
            fontSizeUnit: 'pixels',
            letterSpacing: null,
            letterSpacingValue: null,
            letterSpacingUnit: 'normal',
            fontUrls: null,
            fontSize: '14px',
            fontWeight: null,
            fontStretch: null,
            fontStyle: 'normal',
            fontVariant: null,
            fontVariantCaps: null,
            fontColor: null,
            color: null,
            fontAlignment: 'justify',
            lineHeight: null,
            lineHeightValue: 14,
            lineHeightUnit: 'pixels',
            textDecorationThickness: {
                constant: {
                    value: '1',
                    unit: 'pixels',
                },
            },
            whiteSpace: 'normal',
        };
    }

    // This is a dirty hack to make font-picker correctly close without leaving blank space
    // We have to do this because the lib uses visibility instead of display:none or *ngIf to hide the picker...
    openDialog() {
        // We use the host element because we want multiple font-pickers on one page to mind their own business.
        const target = $(this.hostElementRef.nativeElement).find('font-picker').find('.font-picker')[0];
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutationRecord => {
                if ($(this.hostElementRef.nativeElement).find('font-picker').find('.font-picker').css('visibility') === 'visible') {
                    $(this.hostElementRef.nativeElement).find('font-picker').attr('class', 'open-picker');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('top', '0');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('left', 'unset');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('right', 'unset');
                } else {
                    $(this.hostElementRef.nativeElement).find('font-picker').attr('class', 'closed-picker');
                }
            });
        });

        observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    }

    onChangeColor($event: ColorV2) {
        this._fontStyle.colorV2 = $event;
        if ($event.reference) {
            this._fontStyle.color = new Color({ reference: $event.reference.variable.name });
        } else {
            this._fontStyle.color = new Color({ value: $event.hex.value });
        }

        this.onChangeFont();
    }

    onChangeTextDecorationColor($event: ColorV2) {
        this._fontStyle.textDecorationColor = $event;
        this.onChangeFont();
    }

    onChangeLineHeight($event: { value: string }) {
        this._fontStyle.lineHeight = $event.value;
        this.onChangeFont();
    }

    handleStyleChanged(): void {
        this._fontStyle.fontStyle = this.fontStyleControl.value;
        this._fontStyle.style = {
            constant: {
                value: this.fontStyleControl.value,
            },
        };

        this.onChangeFont();
    }

    handleWeightChanged(): void {
        this._fontStyle.fontWeight = this.fontWeightControl.value;
        this._fontStyle.weight = {
            constant: {
                value: this.fontWeightControl.value,
            },
        };

        this.onChangeFont();
    }

    onChangeFont() {
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onChangeFontAlignment() {
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onFontSizeChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        const { viewPort, unit } = fontSizeChanged;

        this._fontStyle.fontSizeValue = viewPort;
        this._fontStyle.fontSizeUnit = unit;

        // Size
        this._fontStyle.size = {
            constant: {
                value: viewPort + '',
                unit: unit,
            },
        };

        this.onChangeFont();
    }

    onTextDecorationThicknessChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        const { viewPort, unit } = fontSizeChanged;
        // Size
        this._fontStyle.textDecorationThickness = {
            constant: {
                value: viewPort + '',
                unit: unit,
            },
        };
        this.onChangeFont();
    }

    onLetterSpacingChanged(letterSpacingChanged: { viewPort: number; unit: string }) {
        const { viewPort, unit } = letterSpacingChanged;
        this._fontStyle.letterSpacing = {
            constant: {
                value: viewPort + '',
                unit: unit,
            },
        };

        this.onChangeFont();
    }

    onWordSpacingChanged(wordSpacingChanged: { viewPort: number; unit: string }) {
        const { viewPort, unit } = wordSpacingChanged;
        this._fontStyle.wordSpacing = {
            constant: {
                value: viewPort + '',
                unit: unit,
            },
        };

        this.onChangeFont();
    }

    onLineHeightChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        this._fontStyle.lineHeightValue = fontSizeChanged.viewPort;
        this._fontStyle.lineHeightUnit = fontSizeChanged.unit;
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onFontFamilyChanged(fontFamilyChanged: { value: string }) {
        if (this._fontStyle.fontFamily !== fontFamilyChanged.value) {
            this._fontStyle.fontFamily = fontFamilyChanged.value;
            this._fontStyle.family = {
                constant: {
                    value: fontFamilyChanged.value,
                },
            };
            this.onChangeFont();
        }
    }

    private initialiseWeightControl(): void {
        if (this._fontStyle) {
            if (this._fontStyle.weight && this.fontWeightMap) {
                const weight = this._fontStyle.weight;
                if (weight.reference) {
                    const value = this.fontWeightMap.get(weight.reference.variable.name);
                    this.fontWeightControl.setValue(value, { emitEvent: false });
                } else {
                    this.fontWeightControl.setValue(weight.constant.value, { emitEvent: false });
                }
            } else {
                this.fontWeightControl.setValue(this._fontStyle.fontWeight, { emitEvent: false });
            }
        }
    }

    private initialiseFontSizeControl(): void {
        if (this._fontStyle) {
            const size = this._fontStyle.size;
            if (size && this.fontSizeMap) {
                if (size.constant) {
                    this.fontSizeControl.setValue(
                        {
                            value: +size.constant.value,
                            unit: size.constant.unit === 'px' ? 'pixels' : size.constant.unit,
                        },
                        { emitEvent: false }
                    );
                } else {
                    const variableName = size.reference.variable.name;
                    const referencedSize = this.fontSizeMap.get(variableName);
                    this.fontSizeControl.setValue(
                        {
                            value: referencedSize.value,
                            unit: referencedSize.unit === 'px' ? 'pixels' : referencedSize.unit,
                        },
                        { emitEvent: false }
                    );
                }
            }
        }
    }

    private initialiseTextDecorationThicknessControl(): void {
        if (this._fontStyle) {
            const size = this._fontStyle.textDecorationThickness;
            if (size && this.fontSizeMap) {
                if (size.constant) {
                    this.textDecorationThicknessControl.setValue(
                        {
                            value: +size.constant.value,
                            unit: size.constant.unit === 'px' ? 'pixels' : size.constant.unit,
                        },
                        { emitEvent: false }
                    );
                } else {
                    const variableName = size.reference.variable.name;
                    const referencedSize = this.fontSizeMap.get(variableName);
                    this.textDecorationThicknessControl.setValue(
                        {
                            value: referencedSize.value,
                            unit: referencedSize.unit === 'px' ? 'pixels' : referencedSize.unit,
                        },
                        { emitEvent: false }
                    );
                }
            }
        }
    }

    private initialiseWordSpacing(): void {
        if (this._fontStyle) {
            const wordSpacing = this._fontStyle.wordSpacing;
            if (wordSpacing && wordSpacing.constant) {
                this.wordSpacingControl.setValue(
                    {
                        value: +wordSpacing.constant.value,
                        unit: wordSpacing.constant.unit === 'px' ? 'pixels' : wordSpacing.constant.unit,
                    },
                    { emitEvent: false }
                );
            }
        }
    }

    private initialiseLetterSpacing(): void {
        if (this._fontStyle) {
            const letterSpacing = this._fontStyle.letterSpacing;
            if (letterSpacing && this.letterSpacingMap) {
                if (letterSpacing.constant) {
                    this.letterSpacingControl.setValue(
                        {
                            value: +letterSpacing.constant.value,
                            unit: letterSpacing.constant.unit === 'px' ? 'pixels' : letterSpacing.constant.unit,
                        },
                        { emitEvent: false }
                    );
                } else {
                    const variableName = letterSpacing.reference.variable.name;
                    const referencedSize = this.letterSpacingMap.get(variableName);
                    this.letterSpacingControl.setValue(
                        {
                            value: referencedSize.value,
                            unit: referencedSize.unit === 'px' ? 'pixels' : referencedSize.unit,
                        },
                        { emitEvent: false }
                    );
                }
            }
        }
    }

    private initialiseStyleControl(): void {
        if (this._fontStyle) {
            if (this._fontStyle.style && this.fontStyleMap) {
                const style = this._fontStyle.style;
                if (style.reference) {
                    const value = this.fontStyleMap.get(style.reference.variable.name);
                    this.fontStyleControl.setValue(value, { emitEvent: false });
                } else {
                    this.fontStyleControl.setValue(style.constant.value, { emitEvent: false });
                }
            } else {
                this.fontStyleControl.setValue(this._fontStyle.fontStyle, { emitEvent: false });
            }
        }
    }

    private initialiseFamilyControl(): void {
        if (this._fontStyle) {
            if (this._fontStyle.family && this.fontFamilyMap) {
                const family = this._fontStyle.family;
                if (family.reference) {
                    const value = this.fontFamilyMap.get(family.reference.variable.name);
                    this.fontFamilyControl.setValue(value, { emitEvent: false });
                } else {
                    this.fontFamilyControl.setValue(family.constant.value, { emitEvent: false });
                }
            } else {
                this.fontStyleControl.setValue(this._fontStyle.fontFamily, { emitEvent: false });
            }
        }
    }
}
