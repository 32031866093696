import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Action } from '@backoffice/editor/data-access/action';
import { Subscription } from 'rxjs';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-edit-properties',
    templateUrl: './action-edit-properties.component.html',
    styleUrls: ['./action-edit-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditPropertiesComponent implements OnInit, OnDestroy {
    @Input() set action(action: Action) {
        this._action = action;
        this.formGroup.patchValue({ ...action });
    }

    @Output() actionUpdated = new EventEmitter<{ action: Action }>();

    _action: Action;

    formGroup = this.fb.group({
        name: new FormControl('', { nonNullable: true }),
        description: new FormControl('', { nonNullable: true }),
        prototype: new FormControl(false),
    });

    private readonly subscriptions = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this.formGroup.controls.name.valueChanges.subscribe(value => {
            this.onChangeName(value);
        });
        this.formGroup.controls.description.valueChanges.subscribe(value => {
            this.onChangeDescription(value);
        });
        this.formGroup.controls.prototype.valueChanges.subscribe(value => {
            this.onPrototypeChanged(value);
        });
    }

    onChangeName(value: string): void {
        if (this._action.name !== value) {
            this._action.name = value;
            this.actionUpdated.emit({ action: this._action });
        }
    }

    onChangeDescription(value: string): void {
        if (this._action.description !== value) {
            this._action.description = value;
            this.actionUpdated.emit({ action: this._action });
        }
    }

    onChangeIconName(action: Action, $event): void {
        if (this._action.iconName !== $event) {
            this._action.iconName = $event;
            this.actionUpdated.emit({ action: this._action });
        }
    }

    onPrototypeChanged(value: boolean): void {
        if (this._action.prototype !== value) {
            this._action.prototype = value;
            this.actionUpdated.emit({ action: this._action });
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
