import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'codex-info',
    styleUrls: ['./codex-info.component.scss'],
    templateUrl: 'codex-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class CodexInfoComponent {}
