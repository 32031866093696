import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { BorderStyleDto } from '../../../../../interfaces/style/border.style.dto';
import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';

export interface InputPartStyleDto extends GenericStyleDto {
    labelFontStyleDto: FontStyleDto;
    answerFontStyleDto: FontStyleDto;
    inputBorderStyle: BorderStyleDto;
    inputBackground: BackgroundStyleDto;
}
