<ng-container *ngIf="showSubArguments">
    <div class="ml-3">
        <div class="text-xxs" *ngIf="!this._argument.subArguments || this._argument.subArguments.length === 0">
            {{ 'v2.action.argument.template.arguments.not.found' | translate }}
        </div>
        <ng-container *ngFor="let argument of subArguments; trackBy: identifyArgument">
            <argument-value-picker
                class="mb-1 block"
                *ngIf="showArgumentInput(argument, _arguments)"
                [argument]="argument"
                [arguments]="subArguments"
                [contextId]="contextId"
                [root]="root"
                [scope]="scope"
                [language]="language"
                [onlyLiteralValues]="onlyLiteralValues"
                (openTab)="this.openTab.emit($event)"
                (argumentUpdated)="onActionExecutionArgumentUpdated($event)"></argument-value-picker>
        </ng-container>
    </div>
</ng-container>
