<div class="title-button-header">
    <label class="small-input-label">{{ title }}</label>
    <div class="title-button-header-buttons">
        <button class="primary-button button-medium" type="button" (click)="addParameterToSelect()">
            <mat-icon>add</mat-icon>
            <span>{{ 'v2.parameter.select.add.parameter' | translate }}</span>
        </button>
    </div>
</div>
<div
    *ngIf="selectedParameters && selectedParameters.length > 0"
    [attr.id]="'accordion-parameter-select'"
    [attr.data-accordion]="'accordion-parameter-select'"
    data-active-classes="none"
    data-inactive-classes="none">
    <div *ngFor="let selectedParameter of selectedParameters; let i = index; trackBy: getParameterIdentifier">
        <h3
            [attr.id]="'accordion-selected-parameter-' + selectedParameter.id"
            [attr.data-name]="'accordion-selected-parameter-header-' + selectedParameter.id"
            class="p-0">
            <button
                type="button"
                class="accordion-heading"
                [class.accordion-heading-first]="i === 0"
                [class.accordion-heading-last]="i === this.selectedParameters.length - 1"
                [attr.data-accordion-target]="'#accordion-selected-parameter-' + selectedParameter.id + '-body'"
                [attr.aria-controls]="'accordion-selected-parameter-' + selectedParameter.id + '-body'"
                aria-expanded="true">
                <span class="accordion-heading-icon">{{ selectedParameter.name ? selectedParameter.name : 'Select a parameter' }}</span>
                <button
                    color="warn"
                    class="accordion-heading-button destructive-accordion-heading-button"
                    (click)="onRemoveSelectedParameter(i); $event.stopPropagation()"
                    [matTooltip]="'v2.parameter.selected.remove' | translate">
                    <mat-icon>clear</mat-icon>
                </button>
                <svg
                    data-accordion-icon
                    class="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h3>
        <div
            [attr.id]="'accordion-selected-parameter-' + selectedParameter.id + '-body'"
            [attr.data-name]="'content-' + selectedParameter.id"
            [attr.aria-labelledby]="'accordion-selected-parameter-' + selectedParameter.id + '-body'"
            [class.accordion-panel-last]="i === this.selectedParameters.length - 1"
            [class.accordion-panel]="i !== this.selectedParameters.length - 1"
            class="hidden">
            <app-select-parameter
                [selectedParameterId]="selectedParameter.parameterId"
                (changeParameter)="changeSelectedParameter(i, $event)"
                [root]="root">
            </app-select-parameter>
        </div>
    </div>
</div>
