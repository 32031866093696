<ng-container *ngIf="invocation && invocation.nextInvocations.length > 0">
    <ul class="alt-item-list" cdkDropList (cdkDropListDropped)="dropNextInvocation($event)">
        <ng-container *ngFor="let nextInvocation of invocation.nextInvocations; let i = index">
            <li cdkDrag>
                <div class="nextinvocationSequence align-center justify-center w-[35px] flex flex-none">
                    <span
                        class="flex justify-center items-center rounded-full w-[24px] h-[24px] border-2 border-dashed mat-elevation-z3 mb-auto mt-auto"
                        >{{ i + 1 }}</span
                    >
                </div>
                <mat-icon class="mb-auto mt-auto flex-none"
                    >{{ action?.program?.invocationMap?.get(nextInvocation.invocationId)?.method?.iconName }}
                </mat-icon>
                <span class="grow leading-4 text-xxs whitespace-normal flex items-center">{{
                    action?.program?.invocationMap?.get(nextInvocation.invocationId)?.name | truncate: 65
                }}</span>
                <mat-icon [matTooltip]="'general.drag-n-drop-tooltip' | translate" class="simple-icon-button"> import_export </mat-icon>
            </li>
        </ng-container>
    </ul>
</ng-container>
