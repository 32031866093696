<ng-container [formGroup]="formGroup" *ngIf="_argument">
    <div class="mb-2">
        <label class="small-input-label">Task name</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-name" formControlName="name" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Description</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-description" formControlName="description" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Expected output</label>
        <input
            class="small-input"
            type="text"
            autocomplete="off"
            id="{{ this._argument.id }}-expectedOutput"
            formControlName="expectedOutput" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">agent</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-agent" formControlName="agent" />
    </div>
</ng-container>
