import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument, Parameter, Scope } from '@backoffice/data-access/editor';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'argument-array-input',
    templateUrl: './argument-array-input.component.html',
    styleUrls: ['./argument-array-input.component.scss'],
    standalone: false,
})
export class ArgumentArrayInputComponent implements OnInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
        this.loadSubArguments();
    }

    _argument!: Argument;

    @Input()
    language!: string;

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    showSubArguments: boolean = false;

    openedSubArgument!: string;

    constructor() {}

    ngOnInit(): void {}

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(subArgument => {
            subArgument.parameter = new Parameter();
            subArgument.parameter.type = 'JSON_FIELD';
        });
        this.showSubArguments = true;
    }

    onRemoveSubArgument(argument: Argument) {
        this._argument.subArguments.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    onAddField() {
        const subArgument = new Argument();
        subArgument.id = new GUIDFunctions().newGuid();
        subArgument.parameterId = new GUIDFunctions().newGuid();
        subArgument.parameter = new Parameter();
        subArgument.parameter.type = 'JSON_FIELD';
        if (!this._argument.subArguments) {
            this._argument.subArguments = [];
        }
        this._argument.subArguments.push(subArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
