<div class="card-line" *ngIf="false">
    <div class="billing-card">
        <h1 class="!mb-2">Select application</h1>
        <form [formGroup]="form">
            <label for="select-application" class="small-input-label">Select application</label>
            <select id="select-application" class="small-input" formControlName="applicationId">
                <option *ngFor="let application of applications$ | async" [value]="application.id">
                    {{ application.name }}
                </option>
            </select>
        </form>
    </div>
</div>
<ng-container *ngIf="{ billingPackage: this.billingPackage$ | async } as observables">
    <div *ngIf="observables.billingPackage" class="card-line flex flex-row gap-2 justify-between">
        <div class="billing-card shrink grow">
            <h1 class="!mb-2">Subscription</h1>
            <ng-container *ngIf="observables.billingPackage?.type === 'FREE'">
                <strong
                    >{{ 'v2.billing.package.' + observables.billingPackage.type | translate }} (<a
                        class="change-subscription"
                        (click)="initChooseSubscription()"
                        >Change</a
                    >)</strong
                >
            </ng-container>
            <ng-container *ngIf="observables.billingPackage?.type !== 'FREE' && observables.billingPackage?.type.startsWith('APPSUMO_')">
                <strong
                    >{{ 'v2.billing.package.' + observables.billingPackage.type | translate }} (<a
                        class="change-subscription"
                        href="https://appsumo.com/account/products/"
                        >Change</a
                    >)</strong
                >
            </ng-container>
            <ng-container *ngIf="observables.billingPackage?.type !== 'FREE' && !observables.billingPackage?.type.startsWith('APPSUMO_')">
                <strong
                    >{{ observables.billingPackage.amountOfCores }} Core(s) (<a
                        class="change-subscription"
                        (click)="initUpdateBillingDetails()"
                        >Change</a
                    >)</strong
                >
            </ng-container>
        </div>

        <div class="billing-card shrink grow">
            <h1 class="!mb-2">Billing</h1>
            <strong *ngIf="observables.billingPackage?.type !== 'FREE' && !observables.billingPackage?.type.startsWith('APPSUMO_')"
                ><a class="change-subscription" (click)="initUpdateBillingDetails()">Manage billing details</a></strong
            >
            <strong *ngIf="observables.billingPackage?.type !== 'FREE' && observables.billingPackage?.type.startsWith('APPSUMO_')"
                >Manage billing details in AppSumo</strong
            >
            <strong *ngIf="observables.billingPackage?.type === 'FREE'">None</strong>
        </div>
    </div>

    <div class="card-line w-full mt-2">
        <div class="billing-card w-full">
            <h1 class="!mb-2">Usage</h1>
            <codex-application-usage [billingPackage]="billingPackage$ | async"></codex-application-usage>
        </div>
    </div>
</ng-container>
