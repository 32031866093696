import 'reflect-metadata';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageNumberDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/components/layout/search-results/model/page-number.dto';

declare var $: any;

@Component({
    selector: 'search-results-paging',
    styleUrls: ['./search-results-paging.component.scss'],
    templateUrl: 'search-results-paging.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SearchResultsPagingComponent implements OnInit, OnChanges {
    @Input()
    currentPage: number;

    @Input()
    firstPage: number;

    @Input()
    lastPage: number;

    @Input()
    pages: PageNumberDto[];

    lastPageIndex: number;

    cappedMinimum: number;
    cappedMaximum: number;

    capOffset = 5;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.lastPageIndex = this.pages.length - 1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['currentPage'] && changes['currentPage'].previousValue !== changes['currentPage'].currentValue) {
            this.calculateCappedMaxMini();
        }
    }

    calculateCappedMaxMini() {
        if (this.currentPage - this.capOffset < 0) {
            this.cappedMinimum = 0;
            if (this.currentPage + this.capOffset > this.pages.length) {
                this.cappedMaximum = this.pages.length;
            } else {
                this.cappedMaximum = this.cappedMinimum + this.capOffset * 2;
            }
        } else {
            if (this.currentPage + this.capOffset > this.pages.length - 1) {
                this.cappedMaximum = this.pages.length;
                this.cappedMinimum = this.cappedMaximum - this.capOffset * 2;
            } else {
                this.cappedMaximum = this.currentPage + this.capOffset;
                this.cappedMinimum = this.currentPage - this.capOffset;
            }
        }
    }

    changePageParam(page: PageNumberDto) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: page.page },
            queryParamsHandling: 'merge',
        });
    }
}
