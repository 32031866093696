import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssistantMessageDto } from 'backoffice/data-access/assistant';
import { AssistantFacade } from 'backoffice/data-access/assistant';
import { AssistantGenerateDataformatValidateComponent } from '../assistant-generate-dataformat-validate/assistant-generate-dataformat-validate.component';
import { take } from 'rxjs/operators';
import { DataFormat, DataFormatEditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-assistant-generate-api-endpoints-input',
    templateUrl: './assistant-generate-api-endpoints-input.component.html',
    styleUrls: ['./assistant-generate-api-endpoints-input.component.scss'],
    standalone: false,
})
export class AssistantGenerateApiEndpointsInputComponent implements OnInit {
    formGroup: FormGroup;

    @Output()
    addMessage: EventEmitter<{ message: AssistantMessageDto }> = new EventEmitter<{ message: AssistantMessageDto }>();

    chosenDataFormats: DataFormat[] = [];
    constructor(
        private fb: FormBuilder,
        private assistantFacade: AssistantFacade,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.formGroup = this.fb.group({
            input: ['', Validators.required],
        });
        this.addMessage.emit({
            message: {
                user: false,
                text: 'Would you like me to create some api endpoints for you? Please provide me with as much information as possible of the data-formats you need.',
            },
        });
    }

    onAddDataFormat($event) {
        this.dataFormatEditorFacade
            .findById($event.value)
            .pipe(take(1))
            .subscribe(dataFormat => {
                this.chosenDataFormats.push(dataFormat);
            });
    }

    onRemoveDataFormat(dataFormat: DataFormat) {
        this.chosenDataFormats.splice(this.chosenDataFormats.indexOf(dataFormat), 1);
    }

    sendInput($event) {
        const prompt = this.formGroup.get('input')?.value;
        this.addMessage.emit({ message: { user: true, text: this.formGroup.get('input')?.value } });
        this.formGroup.get('input')?.reset();
        this.assistantFacade
            .generateApis(
                this.chosenDataFormats.map(dataFormat => dataFormat.id),
                prompt
            )
            .subscribe(response => {
                this.addMessage.emit({
                    message: {
                        user: false,
                        text: response.response,
                        dialoglink: true,
                        dialogClass: AssistantGenerateDataformatValidateComponent,
                        response: response,
                    },
                });
                this.changeDetectorRef.detectChanges();
            });
    }
}
