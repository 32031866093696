import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { KeycloakService } from 'keycloak-angular';
import { catchError, delay, filter, map, retry, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    closeEditApplicationDialog,
    closeOnboardingDialog,
    createApplication,
    createApplicationError,
    createApplicationSuccess,
    deleteApplication,
    deleteApplicationError,
    deleteApplicationSuccess,
    editApplication,
    editApplicationError,
    editApplicationSuccess,
    editCompany,
    editCompanyError,
    editCompanySuccess,
    openEditApplicationDialog,
    requestApplications,
    requestApplicationsError,
    requestApplicationsSuccess,
    requestBillingPackage,
    requestBillingPackageError,
    requestBillingPackageSuccess,
    requestCompanies,
    requestCompaniesError,
    requestCompaniesSuccess,
    requestUserAchievements,
    requestUserAchievementsSuccess,
    requestUserInformation,
    requestUserInformationError,
    requestUserInformationSuccess,
    requestUserRights,
    requestUserRightsError,
    requestUserRightsSuccess,
    resetSelectedApplication,
    selectApplication,
    selectApplicationError,
    selectApplicationSuccess,
    selectCompany,
    selectCompanyError,
    selectCompanySuccess,
} from './context.actions';
import { UserContext } from '@shared/interfaces/user-context.interface';
import { RouterFacade } from '../../../../../../libs/backoffice/utils-routing/src/lib/facade/router.facade';
import { CompanyService } from '@core/services/company.service';
import { AppFacade } from '@core/facades/app.facade';
import { of, throwError } from 'rxjs';
import { routerNavigated } from '../router-store/router.actions';
import { ApplicationService } from '@core/services/application.service';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { WorkspaceCookieFacade } from '../../../modules/core/facades/workspace-cookie.facade';
import { ApplicationCreatedDto } from '../../v2-application/dto/application-created.dto';
import { Application } from '../../v2-application/model/application';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationUsageAlertComponent } from '../../../features/billing/components/application-usage-alert/application-usage-alert.component';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { ApplicationEditComponent } from '../../../features/edit-application/components/application-edit/application-edit.component';
import { CompanyEditComponent } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/page/my-company-edit.component';
import { driver } from 'driver.js';
import { AchievementsService } from '@core/services/achievements.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../application.state';

@Injectable()
export class Effects {
    requestUserInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserInformation),
            switchMap(() =>
                this.keyCloakService
                    .loadUserProfile()
                    .then(keycloak => {
                        const { id, firstName: firstname, lastName: name, email } = keycloak;
                        const info: UserContext = {
                            id: keycloak['userProfileMetadata']['attributes'].id ? keycloak['userProfileMetadata']['attributes'].id[0] : id,
                            firstname,
                            name,
                            language: 'EN',
                            email,
                        };
                        return requestUserInformationSuccess({ info });
                    })
                    .catch(e => {
                        return requestUserInformationError();
                    })
            )
        )
    );

    requestUserAchievements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserInformationSuccess, requestUserAchievements),
            switchMap(({ info }) => {
                return this.achievementsService.getAchievements(info.id).pipe(
                    map(achievements => {
                        return requestUserAchievementsSuccess({ achievements, info });
                    })
                );
            })
        )
    );

    requestUserInformationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserAchievementsSuccess),
            map(({ info }) => requestCompanies({ userId: info.id }))
        )
    );

    requestCompanies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestCompanies),
            switchMap(({ userId }) => {
                // Get query parameters from the URL
                const urlParams = new URLSearchParams(window.location.search);
                const appsumoParam = urlParams.get('appsumo');
                if (appsumoParam === 'true') {
                    // APPSUMO FALLBACK Logic
                    return this.companyService.getUserCompaniesNoCache(userId).pipe(
                        tap(companies => console.log(companies)),
                        switchMap(companies => {
                            if (!companies || companies.length === 0) {
                                console.log('retrying');
                                return throwError(() => new Error('Empty result'));
                            }
                            console.log('no retry necessary');
                            console.log(companies);
                            return of(companies);
                        }),
                        retry({ count: 3, delay: 1000 }),
                        switchMap(companies => {
                            const recentCompanyIds = this.workspaceCookieFacade.recentCompanies(userId);
                            const recentCompanies = this.appFacade.determineRecentCompanies(companies, recentCompanyIds);
                            return of(requestCompaniesSuccess({ companies, recentCompanies }));
                        }),
                        catchError(error => {
                            const appSumoCode = urlParams.get('appSumoCode');
                            return this.companyService.linkUserCompanies(userId, appSumoCode).pipe(
                                delay(500),
                                switchMap(() => {
                                    return this.companyService.getUserCompaniesNoCache(userId).pipe(
                                        map(companies => {
                                            const recentCompanyIds = this.workspaceCookieFacade.recentCompanies(userId);
                                            const recentCompanies = this.appFacade.determineRecentCompanies(companies, recentCompanyIds);
                                            return requestCompaniesSuccess({ companies, recentCompanies });
                                        }),
                                        catchError(() => of(requestCompaniesError))
                                    );
                                })
                            );
                        })
                    );
                } else {
                    return this.companyService.getUserCompanies(userId).pipe(
                        map(companies => {
                            const recentCompanyIds = this.workspaceCookieFacade.recentCompanies(userId);
                            const recentCompanies = this.appFacade.determineRecentCompanies(companies, recentCompanyIds);
                            return requestCompaniesSuccess({ companies, recentCompanies });
                        }),
                        catchError(() => of(requestCompaniesError))
                    );
                }
            })
        )
    );

    determineCompanyToBeSelected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestCompaniesSuccess),
            filter(({ companies }) => companies && companies.length > 0),
            withLatestFrom(this.routerFacade.companyId),
            map(([{ companies, recentCompanies }, companyId]) => {
                if (!companyId) {
                    return selectCompany({ companyId: recentCompanies[0].id });
                } else {
                    const company = companies.find(c => c.id === companyId);
                    if (company) {
                        return selectCompany({ companyId: company.id });
                    } else if (companies.length > 0) {
                        return selectCompany({ companyId: companies[0].id });
                    }
                }
            })
        )
    );

    selectCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompany),
            withLatestFrom(this.appFacade.companies, this.appFacade.user),
            map(([{ companyId }, companies, user]) => {
                const company = companies.find(c => c.id === companyId);
                if (!!company) {
                    this.workspaceCookieFacade.registerCompany(user.id, company);
                    return selectCompanySuccess({ company });
                } else {
                    return selectCompanyError();
                }
            })
        )
    );

    routeToCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            withLatestFrom(this.routerFacade.companyId),
            tap(([{ company }, companyId]) => {
                if (companyId !== company.id) {
                    this.routerFacade.navigate([`/company/${company.id}`]);
                }
            }),
            map(([{ company }, _]) => requestApplications({ companyId: company.id }))
        )
    );

    selectCompanySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            map(({ company }) => requestApplications({ companyId: company.id }))
        )
    );

    editCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editCompany),
            switchMap(({ company }) =>
                this.companyService.update(company).pipe(
                    map(() => editCompanySuccess({ company })),
                    catchError(() => of(editCompanyError()))
                )
            )
        )
    );

    editCompanySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editCompanySuccess),
                tap(() => {
                    this.snackbar.open(this.translate.instant('my.company.edit.success'), null, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    requestApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplications),
            switchMap(({ companyId }) =>
                this.applicationService.getApplications(companyId).pipe(
                    withLatestFrom(this.appFacade.user),
                    map(([{ content }, user]) => {
                        this.workspaceCookieFacade.cleanupRecentApplications(user.id, companyId, content);
                        const lastUsedApps = this.workspaceCookieFacade.recentApplications(user.id, companyId);
                        const recentApps = this.appFacade.determineRecentApplications(content, lastUsedApps);
                        this.workspaceCookieFacade.registerRecentApplications(
                            user.id,
                            companyId,
                            recentApps.map(r => ({
                                id: r.id,
                                name: r.name,
                            }))
                        );
                        return requestApplicationsSuccess({ applications: content, recentApplications: recentApps });
                    }),
                    catchError(error => {
                        return of(requestApplicationsError());
                    })
                )
            )
        )
    );

    determineApplicationToBeSelected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplicationsSuccess),
            filter(({ applications }) => applications && applications.length > 0),
            withLatestFrom(this.routerFacade.applicationId),
            map(([{ applications, recentApplications }, applicationId]) => {
                if (!applicationId) {
                    return selectApplication({ applicationId: recentApplications[0].id });
                } else {
                    const application = applications.find(a => a.id === applicationId);
                    if (!!application) {
                        return selectApplication({ applicationId: application.id });
                    } else {
                        return selectApplication({ applicationId: recentApplications[0].id });
                    }
                }
            })
        )
    );

    selectApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplication),
            withLatestFrom(this.appFacade.applications, this.appFacade.user),
            map(([{ applicationId }, applications, user]) => {
                const selectedApplication = applications.find(a => a.id === applicationId);
                if (!!selectedApplication) {
                    this.workspaceCookieFacade.registerApplication(user.id, selectedApplication);
                    return selectApplicationSuccess({ application: selectedApplication });
                } else {
                    return selectApplicationError();
                }
            })
        )
    );

    createApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplication),
            withLatestFrom(this.appFacade.selectedCompany),
            switchMap(([_, company]) =>
                this.applicationService.createApplication({ companyId: company.id }, company.id).pipe(
                    switchMap((response: ApplicationCreatedDto) => this.applicationService.getApplication(response.id, company.id)),
                    map((application: ApplicationDto) =>
                        createApplicationSuccess({
                            application: application,
                        })
                    ),
                    catchError(() => of(createApplicationError()))
                )
            )
        )
    );

    selectApplicationAfterCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplicationSuccess),
            map(({ application }) => selectApplication({ applicationId: application.id }))
        )
    );

    openEditApplicationDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(openEditApplicationDialog),
                switchMap(({ application }) =>
                    this.dialog
                        .open(
                            ApplicationEditComponent,
                            Object.assign(
                                {
                                    data: {
                                        application,
                                    },
                                },
                                backofficeEnvironment.dialogConfig.normal
                            )
                        )
                        .afterClosed()
                )
            ),
        { dispatch: false }
    );

    closeEditApplicationDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(closeEditApplicationDialog),
            filter(({ application }) => !!application),
            map(({ action, application }) => {
                const { id: applicationId, companyId } = application;
                if (action === 'UPDATE') {
                    return editApplication({ application });
                } else if (action === 'DELETE') {
                    return deleteApplication({ applicationId, companyId });
                }
            })
        )
    );

    editApplicationAfterCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplicationSuccess),
            map(({ application }) => openEditApplicationDialog({ application }))
        )
    );

    editApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editApplication),
            switchMap(({ application }) =>
                this.applicationService.updateApplication(new Application(application), application.companyId).pipe(
                    map(() => editApplicationSuccess({ application })),
                    catchError(() => of(editApplicationError()))
                )
            )
        )
    );

    editApplicationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editApplicationSuccess),
                withLatestFrom(this.appFacade.selectedApplication),
                tap(() =>
                    this.snackbar.open(this.translate.instant('v2.application.edit.success'), null, {
                        panelClass: ['success'],
                    })
                ),
                map(([{ application }, selectedApplication]) =>
                    selectedApplication?.id != application.id ? selectApplication({ applicationId: application.id }) : null
                )
            ),
        { dispatch: false }
    );

    deleteApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApplication),
            switchMap(({ applicationId, companyId }) =>
                this.applicationService.deleteApplication(applicationId, companyId).pipe(
                    withLatestFrom(this.appFacade.applications, this.appFacade.user),
                    map(([_, applications, user]) => {
                        const updatedApplicationList = applications.filter(application => application.id !== applicationId);
                        this.workspaceCookieFacade.removeApplication(user.id, companyId, applicationId);
                        const lastUsedApps = this.workspaceCookieFacade.recentApplications(user.id, companyId);
                        const recentApplications = this.appFacade.determineRecentApplications(updatedApplicationList, lastUsedApps);
                        return deleteApplicationSuccess({ applicationId, recentApplications });
                    }),
                    catchError(() => of(deleteApplicationError()))
                )
            )
        )
    );

    deleteApplicationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApplicationSuccess),
            withLatestFrom(this.appFacade.selectedApplication),
            filter(([{ applicationId }, { id }]) => id === applicationId),
            switchMap(([{ applicationId, recentApplications }, { companyId }]) => {
                if (recentApplications.length > 0) {
                    return of(selectApplication({ applicationId: recentApplications[0].id }));
                } else {
                    return of(resetSelectedApplication());
                }
            })
        )
    );

    resetSelectedApp$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetSelectedApplication),
                withLatestFrom(this.appFacade.selectedCompany),
                tap(([_, { id }]) => this.routerFacade.navigate([`/company/${id}`]))
            ),
        { dispatch: false }
    );

    routeToApplication$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectApplicationSuccess),
                withLatestFrom(this.routerFacade.applicationId),
                tap(([{ application }, applicationId]) => {
                    if (applicationId !== application.id) {
                        this.routerFacade.navigate([`/company/${application.companyId}/application/${application.id}`]);
                    }
                })
            ),
        { dispatch: false }
    );

    activateGenericOnboarding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectApplicationSuccess),
                concatLatestFrom(() => [this.appFacade.user]),
                tap(([{ application }, userContext]) => {
                    let genericOnboardingAchievement = userContext.achievements.find(
                        achievement => achievement.code === 'GENERIC_ONBOARDING'
                    );
                    if (!genericOnboardingAchievement) {
                        const driverObj = driver({
                            showProgress: true,
                            onDestroyed: () => {
                                this.achievementsService
                                    .createAchievement(userContext.id, 'GENERIC_ONBOARDING')
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.store.dispatch(requestUserAchievements({ info: userContext }));
                                    });
                            },
                            steps: [
                                {
                                    popover: {
                                        title: 'Welcome to NoCode-X — Let’s build something amazing together!',
                                        description:
                                            '<img src="images/theme/guide.gif" width="300" style="height: 300px; margin:auto;"/> We’re so excited to have you here! This quick guide will walk you through the <strong>essentials</strong>, so you can hit the ground running and unlock the full potential of NoCode-X. <br></br> We’ll cover the basics step by step to get you comfortable, and don’t worry — if you decide to skip or forget something, helpful tooltips will be there to guide you along the way. Plus, whenever you reach key points in the app, we’ll pick up right where you need us.' +
                                            "<br></br>Ready? Let’s dive in and start our journey — You'll see soon that the possibilities are endless!",
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: 'codex-company-selector',
                                    popover: {
                                        title: 'Workspaces',
                                        description:
                                            'As a user you can have access to one or more workspaces. Click here to switch between workspaces or create new workspaces.',
                                    },
                                },
                                {
                                    element: 'codex-application-selector',
                                    popover: {
                                        title: 'Applications',
                                        description:
                                            'Within a workspace you can have as many applications as you want, Click here to create new applications or switch between existing applications.',
                                    },
                                },
                                {
                                    element: '.sidenav-items',
                                    popover: {
                                        title: 'Building your application',
                                        description:
                                            '<p>Within your application, you can build using the following concepts:</p>\n' +
                                            '<ul>\n' +
                                            '  <li><strong>Build templates</strong></li>' +
                                            '  <li><strong>Build APIs</strong></li>' +
                                            '  <li><strong>Build Actions</strong></li>' +
                                            '  <li><strong>Build Data-formats</strong></li>' +
                                            '  <li><strong>Store/Use Data</strong></li>' +
                                            '  <li><strong>Store/Use Media</strong></li>' +
                                            '  <li><strong>Build Scheduled jobs</strong></li>' +
                                            '  <li><strong>Create/Use Groups/Rights</strong></li>' +
                                            '  <li><strong>Install plugins from our Hub</strong></li>' +
                                            '</ul><br>' +
                                            '<p>To navigate between these different building concepts within NoCode-X, use the side navigation.</p>',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="templates"]',
                                    popover: {
                                        title: 'Building pages',
                                        description:
                                            'Templates in NoCode-X are the foundation for building pages and reusable elements (which can be used across pages). They allow you to design and structure your web applications efficiently through our visual editor.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="api"]',
                                    popover: {
                                        title: 'Building APIs',
                                        description:
                                            "APIs within NoCode-X enable users to create, manage, and integrate custom functionalities without writing code. By setting up APIs, users can facilitate data exchange, automate workflows, and connect NoCode-X applications with external services. This feature empowers users to build scalable and efficient solutions tailored to their specific needs through an intuitive, no-code interface. <br></br><strong>Watch out</strong> this is not to be confused with 'Calling an API' (Which is handled in 'logic' or 'actions'), this is 'Creating' & 'Exposing' an API.",
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="action"]',
                                    popover: {
                                        title: 'Actions: Building logic',
                                        description:
                                            'Every web application / automation needs some sort of logic. In NoCode-X we define logic by creating "Actions". Actions are small pieces of logic that when put together with other actions, templates, data, etc... they serve a bigger whole defined as a use case.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="dataformat"]',
                                    popover: {
                                        title: 'Data-Formats: Building a database',
                                        description:
                                            '<p>Data formats are where you define the contract for the data you want to use within NoCode-X. This is mostly but not limited to use within actions & the built-in database.</p><p>If you want to be able to reason with data for example use a certain property of a data in a function within one of your actions you are best of with creating a data-format & mark that data as being of that type.</p><p>If you want to store data in the built-in database, you will have to create a data-format. The data you store will have to be created & valid according to the rules within that data-format.</p>',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="data"]',
                                    popover: {
                                        title: 'Data',
                                        description:
                                            'NoCode-X has a built in database. You can use this database to store data used within the applications that you build with NoCode-X. There are several ways of creating/using data.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="media"]',
                                    popover: {
                                        title: 'Building logic',
                                        description:
                                            'Every web application / automation needs some sort of logic. In NoCode-X we define logic by creating "Actions". Actions are small pieces of logic that when put together with other actions, templates, data, etc... they serve a bigger whole defined as a use case.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="jobs"]',
                                    popover: {
                                        title: 'Scheduled jobs',
                                        description:
                                            'Executing logic periodically is often a crucial part of creating automation. Sometimes you need to execute some logic every day, every 5 minutes or even every first saturday of the month. Within NoCode-X you can define a "Job" for this.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="designsystem"]',
                                    popover: {
                                        title: 'Centralized design',
                                        description:
                                            'The design system enables you to centrally create and manage the visual style of your application. It provides a consistent approach to designing and updating the user interface, ensuring that changes to the look and feel are applied uniformly across all elements.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="groups"]',
                                    popover: {
                                        title: 'Building logic',
                                        description:
                                            'Every web application / automation needs some sort of logic. In NoCode-X we define logic by creating "Actions". Actions are small pieces of logic that when put together with other actions, templates, data, etc... they serve a bigger whole defined as a use case.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="rights"]',
                                    popover: {
                                        title: 'Building logic',
                                        description:
                                            'Every web application / automation needs some sort of logic. In NoCode-X we define logic by creating "Actions". Actions are small pieces of logic that when put together with other actions, templates, data, etc... they serve a bigger whole defined as a use case.',
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="hub"]',
                                    popover: {
                                        title: 'The Hub',
                                        description:
                                            "Our Hub contains many prebuilt plugins (automations/ui elements/api calls/etc) that you can utilize to build your own automation with. These are all 'blueprints' so you can tweak them according to your own needs!",
                                    },
                                },
                                {
                                    element: '.sidenav-items div[data-name="plugins"]',
                                    popover: {
                                        title: 'Installed plugins',
                                        description: 'The plugins that you installed from the Hub can be managed here.',
                                    },
                                },
                            ],
                        });
                        driverObj.drive();
                    }
                })
            ),
        { dispatch: false }
    );

    companyIdRouteParamChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigated),
            switchMap(() => this.routerFacade.companyId),
            withLatestFrom(this.appFacade.selectedCompany, this.appFacade.companies),
            filter(([companyId, selectedCompany, companies]) => !!companyId && companyId !== selectedCompany?.id && !!companies),
            map(([companyId]) => selectCompany({ companyId }))
        )
    );

    applicationIdRouteParamChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigated),
            switchMap(() => this.routerFacade.applicationId),
            withLatestFrom(this.appFacade.selectedApplication, this.appFacade.applications),
            filter(
                ([applicationId, selectedApplications, applications]) =>
                    !!applicationId && applicationId !== selectedApplications?.id && !!applications
            ),
            map(([applicationId]) => selectApplication({ applicationId }))
        )
    );

    initiateRights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => requestUserRights())
        )
    );

    requestUserRights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserRights),
            switchMap(() =>
                this.appFacade.getCalculatedRights().pipe(
                    map(calculatedRightSet => {
                        return requestUserRightsSuccess({ calculatedRightSet });
                    }),
                    catchError(() => of(requestUserRightsError))
                )
            )
        )
    );

    initiateRequestBillingPackage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            map(({ company }) => requestBillingPackage({ companyId: company.id }))
        )
    );

    requestBillingPackage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestBillingPackage),
            switchMap(({ companyId }) =>
                this.appFacade.getBillingPackage(companyId).pipe(
                    map(billingPackage => {
                        return requestBillingPackageSuccess({ billingPackage });
                    }),
                    catchError(() => of(requestBillingPackageError))
                )
            )
        )
    );

    requestBillingPackageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestBillingPackageSuccess),
            map(({ billingPackage }) => {
                if (billingPackage.flag === 'RED' || billingPackage.flag === 'YELLOW') {
                    this.dialog
                        .open(ApplicationUsageAlertComponent, backofficeEnvironment.dialogConfig.small)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe((result: { openUsage: boolean }) => {
                            if (result.openUsage) {
                                this.dialog.open(CompanyEditComponent, { minWidth: '80vw', maxHeight: '95vh' });
                            }
                        });
                }
                return billingPackage;
            })
        )
    );

    openOnBoardingDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApplicationSuccess, requestApplicationsSuccess),
            withLatestFrom(this.appFacade.applications),
            map(([, applications]) => applications),
            filter(applications => applications && applications.length == 0),
            switchMap(() => of(createApplication()))
        )
    );

    closeOnboardingDialog$ = createEffect(() => this.actions$.pipe(ofType(closeOnboardingDialog), map(createApplication)));

    constructor(
        protected readonly actions$: Actions,
        private readonly dialog: MatDialog,
        private readonly appFacade: AppFacade,
        private readonly routerFacade: RouterFacade,
        private readonly companyService: CompanyService,
        private readonly applicationService: ApplicationService,
        private readonly keyCloakService: KeycloakService,
        private readonly workspaceCookieFacade: WorkspaceCookieFacade,
        private readonly snackbar: MatSnackBar,
        private readonly translate: TranslateService,
        private readonly achievementsService: AchievementsService,
        private readonly store: Store<ApplicationState>
    ) {}
}
