import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionEditorFacade, Argument, OverviewActionDto, Scope, TabDefinition } from '@backoffice/data-access/editor';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { ActionCreateFormComponent } from '../../action/components/action-create-form/action-create-form.component';

@Component({
    selector: 'argument-action-picker',
    templateUrl: './argument-action-picker.component.html',
    styleUrls: ['./argument-action-picker.component.scss'],
    standalone: false,
})
export class ArgumentActionPickerComponent implements OnInit, OnDestroy {
    @Input() arguments: Argument[];
    @Input() argument: Argument;
    @Input() contextId: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    valueControl(): FormControl {
        return this.form.get('value') as FormControl;
    }

    filterControl(): FormControl {
        return this.form.get('valueFilter') as FormControl;
    }

    filteredActions$!: Observable<OverviewActionDto[]>;
    filterChanged$ = new ReplaySubject<string>();

    form: FormGroup;

    missingAction = false;

    private readonly _subscriptions = new Subscription();

    constructor(
        private fb: FormBuilder,
        private readonly log: LoggerService,
        private actionEditorFacade: ActionEditorFacade
    ) {}

    ngOnInit(): void {
        const actionId = this.argument?.value ? this.removeQuotesIfPresent(this.argument?.value) : '';
        const required = !!this.argument.parameter?.required;

        this.form = this.fb.group({
            value: [actionId, required ? Validators.required : null],
            valueFilter: [''],
        });

        // De subscription kan na de emit van de value komen en dan missen we die.
        this._subscriptions.add(this.filterControl().valueChanges.subscribe(value => this.filterChanged$.next(value)));

        this.filteredActions$ = this.filterChanged$.pipe(
            switchMap(value => this.actionEditorFacade.findAll(value ?? '', [], 'score desc', 0, 10).pipe(map(({ content }) => content)))
        );

        if (!!actionId) {
            this._subscriptions.add(
                this.actionEditorFacade
                    .findById(actionId)
                    .pipe(take(1))
                    .subscribe(action => {
                        if (!action.deleted) {
                            const { name, id } = action;
                            this.filterControl().setValue(name, { emitEvent: true, onlySelf: true });
                            this.valueControl().setValue(id);
                            this.missingAction = false;
                        } else {
                            this.filterControl().setValue('');
                            this.filterControl().setValue('Missing action', { emitEvent: false });
                            this.missingAction = true;
                        }
                    })
            );
        } else {
            this.filterControl().setValue('');
        }

        this._subscriptions.add(this.valueControl().valueChanges.subscribe(value => this.onChange(value)));
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    onChange(value: any) {
        if (`'${value}'` !== this.argument.value) {
            if (value) {
                this.argument.value = `'${value}'`;
            } else {
                this.argument.value = undefined;
            }
            this.missingAction = false;
            this.argumentUpdated.emit({ argument: this.argument });
        }
    }

    onOpenAction(actionId: string) {
        this.openTab.emit({ typeId: this.removeQuotesIfPresent(actionId), type: 'action' });
    }

    onCreateAction() {
        this.actionEditorFacade.create(ActionCreateFormComponent).subscribe(actionCreatedId => {
            this.argument.value = `'${actionCreatedId}'`;
            this.argumentUpdated.emit({ argument: this.argument });
            this.onOpenAction(actionCreatedId);
        });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    deleteActionLink(): void {
        if (this.argument) {
            this.argument.value = undefined;
        }

        this.valueControl().reset(null, { onlySelf: true, emitEvent: false });
        this.filterControl().reset(null, { onlySelf: true, emitEvent: false });

        this.missingAction = false;

        this.argumentUpdated.emit({ argument: this.argument });
    }
}
