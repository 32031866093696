import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { ApplicationSelectorAllComponent } from '../application-selector-all/application-selector-all.component';
import { Router } from '@angular/router';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-application-selector',
    templateUrl: './application-selector.component.html',
    styleUrls: ['./application-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ApplicationSelectorComponent {
    @Input() applications: ApplicationDto[];
    @Input() recentApplications: ApplicationDto[];
    @Input() selectedApplication: ApplicationDto;

    @Output() createClicked = new EventEmitter<void>();
    @Output() editClicked = new EventEmitter<string>();
    @Output() applicationSelect = new EventEmitter<ApplicationDto>();

    private _allApplicationsDialog: MatDialogRef<ApplicationSelectorAllComponent>;

    constructor(
        public dialog: MatDialog,
        public router: Router
    ) {}

    isOpen: boolean;

    onAddApplication(): void {
        this.isOpen = false;
        this.createClicked.emit();
    }

    onApplicationSelected(application: ApplicationDto) {
        this.isOpen = false;
        this.applicationSelect.emit(application);
    }

    onAllApplicationsClicked(): void {
        this._allApplicationsDialog = this.dialog.open(
            ApplicationSelectorAllComponent,
            Object.assign(
                {
                    data: {
                        selectedApplication: this.selectedApplication,
                        applications: this.applications,
                        lastUsedApplications: this.recentApplications,
                    },
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );
        this._allApplicationsDialog.afterClosed().subscribe(result => {
            if (!!result) {
                const { application } = result;
                this.onApplicationSelected(application);
            }
        });
    }

    onEditApplication(applicationId: string): void {
        this.editClicked.emit(applicationId);
        this.isOpen = false;
    }
}
