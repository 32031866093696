import { createAction, props } from '@ngrx/store';
import { ApplicationEventDto } from '../../dto/application-event.dto';

export const createApplicationEvent = createAction('[ACTION] create application event', props<{ applicationEvent: ApplicationEventDto }>());

export const revertToApplicationEvent = createAction(
    '[ACTION] revert application event',
    props<{ applicationEvent: ApplicationEventDto }>()
);

export const undoLastApplicationEvent = createAction('[ACTION] undo last application event');
