<ng-container [formGroup]="formGroup">
    <div class="flex flex-row gap-1 flex-auto items-center">
        <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline">
            <mat-select
                id="scope"
                formControlName="value"
                [placeholder]="'v2.action.argument.scope.select' | translate"
                panelClass="toolpaneSelect scrollbar scrollbar-primary">
                <mat-select-trigger>
                    <ng-container *ngIf="selectedScopeItem"> {{ selectedScopeItem.key }} ({{ selectedScopeItem.type }}) </ng-container>
                    <ng-container *ngIf="!selectedScopeItem"> Select a value from the scope. </ng-container>
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search formControlName="valueFilter" [placeholderLabel]="'v2.action.argument.scope.search' | translate">
                        <span ngxMatSelectNoEntriesFound>
                            {{ 'v2.action.argument.scope.none.found' | translate }}
                        </span>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option
                    class="!h-fit border-b border-light-border dark:border-dark-border hover:!bg-light-button-background"
                    *ngFor="let scopeItem of filteredScopeItems$ | async"
                    [value]="'{' + scopeItem.key + '}'"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="partDescriptionPopover"
                    appendTo="body"
                    [preventOverflow]="false"
                    [boundariesElement]="'rootBoundary'"
                    [appendTo]="'body'"
                    [hideOnMouseLeave]="true"
                    [placement]="NgxFloatUiPlacements.LEFT">
                    <span class="text-xxs font-bold">{{ scopeItem.key }} ({{ scopeItem.type }})</span>
                    <float-ui-content #partDescriptionPopover>
                        <div class="popover">
                            <div class="draggable-card-popover-title">
                                <h3 class="draggable-card-popover-title-element">{{ scopeItem.key }} ({{ scopeItem.type }})</h3>
                            </div>
                            <div class="draggable-card-popover-description">
                                <p>{{ scopeItem.name }}</p>
                                <p class="text-xs">{{ scopeItem.description }}</p>
                            </div>
                        </div>
                    </float-ui-content>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            class="destructive-button button-extra-small !mb-2"
            *ngIf="!!formGroup.get('value').value"
            (click)="clearScopeParameter(); $event.stopPropagation()"
            [matTooltip]="'v2.action.argument.scope.clear' | translate">
            <mat-icon>clear</mat-icon>
        </button>
        <button
            class="secondary-button button-extra-small !mb-2"
            (click)="viewCompleteScope(); $event.stopPropagation()"
            [matTooltip]="'v2.action.scope.view' | translate">
            <mat-icon>search</mat-icon>
        </button>
    </div>
</ng-container>
