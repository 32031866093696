import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'codex-cursor-picker',
    templateUrl: './cursor-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class CursorPickerComponent {
    @Input()
    cursor: string;

    @Output()
    changeCursor: EventEmitter<{ cursor: string }> = new EventEmitter();

    onChangeCursorType($event: string) {
        this.changeCursor.emit({ cursor: $event });
    }
}
