import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnsStyleDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-column-picker',
    templateUrl: './column-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ColumnPickerComponent implements OnInit {
    @Input()
    set columnsStyle(columnsStyle: ColumnsStyleDto) {
        if (columnsStyle) {
            this._columnsStyle = columnsStyle;
        } else {
            this._columnsStyle = this.defaultColumnsStyle();
        }
    }

    _columnsStyle: ColumnsStyleDto;

    @Output()
    changeColumns: EventEmitter<{ columnsStyle: ColumnsStyleDto }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    defaultColumnsStyle(): ColumnsStyleDto {
        return {
            columnAmount: 1,
            columnGutter: '0px',
        };
    }

    onChangeColumnGutter($event: { value: string }) {
        this._columnsStyle.columnGutter = $event.value;
        this.onChangeColumns();
    }

    onChangeColumns() {
        this.changeColumns.emit({ columnsStyle: this._columnsStyle });
    }
}
