import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaddingStyleDto } from '@backoffice/data-access/editor';

interface WrapperStyle {
    paddingStyle?: PaddingStyleDto | null;
    paddingTopStyle?: PaddingStyleDto | null;
    paddingLeftStyle?: PaddingStyleDto | null;
    paddingRightStyle?: PaddingStyleDto | null;
    paddingBottomStyle?: PaddingStyleDto | null;
}

@Component({
    selector: 'codex-padding-picker-wrapper',
    templateUrl: './padding-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class PaddingPickerWrapperComponent {
    @Input()
    firstPanel: boolean = false;

    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Input()
    title: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Output()
    activeWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    activeWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover', 'focus'];

    type: string = 'normal';

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        } else if (this.type === 'active') {
            this.resetActiveWrapperStyle();
        }
    }

    onChangeStyle($event: {
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }) {
        if (!this.wrapperStyle) {
            this.wrapperStyle = {};
        }
        this.wrapperStyle.paddingStyle = $event.padding.paddingStyle;
        this.wrapperStyle.paddingTopStyle = $event.padding.paddingTopStyle;
        this.wrapperStyle.paddingLeftStyle = $event.padding.paddingLeftStyle;
        this.wrapperStyle.paddingBottomStyle = $event.padding.paddingBottomStyle;
        this.wrapperStyle.paddingRightStyle = $event.padding.paddingRightStyle;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: {
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }) {
        if (!this.hoverWrapperStyle) {
            this.hoverWrapperStyle = {};
        }
        this.hoverWrapperStyle.paddingStyle = $event.padding.paddingStyle;
        this.hoverWrapperStyle.paddingTopStyle = $event.padding.paddingTopStyle;
        this.hoverWrapperStyle.paddingLeftStyle = $event.padding.paddingLeftStyle;
        this.hoverWrapperStyle.paddingBottomStyle = $event.padding.paddingBottomStyle;
        this.hoverWrapperStyle.paddingRightStyle = $event.padding.paddingRightStyle;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    onChangeFocusStyle($event: {
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }) {
        if (!this.focusWrapperStyle) {
            this.focusWrapperStyle = {};
        }
        this.focusWrapperStyle.paddingStyle = $event.padding.paddingStyle;
        this.focusWrapperStyle.paddingTopStyle = $event.padding.paddingTopStyle;
        this.focusWrapperStyle.paddingLeftStyle = $event.padding.paddingLeftStyle;
        this.focusWrapperStyle.paddingBottomStyle = $event.padding.paddingBottomStyle;
        this.focusWrapperStyle.paddingRightStyle = $event.padding.paddingRightStyle;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    onChangeActiveStyle($event: {
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }) {
        if (!this.activeWrapperStyle) {
            this.activeWrapperStyle = {};
        }
        this.activeWrapperStyle.paddingStyle = $event.padding.paddingStyle;
        this.activeWrapperStyle.paddingTopStyle = $event.padding.paddingTopStyle;
        this.activeWrapperStyle.paddingLeftStyle = $event.padding.paddingLeftStyle;
        this.activeWrapperStyle.paddingBottomStyle = $event.padding.paddingBottomStyle;
        this.activeWrapperStyle.paddingRightStyle = $event.padding.paddingRightStyle;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }

    resetWrapperStyle() {
        this.wrapperStyle.paddingStyle = null;
        this.wrapperStyle.paddingTopStyle = null;
        this.wrapperStyle.paddingLeftStyle = null;
        this.wrapperStyle.paddingRightStyle = null;
        this.wrapperStyle.paddingBottomStyle = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.paddingStyle = null;
        this.hoverWrapperStyle.paddingTopStyle = null;
        this.hoverWrapperStyle.paddingLeftStyle = null;
        this.hoverWrapperStyle.paddingRightStyle = null;
        this.hoverWrapperStyle.paddingBottomStyle = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    resetFocusWrapperStyle() {
        this.focusWrapperStyle.paddingStyle = null;
        this.focusWrapperStyle.paddingTopStyle = null;
        this.focusWrapperStyle.paddingLeftStyle = null;
        this.focusWrapperStyle.paddingRightStyle = null;
        this.focusWrapperStyle.paddingBottomStyle = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetActiveWrapperStyle() {
        this.activeWrapperStyle.paddingStyle = null;
        this.activeWrapperStyle.paddingTopStyle = null;
        this.activeWrapperStyle.paddingLeftStyle = null;
        this.activeWrapperStyle.paddingRightStyle = null;
        this.activeWrapperStyle.paddingBottomStyle = null;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }
}
