import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[cd-code]',
    standalone: false,
})
export class CdCodeDirective {
    constructor(private control: NgControl) {}

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;
        this.control.control.setValue(input.value.replace(/ /g, '_'));
    }
}
