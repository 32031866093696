<form
    *ngIf="data && dataFormat && !!body && formGroup"
    [formGroup]="formGroup"
    (ngSubmit)="onUpdate()"
    novalidate
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">
    <div class="general-information-container">
        <div class="name-and-description-container">
            <label for="name" class="small-input-label">{{ 'v2.data.edit.name' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="name"
                name="name"
                required
                cdkFocusInitial
                formControlName="name"
                autocomplete="off" />
            <label for="description" class="small-input-label">{{ 'v2.data.edit.description' | translate }}</label>
            <textarea
                class="small-input"
                type="text"
                id="description"
                name="description"
                formControlName="description"
                autocomplete="off"></textarea>
        </div>
        <div class="icon-picker-edit-container">
            <codex-icon-picker [icon]="data.iconName" (changeIcon)="onChangeIconName($event)"></codex-icon-picker>
        </div>
    </div>
    <codex-tag-input type="DATA" [linkedEntityId]="data.id" [applicationId]="data.applicationId" [companyId]="data.companyId">
    </codex-tag-input>
    <hr class="seperator" />
    <label class="toggle-line">
        {{ 'v2.data.edit.installFromHub' | translate }}
        <input type="checkbox" value="" class="sr-only peer" formControlName="installFromHub" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>
    <hr class="seperator" />
    <div class="flex justify-between">
        <div>
            <div class="flex items-center gap-1 mb-2">
                <div class="text-xxs font-bold">Id:&nbsp;</div>
                <div class="text-xxs flex">{{ data.id }}</div>
                <svg class="icon icon-button" [cdkCopyToClipboard]="data.id" [matTooltip]="'Copy ID'" aria-hidden="true" focusable="false">
                    <use href="#copy" class="ui-element"></use>
                </svg>
                <svg
                    class="icon icon-button"
                    (click)="this.refresh.emit()"
                    [matTooltip]="'Refresh data'"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#refresh" class="ui-element"></use>
                </svg>
            </div>
            <div class="flex items-center gap-1 mb-2">
                <div class="text-xxs font-bold">Data-format:&nbsp;</div>
                <div class="text-xxs">{{ dataFormat.name }}</div>
                <svg
                    class="icon icon-button"
                    (click)="this.openDataFormat(dataFormat.id, dataFormat.name)"
                    [matTooltip]="'Edit data-format'"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#edit" class="ui-element"></use>
                </svg>
            </div>
        </div>
    </div>
    <hr class="seperator" />
    <ngx-monaco-editor
        class="mb-3 mt-3"
        [options]="editorOptions"
        [(ngModel)]="body"
        (onInit)="this.onInitEditor($event)"
        [ngModelOptions]="{ standalone: true }"></ngx-monaco-editor>
    <mat-error *ngFor="let error of errors">{{ error }}</mat-error>
    <div class="flex justify-end gap-1">
        <button class="primary-button button-medium" [disabled]="errors.length > 0" data-name="save" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" data-name="cancel" (click)="this.cancel.emit()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
