import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { OverviewDataDto } from '../dto/overview/overview-data.dto';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Data } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';
import { DataCreateDto } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/dto/create-data.dto';
import { DataCreatedDto } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/dto/data-created.dto';

@Injectable()
export class DataService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: DataCreateDto, companyId: string, applicationId: string): Observable<DataCreatedDto> {
        this.log.debug('Creating data', [dto]);
        if (dto.type === 'CREATE_DATA_FROM_CSV' && !!dto.file && !!dto.file.nativeFile) {
            let formData: FormData = new FormData();
            formData.append('file', dto.file.nativeFile);
            formData.append('dataFormatId', dto.dataFormatId);
            formData.append('environment', dto.environment);
            return this.httpClient.post<DataCreatedDto>(
                `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/from-csv`,
                formData
            );
        } else {
            return this.httpClient.post<DataCreatedDto>(
                `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/`,
                {
                    ...dto,
                    dataBody: dto.dataBody ? JSON.parse(dto.dataBody) : undefined,
                }
            );
        }
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting data <${id}>`);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${id}`);
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<Data> {
        this.log.debug(`Find data by id <${id}>`);
        return this.httpClient
            .get<Data>(`${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(data => plainToClass(Data, data)));
    }

    public update(data: Data, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating data`, [data]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${data.id}`,
            data
        );
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewDataDto>> {
        this.log.debug('Getting data overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewDataDto>>(`${backofficeEnvironment.rest.v2.data}`, { params });
    }
}
