<ng-container [formGroup]="form">
    <div class="flex flex-row gap-1 flex-auto items-center">
        <mat-form-field class="mb-2" subscriptSizing="dynamic" [attr.class]="this.missingAction ? 'select-error' : ''" appearance="outline">
            <mat-select id="actions" formControlName="value" panelClass="toolpaneSelect scrollbar scrollbar-primary">
                <mat-option>
                    <ngx-mat-select-search
                        [clearSearchInput]="false"
                        [placeholderLabel]="'v2.action.argument.action.search' | translate"
                        formControlName="valueFilter">
                        <span ngxMatSelectNoEntriesFound>
                            {{ 'v2.action.argument.action.none.found' | translate }}
                        </span>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let action of filteredActions$ | async" [value]="action.id">
                    <mat-icon>{{ action.iconName ? action.iconName : 'bolt' }}</mat-icon>
                    <span>{{ action.name }}</span> <br />
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            (click)="deleteActionLink(); $event.stopPropagation()"
            *ngIf="!!valueControl().value"
            [matTooltip]="'v2.action.argument.action.clear' | translate"
            class="destructive-button button-extra-small !mb-2">
            <mat-icon>clear</mat-icon>
        </button>
        <button
            (click)="onOpenAction(this.argument.value); $event.stopPropagation()"
            *ngIf="!!valueControl().value && !this.missingAction"
            [matTooltip]="'v2.action.argument.action.edit' | translate"
            class="primary-button button-extra-small !mb-2">
            <mat-icon>edit</mat-icon>
        </button>
        <button
            (click)="onCreateAction(); $event.stopPropagation()"
            *ngIf="!valueControl().value || this.missingAction"
            [matTooltip]="'v2.action.argument.action.create' | translate"
            class="primary-button button-extra-small !mb-2">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <mat-error *ngIf="missingAction" class="!text-xxs">{{ 'v2.action.argument.action.select.not.found' | translate }} </mat-error>
</ng-container>
