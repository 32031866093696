<ng-container *ngIf="contractScopeArgument && contractTemplateArgument">
    <argument-template-picker
        [arguments]="[contractTemplateArgument]"
        [argument]="contractTemplateArgument"
        [scope]="scope"
        [contextId]="contextId"
        (argumentUpdated)="onUpdateContractTemplateArgument($event)"
        (openTab)="this.openTab.emit($event)"></argument-template-picker>

    <argument-scope-picker
        [arguments]="[contractScopeArgument]"
        [argument]="contractScopeArgument"
        [scope]="scope"
        [contextId]="contextId"
        (argumentUpdated)="onUpdateContractScopeArgument($event)">
    </argument-scope-picker>
</ng-container>
