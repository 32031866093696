<div class="flex gap-1 justify-end mb-2">
    <h2>Properties</h2>
    <button
        *ngIf="!editorModeEnabled"
        class="secondary-button button-medium"
        type="button"
        aria-label="Switch to editor mode"
        (click)="onModeClicked()">
        <mat-icon>code</mat-icon>
    </button>
    <button
        *ngIf="editorModeEnabled"
        class="secondary-button button-medium"
        type="button"
        aria-label="Switch to UI mode"
        (click)="onModeClicked()">
        <mat-icon>view_list</mat-icon>
    </button>
    <button *ngIf="false" class="secondary-button button-medium" type="button" aria-label="Switch to editor mode" (click)="onAIClicked()">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#generative" class="ui-element"></use>
        </svg>
        Generate properties
    </button>
    <button *ngIf="!editorModeEnabled" class="primary-button button-medium" type="button" (click)="openAddPropertyDialog()">
        <mat-icon>add_circle</mat-icon>
        {{ 'v2.data-format.edit.add.property' | translate }}
    </button>
</div>
<ng-container *ngIf="editorModeEnabled">
    <ngx-monaco-editor
        [options]="editorOptions"
        [(ngModel)]="editorCode"
        [ngModelOptions]="{ standalone: true }"
        (onInit)="onInitEditor($event)"
        (ngModelChange)="onEditorChanged($event)">
    </ngx-monaco-editor>
</ng-container>
<ng-container *ngIf="!editorModeEnabled">
    <div
        [attr.id]="'accordion-data-format-attributes-' + dataFormat.id"
        data-accordion="collapse"
        data-active-classes="none"
        data-inactive-classes="none"
        cdkDropList
        (cdkDropListDropped)="reorderAttributes($event)"
        [cdkDropListData]="dataFormat.attributes">
        <ng-container *ngFor="let attribute of dataFormat.attributes; let i = index; trackBy: identifyAttribute">
            <h3 cdkDrag [attr.id]="'accordion-open-heading-' + attribute.id" [attr.data-name]="'header-' + attribute.id" class="p-0">
                <button
                    type="button"
                    class="accordion-heading"
                    [class.accordion-heading-first]="i === 0"
                    [class.accordion-heading-last]="i === dataFormat.attributes.length - 1"
                    [attr.data-accordion-target]="'#accordion-open-body-' + attribute.id"
                    [attr.aria-controls]="'accordion-open-body-' + attribute.id"
                    aria-expanded="true">
                    <span class="accordion-heading-icon">
                        {{ attribute.name }} ({{ 'v2.types.' + attribute.noCodeXType | translate }})</span
                    >
                    <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                        <mat-icon>import_export</mat-icon>
                    </button>
                    <div *cdkDragPreview class="border bg-primarylight border-primary w-[100%] !h-9 rounded"></div>
                    <button
                        type="button"
                        class="accordion-heading-button destructive-accordion-heading-button"
                        (click)="onDeleteAttribute(attribute)"
                        [matTooltip]="'Remove attribute'">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <svg
                        data-accordion-icon
                        class="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h3>
            <codex-json-schema-property
                [index]="i"
                [attributeAmount]="dataFormat.attributes.length"
                [attribute]="attribute"
                (propertyChanged)="onAttributeChanged($event)">
            </codex-json-schema-property>
        </ng-container>
    </div>
</ng-container>
