import { createAction, props } from '@ngrx/store';
import { UserContext } from '@shared/interfaces/user-context.interface';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { CalculatedRightSet } from '../../authenticated/dto/calculatedrightset';
import { BillingPackage } from '@billing/dto/billingpackage';
import { AchievementDto } from '@shared/interfaces/achievement.dto';

export const requestUserInformation = createAction('[ACTION] request user information');
export const requestUserInformationSuccess = createAction('[EFFECT] request user information success', props<{ info: UserContext }>());
export const requestUserInformationError = createAction('[EFFECT] request user information error');

export const requestUserAchievementsSuccess = createAction(
    '[EFFECT] request user achievements success',
    props<{ achievements: AchievementDto[]; info: UserContext }>()
);

export const requestUserAchievements = createAction('[EFFECT] request user achievements', props<{ info: UserContext }>());

export const requestCompanies = createAction('[ACTION] request companies', props<{ userId: string }>());
export const requestCompaniesSuccess = createAction(
    '[EFFECT] request companies success',
    props<{ companies: CompanyDto[]; recentCompanies: CompanyDto[] }>()
);
export const requestCompaniesError = createAction('[EFFECT] request companies error');

export const selectCompany = createAction('[ACTION] select company', props<{ companyId }>());
export const selectCompanySuccess = createAction('[ACTION] select company success', props<{ company: CompanyDto }>());
export const selectCompanyError = createAction('[ACTION] select company error');

export const createCompanySuccess = createAction('[EFFECT] create company success', props<{ company: CompanyDto }>());
export const createCompanyError = createAction('[EFFECT] create company error');

export const editCompany = createAction('[ACTION] edit company', props<{ company: CompanyDto }>());
export const editCompanySuccess = createAction('[EFFECT] edit company success', props<{ company: CompanyDto }>());
export const editCompanyError = createAction('[EFFECT] edit company error');

export const requestApplications = createAction('[ACTION] request applications for company', props<{ companyId }>());
export const requestApplicationsSuccess = createAction(
    '[EFFECTS] request applications for company succes',
    props<{ applications: ApplicationDto[]; recentApplications: ApplicationDto[] }>()
);
export const requestApplicationsError = createAction('[EFFECTS] request applications for company error');

export const selectApplication = createAction('[ACTION] select application', props<{ applicationId: string }>());
export const selectApplicationSuccess = createAction('[EFFECT] select application success', props<{ application: ApplicationDto }>());
export const selectApplicationError = createAction('[EFFECT] select application error');
export const resetSelectedApplication = createAction('[ACTION] reset selected application');

export const createApplication = createAction('[ACTION] create application');
export const createApplicationSuccess = createAction('[EFFECT] create application success', props<{ application: ApplicationDto }>());
export const createApplicationError = createAction('[EFFECT] create application error');

export const editApplication = createAction('[ACTION] edit application', props<{ application: ApplicationDto }>());
export const editApplicationSuccess = createAction('[EFFECT] edit application success', props<{ application: ApplicationDto }>());
export const editApplicationError = createAction('[EFFECT] edit application error');

export const deleteApplication = createAction('[ACTION]', props<{ applicationId: string; companyId: string }>());
export const deleteApplicationSuccess = createAction(
    '[EFFECT] delete application success',
    props<{ applicationId: string; recentApplications: ApplicationDto[] }>()
);
export const deleteApplicationError = createAction('[EFFECT] delete application error');

export const requestUserRights = createAction('[ACTION] request user rights');
export const requestUserRightsSuccess = createAction(
    '[EFFECT] request user rights success',
    props<{ calculatedRightSet: CalculatedRightSet }>()
);
export const requestUserRightsError = createAction('[EFFECT] request user rights error');

export const requestBillingPackage = createAction('[ACTION] request billing package', props<{ companyId }>());
export const requestBillingPackageSuccess = createAction(
    '[EFFECT] request billing package success',
    props<{ billingPackage: BillingPackage }>()
);
export const requestBillingPackageError = createAction('[EFFECT] request billing package error');
export const billingPackageWarningIssued = createAction(
    '[EFFECT] billing package warning issued',
    props<{ billingPackage: BillingPackage }>()
);

export const openOnboardingDialog = createAction('[ACTION] open onboarding dialog');
export const closeOnboardingDialog = createAction('[ACTION] close onboarding dialog');

export const openEditApplicationDialog = createAction('[ACTION] open edit application dialog', props<{ application: ApplicationDto }>());
export const closeEditApplicationDialog = createAction(
    '[ACTION] close edit application dialog',
    props<{ action: string; application: ApplicationDto }>()
);
