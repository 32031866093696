import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { AchievementDto } from '@shared/interfaces/achievement.dto';

@Injectable()
export class AchievementsService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    public getAchievements(userId: string): Observable<AchievementDto[]> {
        this.log.debug('Getting user achievements', [userId]);
        return this.httpClient.get<AchievementDto[]>(`${backofficeEnvironment.rest.v2.achievements}?userId=${userId}`);
    }

    public createAchievement(userId: string, code: string): Observable<void> {
        this.log.debug('Creating user achievement', [userId]);
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.achievements}${code}?userId=${userId}`, null);
    }
}
