import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Api } from '../../../../../../data-access/editor/src/lib/api/model/api';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-api-edit-form',
    templateUrl: './api-edit-form.component.html',
    styleUrls: ['./api-edit-form.component.scss'],
    standalone: false,
})
export class ApiEditFormComponent implements OnInit {
    @Input() public api: Api;

    @Output()
    public update: EventEmitter<{ api: Api }> = new EventEmitter<{ api: Api }>();

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter();

    public formGroup: FormGroup;

    public methods: string[] = ['GET', 'POST', 'PUT', 'DELETE'];

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: [this.api.name, Validators.required],
            description: [this.api.description],
            endpoint: [this.api.endpoint, Validators.required],
            method: [this.api.apiMethod, Validators.required],
        });
    }

    onChangeIconName(iconName: string) {
        this.api.iconName = iconName;
    }

    onUpdate() {
        if (this.formGroup.valid) {
            this.api.name = this.formGroup.get('name').value;
            this.api.description = this.formGroup.get('description').value;
            this.api.endpoint = this.formGroup.get('endpoint').value;
            this.api.apiMethod = this.formGroup.get('method').value;
            this.update.emit({
                api: this.api,
            });
        }
    }

    onAuthorizationUpdated($event: { authenticatedAccess: boolean }) {
        this.api.authenticatedAccess = $event.authenticatedAccess;
        this.update.emit({
            api: this.api,
        });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
