import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'codex-marketplace-wrapper-page',
    templateUrl: './marketplace-wrapper.component.html',
    standalone: false,
})
export class MarketplaceWrapperComponent implements OnInit {
    currentView: 'browse' | 'detail' | 'add' = 'browse';
    productId: string;

    ngOnInit() {}

    handleLoadProductDetailPage($event: { productId: string }) {
        this.currentView = 'detail';
        this.productId = $event.productId;
    }

    handleLoadAddProductPage() {
        this.currentView = 'add';
    }
}
