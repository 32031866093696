import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { plainToInstance } from 'class-transformer';
import { GenerateDataFormatResponse } from '../../../../../../data-access/assistant/src/lib/dto/generate-data-format-response.dto';
import { DataFormat, DataFormatEditorFacade, EditorFacade, JsonSchema } from '@backoffice/data-access/editor';
import { forkJoin, map, of, switchMap, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'app-assistant-generate-dataformat-validate',
    templateUrl: './assistant-generate-dataformat-validate.component.html',
    styleUrls: ['./assistant-generate-dataformat-validate.component.scss'],
    standalone: false,
})
export class AssistantGenerateDataformatValidateComponent implements OnInit {
    error: boolean;

    promptMessage: string;

    responseMessage: string;

    constructor(
        public dialogRef: MatDialogRef<AssistantGenerateDataformatValidateComponent>,
        public dataFormatEditorFacade: DataFormatEditorFacade,
        private readonly editorFacade: EditorFacade,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: {
                promptMessage: string;
                response: GenerateDataFormatResponse;
            };
        }
    ) {}

    actions: {
        type: string;
        id: string;
        jsonschema: JsonSchema | null;
        dataFormat: DataFormat;
    }[] = [];

    ngOnInit() {
        this.error = false;
        this.promptMessage = this.data.message.promptMessage;
        this.responseMessage = this.data.message.response.response;
        this.actions = this.data.message.response.actions
            .filter(action => action.type !== '' && action.jsonschema !== '')
            .map(action => {
                try {
                    const dataFormat = new DataFormat();
                    dataFormat.id = new GUIDFunctions().newGuid();
                    // @ts-ignore
                    dataFormat.jsonSchema = plainToInstance(JsonSchema, action.jsonschema);
                    return {
                        type: action.type,
                        id: action.id,
                        jsonschema: dataFormat.jsonSchema,
                        dataFormat,
                    };
                } catch (error) {
                    this.error = true;
                }
            });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onRetry() {
        this.dialogRef.close();
    }

    handleSchemaChange(
        action: {
            type: string;
            id: string;
            jsonschema: JsonSchema | null;
            dataFormat: DataFormat;
        },
        jsonSchema: JsonSchema
    ) {
        let title = action.jsonschema?.title;
        let description = action.jsonschema?.description;
        action.jsonschema = jsonSchema;
        action.jsonschema.title = title;
        action.jsonschema.description = description;
    }
    onCreate() {
        of(this.actions)
            .pipe(
                switchMap(actions => {
                    return forkJoin(
                        actions.map(action => {
                            if (action.type === 'create') {
                                return this.dataFormatEditorFacade
                                    .createWithoutDialog(action.jsonschema?.title, action.jsonschema?.description)
                                    .pipe(
                                        switchMap(createdDataFormat => this.dataFormatEditorFacade.findById(createdDataFormat.id)),
                                        map(createdDataFormat => {
                                            createdDataFormat.name = action.jsonschema.title;
                                            createdDataFormat.description = action.jsonschema.description;
                                            createdDataFormat.jsonSchema = action.jsonschema;
                                            return createdDataFormat;
                                        }),
                                        take(1),
                                        tap(createdDataFormat => {
                                            this.dataFormatEditorFacade.update(createdDataFormat, true);
                                        })
                                    );
                            }
                            if (action.type === 'update') {
                                return this.dataFormatEditorFacade.findById(action.id).pipe(
                                    map(dataFormatToUpdate => {
                                        dataFormatToUpdate.name = action.jsonschema.title;
                                        dataFormatToUpdate.description = action.jsonschema.description;
                                        dataFormatToUpdate.jsonSchema = action.jsonschema;
                                        return dataFormatToUpdate;
                                    }),
                                    take(1),
                                    tap(createdDataFormat => {
                                        this.dataFormatEditorFacade.update(createdDataFormat, true);
                                    })
                                );
                            } else {
                                return of(null);
                            }
                        })
                    );
                })
            )
            .subscribe(updated => {
                this.dialogRef.close({ updated });
            });
    }
}
