import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssistantMessageDto } from 'backoffice/data-access/assistant';
import { AssistantFacade } from '../../../../../../data-access/assistant/src/lib/facade/assistant.facade';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
    selector: 'app-assistant',
    templateUrl: './assistant.component.html',
    styleUrls: ['./assistant.component.scss'],
    standalone: false,
})
export class AssistantComponent {
    showAssistantDialog = false;

    chooseType = true;

    showChat = false;

    type: string;

    formGroup: FormGroup;

    messages: AssistantMessageDto[] = [];

    @Input()
    userName: string;

    constructor(
        private assistantFacade: AssistantFacade,
        public changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog
    ) {}

    toggleAssistant() {
        this.showAssistantDialog = !this.showAssistantDialog;
    }

    startTypedChat(type: string) {
        this.chooseType = false;
        this.showChat = true;
        this.type = type;
        this.changeDetectorRef.detectChanges();
    }

    onAddMessage($event: { message: AssistantMessageDto }) {
        this.messages.push($event.message);
    }

    openValidationDialog(component: ComponentType<any>, message: AssistantMessageDto) {
        this.dialog.open(
            component,
            Object.assign(
                {
                    data: {
                        message: message,
                    },
                },
                backofficeEnvironment.dialogConfig.big
            )
        );
    }
}
