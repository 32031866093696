<div data-name="generative" class="link" *ngIf="backofficeEnvironment.vjanuary" (click)="openGenerative()">
    <svg class="icon" aria-hidden="true" focusable="false">
        <use href="#generative" class="ui-element"></use>
    </svg>
</div>
<div
    data-name="application-logs"
    class="link"
    (click)="openDialog.emit('logs')"
    matTooltip="Application Logs"
    [matTooltipPosition]="'right'">
    <svg class="icon" aria-hidden="true" focusable="false">
        <use href="#logs" class="fill-ui-element"></use>
    </svg>
</div>
<div data-name="audit-logs" class="link" (click)="openDialog.emit('audit-logs')" matTooltip="Audit Logs" [matTooltipPosition]="'right'">
    <svg class="icon" aria-hidden="true" focusable="false">
        <use href="#logs" class="fill-ui-element"></use>
    </svg>
</div>
<div class="relative">
    <div
        data-name="preview"
        data-dropdown-toggle="previewDropDown"
        data-dropdown-trigger="hover"
        class="link"
        matTooltip="Preview application"
        [matTooltipPosition]="'right'">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#play" class="ui-element"></use>
        </svg>
    </div>

    <div id="previewDropDown" class="dropdown-items hidden">
        <ul class="dropdown-items-wrapper" aria-labelledby="dropdownDelayButton">
            <li>
                <a
                    href="https://dev-{{ application.applicationDomainName }}.{{ environment.applicationHost }}"
                    class="dropdown-item"
                    target="_blank">
                    <mat-icon>archive</mat-icon>
                    <span>Open in development</span>
                </a>
            </li>
            <li>
                <a
                    href="https://test-{{ application.applicationDomainName }}.{{ environment.applicationHost }}"
                    class="dropdown-item"
                    target="_blank">
                    <mat-icon>check_circle</mat-icon>
                    <span>Open in test</span></a
                >
            </li>
            <li>
                <a
                    href="https://accept-{{ application.applicationDomainName }}.{{ environment.applicationHost }}"
                    class="dropdown-item"
                    target="_blank">
                    <mat-icon>check_circle</mat-icon>
                    <span>Open in accept</span></a
                >
            </li>
            <li>
                <a
                    href="https://{{ application.applicationDomainName }}.{{ environment.applicationHost }}"
                    class="dropdown-item"
                    target="_blank">
                    <mat-icon>archive</mat-icon>
                    <span>Open in production</span></a
                >
            </li>
        </ul>
    </div>
</div>
<button
    *ngIf="application && showVersionNav"
    data-name="publish-button"
    class="primary-button button-medium"
    (click)="openApplicationVersions.emit()"
    [matTooltip]="'v2.navigation.application.version' | translate">
    {{ 'v2.navigation.application.version' | translate }}
</button>
