import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Argument, TabDefinition, Scope, createArguments } from '@backoffice/data-access/editor';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'argument-composed-list',
    templateUrl: './argument-composed-list.component.html',
    standalone: false,
})
export class ArgumentComposedListComponent implements OnInit {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.calculateComposedTitles();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
        this.loadSubArguments();
    }

    _argument!: Argument;

    @Input() language: string;
    @Input() contextId: string;
    @Input() scope: Scope;
    @Input() root: Argument[];

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    openedSubArgument!: number;

    ngOnInit() {}

    loadSubArguments(): void {
        this._argument?.subArguments?.forEach(composedArgument => {
            let parameter = this._argument.parameter?.parameters?.find(parameter => composedArgument.parameterId === parameter.id);
            if (parameter) {
                composedArgument.parameter = parameter;
            }
        });
    }

    onAddArgument() {
        const subArgument = new Argument();
        if (this._argument?.parameter?.parameters && this._argument?.parameter?.parameters[0]) {
            subArgument.parameterId = this._argument?.parameter?.parameters[0].id;
            subArgument.parameter = this._argument?.parameter?.parameters[0];
            subArgument.selectorId = this._argument?.parameter?.parameters[0].selectorId;
            if (this._argument.parameter.parameters[0].parameters) {
                subArgument.subArguments = createArguments(this._argument.parameter.parameters[0].parameters);
            }
            if (!this._argument.subArguments) {
                this._argument.subArguments = [];
            }
            this._argument.subArguments.push(subArgument);
        }
    }

    onRemoveArgument(argument: Argument) {
        this._argument.subArguments?.splice(this._argument.subArguments.indexOf(argument), 1);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    calculateComposedTitles() {
        if (this._argument && this._argument.subArguments && this._argument.subArguments.length > 0) {
            this._argument.subArguments.forEach(subArgument => {
                this.calculateComposedTitle(subArgument);
            });
        }
    }

    calculateComposedTitle(argument: Argument | undefined): void {
        if (argument && argument?.parameter?.composedTitleSubArgumentId) {
            const titleArgument = argument.subArguments?.find(
                subArgument =>
                    subArgument?.selectorId === argument.parameter?.composedTitleSubArgumentId ||
                    subArgument.parameterId === argument.parameter?.composedTitleSubArgumentId
            );
            if (titleArgument) {
                argument.composedTitle = this.removeQuotesIfPresent(titleArgument.value);
            }
        }
    }

    onArgumentUpdated($event: { argument: Argument }) {
        let composedArgument = this._argument?.subArguments?.find(subArgument => subArgument.id === $event.argument.id);
        this.calculateComposedTitle(composedArgument);
        this.argumentUpdated.emit({ argument: this._argument });
    }

    identifyArgument(index: number, item: Argument): string | undefined {
        return item.id;
    }

    removeQuotesIfPresent(value: string | undefined): string {
        if (value) {
            if (value.startsWith("'") && value.endsWith("'")) {
                return value.substring(1, value.length - 1);
            } else {
                return value;
            }
        } else {
            return '';
        }
    }

    reorderSubArguments(event: CdkDragDrop<Argument[]>): void {
        if (this._argument.subArguments) {
            moveItemInArray(this._argument.subArguments, event.previousIndex, event.currentIndex);
            this.argumentUpdated.emit({ argument: this._argument });
        }
    }
}
