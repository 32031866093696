import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { GUIDFunctions } from '@shared/utils';
import { initFlowbite } from 'flowbite';
import { Parameter, ParameterOption } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-parameter-options',
    templateUrl: './parameter-options.component.html',
    standalone: false,
})
export class ParameterOptionsComponent implements AfterViewInit {
    @Input()
    options!: ParameterOption[];

    @Input()
    parameter: Parameter;

    @Output()
    changeParameters: EventEmitter<{ options: ParameterOption[] }> = new EventEmitter<{ options: ParameterOption[] }>();

    openedOptionIndex!: number;

    ngAfterViewInit() {
        initFlowbite();
    }

    onRemoveOption(option: ParameterOption) {
        this.options.splice(this.options.indexOf(option), 1);
        this.changeParameters.emit({ options: this.options });
    }

    onAddOption() {
        if (!this.options) {
            this.options = [];
        }
        this.options.push({ id: new GUIDFunctions().newGuid() });
        this.changeParameters.emit({ options: this.options });
        setTimeout(() => initFlowbite());
    }

    onChangeOption() {
        this.changeParameters.emit({ options: this.options });
    }

    identifyOption(index: number, item: ParameterOption) {
        return index;
    }
}
