<ng-container [formGroup]="formGroup">
    <div class="mb-2">
        <input
            class="small_input"
            type="number"
            id="{{ this._argument.id }}"
            class="small-input"
            autocomplete="off"
            formControlName="value" />
    </div>
</ng-container>
