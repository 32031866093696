<ng-container *ngIf="leftArgument && rightArgument && equalityOperator">
    <div
        class="flex mb-1 mt-1 gap-1"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="argumentInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.BOTTOMSTART">
        <label class="small-input-label"
            >{{ 'v2.action.conditionals.conditional.leftargument.name' | translate
            }}<mat-icon class="help-icon" color="primary">help </mat-icon></label
        >
    </div>

    <float-ui-content #argumentInformationPopover
        ><div class="popover">{{ 'v2.action.conditionals.conditional.leftargument.description' | translate }}</div></float-ui-content
    >
    <argument-value-picker
        [argument]="leftArgument"
        [arguments]="[leftArgument, rightArgument]"
        [contextId]="invocation.id"
        [onlyLiteralValues]="onlyLiteralValues"
        [scope]="scope"
        [language]="language"
        (argumentUpdated)="onLeftArgumentUpdated()"></argument-value-picker>
    <hr class="seperator" />

    <label for="select-equality-operator" class="small-input-label">{{
        'v2.action.conditionals.conditional.equality.operator.title' | translate
    }}</label>
    <select
        id="select-equality-operator"
        class="small-input"
        [ngModel]="equalityOperator"
        (ngModelChange)="onExpressionEqualityOperatorChange($event)">
        <option style="text-align: center" value="==">
            {{ 'v2.action.conditionals.conditional.equality.operator.equals' | translate }}
        </option>
        <option style="text-align: center" value="!=">
            {{ 'v2.action.conditionals.conditional.equality.operator.not.equals' | translate }}
        </option>
        <option style="text-align: center" value="!~">
            {{ 'v2.action.conditionals.conditional.equality.operator.not.contains' | translate }}
        </option>
        <option style="text-align: center" value="~=">
            {{ 'v2.action.conditionals.conditional.equality.operator.contains' | translate }}
        </option>
        <option style="text-align: center" value=">">
            {{ 'v2.action.conditionals.conditional.equality.operator.greater.than' | translate }}
        </option>
        <option style="text-align: center" value="<">
            {{ 'v2.action.conditionals.conditional.equality.operator.lower.than' | translate }}
        </option>
        <option style="text-align: center" value=">=">
            {{ 'v2.action.conditionals.conditional.equality.operator.greater.than.or.equal' | translate }}
        </option>
        <option style="text-align: center" value="<=">
            {{ 'v2.action.conditionals.conditional.equality.operator.lower.than.or.equal' | translate }}
        </option>
        <option style="text-align: center" value="!= NULL">
            {{ 'v2.action.conditionals.conditional.equality.operator.not.equals.null' | translate }}
        </option>
        <option style="text-align: center" value="== NULL">
            {{ 'v2.action.conditionals.conditional.equality.operator.equals.null' | translate }}
        </option>
    </select>
    <hr class="seperator" *ngIf="showRightArgument" />
    <div
        class="flex mb-1 mt-1 gap-1"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="argumentInformationPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[250px]'"
        [placement]="NgxFloatUiPlacements.BOTTOMSTART">
        <label class="small-input-label" *ngIf="showRightArgument">
            {{ 'v2.action.conditionals.conditional.rightargument.name' | translate }}
            <ng-container *ngIf="showRightArgument">
                <mat-icon class="help-icon" color="primary">help </mat-icon>
            </ng-container>
        </label>
    </div>
    <float-ui-content #argumentInformationPopover
        ><div class="popover">{{ 'v2.action.conditionals.conditional.rightargument.description' | translate }}</div></float-ui-content
    >
    <argument-value-picker
        *ngIf="showRightArgument"
        [argument]="rightArgument"
        [arguments]="[leftArgument, rightArgument]"
        [contextId]="invocation.id"
        [scope]="scope"
        [language]="language"
        [onlyLiteralValues]="onlyLiteralValues"
        (argumentUpdated)="onRightArgumentUpdated()"></argument-value-picker>
</ng-container>
