import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppFacade } from '@core/facades/app.facade';
import { InstallationService } from '@core/services/installation.service';
import { catchError, concatMap, debounceTime, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { LoggerService } from '@backoffice/utils';
import { ProductService } from '@core/services/product.service';
import { RouterFacade } from '../../../../../../utils-routing/src/lib/facade/router.facade';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Page } from '@shared/data-access';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { Installation } from '../../../../../company/marketplace/src/lib/models/installation.model';
import { MarketplaceFacade } from '../../../../../company/marketplace/src/lib/facade/marketplace.facade';
import { Product } from '../../../../../company/marketplace/src/lib/models/product.model';

@Component({
    selector: 'codex-plugin-overview-page',
    templateUrl: './plugin-overview-page.component.html',
    styleUrls: ['./plugin-overview-page.component.scss'],
    standalone: false,
})
export class PluginOverviewPageComponent implements OnInit, OnDestroy {
    installations$: Observable<Page<Installation>>;

    searchTerm$ = new BehaviorSubject('');

    refresh$ = new BehaviorSubject<number>(0);

    processing$ = new Subject<string>();

    pagingChanged$ = new BehaviorSubject({ page: 0, maxResults: 10 });

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly appFacade: AppFacade,
        private readonly installationService: InstallationService,
        private readonly productService: ProductService,
        private readonly route: ActivatedRoute,
        private readonly routerFacade: RouterFacade
    ) {}

    ngOnInit(): void {
        this.initProductVersionChangingSubscription();
        this.installations$ = combineLatest([
            this.searchTerm$,
            this.appFacade.selectedApplication.pipe(filter(app => !!app)),
            this.refresh$.pipe(startWith(0)),
        ]).pipe(
            concatMap(([searchTerm, { id: applicationId }]) =>
                this.installationService.findAll({
                    searchTerm,
                    applicationId,
                })
            )
        );
    }

    initProductVersionChangingSubscription() {
        this.subscriptions.add(
            combineLatest([this.refresh$, this.processing$])
                .pipe(
                    filter(([refresh, processingInstallationId]) => !!processingInstallationId),
                    switchMap(([refresh, processingInstallationId]) => this.installationService.findById(processingInstallationId)),
                    debounceTime(2000)
                )
                .subscribe(installation => {
                    if (installation.status === 'INSTALLING' || installation.status === 'UPDATING') {
                        this.refresh$.next(this.refresh$.value + 1);
                    } else {
                        this.processing$.next(null);
                    }
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    handleSearchTermChanged(searchTerm: string): void {
        this.searchTerm$.next(searchTerm);
    }

    handleUpdatedClicked(installation: Installation): void {
        const { id, productId, applicationId, availableVersions } = installation;
        this.processing$.next(id);
        this.subscriptions.add(
            this.productService.upgrade(productId, availableVersions[0].versionId, applicationId).subscribe(() => {
                this.refresh$.next(this.refresh$.value + 1);
                this.processing$.next(id);
            })
        );

        this.routerFacade.navigate([`../hub/${productId}`], { relativeTo: this.route });
    }

    handleUninstall(installation: Installation): void {
        const { productId, applicationId, id } = installation;
        this.processing$.next(id);
        this.subscriptions.add(
            this.productService.uninstall(productId, applicationId).subscribe(() => {
                this.refresh$.next(this.refresh$.value + 1);
                this.processing$.next(null);
            })
        );
    }

    handlePaginationChanged(event: PageEvent): void {
        this.pagingChanged$.next({
            page: event.pageIndex,
            maxResults: event.pageSize,
        });
    }

    onInstallationClick(productId: string) {
        this.routerFacade.navigate([`../hub/${productId}`], { relativeTo: this.route });
    }
}
