<div
    [attr.id]="'subarguments-accordion-' + this._argument.id"
    [attr.data-accordion]="'subarguments-accordion-' + this._argument.id"
    *ngIf="showSubArguments && this._argument.subArguments && this._argument.subArguments.length > 0"
    cdkDropList
    class="mb-2">
    <ng-container *ngFor="let argument of this._argument.subArguments; let i = index; trackBy: identifyArgument">
        <h3
            [attr.id]="'subarguments-accordion-' + argument.id + '-objectitem-heading'"
            [attr.data-name]="'subarguments-accordion-objectitem-header-' + argument.id"
            class="p-0">
            <button
                type="button"
                class="accordion-heading"
                [class.accordion-heading-first]="i === 0"
                [class.accordion-heading-last]="i === this._argument.subArguments.length - 1"
                [attr.data-accordion-target]="'#subarguments-accordion-' + argument.id + '-objectitem'"
                [attr.aria-controls]="'subarguments-accordion-' + argument.id + '-objectitem'"
                aria-expanded="true">
                <span class="leading-6 grow shrink text-left" [matTooltip]="argument?.parameter?.name">{{
                    argument?.parameter?.name ? (argument?.parameter?.name | truncate: 25) : ('New attribute' | truncate: 25)
                }}</span>
                <button
                    class="accordion-heading-button destructive-accordion-heading-button"
                    (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                    [matTooltip]="'v2.action.argument.dataformat.attribute.remove' | translate">
                    <mat-icon>clear</mat-icon>
                </button>
                <svg
                    data-accordion-icon
                    class="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h3>
        <div
            [attr.id]="'subarguments-accordion-' + argument.id + '-objectitem'"
            [attr.data-name]="'subarguments-' + argument.id"
            [attr.aria-labelledby]="'subarguments-accordion-' + argument.id + '-objectitem'"
            [class.accordion-panel-first]="i === 0"
            [class.accordion-panel-last]="i === this._argument.subArguments.length - 1"
            [class.accordion-panel]="true"
            class="hidden">
            <select
                [attr.id]="'select-attribute-' + i"
                class="small-input"
                [ngModel]="argument.id"
                (ngModelChange)="onDataFormatAttributeSelected($event, argument)">
                <option selected value="">{{ 'v2.action.argument.dataformat.attribute.select' | translate }}</option>
                <option
                    *ngFor="let dataFormatAttribute of dataFormatAttributes; trackBy: identifyAttribute"
                    [value]="dataFormatAttribute.id">
                    {{ dataFormatAttribute.name }}
                </option>
            </select>
            <argument-value-picker
                *ngIf="!!argument.id"
                class="mb-1 block"
                [argument]="argument"
                [arguments]="this._argument.subArguments"
                [contextId]="contextId"
                [scope]="scope"
                [language]="language"
                [root]="root"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="argumentUpdated.emit({ argument: this._argument })"
                (openTab)="openTab.emit($event)"></argument-value-picker>
        </div>
    </ng-container>
</div>
<button class="w-full justify-center primary-button button-medium" (click)="onAddDataFormatAttribute()">
    <mat-icon>add</mat-icon>
    <span
        >{{ 'v2.action.argument.object.add.field' | translate }}
        {{ this._argument.parameter?.name ? (this._argument.parameter.name | truncate: 25) : 'object' }}</span
    >
</button>
