import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { initFlowbite } from 'flowbite';
import { Action } from '@backoffice/editor/data-access/action';
import { ActionCtx } from '../../../../../../../data-access/editor/src/lib/action/model/action-ctx';
import { EditorFacade, Parameter } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-action-settings',
    templateUrl: './action-settings.component.html',
    standalone: false,
})
export class ActionSettingsComponent implements AfterViewInit {
    action: Action;

    actionContext: ActionCtx[];

    @Output()
    actionUpdated: EventEmitter<{ action: Action }> = new EventEmitter<{ action: Action }>();

    formGroup: FormGroup;

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close({ action: this.action });
    }

    constructor(
        private dialogRef: MatDialogRef<ActionSettingsComponent>,
        public changeDetectorRef: ChangeDetectorRef,
        public editorFacade: EditorFacade,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.action = this.data.action;
        this.actionContext = this.data.actionContext;
        dialogRef.backdropClick().subscribe(() => {
            // Close the dialog
            dialogRef.close({ action: this.action });
        });
    }

    ngAfterViewInit() {
        initFlowbite();
    }

    onUpdatedParameters($event: { parameters: Parameter[] }) {
        this.action.program.parameters = $event.parameters;
    }

    onOpenTab($event) {
        this.editorFacade.registerTab($event);
        this.dialogRef.close({ action: this.action });
    }
}
