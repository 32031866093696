<div
    class="flex mb-1 mt-1 gap-1 justify-start"
    [disabled]="!this.argument?.parameter?.description"
    [showTrigger]="NgxFloatUiTriggers.hover"
    [floatUi]="argumentInformationPopover"
    [appendTo]="'body'"
    [boundariesElement]="'body'"
    [preventOverflow]="false"
    [positionFixed]="true"
    [applyClass]="'!max-w-[250px]'"
    [placement]="NgxFloatUiPlacements.LEFT">
    <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
        {{ this.argument?.parameter?.name }}
        <ng-container *ngIf="this.argument?.parameter?.description">
            <svg class="icon icon-button" aria-hidden="true" focusable="false">
                <use href="#help" class="ui-element"></use>
            </svg>
        </ng-container>
    </h4>
</div>
<float-ui-content #argumentInformationPopover
    ><div class="popover">{{ this.argument?.parameter?.description }}</div></float-ui-content
>
