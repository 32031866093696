<form class="edit-form" [formGroup]="form">
    <div fxLayout="column" fxLayoutGap="10px">
        <label for="group-name" class="small-input-label" data-name="group-name">{{
            'v2.usermanagement.groups.edit.name' | translate
        }}</label>
        <input
            class="small-input"
            type="text"
            id="group-name"
            name="group-name"
            autocomplete="off"
            cdkFocusInitial
            [formControl]="nameControl" />
        <p class="small-input-error" *ngIf="nameControl.touched && nameControl.invalid">
            {{ 'v2.usermanagement.groups.edit.name.required' | translate }}
        </p>

        <label for="group-description" class="small-input-label" data-name="group-description">{{
            'v2.usermanagement.groups.edit.description' | translate
        }}</label>
        <textarea
            class="small-input"
            type="text"
            id="group-description"
            name="group-description"
            autocomplete="off"
            cdkFocusInitial
            [formControl]="descriptionControl"></textarea>

        <div class="flex flex-row gap-2">
            <div class="flex flex-col w-[50%]">
                <h4 class="!mb-3 grow-0 shrink-0">Available rights</h4>
                <ul
                    cdkDropList
                    class="item-list grow shrink"
                    #availableList="cdkDropList"
                    [id]="availableRolesIdentifier"
                    [cdkDropListConnectedTo]="[selectedList]"
                    [cdkDropListData]="availableRoles"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngIf="!availableRoles || (availableRoles.length === 0 && selectedRoles && selectedRoles.length > 0)">
                        <img class="self-center mt-24 w-[350px] h-[100px] object-contain" src="/src/images/theme/drag.gif" />
                        <h1 class="text-center !mb-2">Drop rights here to remove a right from your group</h1>
                    </ng-container>
                    <div
                        class="p-3 flex flex-col"
                        *ngIf="(!availableRoles || availableRoles.length === 0) && (!selectedRoles || selectedRoles.length === 0)">
                        <img class="self-center w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                        <h1 class="text-center !mb-2">We couldn't find any rights to add to your group.</h1>
                        <p class="!mb-8 text-center text-white">Start building securing your application by adding a new right.</p>
                        <button
                            type="button"
                            class="primary-button button-extra-large w-fit self-center"
                            (click)="handleCreateRightClicked()">
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24">
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 12h14m-7 7V5" />
                            </svg>
                            Create new right
                        </button>
                    </div>
                    <li class="cursor-grab hover:bg-light-button-background" *ngFor="let item of availableRoles" cdkDrag>
                        <div class="item-name">{{ item.name }}</div>
                    </li>
                </ul>
            </div>
            <div class="flex flex-col w-[50%]">
                <h4 class="!mb-3 grow-0 shrink-0">Assigned rights</h4>
                <ul
                    cdkDropList
                    #selectedList="cdkDropList"
                    class="item-list grow shrink"
                    [id]="selectedRolesIdentifier"
                    [cdkDropListConnectedTo]="[availableList]"
                    [cdkDropListData]="selectedRoles"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngIf="!selectedRoles || selectedRoles.length === 0">
                        <img class="self-center mt-24 w-[350px] h-[100px] object-contain" src="/src/images/theme/drag.gif" />
                        <h1 class="text-center !mb-2">Drop rights here to add a right to your group</h1>
                        <p class="!mb-8 text-center text-white">
                            Users assigned to this group will automatically be assigned to all the rights in this list.
                        </p>
                    </ng-container>
                    <li class="cursor-grab hover:bg-light-button-background" *ngFor="let item of selectedRoles" cdkDrag>
                        <div class="item-name">{{ item.name }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="flex justify-end gap-1 mt-2">
        <button class="primary-button button-large" [disabled]="form.invalid" (click)="onSaveClicked()" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-large" data-name="cancel" (click)="onCancelClicked()">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
