import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocomplete as MatAutocomplete, MatAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { TagEditFacade } from '../../facade/tag-edit.facade';
import { OverviewTagLinkDto } from '../../dto/overview-taglink.dto';
import { OverviewTagDto } from '../../dto/overview-tag.dto';
import { TagLinkCreatedDto } from '../../dto/taglink-created.dto';

@Component({
    selector: 'codex-tag-input',
    templateUrl: './tag-input.component.html',
    standalone: false,
})
export class TagInputComponent implements OnInit, OnDestroy {
    @Input() type: string;
    @Input() linkedEntityId: string;
    @Input() companyId: string;
    @Input() applicationId: string;

    @ViewChild('tagAutoComplete', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger;

    separatorKeysCodes: any[] = [ENTER, COMMA, SPACE, TAB];

    tagLinksSubject$: BehaviorSubject<OverviewTagLinkDto[]> = new BehaviorSubject<OverviewTagLinkDto[]>([]);

    tags$: Observable<OverviewTagDto[]>;

    formGroup: FormGroup;

    subscription: Subscription = new Subscription();

    constructor(
        private tagEditFacade: TagEditFacade,
        private fb: FormBuilder,
        public translateService: TranslateService
    ) {}

    initLinkedTags() {
        this.subscription.add(
            this.tagEditFacade
                .getTagLinksForEntity(this.linkedEntityId, this.type, this.applicationId, this.companyId)
                .subscribe(tagLinks => this.tagLinksSubject$.next(tagLinks))
        );
    }

    initForm() {
        this.formGroup = this.fb.group({
            tagInput: [''],
        });
    }

    initAutocomplete() {
        this.tags$ = this.formGroup.get('tagInput').valueChanges.pipe(
            filter(tagInput => typeof tagInput === 'string'),
            switchMap(tagInput => {
                return this.tagEditFacade.findTagsForType(tagInput, this.type, 'score desc', 0, 20, this.applicationId, this.companyId);
            }),
            map(tagPage => {
                const tags = tagPage.content;
                return tags.filter(tag => !this.tagLinksSubject$.value.find(tagLink => tagLink.tag === tag.tag));
            }),
            map(tags => tags.slice(0, 10))
        );
    }

    ngOnInit(): void {
        this.initLinkedTags();
        this.initForm();
        this.initAutocomplete();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAddTag($event) {
        this.formGroup.get('tagInput').setValue('');
        if ($event.value && $event.value !== '') {
            this.addTag($event.value);
        }
    }

    addTagAfterEnter() {
        const tag = this.formGroup.get('tagInput').value;
        if (tag && tag !== '') {
            this.addTag(tag);
        }
        this.formGroup.get('tagInput').setValue('');
    }

    addTag(tag: string) {
        const newTagLinks = this.tagLinksSubject$.value;
        const existingTagLink = newTagLinks.find(tagLink => tagLink.tag === tag);
        if (!existingTagLink) {
            this.tagEditFacade
                .createTagLink({
                    tag,
                    applicationId: this.applicationId,
                    companyId: this.companyId,
                    type: this.type,
                    linkedEntityId: this.linkedEntityId,
                })
                .subscribe((createTagLinkDto: TagLinkCreatedDto) => {
                    newTagLinks.push({
                        id: createTagLinkDto.tagLinkId,
                        tag,
                        tagId: createTagLinkDto.tagId,
                        type: this.type,
                        linkedEntityId: this.linkedEntityId,
                    });
                    this.tagLinksSubject$.next(newTagLinks);
                });
        }
    }

    onAddSelectedTag($event) {
        this.formGroup.get('tagInput').setValue('');
        if ($event.tag && $event.tag !== '') {
            this.addTag($event.tag);
        }
    }

    onRemoveTag(tagLinkToRemove: OverviewTagLinkDto) {
        const newTagLinks = this.tagLinksSubject$.value;
        newTagLinks.splice(
            newTagLinks.findIndex(tagLink => tagLink.id === tagLinkToRemove.id),
            1
        );
        this.tagEditFacade.deleteTagLink(tagLinkToRemove.id).subscribe();
    }

    onClickInput() {
        this.formGroup.get('tagInput').setValue('');
        this.autoCompleteTrigger.openPanel();
    }
}
