import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { Invocation, Scope, TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'action-invocation-arguments',
    templateUrl: './action-invocation-arguments.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionInvocationArgumentsComponent implements OnChanges {
    @Input() action!: Action;
    @Input() invocation!: Invocation;
    @Input() language!: string;
    @Input() scope: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();
    @Output() openTab = new EventEmitter<TabDefinition>();
    @Output() calculateScope = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        const { invocation } = changes;
        if (!!invocation && invocation.currentValue?.id !== invocation.previousValue?.id) {
            this.calculateScope.emit();
        }
    }
}
