import 'reflect-metadata';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { CreateCompanyDto } from '@shared/interfaces/create-company.dto';
import { LoggerService } from '../../../../../../../libs/backoffice/utils/src/lib/services/logger.service';

@Component({
    selector: 'codex-add-company-dialog',
    templateUrl: 'add-company-dialog.component.html',
    styleUrls: ['add-company-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddCompanyDialogComponent implements OnInit {
    private userId: string;

    form: FormGroup;

    get companyNameControl(): FormControl {
        return this.form.get('companyName') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private dialogRef: MatDialogRef<AddCompanyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private readonly logger: LoggerService
    ) {
        this.userId = data.userId;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            companyName: [undefined, [Validators.required]],
        });
    }

    save() {
        this.logger.info(this.userId);
        if (this.form.valid) {
            const company: CreateCompanyDto = {
                userId: this.userId,
                companyName: this.form.get('companyName').value,
            };
            this.dialogRef.close(company);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
