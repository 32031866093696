import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductService } from '@core/services/product.service';
import { catchError, concatMap, debounceTime, filter, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { Product } from '../../models/product.model';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { AddCommentComponent } from '../../components/add-comment/add-comment.component';
import { LoggerService } from '@backoffice/utils';
import { AddVersionComponent } from '../../components/add-version/add-version.component';
import { ProductVersion } from '../../models/product-version.model';
import { InstallationService } from '@core/services/installation.service';
import { AppFacade } from '@core/facades/app.facade';
import { ActivatedRoute } from '@angular/router';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { EditProductComponent } from '../../components/edit-product/edit-product.component';
import { OverviewTagLinkDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-tag/dto/overview-taglink.dto';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { RouterFacade } from '../../../../../../../utils-routing/src';
import { TagEditFacade } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-tag/facade/tag-edit.facade';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-product-detail-page',
    templateUrl: './product-detail-page.component.html',
    styleUrls: ['./product-detail-page.component.scss'],
    standalone: false,
})
export class ProductDetailPageComponent implements OnDestroy, OnInit, AfterViewInit {
    forceReload$ = new BehaviorSubject<void>(undefined);

    forceProductReload$ = new BehaviorSubject<void>(undefined);

    @Input()
    productId: string;

    product$: Observable<Product> = combineLatest([this.routerFacade.params, this.forceProductReload$]).pipe(
        filter(([{ applicationId }]) => !!applicationId),
        switchMap(([{ applicationId }]) =>
            combineLatest([this.productService.findById(this.productId), this.forceReload$]).pipe(
                switchMap(([fetchedProduct]) =>
                    this.installationService.findByProductAndApplication(this.productId, applicationId).pipe(
                        catchError(() => of(undefined)),
                        map(installation => {
                            // This because of the error: "Cannot add property installed, object is not extensible"
                            // No time to properly find out who or what preventsextension so this is the fix for now.
                            let product = { ...fetchedProduct };
                            product.installed = !!installation;
                            if (product.installed) {
                                // This because of the error: "Cannot add property installed, object is not extensible"
                                // No time to properly find out who or what preventsextension so this is the fix for now.
                                product.versions = product.versions.map(version => {
                                    return { ...version };
                                });
                                product.versions.forEach(version => {
                                    if (installation?.versionId === version.versionId) {
                                        version.installed = true;
                                        version.status = installation.status;
                                        product.installedVersionId = version.versionId;
                                        product.installedVersionPublishDate = version.uploaded;
                                    } else {
                                        version.installed = false;
                                    }
                                });
                            }
                            product.versions = product.versions.sort((v1, v2) => (v1.uploaded > v2.uploaded ? -1 : 1));
                            return product;
                        })
                    )
                )
            )
        ),
        shareReplay(1)
    );

    isAdmin$: Observable<boolean> = combineLatest([this.appFacade.context, this.appFacade.userRights, this.product$]).pipe(
        filter(([company, rights, product]) => !!product && !!company && !!rights),
        map(([{ selectedCompany }, rights, product]) => {
            const { id } = selectedCompany;
            return id === product.companyId && rights.calculatedRightNames.includes('APPLICATION_ADMIN');
        }),
        shareReplay(1)
    );

    tags$: Observable<OverviewTagLinkDto[]> = this.routerFacade.params.pipe(
        filter(params => !!params['productId']),
        concatMap(({ productId, applicationId, companyId }) =>
            this.tagsFacade.getTagLinksForEntity(productId, 'PRODUCT', applicationId, companyId)
        )
    );

    versionChanging$ = new BehaviorSubject<string | void>(undefined);

    currentContext$ = this.appFacade.currentContext$;

    subscriptions: Subscription = new Subscription();

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    descriptionCollapsed: boolean = false;

    constructor(
        private readonly appFacade: AppFacade,
        private readonly confirmialog: ConfirmDialog,
        private readonly dialog: MatDialog,
        private readonly installationService: InstallationService,
        private readonly logger: LoggerService,
        private readonly productService: ProductService,
        private readonly routerFacade: RouterFacade,
        private readonly route: ActivatedRoute,
        private readonly tagsFacade: TagEditFacade
    ) {}

    ngOnDestroy() {
        this.subscriptions?.unsubscribe();
    }

    ngOnInit() {
        this.initProductVersionChangingSubscription();
        if (this.route.snapshot.queryParamMap.get('installing')) {
            this.versionChanging$.next(this.route.snapshot.queryParamMap.get('installing') as string);
            this.forceReload$.next();
        }
    }

    ngAfterViewInit() {
        this.subscriptions.add(this.product$.pipe(tap(() => setTimeout(() => initFlowbite()))).subscribe());
        //this.styleCodeExamplesInReadme()
    }

    styleCodeExamplesInReadme() {
        var pre = document.getElementsByTagName('pre');
        var pl = pre.length;

        for (var i = 0; i < pl; i++) {
            pre[i].innerHTML = '<span class="line-number"></span>' + pre[i].innerHTML + '<span class="cl"></span>';
            var num = pre[i].innerHTML.split(/\n/).length;
            for (var j = 0; j < num; j++) {
                var line_num = pre[i].getElementsByTagName('span')[0];
                line_num.innerHTML += '<span>' + (j + 1) + '</span>';
            }
        }
    }

    initProductVersionChangingSubscription() {
        this.subscriptions.add(
            this.product$
                .pipe(
                    map(product => product.versions),
                    map(versions =>
                        versions.filter(
                            version => version.status === 'INSTALLING' || version.status === 'UPDATING' || version.status === 'CREATED'
                        )
                    ),
                    debounceTime(2000)
                )
                .subscribe(versions => {
                    if (!!versions && versions.length > 0) {
                        this.forceReload$.next();
                        this.versionChanging$.next(versions[0].versionId);
                    } else {
                        this.versionChanging$.next();
                    }
                })
        );
    }

    handleAddCommentClicked(): void {
        const dialogRef = this.dialog.open(
            AddCommentComponent,
            Object.assign(
                {
                    restoreFocus: true,
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );

        this.subscriptions.add(
            dialogRef
                .afterClosed()
                .pipe(
                    take(1),
                    switchMap((result: { value: string }) => {
                        if (result) {
                            const { value } = result;
                            return this.routerFacade.params.pipe(
                                take(1),
                                switchMap(({ productId }) => {
                                    this.logger.info(`Adding comment ${value} to ${productId}`);
                                    return this.productService
                                        .addComment({
                                            productId,
                                            value,
                                        })
                                        .pipe(tap(() => this.forceProductReload$.next()));
                                })
                            );
                        }
                        return of();
                    })
                )
                .subscribe()
        );
    }

    handleRemoveClicked(productId: string): void {
        this.productService.delete(productId).subscribe(() => this.routerFacade.navigate(['../'], { relativeTo: this.route }));
    }

    handleAddVersionClicked(product: Product): void {
        const dialogRef = this.dialog.open(
            AddVersionComponent,
            Object.assign(
                {
                    data: product,
                    restoreFocus: true,
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );

        this.subscriptions.add(
            dialogRef
                .afterClosed()
                .pipe(
                    switchMap((result: { name: string; description: string; versionId: string }) => {
                        if (result) {
                            const { name, description, versionId } = result;
                            this.logger.info(`Adding version ${versionId} to ${this.productId}`);
                            return this.productService
                                .addVersion({
                                    productId: this.productId,
                                    name,
                                    description,
                                    versionId,
                                })
                                .pipe(tap(() => this.forceProductReload$.next()));
                        }
                        return of();
                    })
                )
                .subscribe()
        );
    }

    handleInstallVersionClicked(product: Product, version: ProductVersion): void {
        this.logger.info(`Install version ${version.name}`);
        const { id: productId } = product;
        const { versionId } = version;
        this.subscriptions.add(
            this.appFacade.selectedApplication
                .pipe(
                    tap(() => this.versionChanging$.next(versionId)),
                    take(1),
                    switchMap(({ id }) =>
                        this.productService
                            .install(product.id, {
                                applicationId: id,
                                productId,
                                versionId,
                            })
                            .pipe(
                                finalize(() => {
                                    this.forceReload$.next();
                                    this.versionChanging$.next(versionId);
                                })
                            )
                    )
                )
                .subscribe()
        );
    }

    handleUninstallClicked(product: Product): void {
        this.logger.info(`Uninstall product <${product.id}>`);
        this.subscriptions.add(
            this.appFacade.selectedApplication
                .pipe(
                    take(1),
                    switchMap(({ id }) =>
                        this.productService.uninstall(product.id, id).pipe(
                            tap(() => {
                                this.forceReload$.next();
                                this.versionChanging$.next(undefined);
                            })
                        )
                    )
                )
                .subscribe()
        );
    }

    handleDeleteVersionClicked(product: Product, version: ProductVersion): void {
        this.confirmialog.showConfirmDialog(
            'v2.action.conditionals.delete.title',
            product.versions?.length > 1 ? 'v2.action.conditionals.delete.description' : 'v2.hub.dialog.no.more.versions',
            'v2.action.conditionals.delete.ok',
            'v2.action.conditionals.delete.cancel',
            'v2.action.conditionals.delete.success.title',
            'v2.hub.dialog.delete.ok',
            () => {
                this.logger.info(`Removing product version id <${version.id}>`);
                if (product.versions?.length > 1) {
                    this.productService.removeVersion(product.id, version.id).subscribe(() => this.forceProductReload$.next());
                } else {
                    this.productService
                        .delete(product.id)
                        .subscribe(() => this.routerFacade.navigate([`../../hub`], { relativeTo: this.route }));
                }
            }
        );
    }

    handleEditClick(product: Product): void {
        this.subscriptions.add(
            this.dialog
                .open(
                    EditProductComponent,
                    Object.assign(
                        {
                            restoreFocus: true,
                            data: { product },
                        },
                        backofficeEnvironment.dialogConfig.small
                    )
                )
                .afterClosed()
                .pipe(
                    switchMap(value => {
                        if (!!value) {
                            return this.productService.update(value);
                        } else {
                            return of(null);
                        }
                    })
                )
                .subscribe(() => {
                    this.forceProductReload$.next();
                })
        );
    }

    handleUpgradeClicked(productId: string, versionId: string): void {
        this.logger.info(`Update product <${productId}> to version <${versionId}>`);
        this.subscriptions.add(
            this.appFacade.selectedApplication
                .pipe(
                    tap(() => this.versionChanging$.next(versionId)),
                    take(1),
                    switchMap(({ id }) =>
                        this.productService.upgrade(productId, versionId, id).pipe(
                            finalize(() => {
                                this.forceReload$.next();
                                this.versionChanging$.next(versionId);
                            })
                        )
                    )
                )
                .subscribe()
        );
    }
}
