<ng-container [formGroup]="formGroup" *ngIf="_argument">
    <div class="mb-2">
        <label class="small-input-label">Role</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-role" formControlName="role" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Goal</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-goal" formControlName="goal" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Backstory</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-backstory" formControlName="backstory" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Model</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-model" formControlName="model" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Tools</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-tools" formControlName="tools" />
    </div>
</ng-container>
