import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { ProgressService } from '../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/components/progress/progress.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppFacade } from '@core/facades/app.facade';
import { take } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'codex-dataformat-create-form',
    templateUrl: './dataformat-create-form.component.html',
    standalone: false,
})
export class DataformatCreateFormComponent implements OnInit {
    dragOver: boolean;
    selectedType = 'BLANK';

    environment = backofficeEnvironment;

    options: UploaderOptions = {
        concurrency: 1,
        maxUploads: 1,
        maxFileSize: 1024000000,
        allowedContentTypes: ['text/csv'],
    };
    uploadInput = new EventEmitter<UploadInput>();
    files: UploadFile[] = [];

    public formGroup: FormGroup;

    editorOptions = {
        theme: 'vs-dark',
        language: 'json',
        formatOnType: true,
        automaticLayout: true,
    };

    constructor(
        public dialogRef: MatDialogRef<DataformatCreateFormComponent>,
        public fb: FormBuilder,
        public appFacade: AppFacade,
        public progressService: ProgressService,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private readonly snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: ['Unnamed data-format', Validators.required],
            datadescription: [''],
        });
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }

    onUploadOutput(output: UploadOutput): void {
        if (output) {
            switch (output.type) {
                case 'allAddedToQueue':
                    break;
                case 'addedToQueue':
                    if (typeof output.file !== 'undefined') {
                        this.files.push(output.file);
                    }
                    break;
                case 'uploading':
                    if (typeof output.file !== 'undefined') {
                        // update current data in files array for uploading file
                        const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                        this.files[index] = output.file;
                    }
                    this.progressService.show();
                    this.changeDetectorRef.detectChanges();
                    break;
                case 'removed':
                    // remove file from array when removed
                    this.files = this.files.filter((file: UploadFile) => file !== output.file);
                    break;
                case 'rejected':
                    this.snackBar.open(this.translateService.instant('error.media.upload.rejected'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    break;
                case 'dragOver':
                    this.dragOver = true;
                    break;
                case 'dragOut':
                case 'drop':
                    this.dragOver = false;
                    break;
                case 'done':
                    this.progressService.hide();
                    break;
            }
            if (output.file) {
                const { responseStatus } = output.file;
                if (responseStatus == 400) {
                    this.snackBar.open(this.translateService.instant('error.media.upload.failed'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    return;
                }
            }
        }
    }
}
