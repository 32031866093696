import { ColorV2 } from '@backoffice/data-access/editor';

export interface Color {
    reference?: string;
    type: 'RGB' | 'HSV' | 'HSL';
    value: string;
}

export function generateColorStyle(color: Color | undefined) {
    let style = '';
    if (color?.reference) {
        style += `var(${color.reference})`;
    } else if (color && color.value) {
        style += color.value;
    }
    return style;
}

export function generateColorStyleV2(color: ColorV2 | undefined) {
    let style = '';
    if (color?.reference) {
        style += `var(${color.reference.variable.name})`;
    } else if (color?.hex) {
        style += color?.hex.value;
    }
    return style;
}

export function generateColorStyleV2WithVars(variable: string, attributeName: string, status: string, color: ColorV2 | undefined) {
    if (!color) {
        return '';
    }

    let variableName = '--' + variable;
    if (status != '' && status !== 'normal') {
        variableName = variableName + '-' + status + '-' + attributeName;
    } else {
        variableName = variableName + '-' + attributeName;
    }
    const value = generateColorStyleV2(color);
    return `${variableName}:${value};`;
}
