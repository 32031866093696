import { Argument } from '@backoffice/data-access/editor';
import { ActionTestOutputAssertion } from './action-test-output-assertion.dto';

export interface ActionTestDto {
    id?: string;
    name: string;
    arguments: Argument[];
    outputAssertions?: ActionTestOutputAssertion[];
    actionId: string;
    assertOnPublish: boolean;
}
