<form (ngSubmit)="onUpdate()" *ngIf="api && formGroup" [cdkTrapFocusAutoCapture]="true" [formGroup]="formGroup" cdkTrapFocus novalidate>
    <h1 class="!mb-4">{{ 'v2.api.edit.title' | translate }}</h1>
    <div class="general-information-container">
        <div class="name-and-description-container">
            <label for="name" class="small-input-label">{{ 'v2.api.edit.name' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="name"
                name="name"
                data-name="api-name"
                required
                cdkFocusInitial
                formControlName="name"
                autocomplete="off" />
            <p class="small-input-error" *ngIf="this.formGroup.get('name').invalid">{{ 'v2.api.edit.name.error' | translate }}</p>
            <label for="description" class="small-input-label">{{ 'v2.data.edit.description' | translate }}</label>
            <textarea
                class="small-input"
                type="text"
                id="description"
                name="description"
                formControlName="description"
                autocomplete="off"></textarea>
        </div>
        <div class="icon-picker-edit-container">
            <codex-icon-picker (changeIcon)="onChangeIconName($event)" [icon]="api.iconName"></codex-icon-picker>
        </div>
    </div>
    <codex-tag-input [applicationId]="api.applicationId" [companyId]="api.companyId" [linkedEntityId]="api.id" type="API"></codex-tag-input>
    <hr class="seperator" />
    <div class="flex flex-row gap-1">
        <div>
            <label for="select-application" class="small-input-label">{{ 'v2.api.edit.method' | translate }}</label>
            <select id="select-application" class="small-input" formControlName="method" data-name="api-method">
                <option *ngFor="let method of methods" [value]="method">{{ method }}</option>
            </select>
        </div>
        <div class="grow">
            <label for="api-endpoint" class="small-input-label">{{ 'v2.api.edit.endpoint' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="api-endpoint"
                name="endpoint"
                data-name="api-endpoint"
                required
                formControlName="endpoint"
                autocomplete="off"
                required
                slug />
        </div>
    </div>
    <span *ngIf="this.formGroup.get('method').invalid">{{ 'v2.api.edit.method.error' | translate }}</span>
    <span *ngIf="this.formGroup.get('endpoint').invalid" class="small-input-error">{{ 'v2.api.edit.endpoint.error' | translate }}</span>
    <ul class="url-list">
        <li>
            https://dev-{{ api.domainName }}.api.nocode-x.com/api/{{ formGroup.get('endpoint').value }}
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="'https://dev-' + api.domainName + '.api.nocode-x.com/api/' + formGroup.get('endpoint').value"
                [matTooltip]="'v2.api.edit.endpoint.copy' | translate"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://test-{{ api.domainName }}.api.nocode-x.com/api/{{ formGroup.get('endpoint').value }}
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="'https://test-' + api.domainName + '.api.nocode-x.com/api/' + formGroup.get('endpoint').value"
                [matTooltip]="'v2.api.edit.endpoint.copy' | translate"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://accept-{{ api.domainName }}.api.nocode-x.com/api/{{ formGroup.get('endpoint').value }}
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="'https://accept-' + api.domainName + '.api.nocode-x.com/api/' + formGroup.get('endpoint').value"
                [matTooltip]="'v2.api.edit.endpoint.copy' | translate"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://{{ api.domainName }}.api.nocode-x.com/api/{{ formGroup.get('endpoint').value }}
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="'https://' + api.domainName + '.api.nocode-x.com/api/' + formGroup.get('endpoint').value"
                [matTooltip]="'v2.api.edit.endpoint.copy' | translate"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>
    <hr class="seperator" />
    <div class="actions">
        <div
            class="flex mb-1 mt-1 gap-1"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="argumentInformationPopover"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART">
            <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                On Api Call
                <svg class="icon icon-button" aria-hidden="true" focusable="false">
                    <use href="#help" class="ui-element"></use>
                </svg>
            </h4>
        </div>
        <float-ui-content #argumentInformationPopover>
            <div class="popover">These actions will be executed everytime this API is called.</div>
        </float-ui-content>
        <codex-action-picker [referenceId]="api.id" type="API_LOGIC"></codex-action-picker>
    </div>
    <hr class="seperator" />
    <div>
        <codex-authorization-link-picker
            (authorizationUpdated)="onAuthorizationUpdated($event)"
            [applicationId]="api.applicationId"
            [authenticatedAccess]="api.authenticatedAccess"
            [companyId]="api.companyId"
            [referenceId]="api.id"
            [type]="'API'"></codex-authorization-link-picker>
    </div>
    <div class="buttonContainer">
        <button class="primary-button button-medium" data-name="save" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button (click)="this.cancel.emit()" class="secondary-button button-medium" data-name="cancel" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
