import { Component, OnInit } from '@angular/core';
import { BillingFacade } from '@billing/facade/billing.facade';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { CompanyEditComponent } from '../../../../../../../libs/backoffice/feature/company/edit-company/src/lib/page/my-company-edit.component';

@Component({
    selector: 'codex-application-usage-alert',
    templateUrl: './application-usage-alert.component.html',
    standalone: false,
})
export class ApplicationUsageAlertComponent implements OnInit {
    constructor(
        public billingFacade: BillingFacade,
        public readonly dialogRef: MatDialogRef<ApplicationUsageAlertComponent>
    ) {}

    ngOnInit(): void {}

    checkUsage() {
        this.dialogRef.close({ openUsage: true });
    }
}
