import { NgModule } from '@angular/core';
import { HttpLoaderInterceptorProvider } from '../../app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../app/common/http/interceptor/http-authentication.interceptor';
import { ApplicationBillingComponent } from './components/application-billing/application-billing.component';
import { CommonsModule } from '../../app/common/common.module';
import { ApplicationUsageComponent } from './components/application-usage/application-usage.component';
import { ApplicationUsageAlertComponent } from './components/application-usage-alert/application-usage-alert.component';
import { ApplicationUsageExceededAlertComponent } from './components/application-usage-exceeded-alert/application-usage-exceeded-alert.component';
import { ApplicationOrderAiCreditsComponent } from '@billing/components/application-order-ai-credits/application-order-ai-credits.component';

@NgModule({
    declarations: [
        ApplicationBillingComponent,
        ApplicationUsageComponent,
        ApplicationUsageAlertComponent,
        ApplicationUsageExceededAlertComponent,
        ApplicationOrderAiCreditsComponent,
    ],
    imports: [CommonsModule],
    exports: [ApplicationBillingComponent],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider],
})
export class BillingModule {}
