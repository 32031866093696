<div *ngIf="form" fxLayout="column" [formGroup]="form">
    <label [attr.for]="'parameter-type-' + this.parameter.id" class="small-input-label">{{ 'v2.parameter.type' | translate }}</label>
    <select [attr.id]="'parameter-type-' + this.parameter.id" class="small-input" formControlName="type">
        <ng-container *ngFor="let type of backofficeEnvironment.types">
            <option *ngIf="type.parameter" [value]="type.typeKey">{{ 'v2.types.' + type.name | translate }}</option>
        </ng-container>
    </select>

    <label [attr.for]="'parameter-name-' + this.parameter.id" class="small-input-label">{{ 'v2.parameter.name' | translate }}</label>
    <input
        class="small-input"
        type="text"
        [attr.id]="'parameter-name-' + this.parameter.id"
        name="parameter-name"
        class="small-input"
        autocomplete="off"
        cd-code
        formControlName="name" />

    <ng-container *ngIf="parameter.type === 'OBJECT' || parameter.type === 'ARRAY'">
        <label [attr.for]="'parameter-input-type-' + this.parameter.id" class="small-input-label">{{
            'v2.parameter.inputtype.select' | translate
        }}</label>
        <select
            [attr.id]="'parameter-input-type-' + this.parameter.id"
            class="small-input"
            formControlName="inputType"
            [attr.placeholder]="'v2.parameter.inputtype.select' | translate">
            <option value="free">Free input</option>
            <option value="dataformat">Data Format input</option>
            <option value="parameter">Parameter input</option>
        </select>
    </ng-container>

    <div
        *ngIf="
            parameter.type === 'DATA' ||
            parameter.type === 'DATA_BODY' ||
            parameter.type === 'PARTIAL_DATA' ||
            (parameter.type === 'OBJECT' && parameter.inputType === 'dataformat') ||
            (parameter.type === 'ARRAY' && parameter.inputType === 'dataformat')
        ">
        <app-dataformat-picker
            [value]="parameter.subTypeParameterId"
            [addQuotesToValue]="false"
            [required]="parameter.type === 'DATA' || parameter.type === 'DATA_BODY' || parameter.type === 'PARTIAL_DATA'"
            (valueUpdated)="onDataFormatChange($event)"
            (openTab)="this.openTab.emit($event)">
        </app-dataformat-picker>
    </div>

    <ng-container
        *ngIf="
            (parameter.type === 'PARTIAL_DATA' ||
                (parameter.type === 'OBJECT' && parameter.inputType === 'dataformat') ||
                (parameter.type === 'ARRAY' && parameter.inputType === 'dataformat')) &&
            !!subFormats &&
            subFormats.length > 0
        ">
        <label [attr.for]="'parameter-sub-dataformat-' + this.parameter.id" class="small-input-label">{{
            'v2.parameter.sub-dataformat.select' | translate
        }}</label>
        <select
            [attr.id]="'parameter-sub-dataformat-' + this.parameter.id"
            class="small-input"
            formControlName="subformat"
            [attr.placeholder]="'v2.parameter.sub-dataformat.select' | translate">
            <option *ngFor="let subFormat of subFormats" [value]="subFormat">{{ subFormat }}</option>
        </select>
    </ng-container>
    <label for="description" class="small-input-label">{{ 'v2.parameter.description' | translate }}</label>
    <textarea
        id="description"
        formControlName="description"
        rows="4"
        class="small-input"
        placeholder="Describe your parameter here..."></textarea>
    <ng-container *ngIf="parameter.type === 'DATA_FORMAT_ATTRIBUTE'">
        <app-select-parameter
            class="mb-2"
            [title]="'v2.parameter.data-format' | translate"
            [selectedParameterId]="parameter.linkedDataFormatParameterId"
            (changeParameter)="onChangeLinkedDataFormatParameterId($event)"
            [root]="root">
        </app-select-parameter>
    </ng-container>
    <!-- DELETE AFTER OBJECT IS COMPLETE -->
    <ng-container *ngIf="parameter.type === 'DATA_FORMAT_ATTRIBUTES'">
        <app-select-parameter
            class="mb-2"
            [title]="'v2.parameter.data-format' | translate"
            [selectedParameterId]="parameter.linkedDataFormatParameterId"
            (changeParameter)="onChangeLinkedDataFormatParameterId($event)"
            [root]="root">
        </app-select-parameter>
    </ng-container>
    <ng-container *ngIf="parameter.type === 'TEMPLATE_PARAMS'">
        <app-select-parameter
            class="mb-2"
            [title]="'v2.parameter.template' | translate"
            [selectedParameterId]="parameter.linkedTemplateParameterId"
            (changeParameter)="onChangeLinkedTemplateParameterId($event)"
            [root]="root">
        </app-select-parameter>
    </ng-container>
    <ng-container *ngIf="parameter.type === 'ACTION_PARAMS'">
        <app-select-parameter
            class="mb-2"
            [title]="'v2.parameter.action' | translate"
            [selectedParameterId]="parameter.linkedActionParameterId"
            (changeParameter)="onChangeLinkedActionParameterId($event)"
            [root]="root">
        </app-select-parameter>
    </ng-container>

    <ng-container *ngIf="parameter.type === 'COMPOSED_LIST' || (parameter.type === 'ARRAY' && parameter.inputType === 'parameter')">
        <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.parameter.list.item-definition' | translate }}</h4>
        <app-parameters
            class="mb-2"
            [parameters]="parameter.parameters"
            [root]="root"
            [context]="context"
            [language]="language"
            [parentId]="parameter.id"
            (parametersUpdated)="onSubParametersUpdated($event)"
            (openTab)="this.openTab.emit($event)"></app-parameters>
        <label for="add-button" class="small-input-label" data-name="company-name">{{ 'v2.parameter.list.addbutton' | translate }}</label>
        <input class="small-input" type="text" id="add-button" name="add-button" autocomplete="off" formControlName="composedButtonText" />
    </ng-container>
    <ng-container *ngIf="parameter.type === 'COMPOSED' || (parameter.type === 'OBJECT' && parameter.inputType === 'parameter')">
        <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.parameter.object.parameters' | translate }}</h4>
        <app-parameters
            class="mb-2"
            [parameters]="parameter.parameters"
            [root]="root"
            [context]="context"
            [language]="language"
            [parentId]="parameter.id"
            (parametersUpdated)="onSubParametersUpdated($event)"
            (openTab)="this.openTab.emit($event)"></app-parameters>
        <app-select-parameter
            class="mb-2"
            [title]="'v2.parameter.object.titleparam' | translate"
            [selectedParameterId]="parameter.composedTitleSubArgumentId"
            (changeParameter)="onChangeComposedTitleSubArgumentId($event)"
            [root]="root">
        </app-select-parameter>
    </ng-container>
    <ng-container *ngIf="parameter.type === 'SELECT_ONE'">
        <hr class="seperator" />
        <app-parameter-options
            [options]="parameter.options"
            [parameter]="parameter"
            (changeParameters)="onOptionsUpdated($event)"></app-parameter-options>
    </ng-container>
    <ng-container *ngIf="parameters.length > 1">
        <hr class="seperator" />
        <app-select-parameters
            class="mb-2"
            [title]="'v2.parameter.hideifempty' | translate"
            [parameters]="parameters"
            [root]="root"
            [selectedParameterIds]="parameter.hideIfEmptyParameterIds"
            (change)="onHideIfEmptyParameterIdsChange($event)"></app-select-parameters>
    </ng-container>
    <ng-container *ngIf="parameterDefaultArgument && parameterDefaultArgument.type">
        <hr class="seperator" />
        <argument-value-picker
            class="mb-2 block"
            [argument]="parameterDefaultArgument"
            [arguments]="[]"
            (argumentUpdated)="onDefaultArgumentUpdated($event)"
            [onlyLiteralValues]="true"
            (openTab)="this.openTab.emit($event)"></argument-value-picker>
    </ng-container>
</div>
