import 'reflect-metadata';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'company-selector-all',
    styleUrls: ['company-selector-all.dialog.scss'],
    templateUrl: 'company-selector-all.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class CompanySelectorAllDialog implements OnInit {
    @Input() companies: CompanyDto[];

    @Input() lastUsedCompanies: CompanyDto[];

    @Input() selectedCompany: CompanyDto;

    selectWorkspaceForm: FormGroup;

    filteredCompanies$: Observable<CompanyDto[]>;

    constructor(
        public dialogRef: MatDialogRef<CompanySelectorAllDialog>,
        public fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initWorkspaceSelectorForm();
        this.selectedCompany = this.data.selectedCompany;
        this.companies = this.data.companies;
        this.lastUsedCompanies = this.data.lastUsedCompanies;
        this.filteredCompanies$ = this.selectWorkspaceForm.get('filterName').valueChanges.pipe(
            startWith(null),
            switchMap(filterValue => {
                if (filterValue && filterValue !== '') {
                    return of(
                        this.companies.filter(company => company && company.companyName.toLowerCase().startsWith(filterValue.toLowerCase()))
                    );
                } else {
                    return of(this.companies);
                }
            })
        );
    }

    initWorkspaceSelectorForm() {
        this.selectWorkspaceForm = this.fb.group({
            filterName: [''],
        });
    }

    onCompanySelect(company: CompanyDto) {
        this.dialogRef.close(company);
    }
}
