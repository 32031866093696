<mat-dialog-content class="!p-4">
    <h1>Buy AI Credits</h1>
    <label class="small-input-label">Please decide how many AI credits you wish to purchase at €1 for 1000K.</label>
    <input
        placeholder="Amount of credits (min: 10K)"
        class="small-input"
        type="number"
        id="amount-of-credits"
        name="amount-of-credits"
        autocomplete="off"
        [min]="10000"
        [(ngModel)]="amountOfCredits" />
    <input
        placeholder="Coupon code"
        class="small-input"
        type="text"
        id="coupon-code"
        name="coupon-code"
        autocomplete="off"
        [(ngModel)]="couponCode" />
    <button class="primary-button button-large flex justify-center gap-2 mb-2" (click)="initiateBuy()">
        <mat-icon data-name="general-info-tab" class="example-tab-icon">public</mat-icon>
        Buy
    </button>
    <button class="secondary-button button-large flex justify-center gap-2 mb-2" (click)="closeDialog()">
        <mat-icon data-name="general-info-tab" class="example-tab-icon">public</mat-icon>
        Cancel
    </button>
</mat-dialog-content>
