<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="p-4" [formGroup]="formGroup">
        <h1 class="!mb-2">Create Data-format</h1>
        <div class="choice-grid">
            <div class="choice-card" [class.choice-card-selected]="selectedType === 'BLANK'" (click)="onSelectType('BLANK')">
                <mat-icon>api</mat-icon>
                <span class="choice-card-title">Blank</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_FROM_TEXT'"
                (click)="onSelectType('GENERATE_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">From text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_FROM_CSV'"
                (click)="onSelectType('GENERATE_FROM_CSV')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">From csv</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_FROM_JSON'"
                (click)="onSelectType('GENERATE_FROM_JSON')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">From json</span>
            </div>
        </div>
        <ng-container *ngIf="selectedType === 'BLANK' || selectedType === 'GENERATE_FROM_CSV' || selectedType === 'GENERATE_FROM_JSON'">
            <label for="name" class="small-input-label">Name</label>
            <input
                class="small-input"
                type="text"
                id="name"
                name="name"
                data-name="api-name"
                required
                cdkFocusInitial
                formControlName="name"
                autocomplete="off" />
        </ng-container>
        <ng-container *ngIf="selectedType === 'GENERATE_FROM_TEXT'">
            <label for="datadescription" class="small-input-label">Describe your data-format(s)</label>
            <textarea
                class="small-input"
                type="text"
                id="datadescription"
                name="datadescription"
                formControlName="datadescription"
                autocomplete="off"></textarea>
        </ng-container>
        <ng-container *ngIf="selectedType === 'GENERATE_FROM_CSV'">
            <label for="datadescription" class="small-input-label">Upload your csv file</label>
            <div
                (uploadOutput)="onUploadOutput($event)"
                [ngClass]="{ 'is-drop-over': dragOver }"
                [options]="options"
                [style.visibility]="this.files && this.files.length >= 1 ? 'hidden' : 'visible'"
                [style.height]="this.files && this.files.length >= 1 ? '0px' : '200px'"
                [uploadInput]="uploadInput"
                class="drop-container mb-2"
                ngFileDrop>
                <h1 class="!mb-2">{{ 'v2.media.edit.dropfile' | translate }}</h1>
                <label for="file-upload" class="upload-button primary-button button-large"><mat-icon>folder</mat-icon>Browse files</label>
                <input
                    type="file"
                    id="file-upload"
                    class="hidden"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput" />
            </div>
        </ng-container>

        <ng-container *ngIf="selectedType === 'GENERATE_FROM_JSON'">
            <label for="datadescription" class="small-input-label">Copy & paste your JSON here:</label>
            <textarea
                class="small-input"
                type="text"
                id="datadescription"
                name="datadescription"
                formControlName="datadescription"
                autocomplete="off"></textarea>
        </ng-container>
        <div class="flex justify-end gap-1">
            <button
                class="primary-button button-large"
                data-name="save"
                type="submit"
                (click)="
                    dialogRef.close({
                        create: true,
                        type: selectedType,
                        dataformatdescription: this.formGroup.controls['datadescription'].value,
                        name: this.formGroup.controls['name'].value,
                        file: this.files && this.files.length > 0 ? this.files[0] : null,
                    })
                ">
                <mat-icon>add_circle</mat-icon>
                Create Data-format
            </button>
            <button class="secondary-button button-large" data-name="cancel" type="button" (click)="dialogRef.close({ create: false })">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
