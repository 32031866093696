import { FormModule } from './components/form/form.module';
import { LayoutModule } from './components/layout/layout.module';
import { ConfirmDialog } from './lib/confirmdialog/confirm.dialog.lib';
import { DateUtil } from './lib/date/date';
import { Base64Functions } from './lib/base64/base64';
import { GUIDFunctions } from '../../../../../libs/shared/utils/src/lib/guid/guid';
import { NgModule } from '@angular/core';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { CodexMaterialModule } from './components/material/codex.module';
import { TagsToStringPipe } from './pipes/tags-to-string.pipe';
import { PreventDoubleClickPipe } from './pipes/prevent-double-click.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AvatarModule } from 'ngx-avatars';
import { SlugDirective } from './directive/slug.directive';
import { MaterialElevationDirective } from './directive/elevation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFloatUiModule } from 'ngx-float-ui';

@NgModule({
    imports: [
        LayoutModule,
        FormModule,
        HttpClientModule,
        NgxFloatUiModule.forRoot(), // was for root
        AvatarModule,
    ],
    declarations: [
        EnumToArrayPipe,
        TagsToStringPipe,
        PreventDoubleClickPipe,
        StripHtmlPipe,
        TruncatePipe,
        SlugDirective,
        MaterialElevationDirective,
    ],
    providers: [GUIDFunctions, Base64Functions, DateUtil, ConfirmDialog],
    exports: [
        LayoutModule,
        FormModule,
        FormsModule,
        AvatarModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        NgxFloatUiModule,
        CodexMaterialModule,
        HttpClientModule,
        EnumToArrayPipe,
        TagsToStringPipe,
        PreventDoubleClickPipe,
        StripHtmlPipe,
        TruncatePipe,
        SlugDirective,
        MaterialElevationDirective,
    ],
})
export class CommonsModule {}
