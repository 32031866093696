<ng-container *ngIf="{ user: user$ | async } as observables">
    <div class="generative-message w-full">
        <div class="flex-col flex items-end">
            <div class="generative-message-sender-name">{{ observables.user?.firstname }} {{ observables.user?.name }}</div>
            <div class="generative-message-text-wrapper">
                <div class="generative-message-text">{{ message.prompt }}</div>
            </div>
        </div>
    </div>
</ng-container>
