import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[hexCharacters]',
    standalone: false,
})
export class HexCharactersDirective {
    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        return new RegExp('^[#0-9a-fA-F]$').test(event.key);
    }
}
