import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Invocation } from '../../../../../../data-access/editor/src/lib/action/model/invocation';
import { Action } from '../../../../../../data-access/editor/src/lib/action/model/action';
import { Argument, showArgumentInput } from '../../../../../../data-access/editor/src/lib/arguments/argument';
import { Scope } from '../../../../../../data-access/editor/src/lib/action/model/scope';
import { TabDefinition } from '../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';

@Component({
    selector: 'app-arguments',
    templateUrl: './action-edit-invocation-arguments.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditInvocationArgumentsComponent implements OnInit {
    @Input()
    invocation!: Invocation;

    @Input()
    language!: string;

    @Input()
    action!: Action;

    @Input()
    set scope(scope: Scope) {
        this._scope = scope;
    }

    @Input()
    _scope!: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();

    @Output()
    openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    onArgumentUpdated(event: { argument: Argument }) {
        const invocation = this.action.program.invocationMap.get(this.invocation.id);
        if (invocation) {
            const argument = invocation.arguments.find(argument => argument.id === event.argument.id);
            if (argument) {
                argument.value = event.argument.value;
                argument.subArguments = event.argument.subArguments;
                this.invocationUpdated.emit({
                    invocation: this.invocation,
                    action: this.action,
                });
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    identifyArgument(index: number, item: Argument): string {
        return item.id;
    }

    protected readonly showArgumentInput = showArgumentInput;
}
