import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument, OverviewActionDto, ParameterOption, Scope } from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';

@Component({
    selector: 'argument-select-one-input',
    templateUrl: './argument-select-one-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentSelectOneInputComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    actions!: Observable<OverviewActionDto[]>;

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private readonly logger: LoggerService
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    ngOnInit(): void {
        this.initForm();
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            value: [this.removeQuotesIfPresent(this._argument.value), this._argument.parameter?.required ? Validators.required : null],
        });

        this.subscriptions.add(
            this.formGroup
                .get('value')
                ?.valueChanges.pipe(debounceTime(1000))
                .subscribe(value => this.onChange(value))
        );
    }

    updateForm(): void {
        this.formGroup?.get('value')?.setValue(this._argument.value);
    }

    onChange(value: string) {
        this.logger.info(value);
        this._argument.value = `'${value}'`;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    identifyOption(index: number, item: ParameterOption): string {
        return item.id;
    }
}
