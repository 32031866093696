import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-company-edit-api-security',
    templateUrl: './company-edit-api-security.component.html',
    standalone: false,
})
export class CompanyEditApiSecurityComponent {
    @Input()
    company: CompanyDto;

    @Input()
    companySecurity: CompanySecurityDto;

    @Input()
    apiSecret: string;

    @Output()
    companySecurityChanged: EventEmitter<CompanySecurityDto> = new EventEmitter<CompanySecurityDto>();

    @Output()
    viewSecret: EventEmitter<{ companySecurity: CompanySecurityDto; environment: string }> = new EventEmitter<{
        companySecurity: CompanySecurityDto;
        environment: string;
    }>();

    chosenEnvironment: string = 'DEV';

    onCompanySecurityChanged() {
        this.companySecurityChanged.emit(this.companySecurity);
    }

    onViewSecret(environment: string) {
        this.viewSecret.emit({ companySecurity: this.companySecurity, environment: environment });
    }

    onChangeEnvironment($event: { label: string; value: string }) {
        this.chosenEnvironment = $event.value;
        this.apiSecret = null;
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
