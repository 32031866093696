import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BackgroundChanged } from './background-changed.event';
import { BackgroundStyleDto, ColorV2 } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-background-picker',
    templateUrl: './background-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class BackgroundPickerComponent {
    //well dan maar wat comments.
    backgroundAttachments: string[] = ['scroll', 'fixed', 'local', 'initial', 'inherit'];

    backgroundClip: string[] = ['border-box', 'padding-box', 'content-box', 'initial', 'inherit'];

    backgroundOrigin: string[] = ['border-box', 'padding-box', 'content-box', 'initial', 'inherit'];

    backgroundRepeat: string[] = ['repeat', 'repeat-x', 'repeat-y', 'no-repeat', 'initial', 'inherit'];

    backgroundSize: string[] = ['auto', 'length', 'cover', 'contain', 'initial', 'inherit'];

    @Input()
    set backgroundStyle(backgroundStyle: BackgroundStyleDto) {
        if (backgroundStyle) {
            this._backgroundStyle = backgroundStyle;
        } else {
            this._backgroundStyle = this.defaultBackgroundStyle();
        }
    }

    _backgroundStyle: BackgroundStyleDto;

    @Output()
    changeBackground: EventEmitter<BackgroundChanged> = new EventEmitter();

    defaultBackgroundStyle(): BackgroundStyleDto {
        return {
            backgroundColor: null,
            backgroundImage: null,
            backgroundImageUrl: null,
            backgroundImageLinkType: 'media-library',
            backgroundImageMediaId: null,
            backgroundAttachment: null,
            backgroundPositionX: null,
            backgroundPositionY: null,
            backgroundRepeat: null,
            backgroundSize: null,
            backgroundClip: null,
            backgroundOrigin: null,
        };
    }

    onChangeBackgroundColor($event: ColorV2) {
        this._backgroundStyle.backgroundColorV2 = $event;
        if (!!$event.hex) {
            this._backgroundStyle.backgroundColor = $event.hex.value;
        }
        this.onChangeBackground();
    }

    onChangeBackgroundPositionX($event: any) {
        this._backgroundStyle.backgroundPositionX = $event.value;
        this.onChangeBackground();
    }

    onChangeBackgroundPositionY($event: any) {
        this._backgroundStyle.backgroundPositionY = $event.value;
        this.onChangeBackground();
    }

    onChangeMediaId($event: any) {
        this._backgroundStyle.backgroundImageMediaId = $event.mediaId;
        this.onChangeBackground();
    }

    onChangeBackground() {
        this.changeBackground.emit({
            background: {
                backgroundStyle: this._backgroundStyle,
            },
        });
    }
}
