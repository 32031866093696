import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { LoggerService } from '@backoffice/utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { backofficeEnvironment } from '@shared/environment';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Invocation, InvocationOutput, TabDefinition } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-edit-invocation-outputs',
    templateUrl: './action-edit-invocation-outputs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditInvocationOutputsComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    invocation: Invocation;

    @Input()
    action: Action;

    @Output()
    invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    @Input()
    language: string;

    formGroup: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        private log: LoggerService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['invocation']?.previousValue?.id !== changes['invocation']?.currentValue?.id &&
            !!changes['invocation']?.previousValue
        ) {
            this.initForm();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    initForm(): void {
        this.formGroup = this.fb.group({});
        this.invocation?.invocationOutputs?.forEach(invocationOutput => {
            if (invocationOutput.type !== 'ACTION_OUTPUTS') {
                this.formGroup.addControl(invocationOutput.outputId, this.fb.control(invocationOutput.value));
                this.subscriptions.add(
                    this.formGroup
                        .get(invocationOutput.outputId)
                        .valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                        .subscribe(value => {
                            invocationOutput.value = value;
                            this.onOutputChange({ output: invocationOutput });
                        })
                );
            }
        });
    }

    onDataFormatChange(event: { value: string }, output: InvocationOutput) {
        output.value = event.value;
        this.onOutputChange({ output: output });
    }

    onOutputChange(event: { output: InvocationOutput }): void {
        this.log.debug('(invocation outputs) on change output');
        if (
            this.action.program.invocationMap.get(this.invocation.id) &&
            this.action.program.invocationMap.get(this.invocation.id).invocationOutputs
        ) {
            this.action.program.invocationMap
                .get(this.invocation.id)
                .invocationOutputs.find(output => output.outputId === event.output.outputId).value = event.output.value;
            this.invocation.invocationOutputs.find(output => output.outputId === event.output.outputId).value = event.output.value;
            this.invocationUpdated.emit({ invocation: this.invocation, action: this.action });
        }
    }

    identifyOutput(index, item: InvocationOutput) {
        return item.outputId + this.invocation?.id;
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
