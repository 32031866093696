import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import { closeTab, closeTabs, registerTab, updateTab } from '../../../store/actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';

import { toFilter } from '@backoffice/data-access/editor';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { Page } from '@shared/data-access';
import { GroupEditorFacade } from '../facades/group-editor.facade';
import { GroupsService } from '../../services/groups.service';
import {
    clearGroupDialogFilter,
    createGroupSuccess,
    deleteGroup,
    deleteGroups,
    deleteGroupsSuccess,
    deleteGroupSuccess,
    fetchGroupError,
    fetchGroupSuccess,
    findGroup,
    groupDialogFacetsChanged,
    groupDialogFilterPluginsChanged,
    groupDialogPaginationChanged,
    groupDialogSearchTermChanged,
    loadGroupDialogData,
    loadGroupDialogSuccess,
    updateGroup,
    updateGroupSuccess,
} from '../actions/group-editor.actions';
import { createDeletedGroup } from '../../dto/group.dto';
import { OverviewGroupDto } from '../../dto/overview-group.dto';
import { groupEditorSelectors } from '../selectors/group-editor.selectors';

@Injectable()
export class GroupEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: GroupEditorFacade,
        private readonly groupsService: GroupsService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearGroupDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteGroups),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.groupsService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteGroupsSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteGroupsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'group' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteGroup),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.groupsService.delete(id, companyId, applicationId).pipe(map(() => deleteGroupSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findGroup),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(groupEditorSelectors.byId(id)),
            ]),
            filter(([_dataformat, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchGroupSuccess({ group: detail }));
                } else {
                    return this.groupsService.fetchByGroupId(id, companyId, applicationId).pipe(
                        map(result => fetchGroupSuccess({ group: result })),
                        catchError(() => of(fetchGroupError({ group: createDeletedGroup(id) })))
                    );
                }
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteGroupsSuccess,
                loadGroupDialogData,
                groupDialogPaginationChanged,
                groupDialogSearchTermChanged,
                groupDialogFacetsChanged,
                groupDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.groupsService.findAll(applicationId, companyId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewGroupDto>) => loadGroupDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGroup),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ group }, { id: companyId }, { id: applicationId }]) =>
                this.groupsService.update(group, companyId, applicationId).pipe(map(() => updateGroupSuccess({ group })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteGroupSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'group' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateGroupSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.usermanagement.groups.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createGroupSuccess),
            filter(({ id, openTab }) => openTab),
            map(({ id }) => registerTab({ definition: { type: 'group', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGroupSuccess, fetchGroupSuccess),
            map(({ group }) => {
                const { id: typeId, name, iconName: icon } = group;
                return updateTab({ definition: { type: 'group', typeId, name, icon } });
            })
        )
    );
}
