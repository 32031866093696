import 'reflect-metadata';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'search-facets-loading-placeholder',
    styleUrls: ['./search-facets-loading-placeholder.scss'],
    template: `
        <mat-card>
            <ul>
                <li>
                    <div fxFlex="1 1 20px" class="search-facet-checkbox-placeholder loading"></div>
                    <div class="search-facet-name-placeholder loading"></div>
                </li>
                <li>
                    <div fxFlex="1 1 20px" class="search-facet-checkbox-placeholder loading"></div>
                    <div class="search-facet-name-placeholder loading"></div>
                </li>
                <li>
                    <div fxFlex="1 1 20px" class="search-facet-checkbox-placeholder loading"></div>
                    <div class="search-facet-name-placeholder loading"></div>
                </li>
                <li>
                    <div fxFlex="1 1 20px" class="search-facet-checkbox-placeholder loading"></div>
                    <div class="search-facet-name-placeholder loading"></div>
                </li>
            </ul>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SearchFacetsLoadingPlaceholderComponent {}
