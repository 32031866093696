import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionExecutionOutput } from '../../../../../../../editor/data-access/action/src/lib/interface/action-execution-output.dto';
import { ActionTestOutputAssertion } from '../../../../../../../data-access/editor/src/lib/action/dto/action-test-output-assertion.dto';
import { ActionTestComponent } from '../action-test/action-test.component';
import { ActionTestOutputAssertionResult } from '../../../../../../../data-access/editor/src/lib/action/dto/action-test-assertion-result.dto';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-action-outputs',
    templateUrl: './action-outputs.component.html',
    standalone: false,
})
export class ActionOutputsComponent {
    @Input()
    outputs: ActionExecutionOutput[];

    @Input()
    outputAssertions: ActionTestOutputAssertion[] | undefined;

    @Input()
    assertionResults: ActionTestOutputAssertionResult[] | undefined;

    @Output()
    addAssertion: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    addOutputAsAssertion: EventEmitter<ActionExecutionOutput> = new EventEmitter<ActionExecutionOutput>();

    @Output()
    removeAssertion: EventEmitter<ActionTestOutputAssertion> = new EventEmitter<ActionTestOutputAssertion>();

    @Output()
    editAssertion: EventEmitter<ActionTestOutputAssertion> = new EventEmitter<ActionTestOutputAssertion>();

    editorOptions = {
        theme: 'vs-dark',
        autoIndent: true,
        readOnly: false,
        formatOnType: true,
        automaticLayout: true,
        language: 'json',
    };

    isImage(file: { type: string }): boolean {
        return file.type && file.type.startsWith('image/');
    }

    initiateMonaco(editor) {
        setTimeout(() => {
            editor
                .getAction('editor.action.formatDocument')
                .run()
                .then(() => editor.updateOptions({ readOnly: true }));
        }, 500);
    }

    isValid(output: ActionExecutionOutput) {
        let assertion = this.assertionResults?.find(assertionResult => assertionResult.output.name === output.name);
        return !assertion || assertion?.assertionResult === 'SUCCESS';
    }

    getMessage(output: ActionExecutionOutput) {
        let assertion = this.assertionResults?.find(assertionResult => assertionResult.output.name === output.name);
        if (!!assertion) {
            return assertion.reason;
        } else {
            return '';
        }
    }
    onEditAssertion(output: ActionExecutionOutput) {
        let assertion = this.outputAssertions?.find(assertionResult => assertionResult.output.name === output.name);
        this.editAssertion.emit(assertion);
    }

    onRemoveAssertion(output: ActionExecutionOutput) {
        let assertion = this.outputAssertions?.find(assertionResult => assertionResult.output.name === output.name);
        this.removeAssertion.emit(assertion);
    }
    isInvalid(output: ActionExecutionOutput) {
        return !this.isValid(output);
    }

    invalidReasons(output: ActionExecutionOutput): string[] {
        let assertion = this.assertionResults?.find(assertionResult => assertionResult.output.name === output.name);
        return [assertion?.reason];
    }

    hasAssertion(output: ActionExecutionOutput) {
        let assertion = this.outputAssertions?.find(assertion => assertion.output.name === output.name);
        return assertion;
    }

    protected readonly JSON = JSON;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
