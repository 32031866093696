import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { TabDefinition } from '@backoffice/data-access/editor';

@Component({
    selector: 'argument-dataformat-picker',
    templateUrl: './argument-dataformat-picker.component.html',
    standalone: false,
})
export class ArgumentDataformatPickerComponent {
    @Input() arguments: Argument[];
    @Input() argument: Argument;
    @Input() contextId!: string;
    @Input() scope!: Scope;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    constructor() {}
    onChange(value: any) {
        this.argument.value = value;
        this.argumentUpdated.emit({ argument: this.argument });
    }
}
